import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TaskEntity } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-bow-details-header',
  templateUrl: './bow-details-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowDetailsHeaderComponent {
  @Input({ required: true }) public selectedBowItem!: TaskEntity | null;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter();
  @Output() aircraftAttributesViewEvent: EventEmitter<void> =
    new EventEmitter();

  public close(): void {
    this.closeEvent.emit();
  }

  public openAircraftAttributesView(): void {
    this.aircraftAttributesViewEvent.emit();
  }
}
