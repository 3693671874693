import { AssignedMember, TeamAssignment } from './type-defs.model';

export interface TeamAssignmentDisplay extends TeamAssignment {
  DisplayName: string;
}
export interface Crew {
  CrewName: string;
  Station: string;
  CrewDisplayName: string;
  Members: AssignedMember[];
}
