<div class="modal-header">
  <span>Crew Manager</span>
  <div class="display-flex">
    <div class="display-flex aa-mr-2">
      <span class="bold-7 aa-px-2">Station</span>
      <p-dropdown
        [options]="stations"
        panelStyleClass="dropdown-task"
        styleClass="dropdown-width cm-station"
        (onChange)="stationChanges()"
        [(ngModel)]="selectedStation"
      ></p-dropdown>
    </div>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-secondary p-button-text cross"
      (onClick)="close()"
    ></p-button>
  </div>
</div>
<form [formGroup]="crewManagerForm" (ngSubmit)="saveChanges()">
  <div class="modal-body">
    <div class="row aa-m-0">
      <div class="col-10">
        <label for="crew" class="bold-7 aa-m-0 aa-py-2">Crew List</label>
        <div class="row col-12">
          <div class="col-7 aa-px-0">
            <p-dropdown
              *ngIf="!isCreateCrew"
              formControlName="selectedCrewCtrl"
              [options]="allCrews"
              optionLabel="DisplayName"
              panelStyleClass="dropdown-task"
              styleClass="dropdown-width bold-7"
              [placeholder]="allCrews.length === 0 ? 'Create New Crew' : ''"
              (ngModelChange)="crewChanges($event)"
            ></p-dropdown>
            <input
              *ngIf="isCreateCrew"
              type="text"
              pInputText
              placeholder="Crew Name Required"
              class="w-100"
              #createCrew
              autocapitalize="words"
              formControlName="createCrewCtrl"
            />
          </div>
          <div class="row col-5">
            <p-button
              label="Create Crew"
              *ngIf="!isCreateCrew && !isRemoveAll"
              styleClass="p-button-primary p-button-raised aa-mx-2"
              (onClick)="createCrew()"
            ></p-button>
            <p-button
              label="Cancel"
              *ngIf="isCreateCrew || isRemoveAll"
              styleClass="p-button-danger p-button-raised aa-mx-2"
              (onClick)="cancelCreateCrewOrRemoveAll()"
            ></p-button>
            <button
              pButton
              *ngIf="
                (!isCreateCrew && enableSaveChanges && allCrews.length > 0) ||
                (isCreateCrew && getCreateCrewControls.value.length > 0)
              "
              label="Save Changes"
              class="p-button-raised p-button-success"
              type="submit"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-2 cm-all-emp">
        <button
          (click)="showAllCrewMem()"
          pButton
          class="p-button-raised p-button-ripple"
          type="button"
          [disabled]="
            getSelectedCrewControls.value &&
            !isCreateCrew &&
            (getSelectedCrewControls.value.OriginalOwner.EmployeeId !==
              currentUser.EmployeeId ||
              getSelectedCrewControls.value.CurrentOwner)
          "
        >
          <fa-icon [icon]="faUserPlus" size="lg"></fa-icon>
        </button>
      </div>
    </div>
    <div class="col-12 aa-mt-2 aa-mb-8">
      <div class="d-flex flex-row-reverse">
        <div class="col-12 bg-shade-gray" *ngIf="!isCreateCrew">
          <p-button
            *ngIf="
              getSelectedCrewControls.value &&
              currentUser &&
              getSelectedCrewControls.value.OriginalOwner.EmployeeId !==
                currentUser.EmployeeId
            "
            (click)="ownershipConfirm()"
            styleClass="p-button-ripple p-button-text"
          >
            <fa-icon
              [icon]="
                getSelectedCrewControls.value.CurrentOwner
                  ? faArrowTurnUp
                  : faArrowTurnDown
              "
            ></fa-icon
            >&nbsp;
            {{
              getSelectedCrewControls.value.CurrentOwner
                ? 'Release Ownership'
                : 'Take Ownership'
            }}
          </p-button>
          <p-button
            *ngIf="
              getSelectedCrewControls.value &&
              currentUser &&
              getSelectedCrewControls.value.OriginalOwner.EmployeeId ===
                currentUser.EmployeeId
            "
            (click)="shareConfirm()"
            styleClass="p-button-ripple p-button-text"
          >
            <fa-icon
              [icon]="
                getSelectedCrewControls.value.Shared
                  ? faArrowTurnDown
                  : faArrowTurnUp
              "
            ></fa-icon
            >&nbsp;
            {{ getSelectedCrewControls.value.Shared ? 'No Share' : 'Share' }}
          </p-button>
          <!-- for 'Remove all', Using both *ngIf and hidden because when 'CurrentOwner === null' condition placed in ngIf angular was not detecting the change -->
          <p-button
            *ngIf="
              getSelectedCrewControls.value &&
              getSelectedCrewControls.value.OriginalOwner.EmployeeId ===
                currentUser?.EmployeeId
            "
            (click)="clearCrewMember()"
            icon="pi pi-times"
            label="Remove All"
            styleClass="p-button-ripple p-button-text"
            [hidden]="getSelectedCrewControls.value.CurrentOwner ? true : false"
          ></p-button>
          <!-- for 'Delete Crew', Using both *ngIf and hidden because when 'CurrentOwner === null' condition placed in ngIf angular was not detecting the change -->
          <p-button
            *ngIf="
              getSelectedCrewControls.value &&
              getSelectedCrewControls.value.OriginalOwner.EmployeeId ===
                currentUser?.EmployeeId
            "
            (click)="deleteCrewConfirm()"
            icon="pi pi-trash"
            label="Delete Crew"
            styleClass="p-button-ripple p-button-text"
            [hidden]="getSelectedCrewControls.value.CurrentOwner ? true : false"
          ></p-button>
        </div>

        <div class="cm-right-panel" *ngIf="visibleRightPanel" @fadeIn @fadeOut>
          <lm-apps-all-crew-mem
            [allCrewMem]="filteredCrewMem"
            (dragStartEvent)="dragStart($event)"
            (dragEndEvent)="dragEnd()"
          ></lm-apps-all-crew-mem>
        </div>
      </div>

      <div
        class="cm-crew-mem cc-hr"
        formArrayName="tableRows"
        pDroppable="dragCrews"
        (onDrop)="drop()"
      >
        <p-table
          [value]="getTableRowsFormControls.controls"
          (sortFunction)="customSort($event)"
          [customSort]="true"
          styleClass="p-datatable-sm"
          #crewMembersTable
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>
              <th pSortableColumn="idCtrl" class="col-2">
                EmpID<p-sortIcon field="idCtrl"></p-sortIcon>
              </th>
              <th pSortableColumn="firstNameCtrl" class="col-3">
                Name<p-sortIcon field="firstNameCtrl"></p-sortIcon>
              </th>
              <th class="col-2">Status</th>
              <th class="col-2">Online</th>
              <th class="col-1"></th>
              <th class="col-4"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-group let-i="rowIndex">
            <tr [formGroup]="group" [pReorderableRow]="i">
              <td pReorderableRowHandle>
                <fa-icon [icon]="faGripDotsVertical"></fa-icon>
              </td>
              <td pReorderableRowHandle class="col-2">
                {{ group.controls.idCtrl.value }}
              </td>
              <td pReorderableRowHandle class="col-3">
                {{ group.controls.firstNameCtrl.value | titlecase }}&nbsp;{{
                  group.controls.lastNameCtrl.value | titlecase
                }}
              </td>
              <td pReorderableRowHandle class="col-2">
                <p-dropdown
                  formControlName="statusCtrl"
                  [options]="statusList"
                  panelStyleClass="dropdown-task"
                  (onChange)="statusChange($event.value, i)"
                ></p-dropdown>
              </td>
              <td pReorderableRowHandle class="col-2">
                <lm-apps-lmo-ui-online-status
                  [isOnline]="
                    group.controls.idCtrl.value
                      | getOnlineStatus: allCrewMemberOnlineStatus
                  "
                ></lm-apps-lmo-ui-online-status>
              </td>
              <td pReorderableRowHandle class="col-1">
                <p-button
                  *ngIf="
                    isCreateCrew ||
                    (getSelectedCrewControls.value &&
                      getSelectedCrewControls.value.OriginalOwner.EmployeeId ===
                        currentUser.EmployeeId &&
                      !getSelectedCrewControls.value.CurrentOwner)
                  "
                  styleClass="p-button-danger p-button-text p-button-rounded p-button-raised"
                  (onClick)="deleteRow(i)"
                  icon="pi pi-times"
                ></p-button>
              </td>
              <td class="col-4"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</form>
<p-confirmDialog #cd [style]="{ width: '40vw' }">
  <ng-template pTemplate="footer">
    <button
      type="button"
      pButton
      class="p-button-raised"
      icon="pi pi-check"
      label="Yes"
      (click)="cd.accept()"
    ></button>
    <button
      type="button"
      pButton
      class="p-button-raised p-button-secondary"
      icon="pi pi-times"
      label="No"
      (click)="cd.reject()"
    ></button>
  </ng-template>
</p-confirmDialog>
