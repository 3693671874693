import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAlternateTasks from './+state/alternate-tasks.reducer';
import { AlternateTasksEffects } from './+state/alternate-tasks.effects';
import { ALTERNATE_TASKS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      ALTERNATE_TASKS_FEATURE_KEY,
      fromAlternateTasks.reducer
    ),
    EffectsModule.forFeature([AlternateTasksEffects]),
  ],
})
export class LmoUiDataAccessAlternateTasksModule {}
