import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { FeatureFlagsQueryGQL } from './query';
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private _featureFlagsQueryGQL: FeatureFlagsQueryGQL) {}
  getAllFeatureFlags() {
    return this._featureFlagsQueryGQL.fetch().pipe(
      map(({ data }) => {
        return data?.FeatureFlags || [];
      })
    );
  }
}
