import {
  Flight,
  FlightNotification,
  FlightNotificationEntity,
  FlightsEntity,
} from '@lm-apps/lmo/ui/data';
import { MaintenancePriorities } from '@lm-apps/lmo/shared/enums';
import dayjs from 'dayjs';
export function filterNotifications(flight: Flight): FlightNotification[];
export function filterNotifications(
  flight: FlightsEntity
): FlightNotificationEntity[];
export function filterNotifications(flight: Flight | FlightsEntity) {
  const notifications = flight.Notifications;
  if (!notifications) return [];

  const isGoingToBeOnGoundMoreThan4Hours =
    !flight.Departure ||
    !flight.Arrival ||
    dayjs(
      flight.Departure.ActualDeparture ?? flight.Departure.ScheduledDeparture
    ).diff(
      flight.Arrival.ActualArrival ?? flight.Arrival.ScheduledArrival,
      'minutes'
    ) >
      4 * 60
      ? true
      : false;

  const filteredNotifications = notifications.filter((notification) => {
    let showNotification: boolean;

    switch (notification.Label) {
      case MaintenancePriorities.RSMG:
        //shown for right start leg and on ground for less than 4 hours
        showNotification =
          flight.Departure &&
          flight.Departure.IsRightStart &&
          !isGoingToBeOnGoundMoreThan4Hours
            ? true
            : false;
        break;
      case MaintenancePriorities.RSWK:
        //shown for right start leg and on ground for more than 4 hours
        showNotification =
          flight.Departure &&
          flight.Departure.IsRightStart &&
          isGoingToBeOnGoundMoreThan4Hours &&
          !flight.Departure.IsEtopsFlight
            ? true
            : false;
        break;
      case MaintenancePriorities.WKUP:
        //shown for right start leg and on ground for more than 4 hours
        showNotification =
          flight.Departure &&
          !flight.Departure.IsRightStart &&
          isGoingToBeOnGoundMoreThan4Hours
            ? true
            : false;
        break;
      case MaintenancePriorities.MG:
        showNotification = flight.Equipment.IsEtopsCertified;
        break;
      default:
        showNotification = true;
    }

    if (!showNotification && flight.AllTasks) {
      const index = flight.AllTasks.findIndex(
        (task) => task.NotificationId == notification.Id
      );
      if (index > -1) {
        //Remove the corresponding task
        flight.AllTasks.splice(index, 1);
      }
    }
    return showNotification;
  });

  return filteredNotifications;
}
