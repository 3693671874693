import { createAction, props } from '@ngrx/store';
import {
  SaveUserInput,
  SaveUserSource,
  SortableCrewColumn,
  TeamAssignment,
  User,
} from '@lm-apps/lmo/ui/data';

export const init = createAction('[User/API] Init');

export const updateStations = createAction(
  '[User] Update Stations',
  props<{ stations: string[] }>()
);

export const throwError = createAction(
  '[User/API] API request failed',
  props<{ error: any }>()
);

export const loadUserSuccess = createAction(
  '[User/API] Load user success',
  props<{ user: User }>()
);

export const updateUser = createAction(
  '[User] Update User',
  props<{ saveUserInput: SaveUserInput; source: SaveUserSource }>()
);

export const updateCurrentCrew = createAction(
  '[User] Update Current Crew',
  props<{ crew: TeamAssignment | null }>()
);

export const resetCurrentCrew = createAction('[User] Reset Current Crew');

export const userAcknowledgeNotice = createAction(
  '[User] User Acknowledge notice',
  props<{ noticeId: string }>()
);

export const loadUserAcknowledgeNoticeSuccess = createAction(
  '[User/API] User successfully acknowledged notice'
);

export const sortCrew = createAction(
  '[Crew] Update sort details',
  props<{ column: SortableCrewColumn }>()
);
