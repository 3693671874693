import {
  AssignedMember,
  MaintenanceTaskInput,
  SpecialInstruction,
} from '../models/type-defs.model';

export interface AdditionalTaskDetail {
  ReferenceNumber?: string;
  IsOpsOrRobOrRem: boolean;
  DeferralDescription?: string;
  HasTask: boolean;
  IsQuickFix: boolean;
  SpecialInstructions: Array<SpecialInstruction>;
  AssignedMembers: Array<AssignedMember>;
  UserName: string;
  ChangedOn: Date;
  IsBowTaskCreatedOnTheFly: boolean;
}
export interface MaintenanceTaskDisplay {
  MaintenanceTaskInput: MaintenanceTaskInput;
  AdditionalTaskDetail: AdditionalTaskDetail;
}
