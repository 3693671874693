<span
  class="maint-item text-clickable"
  [class.rhs-tile-format]="isRhs"
  [ngClass]="notification | getPriorityClassName"
  *ngIf="!notification?.HasTask"
  (click)="onClick($event)"
>
  <ng-container *ngTemplateOutlet="oilNotificationIcon"></ng-container>
  <ng-container *ngTemplateOutlet="cubaNotificationIcon"></ng-container>
  {{
    (notification | getPriorityClassName | getLabelPrefix) +
      (notification.DisplayName ??
        (notification.Type?.replace('_', ' ') | lowercase | titlecase))
  }}
</span>
<span
  class="maint-item bold-7"
  [class.rhs-tile-format]="isRhs"
  [ngClass]="notification | getPriorityClassName"
  *ngIf="notification?.HasTask"
>
  <ng-container *ngTemplateOutlet="oilNotificationIcon"></ng-container>
  <ng-container *ngTemplateOutlet="cubaNotificationIcon"></ng-container>
  {{
    (notification | getPriorityClassName | getLabelPrefix) +
      notification.DisplayName || 'MEL'
  }}

  <fa-icon
    *ngIf="notification.Task?.IsOilService"
    [icon]="faOilCanDrip"
  ></fa-icon>
</span>

<ng-template #oilNotificationIcon>
  <span
    ><i
      *ngIf="notification.Type === ItemType.Oil"
      class="fa fa-tint vertical-align-middle"
    >
    </i>
  </span>
</ng-template>

<ng-template #cubaNotificationIcon>
  <span
    class="cuba-flag-svg-icon"
    *ngIf="notification.Type === ItemType.Cuba"
  ></span>
</ng-template>
