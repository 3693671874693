<section>
  <ng-container
    *ngIf="
      assignedMember &&
      (assignedMember | mechanicStatus: task.AssignedMembers || []) as status
    "
  >
    <span class="align-items-center vertical-align-top">
      {{ status }}
    </span>
    <span class="aa-ml-4">
      <p-button
        *ngIf="status === 'Assigned'"
        type="submit"
        label="Acknowledge"
        tooltip="Confirm receipt of this assignment."
        tooltipClass="my-task-status-tooltip"
        styleClass="p-button-raised my-task-status-button"
        (click)="save(TaskState.Acknowledged)"
      ></p-button>
      <p-button
        *ngIf="status === 'Acknowledged'"
        type="submit"
        label="Arrive"
        tooltip="Mark yourself as arrived at the aircraft."
        tooltipClass="my-task-status-tooltip"
        styleClass="p-button-raised my-task-status-button"
        (click)="save(TaskState.Arrived)"
      ></p-button>
      <p-button
        *ngIf="status === 'Arrived'"
        type="submit"
        label="Complete"
        tooltip="Confirm that the task is completed."
        tooltipClass="my-task-status-tooltip"
        styleClass="p-button-raised my-task-status-button"
        (click)="save(TaskState.Completed)"
      ></p-button>
    </span>
  </ng-container>
</section>
