import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Arrival, ArrivalStatusCode } from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'lm-apps-lmo-ui-cc-view-arrival',
  templateUrl: './arrival.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrivalComponent {
  @Input() arrival: Arrival | null = null;
  @Input() arrivalTime = '--:--';

  ArrivalStatusCode = ArrivalStatusCode;
}
