import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { RegionStations, Station } from '@lm-apps/lmo/ui/data';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lm-apps-stations-modal',
  templateUrl: './stations-modal.component.html',
})
export class StationsModalComponent implements OnInit {
  readonly NONMAINTENANCE = 'Non-Maintenance';
  readonly SEARCHSTATION = 'searchStation';
  readonly REGIONS = 'regions';
  readonly STATIONS = 'stations';
  readonly SELECT_ALL = 'Select All';
  readonly DESELECT_ALL = 'Deselect All';
  @Input() isMOC!: boolean;
  @Input() filteredStations!: string[];
  @Input() allRegions: RegionStations[] | null = [];

  @Output() updateFilteredStations: EventEmitter<string[]> = new EventEmitter();
  selectedRegionMenu: RegionStations | null = null;
  stationSearch = '';
  form!: FormGroup;
  searchedStation: Station[] | null = null;
  stations: string[] = [];
  indeterminateRegion: string[] = [];
  selectedStations: string[] = [];

  filterCondition = (item: string) => item != this.NONMAINTENANCE;

  @ViewChild('searchStation', { static: false })
  set createFocus(element: ElementRef<HTMLInputElement>) {
    if (element) {
      setTimeout(() => {
        element.nativeElement.focus();
      }, 100);
    }
  }

  constructor(private formBuilder: FormBuilder, public modalRef: BsModalRef) {
    const form = this.formBuilder.group({
      isSelectAll: new FormControl(Boolean),
      regions: new FormControl(<string[]>[]),
      stations: new FormControl(this.stations),
      searchStation: new FormControl(null, [
        Validators.pattern("^[a-zA-Z -']+"),
        Validators.minLength(1),
        Validators.maxLength(3),
      ]),
    });
    this.form = form;
  }

  ngOnInit() {
    this.selectedStations = [...this.filteredStations];
    this.form.get(this.STATIONS)?.valueChanges.subscribe((stations) => {
      this.selectedStations = [...stations];
    });
    this.form.get('isSelectAll')?.setValue(false);
    this.stations.push(...this.filteredStations);
    if (this.filteredStations.length == 1) {
      const region = this.allRegions?.find((region) => {
        const index = region.Stations.findIndex(
          (sta) => sta.IataCode == this.filteredStations[0]
        );
        return index > -1;
      });
      this.selectedRegionMenu = region ? region : null;
    } else {
      this.selectedRegionMenu = this.allRegions ? this.allRegions[0] : null;
    }

    if (this.allRegions) {
      const regionsWithSelectAll = [];
      this.allRegions.forEach((region: RegionStations) => {
        if (this.isRegionSelectAll(region)) {
          this.form.controls['regions'].value.push(region.Name);
          regionsWithSelectAll.push(region.Name);
        }
      });
      if (regionsWithSelectAll.length === this.allRegions.length - 1) {
        this.selectAllRegion(true);
      }
    }

    this.setIndeterminateRegion();
  }

  isRegionSelectAll(region: RegionStations): boolean {
    if (this.filteredStations) {
      return region.Stations.every((station: Station) =>
        this.filteredStations.includes(station.IataCode)
      );
    }
    return false;
  }
  openStationsMenu(region: RegionStations) {
    this.form.get(this.SEARCHSTATION)?.patchValue('');
    this.searchedStation = null;
    this.selectedRegionMenu = region;
  }

  closeModal() {
    this.modalRef.hide();
  }

  removeStation(station: string) {
    const stationsControl = this.form.get(this.STATIONS);
    if (!stationsControl) {
      return; //or throw exception
    }
    const stations = stationsControl.value;
    const idx = stations.findIndex((sta: string) => sta == station);
    stations.splice(idx, 1);
    this.form.get(this.STATIONS)?.setValue(stations);
    this.setIndeterminateRegion();
  }

  saveChanges() {
    const selectedStations = this.form.get(this.STATIONS)?.value;
    this.updateFilteredStations.emit(selectedStations);
    this.modalRef.hide();
  }
  stationFilter() {
    const station = this.form.get(this.SEARCHSTATION)?.value.toUpperCase();
    if (station.length == 3) {
      const regionInfo = this.findRegionWithStationCode(station);
      const region = regionInfo?.region;
      if (region) {
        this.searchedStation = [region.Stations[regionInfo.index]];
        this.selectedRegionMenu = region;
      } else {
        this.selectedRegionMenu = null;
        this.searchedStation = [];
      }
    } else {
      this.searchedStation = null;
    }
  }
  onKeyEnter() {
    if (this.form?.valid) {
      const station =
        this.form.get(this.SEARCHSTATION)?.value.toUpperCase() || '';
      if (station.length === 3) {
        const regionInfo = this.findRegionWithStationCode(station);
        const stations = this.form.get(this.STATIONS)?.value || [];
        if (
          regionInfo?.region &&
          stations.findIndex((sta: string) => sta === station) == -1
        ) {
          this.form.get(this.STATIONS)?.setValue([...stations, station]);
        }
        this.searchedStation = null;
        this.form.get(this.SEARCHSTATION)?.patchValue('');
      }
    }
  }
  selectAllRegion(checked: boolean) {
    this.selectedRegionMenu = null;
    this.indeterminateRegion = [];
    if (checked) {
      const regions: Array<string> = this.form.get(this.REGIONS)?.value;

      this.form.get(this.REGIONS)?.setValue(
        this.allRegions?.reduce((filtered, region) => {
          if (region.Name != this.NONMAINTENANCE) {
            filtered.push(region.Name);
            //calling to check all the stations
            this.regionChange({ checked, value: region.Name });
          }
          return regions.includes(this.NONMAINTENANCE)
            ? [...filtered, this.NONMAINTENANCE]
            : filtered;
        }, new Array<string>())
      );
      this.form.get('isSelectAll')?.setValue(true);
    } else {
      this.form.get(this.REGIONS)?.setValue([]);
      this.form.get(this.STATIONS)?.setValue([]);
      this.form.get('isSelectAll')?.setValue(false);
    }
  }

  regionChange(event: { checked: boolean; value: string }) {
    const region = this.allRegions?.find((reg) => reg.Name === event.value);
    const selectedStations: Array<string> = this.form.get(this.STATIONS)?.value;

    if (event.checked) {
      region?.Stations.forEach((sta) => {
        if (!selectedStations.includes(sta.IataCode || '')) {
          selectedStations.push(sta.IataCode || '');
        }
      });
    } else {
      region?.Stations.forEach((sta) => {
        if (selectedStations.includes(sta.IataCode || '')) {
          const idx = selectedStations.findIndex((x) => x == sta.IataCode);
          selectedStations.splice(idx, 1);
        }
      });
    }
    this.form.get(this.STATIONS)?.setValue(selectedStations);
  }
  stationChange(event: { checked: boolean; value: string }) {
    //
    const selectedRegions = this.form.get(this.REGIONS)?.value || [];
    const selectedStations = this.form.get(this.STATIONS)?.value || [];

    const region = this.allRegions?.find((region) => {
      const index = region.Stations.findIndex(
        (sta) => sta.IataCode == event.value
      );
      return index > -1;
    });
    if (!event.checked) {
      if (region && selectedRegions.includes(region.Name)) {
        const idx = selectedRegions.findIndex(
          (reg: string) => reg == region.Name
        );
        selectedRegions.splice(idx, 1);
      }
    } else {
      let allStationSelected = true;
      for (const sta of region?.Stations || []) {
        if (!selectedStations.includes(sta.IataCode)) {
          allStationSelected = false;
          break;
        }
      }
      if (allStationSelected) {
        selectedRegions.push(region?.Name);
      }
    }
    this.form.get(this.REGIONS)?.setValue(selectedRegions);
    this.setIndeterminateRegion();
  }

  setIndeterminateRegion() {
    const selectedStations = this.form.get(this.STATIONS)?.value;

    this.allRegions?.find((region) => {
      let allStationChecked = true;
      let allStationUnchecked = true;

      region.Stations.forEach((sta) => {
        if (selectedStations.includes(sta.IataCode)) {
          allStationUnchecked = false;
        }
        if (!selectedStations.includes(sta.IataCode)) {
          allStationChecked = false;
        }
      });

      if (allStationChecked || allStationUnchecked) {
        if (this.indeterminateRegion.includes(region.Name)) {
          const index = this.indeterminateRegion.findIndex(
            (reg) => reg == region.Name
          );
          this.indeterminateRegion.splice(index, 1);
          this.indeterminateRegion = [...this.indeterminateRegion];
        }
      }

      if (!allStationChecked && !allStationUnchecked) {
        if (!this.indeterminateRegion.includes(region.Name)) {
          this.indeterminateRegion = [...this.indeterminateRegion, region.Name];
        }
      }
    });
  }

  private findRegionWithStationCode(station: string): {
    region: RegionStations | undefined;
    index: number;
  } {
    let index = -1;
    const region = this.allRegions?.find((region) => {
      index = region.Stations.findIndex((sta) => sta.IataCode == station);
      return index > -1;
    });
    return { region, index };
  }
}
