import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class SaveNoticeGQL extends Mutation<
  Pick<data.Mutation, 'SaveNotices'>,
  data.MutationSaveNoticesArgs
> {
  override document = gql`
    mutation SaveNotices($notices: [NoticeInput!]!) {
      SaveNotices(notices: $notices) {
        Created
        Description
        DisplayEnd
        DisplayStart
        DowntimeEnd
        DowntimeStart
        Id
        Title
        Updated
      }
    }
  `;
}
