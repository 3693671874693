//import from @apollo/client/core to avoid `react` error
import { provideHttpClient } from '@angular/common/http';
import { inject, Inject, NgModule } from '@angular/core';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { PingAuthenticationService } from '@aa-techops-ui/ping-authentication';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import {
  ConnectionParams,
  Middleware,
  SubscriptionClient,
} from 'subscriptions-transport-ws';
import {
  FeatureFlagsConfig,
  FEATURE_FLAGS_CONFIG,
  FEATURE_FLAGS_GATEWAY,
} from './feature-flags-config';

export function createFeatureFlagsApolloClient(
  apollo: Apollo,
  config: FeatureFlagsConfig,
  authService: PingAuthenticationService
) {
  const httpLink = inject(HttpLink);
  const basic = setContext((_operation, _context) => ({
    headers: {
      Accept: 'charset=utf-8',
      // authorization: `Bearer ${this.token}`, //Taken care by Angular Interceptor
    },
  }));

  const uri = config.uri;
  if (!uri) throw new Error('FeatureFlagsConfig.uri is required');
  const http = httpLink.create({
    uri: uri,
  });
  const subscriptionMiddleware: Middleware = {
    applyMiddleware(options, next) {
      options['authorization'] = `Bearer ${authService.AccessToken}`;
      next();
    },
  };

  const wsClient = new SubscriptionClient(uri.replace('http', 'ws'), {
    lazy: true,
    reconnect: true,
    connectionCallback: (err) => {
      if (err) {
        console.log('Error Connecting to FeatureFlags Server', err);
        wsClient.close(false, false);
      }
    },

    connectionParams: () => {
      const conParams: ConnectionParams = {
        authorization: `Bearer ${authService.AccessToken}`,
      };
      return conParams;
    },
  });
  wsClient.use([subscriptionMiddleware]);
  wsClient.onError((err) => {
    console.log('Error Connecting to FeatureFlags Server', err);
    wsClient.close(false, false);
  });

  const ws = new WebSocketLink(wsClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    ws,
    http as any //TODO: fix one day
  );

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions['code']) {
            case 'UNAUTHENTICATED': {
              wsClient.close(false, false);
            }
          }
        }
      }
      if (networkError) {
        if (networkError.message === 'jwt expired') {
          //console.log('Token expired .retrying with latest token');
          wsClient.close(false, false);
        }
        console.log(`[Network error]: ${networkError}`);
      }
      return forward(operation);
    }
  );

  //const link = ApolloLink.from([basic, authMiddleware, splitLink]);
  const link = ApolloLink.from([basic, errorLink, splitLink]);
  const cache = new InMemoryCache();
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  apollo.createNamed(FEATURE_FLAGS_GATEWAY, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    link: link as any, //TODO: fix one day
    cache,
    defaultOptions,
  });
}
@NgModule({
  exports: [],
  imports: [ApolloModule],
  providers: [provideHttpClient()],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    @Inject(FEATURE_FLAGS_CONFIG) config: FeatureFlagsConfig,
    authService: PingAuthenticationService
  ) {
    createFeatureFlagsApolloClient(apollo, config, authService);
  }
}
