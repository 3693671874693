import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UpdateTaskStateGQL extends Mutation<
  Pick<data.Mutation, 'UpdateTaskState'>,
  data.MutationUpdateTaskStateArgs
> {
  override document = gql`
    mutation UpdateTaskState($newTaskState: TaskState!, $taskId: String!) {
      UpdateTaskState(newTaskState: $newTaskState, taskId: $taskId) {
        Id
        TaskState
      }
    }
  `;
}
