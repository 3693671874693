<div class="task-hisory">
  <table class="table">
    <thead>
      <tr>
        <th class="col-sm-6">Description</th>
        <th class="col-sm-4">Author</th>
        <th class="col-sm-2">Creation Date</th>
      </tr>
    </thead>
    <tbody class="attr">
      <!-- <td>{{instructionList||''}}</td> -->
      <tr *ngFor="let instruction of instructionList">
        <td>
          {{ instruction.Instructions || '-' }}
        </td>
        <td>
          {{ instruction.EmployeeName || '-' }} ({{
            instruction.EmployeeId || '-'
          }})
        </td>

        <td>
          {{
            (instruction.Created | dateOffset: 0:'DDMMMYY HH:mm') + ' UTC' ||
              '-'
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
