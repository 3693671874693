export enum DepartureStatusCode {
  /**
   *Scheduled
   */
  SCHEDULED = 'S',
  /**
   *Estimated departure posted
   */
  ESTIMATED = 'E',
  /**
   *Decision time posted
   */
  DECISION = 'D',
  /**
   *Aircraft is out of gate
   */
  OUT_FROM_GATE = 'O',
  /**
   *Aircraft is taken off
   */
  OFF_GROUND = 'F',
  /**
   *Indefinate delay
   */
  INDEF_DELAY = 'N',
  /**
   *Leg delayed
   */
  DELETED = 'X',
  /**
   *Leg cancelled
   */
  CANCELLED = 'Z',
  /**
   *Flight departed early
   */
  EARLY = 'T',
  /**
   * For null values
   */
  EMPTY = '',
}
