import { GraphQLModule } from './graphql.module';
import { FeatureFlagsService } from './feature-flags.service';
import {
  FeatureFlagsConfig,
  FEATURE_FLAGS_CONFIG,
  _FEATURE_FLAGS_CONFIG,
} from './feature-flags-config';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';

export function getConfiguration(
  config: FeatureFlagsConfig,
  runtimeConfig: { FeatureFlags: FeatureFlagsConfig }
): FeatureFlagsConfig {
  const DEFAULT_CONFIG: Partial<FeatureFlagsConfig> = {
    uri: 'https://featureflags-api.aa.com/graphql',
    envName: 'prod',
  };
  const final_config = {
    ...DEFAULT_CONFIG,
    ...config,
    ...runtimeConfig.FeatureFlags,
  };
  return final_config;
}

@NgModule({
  imports: [CommonModule, GraphQLModule],
})
export class FeatureFlagsModule {
  public static forRoot(
    config: FeatureFlagsConfig
  ): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [
        {
          provide: _FEATURE_FLAGS_CONFIG,
          useValue: config,
        },
        {
          provide: FEATURE_FLAGS_CONFIG,
          useFactory: getConfiguration,
          deps: [_FEATURE_FLAGS_CONFIG, PING_APP_ENVIRONMENT],
        },
        FeatureFlagsService,
      ],
    };
  }
}
