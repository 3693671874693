import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isIncludedinArray',
})
export class IsIncludedinArrayPipe implements PipeTransform {
  transform<T>(array: T[], value: T): boolean {
    return array.includes(value);
  }
}
