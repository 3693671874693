<div class="assign-to">
  <p-dropdown
    [inputId]="inputId"
    [options]="ddOptions"
    styleClass="dropdown-width"
    panelStyleClass="dropdown-task"
    optionLabel="EmployeeId"
    emptyMessage="No Crew Members"
    [disabled]="crewMembers?.length ? false : true"
    scrollHeight="showSearchBar ? '275px' : '250px'"
    [placeholder]="'Assign(' + selectedMembers.length + ')'"
  >
    <ng-template pTemplate="selectedItem" let-selected>
      <span>Assign({{ selectedMembers.length }})</span>
    </ng-template>
    <ng-template let-member pTemplate="item">
      <p-table
        #dt2
        [value]="crewMembers || []"
        styleClass="p-datatable-sm assign-dd-table"
        [scrollable]="true"
        scrollHeight="230px"
        tableStyleClass="table"
        sortField="{{ sortState ? sortState.column : sortBy.NAME }}"
        [sortOrder]="
          sortState ? (sortState.sortOrder === sortOrder.ASCENDING ? 1 : -1) : 1
        "
        [globalFilterFields]="['FirstName', 'LastName', 'EmployeeId']"
      >
        <ng-container *ngIf="showSearchBar">
          <ng-template pTemplate="caption">
            <div class="flex" (click)="$event.stopPropagation()">
              <input
                class="w-100"
                pInputText
                type="text"
                (input)="dt2.filterGlobal($event.target.value, 'contains')"
                placeholder="Search"
              />
            </div>
          </ng-template>
        </ng-container>
        <ng-template pTemplate="header">
          <tr>
            <th
              pSortableColumn="{{ sortBy.NAME }}"
              (click)="onSort(sortBy.NAME, $event)"
            >
              {{ columnName.NAME }}&nbsp;<i
                class="fa pl-1"
                [ngClass]="{
                'fa-caret-up':
                  sortState?.column === sortBy.NAME &&
                  sortState?.sortOrder === sortOrder.ASCENDING,
                'fa-caret-down':
                  sortState?.column === sortBy.NAME &&
                  sortState?.sortOrder === sortOrder.DESCENDING,
              }"
              ></i>
            </th>
            <th
              pSortableColumn="{{ sortBy.ID }}"
              (click)="onSort(sortBy.ID, $event)"
            >
              {{ columnName.ID }}&nbsp;<i
                class="fa pl-1"
                [ngClass]="{
                'fa-caret-up':
                  sortState?.column === sortBy.ID &&
                  sortState?.sortOrder === sortOrder.ASCENDING,
                'fa-caret-down':
                  sortState?.column === sortBy.ID &&
                  sortState?.sortOrder === sortOrder.DESCENDING,
              }"
              ></i>
            </th>
            <th *ngIf="showOnlineStatus" (click)="$event.stopPropagation()">
              {{ columnName.ONLINE_STATUS }}
            </th>
            <th *ngIf="showCheckmarkOnSelected" class="tick"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-crewMember>
          <tr
            (click)="toggleMember($event, crewMember)"
            [class.bg-light-blue]="selectedState[crewMember.EmployeeId]"
          >
            <td class="text-break">
              <span>{{
                (crewMember.LastName | titlecase) +
                  ', ' +
                  (crewMember.FirstName | titlecase)
              }}</span>
            </td>
            <td>
              <span>{{ crewMember.EmployeeId | trimBadgeNumber }}</span>
            </td>
            <td *ngIf="showOnlineStatus">
              <lm-apps-lmo-ui-online-status
                [isOnline]="
                  crewMember.EmployeeId | getOnlineStatus: allOnlineStatus
                "
              ></lm-apps-lmo-ui-online-status>
            </td>
            <td *ngIf="showCheckmarkOnSelected" class="tick">
              <span
                class="aa-white border-none"
                *ngIf="selectedState[crewMember.EmployeeId]"
              >
                <i class="fa fa-check"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr *ngIf="crewMembers?.length">
            <td class="aa-pb-0 bg-white" colspan="8">
              <span class="aa-red d-flex"> No Match Found. </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </ng-template>
  </p-dropdown>
  <span *ngIf="!crewMembers?.length" class="aa-red">No Crew Selected</span>
</div>
