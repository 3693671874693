import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Notice } from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'lm-apps-notices-modal',
  templateUrl: './notices-modal.component.html',
})
export class NoticesModalComponent {
  @Input() notice!: Notice;
  @Output() acknowledgeEvent = new EventEmitter<void>();

  constructor(public modalRef: BsModalRef) {}

  acknowledge() {
    this.acknowledgeEvent.emit();
    this.modalRef.hide();
  }
}
