import { RouterStateSnapshot } from '@angular/router';
import { CREW_CHIEF } from '@lm-apps/lmo/ui/data';

export function isCcView(routerState: RouterStateSnapshot): boolean {
  // checking if url is string or not because at runtime somehow angular passing ActivatedRouteSnapshot which has url and that url is type string
  if (!routerState || !routerState.url || typeof routerState.url !== 'string')
    return false;
  const segment = routerState.url.trim().split('/');
  return Boolean(
    segment &&
      segment.length > 1 &&
      (!segment[1] || segment[1].trim() === CREW_CHIEF)
  );
}
