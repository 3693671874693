<header class="bg-shade-gray d-flex justify-content-between align-items-center">
  <span class="d-flex">
    <p-button
      icon="pi pi-times"
      styleClass="p-button-secondary p-button-text cross"
      (onClick)="close()"
    ></p-button>
    <span class="title-txt">
      BOW Details @if (selectedBowItem) { ({{ selectedBowItem.Label }}) }
    </span>
  </span>
  <p-button
    label="Aircraft Attributes"
    styleClass="p-button-outlined"
    class="aa-pr-2"
    (onClick)="openAircraftAttributesView()"
  ></p-button>
</header>
