<div class="d-flex justify-content-between p-2 cch">
  <div class="d-flex justify-content-between">
    <p-button
      label="Filter"
      icon="pi pi-filter"
      styleClass="p-button-raised"
      (onClick)="openFilterModal()"
    ></p-button>

    <form [formGroup]="noseFormGroup" class="aa-ml-2 aa-mr-2">
      <p-autoComplete
        type="text"
        pInputText
        formControlName="cchNose"
        placeholder="A/C #"
        inputStyleClass="cch-ac text-left"
        [suggestions]="aircraftSearchResults"
        [minLength]="1"
        [maxlength]="3"
        [size]="3"
        uppercase
      >
        <ng-template pTemplate="loadingicon">
          <!--  this element is being used to hide loadingicon -->
        </ng-template>
      </p-autoComplete>
    </form>

    <form [formGroup]="flightFormGroup">
      <p-autoComplete
        [type]="text"
        pInputText
        formControlName="fltNumber"
        placeholder="Flight #"
        inputStyleClass="cch-flt text-left"
        [minLength]="1"
        [maxlength]="4"
        [size]="4"
      >
        <ng-template pTemplate="loadingicon">
          <!--  this element is being used to hide loadingicon -->
        </ng-template>
      </p-autoComplete>
    </form>
    <p-divider layout="vertical"></p-divider>
    <!-- commenting out as we feel it might be needed in the future -->
    <!-- <p-autoComplete
      [(ngModel)]="selectedStation"
      placeholder="Station"
      [suggestions]="suggestedStations"
      (completeMethod)="getStationsSuggestions($event)"
      (onSelect)="selectStation($event)"
      [minLength]="1"
      [maxlength]="3"
      [size]="8"
      inputStyleClass="text-uppercase"
      pKeyFilter="alpha"
    >
    </p-autoComplete> -->
    <p-tag
      *ngFor="let station of filteredStations | slice: 0:5; let i = index"
      styleClass="cch-selected-station "
      [ngClass]="{ 'aa-ml-2': i !== 0 }"
    >
      <span class="display-station-tag-text">{{ station }}</span>
      <!-- commenting out as we feel it might be needed in the future -->
      <!-- <button
        *ngIf="filteredStations.length !== 1 || station !== defaultStation"
        (click)="removeStation(station)"
        class="remove-station-button pi pi-times"
      ></button> -->
    </p-tag>
    <button
      pButton
      class="aa-ml-2 p-button-rounded p-button-raised add-station-button"
      [ngClass]="filteredStations.length > 5 ? '' : 'additional-stations'"
      (click)="openStationsModal()"
    >
      {{
        filteredStations.length > 5 ? '+' + (filteredStations.length - 5) : '+'
      }}
    </button>
  </div>
  <div class="d-flex justify-content-between">
    <p-button
      label=" Task"
      icon="pi pi-plus"
      styleClass="p-button-raised"
      (onClick)="openNewTaskModal()"
    ></p-button>
    <p-button
      label=" Crew Manager"
      icon="pi pi-pencil"
      styleClass="p-button-raised aa-ml-2 aa-mr-2"
      (onClick)="openCrewManagerModal()"
    ></p-button>

    <p-button
      label=" History"
      icon="pi pi-folder-open"
      styleClass="p-button-raised"
      [routerLink]="['/history']"
    ></p-button>
  </div>
</div>
