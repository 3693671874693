import { FlightLegStatus } from '@lm-apps/lmo/shared/enums';
import { offsetDateTime } from './offset-date-time';

export function departureTime(
  arrivalFlightNumber?: string | null,
  departureFlightNumber?: string,
  departureActualDateTimeUtc?: Date | null,
  arrivalLegStatus?: string | null,
  estimatedTimeOfDepartureUtc?: Date | null,
  departureUTCAdjust?: number | null
) {
  if (!departureFlightNumber) return '--:--';

  let departureDateTime: Date | undefined | null = departureActualDateTimeUtc;

  if (
    arrivalLegStatus === FlightLegStatus.GROUND_INTERRUPT &&
    departureFlightNumber === arrivalFlightNumber
  ) {
    departureDateTime = estimatedTimeOfDepartureUtc;
  }
  if (!departureDateTime) return '--:--';

  const departTime = offsetDateTime(
    departureDateTime,
    departureUTCAdjust,
    'HH:mm'
  );

  return departTime?.toString() || '--:--';
}
