import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CarrierType, FlightLeg, FlightLegDetail } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-flight-leg-result',
  templateUrl: './flight-leg-result.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightLegResultComponent {
  @Input() flightLegs: FlightLeg[] = [];
  @Input() selectedStations: string[] = [];
  @Output() showManualTask: EventEmitter<FlightLegDetail> = new EventEmitter();

  getArrivalStatusText(statusCode: string) {
    switch (statusCode) {
      case 'S':
        return 'SCHEDULED';
      case 'E':
        return 'ESTIMATED';
      case 'O':
        return 'ON';
      case 'I':
        return 'IN';
      default:
        return '';
    }
  }

  getDepartureStatusText(statusCode: string) {
    switch (statusCode) {
      case 'S':
        return 'SCHEDULED';
      case 'E':
        return 'ESTIMATED';
      case 'O':
        return 'OUT';
      case 'F':
        return 'OFF';
      default:
        return '';
    }
  }

  createManualTask(leg: FlightLeg) {
    const fld: FlightLegDetail = {
      AircraftId: leg.AircraftId,
      ArrivalStation: leg.ArrivalStation,
      ArrivalId: leg.Id,
      DepartureStation: leg.DepartureStation,
      NoseNumber: leg.Equipment?.NoseNumber ?? '',
      AirlineCode: leg.Equipment?.AirlineCode ?? CarrierType.Aa,
    };
    this.showManualTask.emit(fld);
  }
}
