import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class SaveUserGQL extends Mutation<
  Pick<data.Mutation, 'SaveUser'>,
  data.MutationSaveUserArgs
> {
  override document = gql`
    mutation SaveUser($user: SaveUserInput!) {
      SaveUser(user: $user) {
        Filter {
          Range
          Delay
          Miscellaneous
          Gates
          GateOptions
          NotificationType
          TaskStatus
          IncludeTasks
          OnlyMyTasks
          Fleets
          Zones
          OtherToaster
          RedToaster
          NoiseToaster
        }
        EmployeeId
        FirstName
        LastName
        DefaultStation
        Id
        NoticeAcknowledgedDate
        PredefinedTasks {
          Description
          Id
          IsActive
          TaskCallType {
            DisplayCallType
            Id
            IsActive
            KeyCallType
          }
          TaskJobType {
            DisplayJobType
            Id
            IsActive
            IsSelection
            KeyJobType
          }
          Title
        }
        SelectedCrewId
        Crew {
          AssignedMembers {
            AssignedOn
            EmployeeId
            FirstName
            LastName
            Status
          }
          Created
          CurrentOwner {
            EmployeeId
            FirstName
            LastName
          }
          Id
          Name
          OriginalOwner {
            EmployeeId
            FirstName
            LastName
          }
          Shared
          Station
          Updated
        }
      }
    }
  `;
}
