<section class="aa-mt-3 aa-mb-3">
  <header class="title-txt bg-shade-gray">BOW</header>
  <hr class="cc-hr" />
  <table class="table table-condensed">
    <thead>
      <tr>
        <th>Work Control#</th>
        <th>Scheduled Date</th>
        <th>Description</th>
        <th>Status</th>
        <th *ngIf="!isMyTasks">Assigned</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr *ngFor="let bowItem of bowItemsList">
        <ng-container *ngIf="bowItem?.BowDetails as bowDetails">
          <td>
            <span
              class="bow-item-wc-number text-clickable"
              (click)="openBowDetailsView({ event: $event, Task: bowItem })"
            >
              {{ bowItem.Label || '-' }}
            </span>
          </td>
          <td>
            {{
              bowDetails.ScheduledDate
                ? (bowDetails.ScheduledDate | date: 'ddMMMyy' | uppercase)
                : '-'
            }}
          </td>
          <td>{{ bowItem.Description || '-' }}</td>
          <td>
            <ng-container *ngIf="!isMyTasks; else myTasksStatus"
              >{{
                bowItem.TaskState
                  ? (bowItem.TaskState | getTaskStatusLabel)
                  : '-'
              }}
            </ng-container>
            <ng-template #myTasksStatus>
              <lm-apps-lmo-ui-task-status
                *ngIf="user"
                [task]="bowItem"
                [employeeId]="user.EmployeeId"
                (updatedTaskState)="updateTaskState($event)"
              >
              </lm-apps-lmo-ui-task-status>
            </ng-template>
          </td>
          <td *ngIf="!isMyTasks">
            <lm-apps-lmo-ui-bow-assigned-members-list
              [selectedBowItem]="bowItem"
            ></lm-apps-lmo-ui-bow-assigned-members-list>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</section>
