import * as actions from './alternate-tasks.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { AlternateTasksState } from './alternate-tasks.interfaces';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  AlternateTasksColumnName,
  AlternateTasksSortOrder,
  AlternateTask,
} from '@lm-apps/lmo/ui/data';

export const adapter: EntityAdapter<AlternateTask> =
  createEntityAdapter<AlternateTask>({
    selectId: (alternateTask: AlternateTask) => alternateTask.Id,
  });

export const initialState: AlternateTasksState = adapter.getInitialState({
  ids: [],
  entities: Array<AlternateTask>(),
  selectedId: null,
  loaded: false,
  error: null,
  sortDetail: {
    column: AlternateTasksColumnName.AIRCRAFT,
    sortOrder: AlternateTasksSortOrder.ASCENDING,
  },
});

const alternateTasksReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(actions.setSelectedAlternateTask, (state, { alternateTask }) => ({
    ...state,
    selectedId: alternateTask.Id,
  })),
  on(actions.resetSelectedAlternateTask, (state) => ({
    ...state,
    selectedId: null,
  })),
  on(actions.loadAlternateTasksSuccess, (state, { alternateTasks }) =>
    adapter.setAll(alternateTasks, { ...state, loaded: true })
  ),
  on(actions.loadAlternateTaskDetailsSuccess, (state, { alternateTasks }) =>
    adapter.setAll(alternateTasks, { ...state, loaded: true })
  ),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  })),
  on(actions.setSortDetail, (state, { sortDetail }) => ({
    ...state,
    sortDetail,
  }))
);

export const getSelectedAlternateTasksSortDetail = (
  state: AlternateTasksState
) => state.sortDetail;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of alternate task ids
export const selectAlternateTaskIds = selectIds;

// select the dictionary of alternate task entities
export const selectAlternateTaskEntities = selectEntities;

// select the array of alternate tasks
export const selectAllAlternateTasks = selectAll;

// select the total alternate tasks count
export const selectAlternateTasksTotal = selectTotal;

export function reducer(
  state: AlternateTasksState | undefined,
  action: Action
) {
  return alternateTasksReducer(state, action);
}
