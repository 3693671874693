import { CREW_MEMBER_STATUS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCrewMemberStatus from './crew-member-status.reducer';
import { adapter } from './crew-member-status.reducer';

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectCrewMemberStatustate =
  createFeatureSelector<fromCrewMemberStatus.State>(
    CREW_MEMBER_STATUS_FEATURE_KEY
  );

export const selectAllCrewMemberOnlineStatus = createSelector(
  selectCrewMemberStatustate,
  selectAll
);

export const selectEntitiesCrewMemberOnlineStatus = createSelector(
  selectCrewMemberStatustate,
  selectEntities
);
