import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './stations.actions';
import {
  CustomToastrService,
  RegionsService,
  ZoneService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
import { RegionStations, Zone } from '@lm-apps/lmo/ui/data';

@Injectable()
export class StationsEffects {
  getAllRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() =>
        this.regionsService.getAllRegions().pipe(
          map((regions: RegionStations[]) => {
            regions.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
            return actions.loadAllRegionsSuccess({ regions: regions });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load regions.');
            return of(actions.throwError({ error }));
          })
        )
      )
    )
  );

  getZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadZones),
      switchMap(({ station }) =>
        this.zoneService.getZonesGates([station]).pipe(
          map((zones: Zone[]) => {
            return actions.loadZonesSuccess({ zones });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load zones.');
            return of(actions.throwError({ error: error.message }));
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private regionsService: RegionsService,
    private zoneService: ZoneService,
    private toastService: CustomToastrService
  ) {}
}
