export enum AssignToColumnName {
  NAME = 'Name',
  ID = 'Id',
  ONLINE_STATUS = 'iPad Status',
}

export enum SortableCrewColumn {
  NAME = 'LastName',
  ID = 'EmployeeId',
}
