import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';
import { FeatureFlagsService } from '@aa-techops-ui/feature-flags';
import {
  combineLatest,
  filter,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import {
  PING_APP_ENVIRONMENT,
  PingAuthenticationService,
} from '@aa-techops-ui/ping-authentication';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import { select, Store } from '@ngrx/store';
import * as fromFeatureFlag from '@lm-apps/lmo/ui/data-access-feature-flag';
import * as fromCrewManpower from '@lm-apps/lmo/ui/data-access-crew-manpower';

import { map } from 'rxjs/operators';
import { MANPOWER_CREW_MANAGER } from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  featureFlag$: Observable<FeatureFlag> =
    this.featureFlagsService.onFeatureChange();
  enabledPilotStations$: Observable<string[]> = this.store.pipe(
    select(fromFeatureFlag.selectEnabledPilotStations)
  );
  superUsers$: Observable<string[]> = this.store.pipe(
    select(fromFeatureFlag.superUsers),
    filter((value) => value !== null)
  );
  enableStationRolloutFeature$: Observable<boolean> = this.store.pipe(
    select(fromFeatureFlag.selectStationRolloutFlag),
    filter((value) => value !== null),
    map((flag) => flag?.enabled ?? false)
  );
  roles$: Observable<string[]> = this.authService.roles$;
  private destroy$ = new Subject<void>();

  constructor(
    private authService: PingAuthenticationService,
    @Inject(PING_APP_ENVIRONMENT) private env: AppEnvironment,
    private featureFlagsService: FeatureFlagsService,
    private store: Store
  ) {}
  ngOnInit() {
    this.featureFlagSub();

    //TODO: Jag: Just for the purpose of station roll out. Should be removed after big bang
    // Once the user is logged in,
    // we will get the profile and the enabled pilot stations from feature flags
    // and redirect the user if user location matches the pilot stations
    this.redirectOnStationRollout();
  }
  featureFlagSub() {
    this.featureFlag$.pipe(takeUntil(this.destroy$)).subscribe((flag) => {
      flag && this.store.dispatch(fromFeatureFlag.actions.upsertFlag({ flag }));
      if (flag.name === MANPOWER_CREW_MANAGER && flag.enabled) {
        this.store.dispatch(fromCrewManpower.actions.init());
      }
    });
  }

  private redirectOnStationRollout() {
    this.authService.loggedIn$
      .pipe(
        filter((isLoggedIn) => isLoggedIn), // Only proceed if logged in
        switchMap(() =>
          combineLatest([
            this.authService.profile$,
            this.enableStationRolloutFeature$,
            this.enabledPilotStations$, // Ensure defaults
            this.superUsers$,
          ])
        ),
        filter(
          ([profile, enableStationRolloutFeature]) =>
            !!profile &&
            !!profile.sub &&
            !!profile.location &&
            enableStationRolloutFeature
        ),
        take(1),
        takeUntil(this.destroy$), // Ensure cleanup
        tap(([profile, _, pilotStations, superUsers]) => {
          if (!pilotStations.length) return; // No pilot stations, no need to redirect

          const userLocation = (profile['location'] as string).split('-')[0]; // PHX-52S1 -> PHX
          const userId = profile.sub;
          const isLocationInPilotStations =
            pilotStations.includes(userLocation);
          const isUserToBypassRedirection =
            !!superUsers && superUsers.includes(userId);

          if (!isLocationInPilotStations && !isUserToBypassRedirection) {
            window.location.href = this.env.LMO_CLASSIC_URL;
          } else {
            console.log(
              `Ignore redirection for user: ${userId} at location: ${userLocation}`
            );
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
