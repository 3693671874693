import { createAction, props } from '@ngrx/store';
import {
  AlternateTaskHistoryInput,
  AlternateTaskHistoryDetails,
  AutomaticHoldHistoryInput,
  AutomaticHoldHistory,
  PairsHistoryFilterInput,
  PairsHistory,
  TaskHistoryDisplay,
} from '@lm-apps/lmo/ui/data';

export const throwError = createAction(
  '[History/API] API request failed',
  props<{ error: unknown }>()
);

export const initAlternateTaskHistory = createAction(
  '[AlternateTaskHistory] Init',
  props<{ alternateTaskHistoryInput: AlternateTaskHistoryInput }>()
);

export const loadAlternateTaskHistorySuccess = createAction(
  '[AlternateTasksHistory/API] Load alternate tasks history success',
  props<{ alternateTaskHistoryDetails: AlternateTaskHistoryDetails[] }>()
);

export const initMTHoldHistory = createAction(
  '[MTHoldHistory] Init',
  props<{ automaticHoldHistoryInput: AutomaticHoldHistoryInput }>()
);

export const loadMTHoldHistorySuccess = createAction(
  '[MTHoldHistory/API] Load Maintenance Hold history success',
  props<{ automaticHoldHistory: AutomaticHoldHistory[] }>()
);

export const initPairsHistory = createAction(
  '[PairsHistory] Init',
  props<{ filterInput: PairsHistoryFilterInput }>()
);

export const loadPairsHistorySuccess = createAction(
  '[PairsHistory/API] Load Pairs history success',
  props<{ pairsHistory: PairsHistory[] }>()
);

export const initTaskHistory = createAction(
  '[TaskHistory] Init',
  props<{ taskId: string }>()
);

export const loadTaskHistorySuccess = createAction(
  '[TaskHistory/API] Load Task history success',
  props<{ taskHistoryDisplay: TaskHistoryDisplay[] }>()
);

export const clearTaskHistory = createAction('[TaskHistory] Clear');

export const resetHistoryPageState = createAction('[History] Reset');
