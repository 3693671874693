import { offsetDateTime } from './offset-date-time';

export function arrivalDate(
  flightNumber?: string | null,
  actualArrival?: Date | null,
  arrivalUTCOffset?: number | null,
  actualDeparture?: Date | null,
  departureUTCOffset?: number | null
) {
  let display = '-';

  if (flightNumber && actualArrival) {
    // I assume we want the day according to current station
    const inbound = offsetDateTime(actualArrival, arrivalUTCOffset, 'DD');
    const outbound =
      actualDeparture &&
      offsetDateTime(actualDeparture, departureUTCOffset, 'DD');

    display =
      outbound && inbound !== outbound
        ? flightNumber + '/' + inbound
        : flightNumber;
  }

  return display;
}

export function arrivalTime(
  actualArrival?: Date | null,
  arrivalUtcAdjustment?: number | null
): string {
  if (!actualArrival) {
    return '--:--';
  }

  const arrivalTime = offsetDateTime(
    actualArrival,
    arrivalUtcAdjustment,
    'HH:mm'
  );

  return arrivalTime?.toString() || '--:--';
}
