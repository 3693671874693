import { OnlineStatus } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[CrewMember Status] Init');
export const loadCrewMemberOnlineAllSuccess = createAction(
  '[CrewMember Status] Load crew members online status success',
  props<{ crewMembers: OnlineStatus[] }>()
);
export const updateSpecificCrewMemberStatus = createAction(
  '[CrewMember Status] update specific crew member online status',
  props<{ crewMember: OnlineStatus }>()
);

export const throwError = createAction(
  '[CrewMember Status] API request failed',
  props<{ error: any }>()
);
