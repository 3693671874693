export enum TaskState {
  EMPTY = 'Empty',
  UNASSIGNED = 'Unassigned',
  CLOSED = 'Closed',
  ASSIGNED = 'Assigned',
  DEFERRED = 'Deferred',
  COMPLETED = 'Completed',
  SYSTEM_CLOSED = 'SystemClosed',
  ACKNOWLEDGED = 'Acknowledged',
  ARRIVED = 'Arrived',
  SUPPRESSED = 'Suppressed',
  DELIVERED = 'Delivered',
}

export enum TaskStatusLabel {
  Acknowledged = 'Ack',
  Arrived = 'Arrived',
  Assigned = 'Assigned',
  Closed = 'Closed',
  Completed = 'Completed',
  Unassigned = 'Unassigned',
  Delivered = 'Delivered',
  Deferred = 'Deferred',
}
