import {
  AdditionalTaskDetail,
  CarrierType,
  FlightsEntity,
  FlightNotificationEntity,
  ItemType,
  JobTypeInput,
  MaintenanceTaskDisplay,
  MaintenanceTaskInput,
  TaskEntity,
  TaskState,
  TaskAction,
  ExtendedFlightAttributes,
} from '@lm-apps/lmo/ui/data';
import {
  ItemType as ItemTypeEnum,
  EnumUtil,
  JobTypeDisplay,
  MaintenancePriorities,
} from '@lm-apps/lmo/shared/enums';
import { SystemGenerated, jobTypeInputMap } from '@lm-apps/lmo/shared/master';
import { GetPriorityClassNamePipe } from '@lm-apps/lmo/ui/common/pipes';
import {
  PriorityCssClasses,
  PriorityValue,
} from '@lm-apps/lmo/ui/common/pipes';

const QuickFix = ['QFX', 'QFX R', 'QFX CI'];
const NefQuickFix = ['QFX', 'QFX R', 'QFX CI', 'QFY'];

export function CreateTaskObj(
  flight: FlightsEntity,
  noti: FlightNotificationEntity | undefined,
  task?: TaskEntity
): MaintenanceTaskDisplay {
  const maintenanceTask: MaintenanceTaskInput = {
    Aircraft: {
      NoseNumber: flight.Equipment.NoseNumber,
      AirlineCode: flight.Equipment.AirlineCode,
    },
    AircraftId: flight.Equipment.Id,
    AssignedToIds: task?.AssignedMembers?.map((x) => x.EmployeeId) || [],
    CallType: {
      DisplayCallType:
        task?.CallType.DisplayCallType ?? SystemGenerated.DisplayCallType,
      KeyCallType: task?.CallType.KeyCallType ?? SystemGenerated.KeyCallType,
    },
    DeferredReason: task?.DeferredReason ?? '',
    Description: task?.Description ?? noti?.Description ?? '',
    IataCode: flight.Arrival?.ArrivalStation ?? '',
    InboundFlightDesignator: {
      AirlineCode: CarrierType.Aa,
      FlightNumber: flight.Arrival?.FlightNumber ?? '',
      DepartureStation: flight.Arrival?.DepartureStation ?? '',
      LegOriginDate: flight.Arrival?.LegOriginDate,
      DuplicateDepartureNumber: flight.Departure?.DuplicateDepartureNumber ?? 0,
    },
    InboundFlightLegId: flight.Arrival?.Id,
    IsAlternateTask: task?.IsAlternateTask ?? false,
    IsAvionic: task?.IsAvionic ?? false,
    IsManualTask: task?.IsManualTask ?? false,
    IsOilService: task?.IsOilService ?? false,
    JobType: populateJobType(noti, task),
    JsonAttribute: null,
    Label: task?.Label ?? noti?.Label ?? '',
    Location: task?.Location ?? null,
    MaintenanceType: task?.MaintenanceType ?? noti?.Type ?? ItemType.Undefined,
    IsNfnf: task?.IsNfnf ?? false,
    SpecialInstruction: task?.SpecialInstructions[0]?.Instructions ?? '',
    TaskState: task?.TaskState ?? TaskState.Unassigned,
    PredefinedTask: undefined,
    NotificationId: task?.NotificationId ?? noti?.Id ?? '',
    MaintenancePriority: {
      Description:
        task?.MaintenancePriority?.Description ?? noti?.PriorityName ?? '',
    },
    TaskId: task?.Id ?? null,
    UserId: task?.UserId ?? null,
    IsUpdate: false,
    // This is just a placeholder rightnow, will be update accordingly while create/updating it in task details/new task creation
    TaskAction: task?.Id ? TaskAction.Update : TaskAction.Create,
    WorkControlNumber: task?.BowDetails?.WorkControlNumber ?? null,
    ScheduledDate: task?.BowDetails?.ScheduledDate ?? null,
  };
  const add: AdditionalTaskDetail = {
    ReferenceNumber: noti && getDisplayName(noti),
    DeferralDescription: noti && getDeferralDescription(noti),
    IsOpsOrRobOrRem:
      noti?.Type === ItemType.Ops ||
      noti?.Type === ItemType.Rob ||
      noti?.Type === ItemType.Rem,
    HasTask: task ? true : false,
    IsQuickFix: !!(
      task?.MaintenancePriority?.Description &&
      QuickFix.includes(task.MaintenancePriority.Description)
    ),
    SpecialInstructions: task?.SpecialInstructions ?? [],
    AssignedMembers: task?.AssignedMembers || [],
    UserName: task?.UserName ?? '',
    ChangedOn: task?.ChangedOn || new Date(),
    IsBowTaskCreatedOnTheFly:
      task?.BowDetails?.IsBowTaskCreatedOnTheFly ?? false,
  };
  const maint: MaintenanceTaskDisplay = {
    MaintenanceTaskInput: maintenanceTask,
    AdditionalTaskDetail: add,
  };
  return maint;
}

export function populateJobType(
  noti: FlightNotificationEntity | undefined,
  task?: TaskEntity
): JobTypeInput {
  const jobTypeName = getJobType(
    noti?.PriorityName ?? '',
    noti?.ExtendedAttributes
  )?.name;

  // Handling rare scenario where we are not able to create a task out of noti, allows user to create OSP task by setting job type manually
  const jobType =
    noti?.Type === ItemType.Oil
      ? jobTypeInputMap.get(JobTypeDisplay.Servicing)
      : jobTypeInputMap.get(jobTypeName ?? '');

  return task
    ? <JobTypeInput>{ ...task.JobType }
    : <JobTypeInput>{ ...jobType };
}

function getJobType(
  priorityName: string,
  attributes?: ExtendedFlightAttributes | null
): PriorityValue {
  if (
    attributes?.Classification === 'NEF' &&
    NefQuickFix.includes(priorityName)
  ) {
    return { name: JobTypeDisplay.NefQuickFix };
  }

  priorityName = priorityName.toUpperCase().trim().split(' ').join('_');
  return (
    PriorityCssClasses[priorityName] || { name: JobTypeDisplay.MelGeneral }
  );
}

function getDisplayName(notification: FlightNotificationEntity): string {
  const pipe = new GetPriorityClassNamePipe();
  const classArray = pipe.transform(notification).split(' ');
  const className = classArray ? classArray[0] + '.' + classArray[1] : '';
  const displayName =
    notification.DisplayName ||
    EnumUtil.getValue(ItemTypeEnum, notification.Type);
  let prefix = '';
  switch (className) {
    case 'deferral.qfx':
      prefix = 'Q';
      break;

    case 'deferral.qdaily':
      prefix = 'QD';
      break;

    case 'deferral.qcycle':
      prefix = 'QF';
      break;

    case 'deferral.daily':
      prefix = 'D';
      break;

    case 'deferral.cycle':
      prefix = 'F';
      break;

    case 'deferral.melr':
      prefix = 'R';
      break;

    case 'deferral.melci':
      prefix = 'CI';
      break;

    case 'deferral.melf':
      prefix = 'F';
      break;

    case 'deferral.melfr':
      prefix = 'FR';
      break;

    case 'deferral.melfci':
      prefix = 'FCI';
      break;

    case 'deferral.meld':
      prefix = 'D';
      break;

    case 'deferral.meldr':
      prefix = 'DR';
      break;

    case 'deferral.meldci':
      prefix = 'DCI';
      break;

    case 'deferral.melq':
      prefix = 'Q';
      break;

    case 'deferral.melqr':
      prefix = 'QR';
      break;

    case 'deferral.melqci':
      prefix = 'QCI';
      break;

    case 'deferral.melqf':
      prefix = 'QF';
      break;

    case 'deferral.melqfri':
      prefix = 'QFRI';
      break;

    case 'deferral.melqfci':
      prefix = 'QFCI';
      break;

    case 'deferral.melqd':
      prefix = 'QD';
      break;

    case 'deferral.melqdr':
      prefix = 'QDR';
      break;

    case 'deferral.melqcid':
      prefix = 'QCID';
      break;
  }

  return prefix + displayName;
}

function getDeferralDescription(noti: FlightNotificationEntity) {
  const deferral = getJobType(
    noti.PriorityName ?? '',
    noti.ExtendedAttributes
  )?.DeferralDescription;
  if (!deferral) {
    if (noti.ExtendedAttributes?.Description)
      return noti.ExtendedAttributes?.Description;
    else if (noti.Description) return noti.Description;
  }

  return deferral;
  //This Logic is not executing in existing LMO
  // if ($scope.filteredMaintenanceItems != undefined)
  // for (var index = 0; index < $scope.filteredMaintenanceItems.length; index++) {
  //   if ($scope.task.selectedMaint != undefined)
  //     if ($scope.task.selectedMaint.priorityId != 1013)
  //       if ($scope.task.selectedMaint.notificationId != undefined)
  //         if ($scope.task.selectedMaint.notificationId == $scope.filteredMaintenanceItems[index].id) {
  //           $scope.deferralDescription = $scope.filteredMaintenanceItems[index].attributes.description;
  //           if ($scope.filteredMaintenanceItems[index].attributes.referenceNumber != undefined)
  //             $scope.referenceNumber = ' (' + $scope.filteredMaintenanceItems[index].attributes.referenceNumber + ')';
  //           else
  //             $scope.referenceNumber = '';

  //         }
  // }
}

export function getTooltip(flight: FlightsEntity, task: TaskEntity) {
  let desc = task && task.Label ? task.Label : '';
  //TODO: Why do we show `Manual Task` in the tooltip?
  //wouldnt it be better if we show the description of the task?
  const taskNotification = flight.Notifications?.find(
    (noti) => noti.Id == task.NotificationId
  );
  if (task.Label == ItemType.Hermes && taskNotification) {
    desc = taskNotification.Description || '';
  }

  if (task.MaintenanceType == ItemType.Oil) {
    desc = 'Oil Servicing Due';
  }

  const selectedMaint = flight.Notifications
    ? flight.Notifications.find(function (itm) {
        return itm.Id == task.NotificationId;
      })
    : undefined;
  if (
    task.MaintenancePriority &&
    (task.MaintenancePriority.Description == MaintenancePriorities.IBGATE ||
      task.MaintenancePriority.Description == MaintenancePriorities.OBGATE) &&
    selectedMaint?.ExtendedAttributes?.Description
  ) {
    desc = selectedMaint.ExtendedAttributes.Description.replace(
      ' and',
      '\n and'
    );
  }
  return desc;
}
