import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './components/filter/filter.component';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { FilterHomeComponent } from './components/home/home.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyTasksFilterHomeComponent } from './components/my-tasks-filter/home/home.component';
import { MyTasksFilterComponent } from './components/my-tasks-filter/filter/filter.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RadioButtonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    TagModule,
    InputTextModule,
    KeyFilterModule,
    InputSwitchModule,
    TooltipModule.forRoot(),
    FontAwesomeModule,
  ],
  declarations: [
    FilterComponent,
    FilterHomeComponent,
    MyTasksFilterComponent,
    MyTasksFilterHomeComponent,
  ],
  exports: [FilterHomeComponent, MyTasksFilterHomeComponent],
})
export class LmoUiFeatureFilterModule {}
