<div class="col-12" *ngIf="crewMembersloaded">
  <div class="aa-py-4">
    <span class="bold-7">Crew Name</span>
    <div class="display-flex justify-space-between">
      <span>
        {{ crewName }}
      </span>
      <div class="display-flex">
        <p-checkbox
          inputId="exception"
          [(ngModel)]="exceptionChecked"
          [binary]="true"
          (onChange)="exceptionClick()"
        ></p-checkbox
        ><label for="exception" class="aa-m-0 aa-pb-1">Exceptions</label>
      </div>
    </div>
  </div>
  <div class="aa-mb-4">
    <p-table
      #dt1
      [value]="filteredCrewMembers"
      styleClass="p-datatable-sm cm-table"
      tableStyleClass="table table-striped"
      [scrollable]="true"
      scrollHeight="532px"
      [globalFilterFields]="['firstName', 'lastName', 'employeeNumber']"
    >
      <ng-template pTemplate="caption" class="display-flex">
        <input
          pInputText
          pKeyFilter="alphanum"
          placeholder="Search"
          (input)="dt1.filterGlobal($event?.target?.value, 'contains')"
        />
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="cm-online-status p-0"></th>
          <th class="col-4">Name</th>
          <th class="col-1">Status</th>
          <th class="col-2">Clock In/Out</th>
          <th>Qualifications</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-member let-i="rowIndex">
        <tr>
          <td class="cm-online-status p-0 ac-center">
            <lm-apps-lmo-ui-online-status
              [class]="
                (member.employeeNumber
                | getOnlineStatus: allMembersOnlineStatus)
                  ? 'cm-online-font-size'
                  : 'cm-offline'
              "
              [isOnline]="
                member.employeeNumber | getOnlineStatus: allMembersOnlineStatus
              "
            ></lm-apps-lmo-ui-online-status>
          </td>
          <td class="col-4 pl-1 gap-10">
            <div class="display-flex">
              <div class="aa-pl-1">
                <div class="bold-7 aa-pb-1">
                  {{ member.firstName }} {{ member.lastName }} ({{
                    member.employeeNumber
                  }})
                </div>
                <div>{{ member.jobDescription }}</div>
              </div>
            </div>
          </td>
          <td class="col-1 cm-shift-status">
            <div
              class="aa-pb-2"
              [class]="
                member.status === EmployeeShiftStatus.On ? 'aa-green' : 'aa-red'
              "
            >
              {{
                member.status === EmployeeShiftStatus.On
                  ? 'On Shift'
                  : 'Off Shift'
              }}
            </div>
            <span *ngIf="member.codes?.length" class="cm-exception">{{
              member.codes[0] | uppercase
            }}</span>
          </td>
          <td class="col-2">
            <span>{{
              (member.clockInTime | date: 'MM/dd/yy HH:mm') || '-'
            }}</span
            ><br />
            <span>{{
              (member.clockOutTime | date: 'MM/dd/yy HH:mm') || '-'
            }}</span>
          </td>
          <td>-</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td class="aa-pb-0 bg-white" colspan="8">
            <span class="aa-red d-flex aa-mt-4">
              {{
                filteredCrewMembers.length
                  ? 'No Results Found.'
                  : 'No Crew Members Assigned'
              }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<!-- Skeleton Loader-->
<div class="col-12" *ngIf="!crewMembersloaded">
  <div class="aa-pt-4">
    <p-skeleton width="5rem" styleClass="mb-2" height="1.5rem"></p-skeleton>
    <div class="d-flex justify-content-between">
      <p-skeleton width="20rem" styleClass="mb-2" height="2rem"></p-skeleton>
      <p-skeleton width="10rem" styleClass="mb-2" height="2rem"></p-skeleton>
    </div>
  </div>
  <div class="col-12 rounded border border-surface-200 pt-2 mb-4">
    <p-skeleton
      width="15rem"
      height="2.5rem"
      styleClass="mb-2"
      class="pt-2"
    ></p-skeleton>
    <p-skeleton width="100%" height="1.5rem"></p-skeleton>
    <p-skeleton width="100%" height="10rem" class="p-4"></p-skeleton>
  </div>
</div>
