<ng-container
  *ngIf="
    selectedBowItem.AssignedMembers && selectedBowItem.AssignedMembers.length;
    else noAssignedMembers
  "
>
  <ng-container *ngFor="let assignedMember of selectedBowItem.AssignedMembers">
    <ng-container
      *ngIf="
        assignedMember | getIndividualAmtStatus as assignedMemberStatusInfo
      "
    >
      <div
        class="d-flex aa-mb-1"
        [tooltip]="assignedMemberStatusInfoTemplate"
        placement="bottom"
      >
        <span class="aa-mr-2">
          <lm-apps-lmo-ui-bow-task-status-icon
            [taskState]="assignedMemberStatusInfo.status"
          ></lm-apps-lmo-ui-bow-task-status-icon>
        </span>
        <span>
          {{ assignedMember.LastName }},
          {{ assignedMember.FirstName.charAt(0) }}
        </span>
      </div>

      <ng-template #assignedMemberStatusInfoTemplate>
        <span>{{ assignedMemberStatusInfo.status | uppercase }}</span>
        <p>
          {{
            assignedMemberStatusInfo.timestamp | date: 'ddMMMyy hh:mm a':'GMT'
          }}
          GMT
        </p>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noAssignedMembers>
  <span>-</span>
</ng-template>
