import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './crew.actions';
import {
  CrewMemberService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
import { TEAM_OWENERSHIP_NOT_AVAILABLE_MSG } from '@lm-apps/lmo/shared/enums';
import { Action } from '@ngrx/store';
@Injectable()
export class CrewEffects {
  constructor(
    private readonly actions$: Actions,
    private crewMemberService: CrewMemberService,
    private toastService: CustomToastrService
  ) {}

  getAllCrewMembersByStation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(({ station }) =>
        this.crewMemberService.getAllCrewMemberByStation(station).pipe(
          map((crewMembers) => {
            return actions.loadCrewMembersByStationSuccess({ crewMembers });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load crew members at station',
              station
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  getAvailableTeamAssignmentsByStation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(({ station }) =>
        this.crewMemberService.availableTeamAssignment(station).pipe(
          switchMap((crews) => [
            actions.loadAvailableCrewsSuccess({ crews }),
            actions.updateSelectedStation({ station }),
          ]),
          catchError((error) => {
            this.toastService.error('Failed to load crew at station', station);
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  createCrew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createCrew),
      switchMap(({ crew }) =>
        this.crewMemberService.createTeamAssignment(crew).pipe(
          switchMap((crew) => {
            this.toastService.success('Created/Updated', crew.Name);
            return [
              actions.loadUpsertCrewSuccess({ crew }),
              actions.setSelectedCrew({ crew }),
            ];
          }),
          catchError((error) => {
            this.toastService.error('Creation Failed', crew.Name);
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  updateCrew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateCrew),
      switchMap(({ crew }) =>
        this.crewMemberService.updateTeamAssignment(crew).pipe(
          switchMap((crew) => {
            this.toastService.success('Created/Updated', crew.Name);
            return [
              actions.loadUpsertCrewSuccess({ crew }),
              actions.setSelectedCrew({ crew }),
            ];
          }),
          catchError((error) => {
            this.toastService.error('Update Failed', crew.Name || 'Error');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  deleteCrew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteCrew),
      switchMap(({ crew }) =>
        this.crewMemberService.deleteTeamAssignment(crew.Id).pipe(
          switchMap(() => {
            this.toastService.success('Deleted', crew.Name);
            return [
              actions.removeCrew({
                crewId: crew.Id,
              }),
              actions.clearSelectedCrewIfSelected({ crew }),
            ];
          }),
          catchError((error) => {
            this.toastService.error('Delete Failed', crew.Name);
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  enableShareCrew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.enableShareCrew),
      switchMap(({ crew }) =>
        this.crewMemberService.enableTeamSharing(crew.Id).pipe(
          map((crew) => {
            this.toastService.success('Created/Updated', crew.Name);
            return actions.loadUpsertCrewSuccess({ crew });
          }),
          catchError((error) => {
            this.toastService.error('Update Failed', crew.Name);
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  disableShareCrew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.disableShareCrew),
      switchMap(({ crew }) =>
        this.crewMemberService.disableTeamSharing(crew.Id).pipe(
          map((crew) => {
            this.toastService.success('Created/Updated', crew.Name);
            return actions.loadUpsertCrewSuccess({ crew });
          }),
          catchError((error) => {
            this.toastService.error('Update Failed', crew.Name);

            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  takeCrewOwnership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.takeCrewOwnership),
      switchMap(({ crew }) =>
        this.crewMemberService.takeTeamOwnership(crew.Id).pipe(
          switchMap((crew) => {
            this.toastService.success('Ownership Updated', crew.Name);
            return [
              actions.loadUpsertCrewSuccess({ crew }),
              actions.setSelectedCrew({ crew }),
            ];
          }),
          catchError((error) => {
            this.toastService.error('Update Failed', crew.Name);
            const actionArr: Array<Action> = [
              actions.throwError({ error: error.message || 'Unknown error' }),
            ];
            if (error.message.includes(TEAM_OWENERSHIP_NOT_AVAILABLE_MSG)) {
              actionArr.push(actions.removeCrew({ crewId: crew.Id }));
            }
            return actionArr;
          })
        )
      )
    )
  );

  releaseCrewOwnership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.releaseCrewOwnership),
      switchMap(({ crew }) =>
        this.crewMemberService.releaseTeamOwnership(crew.Id).pipe(
          switchMap((crew) => {
            this.toastService.success('Ownership Released', crew.Name);
            return [
              actions.loadUpsertCrewSuccess({ crew }),
              actions.clearSelectedCrewIfSelected({ crew }),
            ];
          }),
          catchError((error) => {
            this.toastService.error('Update Failed', crew.Name);
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );
}
