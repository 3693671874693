/**
 * Mel Types
 */
export enum MelTypes {
  /**
   * One Cycle Mel
   */
  ONE_CYCLE_MEL = 'One Cycle Mel',
  /**
   * Black Mels
   */
  BLACK_MEL = 'Black Mel',
}
