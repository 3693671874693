import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as fromCrewManpower from '@lm-apps/lmo/ui/data-access-crew-manpower';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';
import { ManpowerCrewMember, OnlineStatus } from '@lm-apps/lmo/ui/data';
import { Observable } from 'rxjs';

@Component({
  selector: 'lmo-ui-crew-manager-manpower-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewManagerManpowerHomeComponent implements OnInit {
  allCrewMembers$: Observable<ManpowerCrewMember[]> = this.store.pipe(
    select(fromCrewManpower.selectAllCrewMembers)
  );
  crewName$: Observable<string> = this.store.pipe(
    select(fromCrewManpower.selectCurrentCrewName)
  );
  crewMembersloaded$: Observable<boolean> = this.store.select(
    fromCrewManpower.selectCrewManpowerLoaded
  );
  allMembersOnlineStatus$: Observable<OnlineStatus[]> = this.store.pipe(
    select(fromCrewMemberStatus.selectAllCrewMemberOnlineStatus)
  );

  constructor(public modalRef: BsModalRef, private store: Store<any>) {}
  ngOnInit(): void {
    this.store.dispatch(fromCrewManpower.actions.init());
  }
  closeModal(): void {
    this.modalRef.hide();
  }
}
