import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class FlightsQueryGQL extends Query<
  { Flights: Array<data.Flight> },
  data.QueryFlightsArgs
> {
  override document = gql`
    query Flights($flightsSearchCriteria: FlightsSearchCriteria!) {
      FlightsFromQuery(flightsSearchCriteria: $flightsSearchCriteria) {
        Station
        Hash
        Equipment {
          AirlineCode
          DeliveryAirline
          EngineType
          EstimatedReturnToService
          FleetType
          Id
          IsActive
          IsEtopsCertified
          IsMaintenanceHold
          IsOutOfService
          NoseNumber
          RegistrationNumber
          Remark
          RONStation
        }
        Arrival {
          Id
          FlightNumber
          ActualOn
          ArrivalUtcAdjustment
          ActualIn
          ActualArrival
          ArrivalGate
          ArrivalStation
          ArrivalStatus
          ArrivalStatusCode
          EstimatedArrival
          ScheduledArrival
          EtopsCheckCompleted
          Hash
          DepartureStation
          LegStatus
          LegOriginDate
          ArrivalDelayOrEarlyStatusCode
          ArrivalDelayOrEarlyStatus
          IsEtopsFlight
        }
        Departure {
          Id
          FlightNumber
          ActualOut
          ActualOff
          ActualDeparture
          DepartureUtcAdjustment
          DepartureGate
          DepartureStatus
          DepartureStatusCode
          DepartureDelayOrEarlyStatusCode
          DepartureDelayOrEarlyStatus
          EstimatedDeparture
          ScheduledDeparture
          EtopsCheckCompleted
          Hash
          DepartureStation
          LegStatus
          LegOriginDate
          ArrivalStation
          IsEtopsFlight
        }
        Notifications {
          AircraftHash
          AircraftId
          AirlineCode
          AssignedToNames {
            FirstName
            LastName
          }
          Created
          CustomNotificationType
          Description
          Discrepancy
          DisplayName
          Effectivity
          ExtendedAttributes {
            ArmCode
            Classification
            Description
            MelNumber
            MicNumber
            ReferenceNumber
          }
          FlighttHash
          ForecastDate
          Id
          InBoundFlightLegId
          IsClosed
          IsDeleted
          ItemClosed
          ItemDeleted
          Label
          NoseNumber
          OutBoundFlightLegId
          Priority {
            Description
            Order
          }
          PriorityName
          ProcessDate
          Status
          SourceKey
          SourceSystem
          Type
          HasTask
          Task {
            Id
            AircraftId
            InboundFlightLegId
          }
        }
        AllTasks {
          AircraftId
          CallType {
            DisplayCallType
            KeyCallType
          }
          AssignedMembers {
            EmployeeId
            FirstName
            LastName
            AssignedByName
            AssignedBy
            AssignedOn
            Status
          }
          ChangedBy
          ChangedOn
          Created
          CustomNotificationType
          DeferredReason
          Description
          Effectivity
          ExternalItemId
          Id
          InboundFlightLegId
          InternalItemId
          IsAlternateTask
          IsAvionic
          IsManualTask
          IsOilService
          IsOspCheck
          JobType {
            DisplayJobType
            KeyJobType
          }
          Label
          Location
          MaintenancePriority {
            Description
            Order
          }
          MaintenanceType
          IsNfnf
          NotificationId
          OutBoundFlightLegId
          SpecialInstructions {
            Created
            EmployeeId
            EmployeeName
            Instructions
          }
          Station
          TaskState
          TaskUserReferenceId
          To
          ToEmployeeId
          UserDefinedItemId
          UserId
          UserName
        }
        GroundTime
      }
    }
  `;
}
