import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class AlternateTaskHistoryGQL extends Query<
  Pick<data.Query, 'AlternateTaskHistoryDetails'>,
  data.QueryAlternateTaskHistoryDetailsArgs
> {
  override document = gql`
    query AlternateTaskHistoryDetails(
      $alternateTaskHistoryInput: AlternateTaskHistoryInput!
    ) {
      AlternateTaskHistoryDetails(
        alternateTaskHistoryInput: $alternateTaskHistoryInput
      ) {
        NoseNumber
        ChangedBy
        Created
        Id
        IsAvionic
        IsOilService
        Location
        Station
        TaskState
      }
    }
  `;
}
