<form
  novalidate
  [formGroup]="formGroup"
  (ngSubmit)="getFlightLegs()"
  class="d-flex"
>
  <input
    class="form-control auto-height text-uppercase"
    type="text"
    [id]="inputId"
    formControlName="noseNumber"
    maxlength="3"
    pInputText
    pKeyFilter="alphanum"
  />
  <button label="Find" class="aa-pr-5" pButton type="submit"></button>
</form>
