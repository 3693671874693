import { Pipe, PipeTransform } from '@angular/core';
import {
  MaintenancePriorities,
  SceptreLables,
} from '@lm-apps/lmo/shared/enums';
import {
  DeferralItemType,
  FlightNotificationEntity,
  ItemType,
} from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'filterNotificationsToBeHidden',
  pure: true,
})
export class FilterNotificationsToBeHiddenDataPipe implements PipeTransform {
  notsToBeHidden = [
    MaintenancePriorities.NO_TAG.toString(),
    MaintenancePriorities.RESTRICTIVE.toString(),
    MaintenancePriorities.CI.toString(),
  ];

  excludedNotifications = new Set([SceptreLables.AirworthinessOPS.toString()]);

  transform(
    notifications: FlightNotificationEntity[],
    deferralMels: DeferralItemType[],
    fleetType: string
  ): FlightNotificationEntity[] {
    return notifications.filter((n) => {
      if (n.IsClosed) {
        return false;
      }

      if (this.excludedNotifications.has(n.Label)) {
        return false;
      }

      if (
        !n ||
        n.CustomNotificationType === 'melCycle' ||
        (n.Type && n.Type !== ItemType.Deferral)
      ) {
        return true;
      }

      /*
       * deferralMels is an array of BlackMel which we should show in left hand view
       * when PriorityName is included in notsToBeHidden array and also not included in deferralMels array then hide it from left hand view
       * when PriorityName is included in notsToBeHidden array but included in deferralMels array then show it in left hand view
       */
      if (
        (!n.PriorityName || this.notsToBeHidden.includes(n.PriorityName)) &&
        !deferralMels.some(
          (m) =>
            n.PriorityName !== null &&
            n.PriorityName !== undefined &&
            m.Name.trim().toUpperCase() ===
              n.PriorityName.trim().toUpperCase() &&
            m.Fleet === fleetType.trim().toUpperCase()
        )
      ) {
        return false;
      }

      return true;
    });
  }
}
