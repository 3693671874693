<section>
  <div class="modal-header">
    <span class="">Filters</span>
  </div>
  <div class="modal-body col-12">
    <lm-apps-cc-view-filter
      [isMoc]="isMoc"
      [filter]="filter$ | async"
      [allFleets]="(fleets$ | async) || []"
      [zones]="(zones$ | async) || []"
      [stations]="(stations$ | async) || []"
      (closeEvent)="closeModal()"
      (saveEvent)="saveFilter($event)"
    ></lm-apps-cc-view-filter>
  </div>
</section>
