/**
 * Deferral Type
 */
export enum DeferralType {
  /**
   * MEL
   */
  MEL = 'MEL',
  /**
   * CDL Configuration Deviation List
   */
  CDL = 'CDL',
  /**
   * TAC Tracking And Control
   */
  TAC = 'TAC',
  /**
   * NEF Non-Essential Equipment
   */
  NEF = 'NEF',
  /**
   * ME8
   */
  ME8 = 'ME8',
  /**
   * Y
   */
  Y = 'Y',
  /**
   * Monitors
   */
  MON = 'MON',
  /**
   * Open Mic
   */
  OPS = 'OPS',
  /**
   * ROB
   */
  ROB = 'ROB',
  /**
   * SAD
   */
  SAD = 'SAD',
  /**
   * CKC
   */
  CKC = 'CKC',
  /**
   * Daily Check
   */
  DailyCheck = 'DailyCheck',
  /**
   * Deferral
   */
  DEFERRAL = 'DEFERRAL',

  REM = 'REM',
  PilotOPS = 'PilotOPS',
}
