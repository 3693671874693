import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import {
  CreateTaskObj,
  SidePanelLayoutService,
} from '@lm-apps/lmo/ui/common/feature-shared';
import { CustomToastrService } from '@lm-apps/lmo/ui/common/services';
import {
  FlightNotificationEntity,
  FlightsEntity,
  ItemType,
  KeyJobType,
  MaintenanceTaskDisplay,
  MutationCreateTaskArgs,
  SelectedFlight,
  SelectedFlightData,
  SortableCrewColumn,
  SourceType,
  TaskEntity,
  TaskEvent,
  TaskState,
} from '@lm-apps/lmo/ui/data';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';
import * as fromFlights from '@lm-apps/lmo/ui/data-access-flight';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromRoot from '@lm-apps/lmo/ui/data-access-root';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
@Component({
  selector: 'lm-apps-lmo-ui-cc-view-rhs-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  @Output() scrollRhsEvent = new EventEmitter<string | null>();
  selectedFlightData$ = this.store.pipe(
    select(fromFlights.selectSelectedFlightData)
  );
  crew$ = this.store.pipe(select(fromRoot.selectCurrentCrew));
  crewMembersForAssignToDropdown$ = this.store.pipe(
    select(fromRoot.selectCrewMembersForAssignToDropdown)
  );

  maintenanceTaskDisplay!: MaintenanceTaskDisplay;
  predefinedTask$ = this.store.pipe(
    select(fromUser.selectActivePredefinedTasks)
  );
  showTaskDetail = false;
  showBowTaskDetail = false;
  user$ = this.store.pipe(select(fromUser.selectUser));
  allCrewMemberOnlineStatus$ = this.store.pipe(
    select(fromCrewMemberStatus.selectAllCrewMemberOnlineStatus)
  );
  sortCrewState$ = this.store.pipe(select(fromUser.selectSortCrewState));

  public billOfWorkItemsList$: Observable<TaskEntity[] | undefined> =
    this.store.pipe(select(fromFlights.getFlightBillOfWorkItems));
  public readonly keyJobTypes = KeyJobType;

  private flightDetailSub?: Subscription;
  public latestSelectedFlightData?: SelectedFlightData;

  constructor(
    @Inject(PING_APP_ENVIRONMENT) public config: AppEnvironment,
    private store: Store,
    private toastService: CustomToastrService,
    private layoutService: SidePanelLayoutService
  ) {}

  ngOnInit(): void {
    this.flightDetailSub = this.selectedFlightData$.subscribe((data) => {
      this.latestSelectedFlightData = data;
      if (data && data.flight) {
        if (data.task) {
          this.taskSelected(data.flight, data.task);
        } else if (data.notification) {
          this.selectNotification(data.flight, data.notification);
        } else {
          this.showTaskDetail = false;
          this.showBowTaskDetail = false;
          this.scrollRhsEvent.emit(data.flight.Hash);
        }
      }
    });
  }

  taskSelected(flight: FlightsEntity, task: TaskEntity) {
    const notification: FlightNotificationEntity | undefined =
      flight.Notifications?.find((n) => task.NotificationId === n.Id);
    //Not showing notification error toast for Bow tasks,
    if (!notification && task.JobType?.KeyJobType !== KeyJobType.Bow) {
      this.toastService.error('Task does not have respective notification');
      return;
    }
    this.maintenanceTaskDisplay = CreateTaskObj(flight, notification, task);
    if (task.JobType?.KeyJobType === KeyJobType.Bow) {
      this.showBowTaskDetail = true;
      this.showTaskDetail = false;
    } else {
      this.showTaskDetail = true;
      this.showBowTaskDetail = false;
    }
    this.scrollRhsEvent.emit(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId
    );
  }

  setSelectedFlight(
    flight: FlightsEntity,
    notificationId: string,
    taskEvent?: TaskEvent
  ) {
    let notId: string | null | undefined = notificationId;
    if (taskEvent) {
      notId = taskEvent.Task.NotificationId;
    }
    const selectedFlight: SelectedFlight = {
      FlightHash: flight.Hash,
      FlightNotificationId: notId,
      TaskId: taskEvent?.Task?.Id,
    };
    this.store.dispatch(
      fromFlights.actions.setSelectedFlight({ selectedFlight })
    );
    this.scrollRhsEvent.emit(notId);
  }

  selectNotification(
    flight: FlightsEntity,
    notification: FlightNotificationEntity
  ) {
    this.maintenanceTaskDisplay = CreateTaskObj(flight, notification);
    this.showTaskDetail = true;
    this.showBowTaskDetail = false;
    this.scrollRhsEvent.emit(notification.Id);
  }

  closeSidePanel() {
    this.layoutService.closeSidePanel();
  }

  closeTask() {
    this.showTaskDetail = false;
    this.showBowTaskDetail = false;
    this.store.dispatch(fromFlights.actions.resetSelectedFlight());
  }

  upsertTask(taskArg: MutationCreateTaskArgs) {
    this.store.dispatch(
      fromFlights.actions.createTask({ maintenanceTaskArgs: taskArg })
    );
  }

  createManualTask(taskArg: MutationCreateTaskArgs) {
    this.store.dispatch(
      fromFlights.actions.createManualTask({ maintenanceTaskArgs: taskArg })
    );
  }

  /*
    The following notification types are always reopenable even if they are closed:
    - External
      - Hermes
      - DailyCheck
    - Internal: All
    - User Defined: All
  */
  public isAlwaysReopenable(): boolean {
    const notification = this.latestSelectedFlightData?.notification;

    if (
      notification?.SourceType === SourceType.Internal ||
      notification?.SourceType === SourceType.UserDefined
    ) {
      return true;
    }

    const reopenableExternalNotificationTypes = [
      ItemType.Hermes,
      ItemType.DailyCheck,
    ];
    if (!notification?.Type) {
      return false;
    }

    if (
      notification?.SourceType === SourceType.External &&
      reopenableExternalNotificationTypes.includes(notification?.Type)
    ) {
      return true;
    }

    return false;
  }

  canReopenTask() {
    const taskStatusList = new Set([TaskState.Completed, TaskState.Deferred]);

    const taskState =
      this.maintenanceTaskDisplay?.MaintenanceTaskInput?.TaskState;
    const hasTask = this.maintenanceTaskDisplay?.AdditionalTaskDetail?.HasTask;

    if (hasTask && taskState) {
      return (
        taskStatusList.has(taskState) ||
        (taskState === TaskState.Closed && this.isAlwaysReopenable())
      );
    }

    return false;
  }

  canCompleteOrDeferTask() {
    const completedTaskStatusList = new Set([
      TaskState.Completed,
      TaskState.Closed,
      TaskState.Deferred,
      TaskState.SystemClosed,
    ]);
    if (this.maintenanceTaskDisplay.AdditionalTaskDetail.HasTask) {
      if (this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskState) {
        return !completedTaskStatusList.has(
          this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskState
        );
      } else {
        return false;
      }
    }
    return false;
  }
  ngOnDestroy() {
    // remove mouse down listener here
    this.flightDetailSub?.unsubscribe();
  }

  sortCrew(column: SortableCrewColumn) {
    this.store.dispatch(fromUser.actions.sortCrew({ column }));
  }
}
