import { Injectable } from '@angular/core';
import { SaveUserInput } from '@lm-apps/lmo/ui/data';
import { map } from 'rxjs/operators';
import { GetUserGQL, SaveUserGQL } from './query';
import { MissingDataError } from '../custom-errors';
import { UserAcknowledgeNoticeGQL } from './query/user-acknowledge-notice.query';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private _getUser: GetUserGQL,
    private _saveUser: SaveUserGQL,
    private _userAcknowledgeNotice: UserAcknowledgeNoticeGQL
  ) {}
  getUser() {
    return this._getUser.fetch().pipe(
      map(({ data }) => {
        return data.User;
      })
    );
  }

  saveUser(user: SaveUserInput) {
    return this._saveUser.mutate({ user }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.SaveUser; // !! Needs testing. Will not throw error if SaveUser is null.
      })
    );
  }

  userAcknowledgeNotice(noticeId: string) {
    return this._userAcknowledgeNotice.mutate({ noticeId }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.UserAcknowledgeNotice;
      })
    );
  }
}
