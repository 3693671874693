export enum ItemType {
  HERMES = 'HERMES',
  DAILY_CHECK = 'DailyCheck',
  TII = 'TII',
  DEFERRAL = 'Deferral',
  X = 'X',
  ARMS = 'ARMS',
  DAILY_CHECK_X = 'DailyCheck-x',
  MON = 'MON',
  ROB = 'ROB',
  OPS = 'OPS',
  POST_ARRIVAL_CHECK = 'PostArrivalCheck',
  PRE_DEPARTURE_CHECK = 'PreDepartureCheck',
  TIRE_CHECK = 'TireCheck',
  TRANSIT_CHECK = 'TransitCheck',
  CFP = 'CFP',
  CRC = 'CRC',
  AIRCRAFT_WASH = 'AircraftWash',
  OIL_CHECK = 'OilCheck',
  OIL = 'OIL', // OIL is used only for OSP oil icon
  IFE_CHECK = 'IFECheck',
  IBGATE = 'I/B GATE',
  OBGATE = 'O/B GATE',
  CUBA = 'CUBA',
  MANUAL_CREATED = 'ManualCreated',
  MANUAL_TASK = 'ManualTask',
  MANUAL_CUBA_TASK = 'ManualCubaTask',
  UNDEFINED = 'Undefined',
  REM = 'REM',
  BOW = 'BOW',
}
