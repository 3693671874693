import { Injectable } from '@angular/core';
import { Subscription } from 'apollo-angular';
import { FEATURE_FLAGS_GATEWAY } from './feature-flags-config';
import {
  FeatureFlagChangedResponse,
  FeatureFlagParams,
  FeatureFlagSubscription,
} from '@lm-apps/common/feature-flag-data';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsSubscriptionService extends Subscription<
  FeatureFlagChangedResponse,
  FeatureFlagParams
> {
  override client = FEATURE_FLAGS_GATEWAY;
  document = FeatureFlagSubscription;
}
