<div class="modal-header d-print-none">
  <span>New Task</span>
  <p-button
    icon="pi pi-times"
    styleClass="p-button-secondary p-button-text cross"
    (onClick)="closeModal()"
  ></p-button>
</div>
<div class="modal-body nt-radius">
  <div *ngIf="!flight">
    <div
      *ngIf="!((this.flightLegs$ | async)?.length > 0)"
      class="row col-sm-12"
    >
      <div class="col-sm-3 aa-pb-4">
        <label for="newTaskSearchNoseNumber" class="my-2">Enter A/C#</label>
        <lm-apps-search-nose-number
          inputId="newTaskSearchNoseNumber"
          (noseChange)="searchFlightLegs($event)"
        ></lm-apps-search-nose-number>
      </div>
    </div>
    <div *ngIf="(this.flightLegs$ | async)?.length > 0; else notFound">
      <lm-apps-flight-leg-result
        [selectedStations]="(selectedStations$ | async) || []"
        [flightLegs]="(flightLegs$ | async) || []"
        (showManualTask)="showManualTask($event)"
      ></lm-apps-flight-leg-result>
    </div>
    <ng-template #notFound>
      <div *ngIf="(this.flightLegs$ | async)?.length === 0">
        <h5 class="text-danger aa-pl-4 aa-pb-4 hidden-print">
          No flight legs found or incorrect AC#
        </h5>
      </div>
    </ng-template>
  </div>
  <form
    *ngIf="flight"
    [formGroup]="newTaskForm"
    (ngSubmit)="submitForm()"
    class="d-print-block printable"
  >
    <div class="col-sm-12 row my-2">
      <div class="col-sm-3">
        <label class="bold-7">Nose #</label>&nbsp;
        <span class="h3">{{ flight.NoseNumber | uppercase }}</span>
      </div>
      <span class="col-sm-3 aa-pl-5">
        <label class="bold-7">Route</label>&nbsp;
        <span class="h3">
          {{ flight.DepartureStation | uppercase }} -
          {{ flight.ArrivalStation | uppercase }}
        </span>
      </span>
    </div>
    <div class="col-sm-12 row mb-2 pr-0">
      <div class="d-flex col-sm-8 pl-0">
        <div class="col-sm-4 pr-0">
          <label for="predefinedTypeNewTask" class="bold-7">Pre-defined</label>
          <p-dropdown
            inputId="predefinedTypeNewTask"
            formControlName="predefinedTypes"
            [options]="(predefinedTask$ | async) || []"
            optionLabel="Title"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
            placeholder="Select a Predefined Task Type"
            (onChange)="predefinedChange($event.value)"
          ></p-dropdown>
        </div>
        <div class="col-sm-4 pr-0">
          <label for="jobTypeNewTask" class="bold-7">Task Type</label>
          <p-dropdown
            inputId="jobTypeNewTask"
            formControlName="jobType"
            [options]="jobTypes"
            optionLabel="DisplayJobType"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
          ></p-dropdown>
        </div>
        <div class="col-sm-4 pr-0">
          <label for="callTypeNewTask" class="bold-7">Call Type</label>
          <p-dropdown
            inputId="callTypeNewTask"
            formControlName="callType"
            [options]="callTypes"
            optionLabel="DisplayCallType"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-sm-4 px-0 d-print-none">
        <label for="ccAssignToNewTask" class="bold-7">Assign To</label>
        <lm-apps-assign-to-dropdown
          inputId="ccAssignToNewTask"
          [allOnlineStatus]="(allCrewMemberOnlineStatus$ | async) || []"
          [crewMembers]="this.crew?.Members || []"
          (sortCrew)="sortCrew($event)"
          [sortState]="sortCrewState$ | async"
          formControlName="selectedCrewMembers"
        ></lm-apps-assign-to-dropdown>
      </div>
    </div>
    <div class="col-sm-12 mb-2">
      <label class="bold-7">Assigned</label>
      <lm-apps-assigned-crew-member-table
        *ngIf="this.newTaskForm.controls.selectedCrewMembers.valid"
        [selectedCrewMembers]="
          this.newTaskForm.controls.selectedCrewMembers.value ?? []
        "
        (selectedCrewMemberChange)="addOrRemoveSelectedMechanic($event)"
      >
      </lm-apps-assigned-crew-member-table>
    </div>
    <div class="col-sm-12 mb-2" *ngIf="!this.isMOC">
      <label class="bold-7" for="labelNewTask">Label</label>
      <div class="col-sm-2 pl-0">
        <input
          type="text"
          class="form-control"
          id="labelNewTask"
          name="label"
          formControlName="label"
          maxlength="11"
        />
      </div>
    </div>
    <div class="col-sm-12 mb-2">
      <label class="bold-7" for="descriptionNewTask">Description</label>
      <div class="col-sm-6 pl-0">
        <textarea
          rows="1"
          type="text"
          class="form-control"
          id="descriptionNewTask"
          name="description"
          formControlName="description"
          maxlength="380"
        ></textarea>
        <label class="right"
          >{{ newTaskForm.controls.description.value?.length }}/380</label
        >
      </div>
    </div>
    <div class="col-sm-12 row mb-2">
      <div class="col-sm-9">
        <label class="bold-7" for="specialInstructionNewTask"
          >Special Instructions</label
        >
        <textarea
          rows="3"
          type="text"
          class="form-control"
          id="specialInstructionNewTask"
          name="specialInstruction"
          formControlName="specialInstruction"
          minlength="1"
          maxlength="380"
          placeholder="Add instructions"
        ></textarea>
        <label class="right"
          >{{
            newTaskForm.controls.specialInstruction.value?.length
          }}/380</label
        >
      </div>
      <div class="col-sm-3 pt-4 mt-2">
        <div class="d-flex">
          <p-checkbox
            class="bold-7"
            formControlName="isAvionic"
            [binary]="true"
            label="Avionic"
          ></p-checkbox>
        </div>
        <div class="d-flex">
          <p-checkbox
            class="bold-7"
            formControlName="isNFNF"
            [binary]="true"
            label="NFNF"
          ></p-checkbox>
        </div>
        <div class="d-flex">
          <p-checkbox
            class="bold-7"
            formControlName="isOilService"
            [binary]="true"
            label="Oil Service"
          ></p-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="flight" class="col-sm-12 mb-2 modal-footer d-print-none">
      <p-button label="Print" (click)="printSection()"
        ><i class="fa fa-duotone fa-print aa-pr-2"></i
      ></p-button>
      <button
        label="Create Manual Task"
        pButton
        type="submit"
        [disabled]="!newTaskForm.valid"
      ></button>
    </div>
  </form>
</div>
