import { ManpowerCrew } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Crew Manpower] Init');

export const throwError = createAction(
  '[Crew Manpower] API request failed',
  props<{ error: any }>()
);
export const loadCrewManpowerSuccess = createAction(
  '[Crew Manpower] Load crew from manpower api success',
  props<{ crews: ManpowerCrew[] }>()
);

export const upsertCrewMember = createAction(
  '[Crew Manpower] Upsert specific crew member',
  props<{ crew: ManpowerCrew }>()
);
