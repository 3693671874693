<div class="checkboxList" *ngFor="let item of data; let i = index">
  <div
    class="aa-pl-2 aa-p-1"
    [ngClass]="selecteditem === item ? selectedCss : ''"
    (click)="selectItem(item)"
  >
    <div
      class="p-checkbox p-element indeterminate-margin-0-25"
      *ngIf="this.indeterminate_values | isIncludedinArray: item[valueField]"
    >
      <div class="p-component">
        <div class="p-hidden-accessible">
          <input
            type="checkbox"
            [value]="item[valueField]"
            [name]="identifier + '_' + i"
            aria-checked="false"
          />
        </div>
        <div
          class="p-checkbox-box pi pi-minus indeterminate-checkbox"
          (click)="onChange({ checked: true }, item[valueField])"
        ></div>
      </div>
      <label class="p-checkbox-label indeterminate-checkbox-label">{{
        item[labelField]
      }}</label>
    </div>

    <p-checkbox
      *ngIf="!(this.indeterminate_values | isIncludedinArray: item[valueField])"
      [label]="item[labelField]"
      [name]="identifier + '_' + i"
      [value]="item[valueField]"
      (onChange)="onChange($event, item[valueField])"
      (blur)="onTouch()"
      [disabled]="_disabled"
      [binary]="true"
      [ngModel]="isChecked(item)"
      [attr.tabindex]="tabindex"
      [ngClass]="
        item[foreColorConditionField]
          ? foreColorConditionCss
          : foreColorConditionElseCss
      "
    ></p-checkbox>
  </div>
</div>
