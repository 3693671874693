import { Component, Input } from '@angular/core';
import { FlightsEntity } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-aircraft-attribute-table',
  templateUrl: './aircraft-attribute-table.component.html',
})
export class AircraftAttributeTableComponent {
  @Input() flight!: FlightsEntity;
  @Input()
  safeAircraftUri!: string;
}
