import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TaskEntity,
  TaskEvent,
  ItemType,
  TaskState,
  KeyJobType,
} from '@lm-apps/lmo/ui/data';
import { faOilCanDrip } from '@fortawesome/pro-solid-svg-icons';
@Component({
  selector: 'lm-apps-task-item',
  templateUrl: './task-item.component.html',
  styleUrls: ['./task-item.component.scss'],
})
export class TaskItemComponent {
  faOilCanDrip = faOilCanDrip;
  @Input() task!: TaskEntity;
  @Input() isRhs = false;
  @Input() title = '';
  @Output() selectedTask = new EventEmitter<TaskEvent>();
  ItemType = ItemType;
  TaskState = TaskState;
  public readonly keyJobType = KeyJobType;

  onClick(event: Event) {
    this.selectedTask.emit({ event: event, Task: this.task });
  }
}
