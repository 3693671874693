import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  FlightsEntity,
  FlightNotificationEntity,
  ItemType,
  NotificationEvent,
} from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-ac-attr-maint',
  templateUrl: './ac-attr-maint.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcAttrMaintComponent implements OnChanges {
  @Input() flight!: FlightsEntity;
  @Input()
  safeAircraftUri!: string;
  @Output() emitSelectNotification = new EventEmitter<string>();
  ItemType = ItemType;

  excludedTypes = [ItemType.Arms, ItemType.Hermes, ItemType.Bow];
  filteredNotifications: FlightNotificationEntity[] = [];

  ngOnChanges() {
    this.filteredNotifications =
      this.flight?.Notifications?.filter(
        (n) => !this.excludedTypes.includes(n.Type ?? ItemType.Undefined)
      ).map((x) => {
        const task = this.flight.AllTasks?.find(
          (task) => task.Id == x.Task?.Id
        );

        return <FlightNotificationEntity>{
          ...x,
          Task: { ...x.Task, IsOilService: task?.IsOilService },
        };
      }) ?? [];
  }
  selectNotification(notificationEvent: NotificationEvent) {
    this.emitSelectNotification.emit(notificationEvent.Notification.Id);
  }
}
