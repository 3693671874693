/**
 * Deferral Priority Code
 */
export enum DeferralPriorityCode {
  /**
   * Quick Fix
   */
  QFX = 'QFX',
  /**
   * QFIX
   */
  QFIX = 'QFIX',
  /**
   * Each Day Follow Up with Quick Fix
   */
  QDAY = 'QDAY',
  /**
   * Each Flight Follow Up with Quick Fix
   */
  QFLT = 'QFLT',
  /**
   * Each Day Follow Up
   */
  DAY = 'DAY',
  /**
   * Each Flight Follow Up
   */
  FLT = 'FLT',
  /**
   * NEF (Quick Fix)
   */
  QNEF = 'QNEF',
  /**
   * Monitors
   */
  MON = 'MON',
  /**
   * Open Mic
   */
  OPS = 'OPS',
  /**
   * ROB
   */
  ROB = 'ROB',
  /**
   * SAD
   */
  SAD = 'SAD',
  /**
   * Daily Transit
   */
  DAILYTRN = 'DAILYTRN',
  /**
   * Daily
   */
  DAILY = 'DAILY',

  REM = 'REM',

  PilotOPS = 'PilotOPS',
}
