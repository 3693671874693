import { Inject, NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloLink,
} from '@apollo/client/core'; //dont use `@apollo/client` as it needs `react` dependency
import 'isomorphic-fetch';
import { setContext } from '@apollo/client/link/context';
import {
  PING_APP_ENVIRONMENT,
  PingAuthenticationService,
} from '@aa-techops-ui/ping-authentication';
import { ApolloModule, Apollo } from 'apollo-angular';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import { APP_VERSION } from '@lm-apps/lmo/shared/enums';
import { CustomToastrService } from '../custom-toastr.service';
export const TFP_GRAPH_GATEWAY = 'TFP_GRAPH_GATEWAY';
export function createApolloClients(
  apollo: Apollo,
  env: AppEnvironment,
  pingAuthenticationService: PingAuthenticationService,
  toastrService: CustomToastrService
) {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      const url = `${env.LMO_API_BASE_URL}/graphql`;
      if (graphQLErrors) {
        console.log('GraphQLErrors', graphQLErrors);
        for (const err of graphQLErrors) {
          if (
            err.message.includes('Invalid token') ||
            err.message.includes('jwt expired')
          ) {
            pingAuthenticationService.unAuthorizeRequest(url);
          }
          if (err.extensions && err.extensions['originalError']) {
            const originalError: any = err.extensions['originalError'];
            if (originalError.statusCode && originalError.statusCode === 401) {
              pingAuthenticationService.unAuthorizeRequest(url);
            }
          }
          console.log(
            `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
          );
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if ('statusCode' in networkError && networkError.statusCode === 401) {
          pingAuthenticationService.unAuthorizeRequest(url);
        }
      }
      return forward(operation);
    }
  );
  const addQueryNameLink = (url: string) =>
    new ApolloLink((operation, forward) => {
      operation.setContext({
        uri: `${url}?op=${operation.operationName}`,
      });
      return forward(operation);
    });
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `bearer ${pingAuthenticationService.AccessToken}`,
      },
    };
  });
  const http = createHttpLink({
    uri: `${env.LMO_API_BASE_URL}/graphql`,
    headers: {
      Accept: 'application/json; charset=utf-8',
      'x-app-name': env.APP_NAME,
      'apollographql-client-name': env.APP_NAME,
      'apollographql-client-version': '1.0',
    },
  });
  const retryLink = new RetryLink({
    delay: {
      initial: 100,
      max: 2000,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: async (err, _operation) => {
        console.log('Retry logic....', err, _operation);
        return !!err;
      },
    },
  });
  //Check UI Version Link
  const checkUIVersionLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;
      if (headers) {
        const app_version = headers.get(APP_VERSION);
        if (app_version > env.APP_VERSION) {
          toastrService.warning(
            'A new version of the app is now available. Please reload your browser window to continue.',
            'New Version Available'
          );
        }
      }
      return response;
    });
  });
  const link = from([
    errorLink,
    retryLink,
    authLink,
    addQueryNameLink(`${env.LMO_API_BASE_URL}/graphql`),
    http,
  ]);
  const defaultOptions: ApolloClientOptions<unknown> = {
    cache: new InMemoryCache({ addTypename: false }),
    link: checkUIVersionLink.concat(link),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
  const httpGraphLink = createHttpLink({
    uri: env.TFP_GRAPH_URL,
    headers: {
      Accept: 'application/json; charset=utf-8',
      'apollographql-client-name': env.APP_NAME,
      'apollographql-client-version': '1.0',
    },
  });

  const graphLink = from([errorLink, retryLink, authLink, httpGraphLink]);

  const tfpOptions: ApolloClientOptions<unknown> = {
    name: TFP_GRAPH_GATEWAY,
    link: graphLink,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  };
  apollo.createDefault(defaultOptions);
  apollo.createNamed(TFP_GRAPH_GATEWAY, tfpOptions);
}
@NgModule({
  exports: [ApolloModule],
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    pingAuthenticationService: PingAuthenticationService,
    toastrService: CustomToastrService,
    @Inject(PING_APP_ENVIRONMENT) config: AppEnvironment
  ) {
    createApolloClients(
      apollo,
      config,
      pingAuthenticationService,
      toastrService
    );
  }
}
