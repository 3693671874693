import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
@Pipe({
  name: 'orderBy',
})
export class orderByPipe implements PipeTransform {
  transform(array: any, sortBy: string, order: 'asc' | 'desc' = 'asc'): any[] {
    // setting default ascending order
    return orderBy(array, [sortBy], [order]);
  }
}
