import { Injectable } from '@angular/core';
import { NoticeInput } from '@lm-apps/lmo/ui/data';
import { map } from 'rxjs/operators';
import {
  GetActiveNoticesGQL,
  GetAllNoticesGQL,
  DeleteNoticeGQL,
  SaveNoticeGQL,
} from './query';
import { MissingDataError } from '../custom-errors';

@Injectable({
  providedIn: 'root',
})
export class NoticesService {
  constructor(
    private _getActiveNotices: GetActiveNoticesGQL,
    private _getAllNotices: GetAllNoticesGQL,
    private _deleteNotices: DeleteNoticeGQL,
    private _saveNotices: SaveNoticeGQL
  ) {}
  getAllNotices() {
    return this._getAllNotices.fetch().pipe(
      map(({ data }) => {
        return data.Notices;
      })
    );
  }

  getActiveNotices() {
    return this._getActiveNotices.fetch().pipe(
      map(({ data }) => {
        return data.ActiveNotices;
      })
    );
  }

  saveNotice(notice: NoticeInput) {
    const notices = [notice];
    return this._saveNotices.mutate({ notices }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.SaveNotices[0];
      })
    );
  }

  deleteNotice(id: string) {
    const ids = [id];
    return this._deleteNotices.mutate({ ids }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.DeleteNotices;
      })
    );
  }

  // Commenting these out because they are not currently used in UI.
  // Can be added back if needed.

  // getNotice() {
  //   return this._getNotice.fetch().pipe(
  //     map(({ data }) => {
  //       return data.Notice;
  //     })
  //   );
  // }

  // saveNotices(notices: NoticeInput[]) {
  //   return this._saveNotices.mutate({ notices }).pipe(
  //     map(({ data }) => {
  //       if (!data) {
  //         throw new MissingDataError();
  //       }
  //       return data.SaveNotices;
  //     })
  //   );
  // }

  // deleteNotices(ids: string[]) {
  //   return this._deleteNotices.mutate({ ids }).pipe(
  //     map(({ data }) => {
  //       if (!data) {
  //         throw new MissingDataError();
  //       }
  //       return data.DeleteNotices;
  //     })
  //   );
  // }
}
