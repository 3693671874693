import {
  FlightsEntity,
  SortDetail,
  ColumnName,
  SortOrder,
  SelectedFlight,
  FLIGHT_FEATURE_KEY,
  TaskState,
  TaskEntity,
} from '@lm-apps/lmo/ui/data';
import { mapper, updatePendingStatus } from './signalr-mapper';
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update,
} from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './flight.actions';
import { mergeFlightUpdates } from '../helpers/selective-merge';
export interface State extends EntityState<FlightsEntity> {
  selectedId?: string | number; // which Flight record has been selected
  loaded: boolean; // is api call in progress
  error?: string | null; // last known error (if any)
  sortDetail: SortDetail;
  selectedFlight: SelectedFlight;
  // filter: FlightFilter;
  myTasks: {
    selectedFlight: SelectedFlight;
    sortDetail: SortDetail;
  };
  selectedBowItem: TaskEntity | null;
  lastUpdated?: string;
}

export interface FlightPartialState {
  readonly [FLIGHT_FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<FlightsEntity> =
  createEntityAdapter<FlightsEntity>({
    selectId: (flight: FlightsEntity) => flight.Hash,
  });

export const initialState: State = adapter.getInitialState({
  // set initial required properties
  ids: [],
  entities: Array<FlightsEntity>(),
  selectedId: undefined,
  loaded: false,
  error: null,
  sortDetail: {
    column: ColumnName.ARRIVAL,
    sortOrder: SortOrder.ASCENDING,
  },
  selectedFlight: {
    FlightHash: '',
  },
  myTasks: {
    selectedFlight: {
      FlightHash: '',
    },
    sortDetail: {
      column: ColumnName.ARRIVAL,
      sortOrder: SortOrder.ASCENDING,
    },
  },
  selectedBowItem: null,
});

const flightReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(actions.loadFlightSuccess, (state, { flights }) => {
    console.time('Selective merge execution time'); // record start time for performance measurement
    try {
      const updatedFlights = mergeFlightUpdates(state, flights);
      return adapter.setAll(updatedFlights, {
        ...state,
        loaded: true,
        lastUpdated: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Error merging flight updates:', error);
      console.warn('Falling back and considering api response as latest.');
      return adapter.setAll(flights, {
        ...state,
        loaded: true,
        lastUpdated: new Date().toISOString(),
      }); // Fallback: If an error occurs, consider all flights as new.
    } finally {
      console.timeEnd('Selective merge execution time');
    }
  }),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  })),
  on(actions.setSortDetail, (state, { sortDetail }) => ({
    ...state,
    sortDetail,
  })),
  on(actions.setSelectedFlight, (state, { selectedFlight }) => ({
    ...state,
    selectedFlight,
  })),
  on(actions.resetSelectedFlight, (state) => ({
    ...state,
    selectedFlight: {
      ...state.selectedFlight,
      FlightNotificationId: undefined,
      TaskId: undefined,
    },
  })),
  on(actions.setSelectedMyTasksFlight, (state, { selectedFlight }) => ({
    ...state,
    myTasks: {
      ...state.myTasks,
      selectedFlight,
    },
  })),
  on(actions.loadFlightsRequest, (state) => ({
    ...state,
    loaded: false,
  })),
  on(actions.loadUpdateTaskStateSuccess, (state, { taskId, taskState }) => ({
    ...state,
    entities: updateEntitiesWithTaskState(state.entities, taskId, taskState),
  })),
  on(actions.createTask, (state, { maintenanceTaskArgs }) => {
    return updatePendingStatus(state, maintenanceTaskArgs);
  }),
  on(
    actions.updateFlightData,
    (
      state,
      { aircraftId, flightLegId, notification, task, realTimeNotification }
    ) => {
      const flight = Object.values(state.entities).find(
        (f) =>
          f &&
          f.Equipment.Id === aircraftId &&
          (f.Arrival?.Id ?? f.Departure?.Id) == flightLegId
      );

      if (!flight) {
        //if no flight found, return state
        return state;
      }
      //update the flight with the new notification and task
      const updatedFlight = mapper(
        flight,
        notification,
        task,
        realTimeNotification
      );
      //if the updated flight is null(took off or other conditions???), remove the flight from the state
      if (updatedFlight === null) {
        return adapter.removeOne(flight.Hash, {
          ...state,
          lastUpdated: new Date().toISOString(),
        }); //TODO: check if this is correct
      }
      //update the flight in the state
      return adapter.updateOne(
        { id: flight.Hash, changes: updatedFlight },
        { ...state, lastUpdated: new Date().toISOString() }
      );
    }
  ),
  on(actions.setMyTasksSortDetail, (state, { sortDetail }) => ({
    ...state,
    myTasks: {
      ...state.myTasks,
      sortDetail,
    },
  })),
  on(actions.setSelectedBowItem, (state, { selectedBowItem }) => ({
    ...state,
    selectedBowItem,
  })),
  on(actions.clearSelectedBowItem, (state) => ({
    ...state,
    selectedBowItem: null,
  }))
);

export const getSelectedFlightId = (state: State) => state.selectedId;

export const getSelectedFlightSortDetail = (state: State) => state.sortDetail;

export const getSelectedFlight = (state: State) => state.selectedFlight;

export const getSelectedMyTasksFlight = (state: State) =>
  state.myTasks.selectedFlight;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of flight ids
export const selectFlightIds = selectIds;

// select the dictionary of flight entities
export const selectFlightEntities = selectEntities;

// select the array of flights
export const selectAllFlights = selectAll;

// select the total flight count
export const selectFlightTotal = selectTotal;

export function reducer(state: State | undefined, action: Action) {
  return flightReducer(state, action);
}

// This function updates the task state of a specific task within all flight entities.
export function updateEntitiesWithTaskState(
  entities: { [key: string]: FlightsEntity | undefined },
  taskId: string,
  taskState: TaskState
): { [key: string]: FlightsEntity } {
  const accumulator: { [key: string]: FlightsEntity } = {};
  return Object.keys(entities).reduce((acc, key) => {
    const flight = entities[key];
    if (!flight) {
      return acc;
    }
    const updatedTasks = flight.AllTasks.map((task) => {
      if (task.Id === taskId) {
        return { ...task, TaskState: taskState };
      }
      return task;
    });
    acc[key] = { ...flight, AllTasks: updatedTasks };
    return acc;
  }, accumulator);
}
