import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightsEntity } from '@lm-apps/lmo/ui/data';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-delay-alert',
  templateUrl: './delay-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelayAlertComponent {
  @Input() flightPair!: FlightsEntity;
  @Input() toggleHeaderBgColor!: boolean;
  constructor(public modalRef: BsModalRef) {}
}
