import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFeatureFlagStatus from './+state/feature-flag.reducer';
import { ToastrModule } from 'ngx-toastr';
import { FEATURE_FLAG_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { FeatureFlagEffects } from './+state/feature-flag.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      FEATURE_FLAG_FEATURE_KEY,
      fromFeatureFlagStatus.reducer
    ),
    EffectsModule.forFeature([FeatureFlagEffects]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 0,
      preventDuplicates: true,
      newestOnTop: false,
    }),
  ],
})
export class LmoUiDataAccessFeatureFlagModule {}
