import { Component, Input } from '@angular/core';
import { PingAuthenticationService } from '@aa-techops-ui/ping-authentication';

@Component({
  selector: 'lm-apps-lmo-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() date: string | null = '';
  @Input() appEnvironment = '';
  @Input() ping!: PingAuthenticationService;
  isMenuOpen = false;

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  closeMenu() {
    this.isMenuOpen = false;
  }
  async refreshToken() {
    await this.ping.signinSilent();
  }
}
