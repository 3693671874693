import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChevronDownComponent } from './components/chevron/chevron-down/chevron-down.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { SidePanelLayoutComponent } from './components/side-panel-layout/side-panel-layout.component';
import { OnlineStatusComponent } from './components/online-status/online-status.component';
import { NosenumberComponent } from './components/nosenumber/nosenumber.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { AssignedCrewMemberTableComponent } from './components/assigned-crew-member-table/assigned-crew-member-table.component';
import { AssignedCrewDetailComponent } from './components/assigned-crew-detail/assigned-crew-detail.component';
import { TaskHistoryComponent } from './components/task-history/task-history.component';
import { BowTaskStatusIconComponent } from './components/bow-task-status-icon/bow-task-status-icon.component';
import { DropdownModule } from 'primeng/dropdown';
import { AssignToDropdownComponent } from './components/assign-to-dropdown/assign-to-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    LmoUiCommonPipesModule,
    FontAwesomeModule,
    FormsModule,
    CheckboxModule,
    MultiSelectModule,
    TableModule,
    DropdownModule,
  ],
  declarations: [
    ChevronDownComponent,
    NotificationItemComponent,
    TaskItemComponent,
    CheckboxListComponent,
    SidePanelLayoutComponent,
    OnlineStatusComponent,
    NosenumberComponent,
    AssignedCrewMemberTableComponent,
    AssignedCrewDetailComponent,
    TaskHistoryComponent,
    BowTaskStatusIconComponent,
    AssignToDropdownComponent,
  ],
  exports: [
    ChevronDownComponent,
    NotificationItemComponent,
    TaskItemComponent,
    CheckboxListComponent,
    SidePanelLayoutComponent,
    OnlineStatusComponent,
    NosenumberComponent,
    AssignedCrewMemberTableComponent,
    AssignedCrewDetailComponent,
    TaskHistoryComponent,
    BowTaskStatusIconComponent,
    AssignToDropdownComponent,
  ],
})
export class LmoUiCommonFeatureSharedModule {}
