import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetMyTeamGQL extends Query<
  Pick<data.Query, 'GetMyTeams'>,
  data.QueryGetMyTeamsArgs
> {
  override document = gql`
    query GetMyTeams($date: String, $employeeId: String) {
      GetMyTeams(date: $date, employeeId: $employeeId) {
        id
        crew
        name
        station
        members {
          employees {
            employeeNumber
            firstName
            lastName
            jobName
            jobDescription
            station
            status
            codes
            workbrainTeam
            clockInTime
            clockOutTime
            additionalTeams
            assignedTeam
            scheduledTeam
            createdAt
            updatedAt
          }
        }
        createdAt
        updatedAt
      }
    }
  `;
}
