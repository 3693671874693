import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeferralMelGql extends Query<
  Pick<data.Query, 'DeferralItemType'>,
  data.QueryDeferralItemTypeArgs
> {
  override document = gql`
    query DeferralItemType($melType: MelTypes!) {
      DeferralItemType(melType: $melType) {
        Fleet
        MelType
        Name
      }
    }
  `;
}
