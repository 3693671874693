import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CrewManagerHomeComponent } from '@lm-apps/lmo/ui/feature-crew-manager';
import { FilterHomeComponent } from '@lm-apps/lmo/ui/feature-filter';
import { StationsComponent } from '@lm-apps/lmo/ui/feature-stations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTaskComponent } from '../new-task/new-task/new-task.component';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Crew } from '@lm-apps/lmo/ui/data';
import { isMocUser } from '@lm-apps/lmo/ui/common/feature-shared';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';
import { CrewManagerManpowerHomeComponent } from '@lm-apps/lmo/ui/feature-crew-manager-manpower';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() formattedStationInfo!: string[];
  @Input()
  aircraftSearchResults: string[] = [];
  @Input() currentCrew: Crew | null = null;
  @Input() filteredStations: string[] = [];
  @Input() defaultStation!: string;
  @Input() roles!: string[];
  @Input() crewManagerManpowerFlag: FeatureFlag | null = null;
  @Output()
  searchNoseNumber: EventEmitter<string> = new EventEmitter();
  @Output() flightNumber: EventEmitter<string> = new EventEmitter();
  @Output() updateFilteredStations: EventEmitter<string[]> = new EventEmitter();

  modalRef!: BsModalRef;
  flightFormGroup: FormGroup = new FormGroup({});
  public noseFormGroup: FormGroup = new FormGroup({});
  fltNumber = '';
  fltNumberSub: Subscription | undefined;
  cchNose = '';
  text = '';
  noseFilterSubs: Subscription | undefined;

  suggestedStations: string[] = [];
  selectedStation = '';

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.flightFormGroup = this.formBuilder.group({
      fltNumber: new FormControl(this.fltNumber),
    });

    this.monitorFlightNumberFilter();

    this.noseFormGroup = this.formBuilder.group({
      cchNose: new FormControl(this.cchNose),
    });
    this.getNoseNumbers();
  }

  getStationsSuggestions(event: AutoCompleteCompleteEvent) {
    this.suggestedStations = this.formattedStationInfo.filter((item) =>
      item.toLowerCase().startsWith(event.query.toLowerCase())
    );
  }

  selectStation(station: string) {
    const stationCode = station.substring(0, 3);
    if (!this.filteredStations.includes(stationCode)) {
      const newSelectedStations = [...this.filteredStations, stationCode];
      this.updateFilteredStations.emit(newSelectedStations);
      this.selectedStation = '';
    }
  }

  removeStation(station: string) {
    const newSelectedStations = this.filteredStations.filter(
      (x) => x !== station
    );
    this.updateFilteredStations.emit(newSelectedStations);
  }

  monitorFlightNumberFilter() {
    this.fltNumberSub = this.flightFormGroup
      .get('fltNumber')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((f) => {
        this.flightNumber.emit(f);
      });
  }

  openFilterModal() {
    this.modalRef = this.modalService.show(FilterHomeComponent, {
      class: 'modal-dialog modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        isMoc: isMocUser(this.roles),
      },
    });
  }

  openStationsModal() {
    this.modalRef = this.modalService.show(StationsComponent, {
      class: 'modal-dialog modal-m',
      ignoreBackdropClick: true,

      initialState: {
        isMOC: isMocUser(this.roles),
      },
    });
  }

  openNewTaskModal() {
    this.modalRef = this.modalService.show(NewTaskComponent, {
      class: 'modal-dialog modal-lg',
      ignoreBackdropClick: true,
      initialState: {
        crew: this.currentCrew,
        isMOC: isMocUser(this.roles),
      },
    });
  }
  openCrewManagerModal() {
    if (this.crewManagerManpowerFlag && this.crewManagerManpowerFlag.enabled) {
      this.modalRef = this.modalService.show(CrewManagerManpowerHomeComponent, {
        class: 'modal-dialog modal-lg',
        ignoreBackdropClick: true,
      });
    } else {
      this.modalRef = this.modalService.show(CrewManagerHomeComponent, {
        class: 'cm-modal',
        ignoreBackdropClick: true,
      });
    }
  }

  getNoseNumbers() {
    this.noseFilterSubs = this.noseFormGroup
      .get('cchNose')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((noseNumber) => {
        if (noseNumber && noseNumber.trim() !== '') {
          this.searchNoseNumber.emit(noseNumber);
        } else {
          this.searchNoseNumber.emit('');
        }
      });
  }

  ngOnDestroy() {
    this.fltNumberSub?.unsubscribe();
    this.noseFilterSubs?.unsubscribe();
  }
}
