export class EnumUtil {
  public static getValue(
    enumObject: any,
    enumKey: string | number | null | undefined
  ) {
    let value: string | null = null;

    if (enumKey != null && enumKey != undefined) {
      const foundEnum = Object.keys(enumObject).find((x) => x === enumKey);
      value = foundEnum ? enumObject[foundEnum] : null;
    }
    return value;
  }

  public static getKey(
    enumObject: any,
    enumValue: string | number | null | undefined
  ) {
    let key: string | null = null;

    if (enumValue != null && enumValue != undefined) {
      const found = Object.entries(enumObject).find((e) => {
        const [k, v] = e;
        return v == enumValue;
      });
      key = found ? found[0] : null;
    }
    return key;
  }

  public static doesExistInCollection<T extends string>(
    collection: T[],
    value: string
  ): boolean {
    const result =
      value != undefined && value != null
        ? collection.some(
            (c) =>
              c.toString().trim().toUpperCase() == value.trim().toUpperCase()
          )
        : false;
    return result;
  }
}
