<section>
  <div class="modal-header">
    <span>Crew Manager</span>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-secondary p-button-text cross"
      (onClick)="closeModal()"
    ></p-button>
  </div>
  <div class="modal-body col-12 cm">
    <lmo-ui-crew-manager-manpower
      [crewName]="(crewName$ | async) || ''"
      [allCrewMembers]="(allCrewMembers$ | async) || []"
      [allMembersOnlineStatus]="(allMembersOnlineStatus$ | async) || []"
      [crewMembersloaded]="(crewMembersloaded$ | async) || false"
    ></lmo-ui-crew-manager-manpower>
  </div>
</section>
