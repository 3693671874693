<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <div class="stations-modal">
    <div class="modal-header">
      <span class="bold">Select Stations</span>
      <p-button
        icon="pi pi-times"
        styleClass="p-button-secondary p-button-text cross"
        (onClick)="closeModal()"
      ></p-button>
    </div>

    <div class="stations-modal-selected-stations regions-border aa-mb-0">
      <span class="p-input-icon-right station-search-icon">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText
          formControlName="searchStation"
          placeholder=" Search Station"
          [minLength]="1"
          [maxlength]="3"
          class="text-uppercase station-search-input"
          (input)="stationFilter()"
          (keydown.enter)="onKeyEnter()"
          #searchStation
        />
      </span>
    </div>
    <div *ngIf="form.controls.searchStation.invalid" class="aa-ml-3 aa-mr-3">
      <p
        *ngIf="form.controls.searchStation.errors.pattern"
        class="admin-notice-error-message aa-mb-1"
      >
        Alphabetic characters only
      </p>
      <p
        *ngIf="form.controls.searchStation.errors.maxlength"
        class="admin-notice-error-message aa-mb-1"
      >
        Please enter no more than 3 letters
      </p>
    </div>

    <div class="stations-modal-selected-stations regions-border">
      <p-tag
        *ngFor="let station of this.form.get('stations')?.value"
        styleClass="cch-selected-station aa-m-1"
      >
        <span class="display-station-tag-text">{{ station }}</span>
        <button
          (click)="removeStation(station)"
          class="remove-station-button pi pi-times"
        ></button>
      </p-tag>
    </div>
    <div class="d-flex regions-lhs modal-body aa-mb-2">
      <div class="regions-stations-menu regions-border aa-ml-3 aa-mr-2">
        <div *ngIf="allRegions">
          <div *ngIf="isMOC" class="regions-menu-item regions-select-all-bg">
            <div class="aa-pl-2 aa-p-1">
              <p-checkbox
                [label]="
                  this.form.get('isSelectAll')?.value
                    ? DESELECT_ALL
                    : SELECT_ALL
                "
                formControlName="isSelectAll"
                [binary]="true"
                (onChange)="selectAllRegion($event.checked)"
                name="group"
              ></p-checkbox>
            </div>
          </div>

          <lm-apps-checkbox-list
            [data]="allRegions"
            colClass="inline"
            formControlName="regions"
            [valueField]="'Name'"
            [labelField]="'Name'"
            [selectedCss]="'light-blue-regions'"
            (selected)="openStationsMenu($event)"
            (checkboxChange)="regionChange($event)"
            [selecteditem]="selectedRegionMenu"
            [indeterminate_values]="indeterminateRegion"
          >
          </lm-apps-checkbox-list>
        </div>
      </div>
      <div class="regions-stations-menu regions-border aa-mr-3">
        <lm-apps-checkbox-list
          [data]="searchedStation || selectedRegionMenu?.Stations || []"
          colClass="inline"
          formControlName="stations"
          [valueField]="'IataCode'"
          [labelField]="'IataCode'"
          [foreColorConditionField]="'IsHubStation'"
          [foreColorConditionCss]="'blue-station'"
          [foreColorConditionElseCss]="'green-station'"
          (checkboxChange)="stationChange($event)"
        >
        </lm-apps-checkbox-list>
      </div>
    </div>

    <div class="modal-footer justify-content-center aa-mt-1 py-1">
      <div class="cancel-button">
        <p-button
          label="Cancel"
          plain="true"
          styleClass="p-button-secondary stations-footer-button"
          (onClick)="closeModal()"
        ></p-button>
      </div>
      <div>
        <p-button
          label="Apply"
          styleClass="stations-footer-button"
          [disabled]="filteredStations | arraysEqual: selectedStations"
          (onClick)="saveChanges()"
        ></p-button>
      </div>
    </div>
  </div>
</form>
