import { DEFAULT_RANGE } from '../constants';
import {
  DelaysFilter,
  Filter,
  MiscFilter,
  NotificationFilter,
  TaskStatusFilter,
} from '../models';

export const defaultFilter: Filter = {
  Range: DEFAULT_RANGE,
  Delay: DelaysFilter.ShowAllAc,
  Miscellaneous: MiscFilter.ShowAllAc,
  Gates: [],
  GateOptions: false,
  NotificationType: [NotificationFilter.ShowAllAc],
  TaskStatus: [TaskStatusFilter.ShowAllAc],
  IncludeTasks: false,
  OnlyMyTasks: false,
  Fleets: [],
  Zones: [],
  OtherToaster: true,
  RedToaster: true,
  NoiseToaster: true,
};
