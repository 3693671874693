import { Pipe, PipeTransform } from '@angular/core';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';
import { TaskEntity, TaskState } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'assigned',
})
export class AssignedTaskPipe implements PipeTransform {
  excludedNotifications = new Set([SceptreLables.AirworthinessOPS.toString()]);

  transform(tasks: TaskEntity[]) {
    return (
      tasks.filter(
        (x) =>
          (!this.excludedNotifications.has(x.Label ?? '') &&
            x.TaskState === TaskState.Assigned) ||
          x.TaskState === TaskState.Acknowledged ||
          (x.TaskState === TaskState.Arrived &&
            x.CallType !== null &&
            x.JobType !== null)
      ) ?? []
    );
  }
}
