import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import * as fromCrew from '@lm-apps/lmo/ui/data-access-crew';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromRoot from '@lm-apps/lmo/ui/data-access-root';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';

import {
  CreateTeamAssignmentInput,
  OnlineStatus,
  SaveUserSource,
  TeamAssignmentDisplay,
  UpdateTeamAssignmentInput,
} from '@lm-apps/lmo/ui/data';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs';

@Component({
  selector: 'lm-apps-crew-manager-home',
  templateUrl: './home.component.html',
  providers: [ConfirmationService],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewManagerHomeComponent implements OnInit {
  allCrews$ = this.store.pipe(select(fromRoot.selectAllCrewsForCM));
  allCrewMembers$ = this.store.pipe(select(fromCrew.selectAllCrewMembers));
  defaultStation$ = this.store.pipe(select(fromUser.selectDefaultStation));
  stations$ = this.store.pipe(select(fromRoot.selectStationsForCrewManager));
  currentCrew$ = this.store.pipe(select(fromUser.selectCurrentCrew));
  currentUser$ = this.store.pipe(select(fromUser.selectUser));
  allCrewMemberOnlineStatus$ = this.store.pipe(
    select(fromCrewMemberStatus.selectAllCrewMemberOnlineStatus)
  );

  constructor(public modalRef: BsModalRef, private store: Store) {}

  ngOnInit() {
    this.stations$
      .pipe(take(1))
      .subscribe((station) => this.loadDataForModal(station[0]));
  }

  closeModal() {
    this.modalRef.hide();
  }

  loadDataForModal(station: string) {
    this.store.dispatch(fromCrew.actions.init({ station }));
  }

  createCrew(crew: CreateTeamAssignmentInput) {
    this.store.dispatch(fromCrew.actions.createCrew({ crew }));
  }

  updateCrew(crew: UpdateTeamAssignmentInput) {
    this.store.dispatch(fromCrew.actions.updateCrew({ crew }));
  }
  saveSharedCrew(crew: TeamAssignmentDisplay) {
    this.store.dispatch(
      fromUser.actions.updateUser({
        saveUserInput: { SelectedCrewId: crew.Id },
        source: SaveUserSource.CREW_MANAGER,
      })
    );
  }
  deleteCrew(crew: TeamAssignmentDisplay) {
    this.store.dispatch(
      fromCrew.actions.deleteCrew({
        crew,
      })
    );
  }
  shareCrew(crew: TeamAssignmentDisplay) {
    if (!crew.Id) return;
    crew.Shared
      ? this.store.dispatch(
          fromCrew.actions.disableShareCrew({
            crew,
          })
        )
      : this.store.dispatch(
          fromCrew.actions.enableShareCrew({
            crew,
          })
        );
  }
  changeOwnership(crew: TeamAssignmentDisplay) {
    if (!crew.Id) return;
    crew.CurrentOwner
      ? this.store.dispatch(fromCrew.actions.releaseCrewOwnership({ crew }))
      : this.store.dispatch(fromCrew.actions.takeCrewOwnership({ crew }));
  }
}
