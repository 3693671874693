import { Injectable } from '@angular/core';
import { Query as QueryResults } from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { RETIRED_FLEET_TYPE } from '@lm-apps/lmo/shared/master';

@Injectable({
  providedIn: 'root',
})
export class AircraftGQL extends Query<Pick<QueryResults, 'ActiveAircrafts'>> {
  override document = gql`
    query Aircrafts {
      ActiveAircrafts {
        NoseNumber
        FleetType
        RegistrationNumber
        IsActive
        IsMaintenanceHold
        IsEAMLEnabled
        IsEtopsCertified
        AirlineCode
        Id
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AircraftByNoseNumberGQL extends Query<
  Pick<QueryResults, 'Aircraft'>
> {
  override document = gql`
    query Aircraft($noseNumber: String!) {
      Aircraft(noseNumber: $noseNumber) {
        Id
        NoseNumber
        AirlineCode
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AircraftService {
  constructor(
    private _aircraft: AircraftGQL,
    private _aircraftByNoseNumber: AircraftByNoseNumberGQL
  ) {}

  aircrafts() {
    return this._aircraft.fetch().pipe(map(({ data }) => data.ActiveAircrafts));
  }

  aircraft(noseNumber: string) {
    return this._aircraftByNoseNumber
      .fetch({ noseNumber })
      .pipe(map(({ data }) => data.Aircraft));
  }

  fleet() {
    return this._aircraft.fetch().pipe(
      map(({ data }) => {
        const fleet = data.ActiveAircrafts.filter(
          (a) => a.IsActive && !RETIRED_FLEET_TYPE.includes(a.FleetType)
        ).map((a) => {
          return a.FleetType;
        });
        return Array.from(new Set(fleet));
      })
    );
  }
}
