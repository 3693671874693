export const priorityCssClasses: any = {
  1000: { cssClass: 'qcycle' },
  1001: { cssClass: 'cycle' },
  1003: { cssClass: 'qdaily' },
  1004: { cssClass: 'daily' },
  1005: { cssClass: 'qfx' },
  2000: { cssClass: 'rsmg' },
  2001: { cssClass: 'mg' },
  2002: { cssClass: 'term' },
  2003: { cssClass: 'wkup' },
  2004: { cssClass: 'rswk' },
  2005: { cssClass: 'oil' },
  2007: { cssClass: 'tirechk' },
  2008: { cssClass: 'transchk' },
  3000: { cssClass: 'qcycle' },
  1030: { cssClass: 'default' },
  1031: { cssClass: 'melr' },
  1032: { cssClass: 'melci' },
  1040: { cssClass: 'melf' },
  1041: { cssClass: 'melfr' },
  1042: { cssClass: 'melfci' },
  1050: { cssClass: 'meld' },
  1051: { cssClass: 'meldr' },
  1052: { cssClass: 'meldci' },
  1130: { cssClass: 'melq' },
  1131: { cssClass: 'melqr' },
  1132: { cssClass: 'melqci' },
  1140: { cssClass: 'melqf' },
  1141: { cssClass: 'melqfri' },
  1142: { cssClass: 'melqfci' },
  1150: { cssClass: 'melqd' },
  1151: { cssClass: 'melqdr' },
  1152: { cssClass: 'melqcid' },
  1155: { cssClass: 'tii' },
  1156: { cssClass: 'mon' },
  1157: { cssClass: 'ops' },
  1158: { cssClass: 'rob' },
  1159: { cssClass: 'ospoil' },
  1160: { cssClass: 'rem' },
};
export const notificationClasses: any = {
  1000: { cssClass: 'deferral' },
  1001: { cssClass: 'arms' },
  1002: { cssClass: 'hermes' },
  1003: { cssClass: '' },
  1004: { cssClass: '' },
  1005: { cssClass: 'daily' },
  1006: { cssClass: 'tii' },
  1007: { cssClass: 'mon' },
  1008: { cssClass: 'ops' },
  1009: { cssClass: 'rob' },
  1010: { cssClass: 'ospoil' },
  1011: { cssClass: 'rem' },
  2000: { cssClass: 'rmsg' },
  2001: { cssClass: 'wkup' },
  2002: { cssClass: '' },
  2003: { cssClass: '' },
  2007: { cssClass: '' },
  2009: { cssClass: 'ibgatemanning', priorityClassFunction: null },
  2010: { cssClass: 'obgatemanning', priorityClassFunction: null },
  3000: { cssClass: 'manual' },
  3500: { cssClass: 'manualtask' },
  2011: { cssClass: 'manualcubatask', priorityClassFunction: null },
};
