import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Equipment, Notification, Task } from '@lm-apps/lmo/wall/shared';
import { JobTypeDisplay } from '@lm-apps/lmo/shared/enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lmo-maintenance-items',
  templateUrl: './maintenance-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceItemsComponent implements OnInit {
  @Input()
  notifications: Notification[] = [];
  @Input() equipment: Equipment = <Equipment>{};
  @Input() allTasks: Task[] = [];
  bowJobTypeDisplay: JobTypeDisplay = JobTypeDisplay.BOW;

  ngOnInit() {
    if (!this.equipment) this.equipment = <Equipment>{};
  }
}
