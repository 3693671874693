import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  FlightsEntity,
  FlightsSearchCriteria,
  MutationCreateManualTaskArgs,
  Scalars,
  MiscFilter,
  Flight,
} from '@lm-apps/lmo/ui/data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { Observable } from 'rxjs';
import { CreateManualTaskGQL, FeatureFlagsQueryGQL, FlightsGQL } from './query';
import { TransformDates } from '../date-transformer.decorator';
import {
  getBackgroundRowColor,
  isRightStartFlight,
  filterNotifications,
} from '../util';
import { cloneDeep } from 'lodash';
import {
  flight_symbol,
  flightEntity_symbol,
  getFlightsMapper,
} from './flight.mapping';
import { Mapper } from '@automapper/core';
@Injectable({
  providedIn: 'root',
})
export class FlightService {
  private autoMapper: Mapper;
  constructor(
    private _flight: FlightsGQL,
    private _createManualTask: CreateManualTaskGQL,
    private _featureFlagsQueryGQL: FeatureFlagsQueryGQL
  ) {
    dayjs.extend(utc);
    dayjs.extend(weekday);
    this.autoMapper = getFlightsMapper();
  }
  @TransformDates()
  flights(searchCriteria: FlightsSearchCriteria): Observable<FlightsEntity[]> {
    return this._flight
      .fetch({
        flightsSearchCriteria: searchCriteria,
      })
      .pipe(
        map((resp) => {
          let flights = resp.data.Flights.map((f) => {
            if (f.Departure) {
              f.Departure.IsRightStart = isRightStartFlight(f); //TODO: check if i can keep this in automapper
            }
            //filter out notifications that are not applicable
            f.Notifications = filterNotifications(f);
            return f;
          })
            .map((f) =>
              this.autoMapper.map<Flight, FlightsEntity>(
                f,
                flight_symbol,
                flightEntity_symbol
              )
            )
            .filter((f) => {
              // Filter out flights that have already departed? TODO: Is that what this is doing?
              return !(
                f.Departure?.ActualOff &&
                f.BackgroundRowColor.trim().length === 0
              );
            });
          // Filter logic: if user wants to see Right Start flights then filter by Departure.IsRightStart
          if (searchCriteria.Miscellaneous === MiscFilter.RightStartFlights) {
            flights = flights.filter((x) => x && x.Departure?.IsRightStart);
          }
          return flights;
        })
      );
  }

  createManualTask(
    maintenanceTaskArgs: MutationCreateManualTaskArgs
  ): Observable<Scalars['UUID']['output']> {
    return this._createManualTask.mutate(maintenanceTaskArgs).pipe(
      map((response) => {
        if (response.data?.CreateManualTask?.TaskId) {
          return response.data.CreateManualTask.TaskId;
        } else {
          throw new Error();
        }
      })
    );
  }

  getAllFeatureFlags() {
    return this._featureFlagsQueryGQL.fetch().pipe(
      map(({ data }) => {
        return data?.FeatureFlags || [];
      })
    );
  }
}
