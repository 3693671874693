import { CrewBroadcastEvent } from '@lm-apps/lmo/shared/enums';
import {
  CreateTeamAssignmentInput,
  CrewMember,
  TeamAssignment,
  TeamAssignmentDisplay,
  UpdateTeamAssignmentInput,
} from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Crew] Init', props<{ station: string }>());

export const throwError = createAction(
  '[Crew/API] API request failed',
  props<{ error: any }>()
);
export const updateSelectedStation = createAction(
  '[Crew] Update seleted station',
  props<{ station: string }>()
);
export const createCrew = createAction(
  '[Crew] Create team assignment',
  props<{ crew: CreateTeamAssignmentInput }>()
);
export const updateCrew = createAction(
  '[Crew] Update team assignment',
  props<{ crew: UpdateTeamAssignmentInput }>()
);
export const updateCrewSignalr = createAction(
  '[Crew] Update team assignment from SignalR response',
  props<{ event: CrewBroadcastEvent; crew: TeamAssignment }>()
);
export const setSelectedCrew = createAction(
  '[Crew] Update current crew',
  props<{ crew: TeamAssignment | null }>()
);
export const clearSelectedCrewIfSelected = createAction(
  '[Crew] Clear selected crew',
  props<{ crew: TeamAssignment }>()
);

export const deleteCrew = createAction(
  '[Crew] Delete team assignment',
  props<{ crew: TeamAssignmentDisplay }>()
);
export const enableShareCrew = createAction(
  '[Crew] Enable team assignment sharing',
  props<{ crew: TeamAssignmentDisplay }>()
);
export const disableShareCrew = createAction(
  '[Crew] Disable team assignment sharing',
  props<{ crew: TeamAssignmentDisplay }>()
);

export const takeCrewOwnership = createAction(
  '[Crew] Take ownership of team assignment',
  props<{ crew: TeamAssignmentDisplay }>()
);
export const releaseCrewOwnership = createAction(
  '[Crew] Release ownership of shared team assignment',
  props<{ crew: TeamAssignmentDisplay }>()
);

export const loadUpsertCrewSuccess = createAction(
  '[Crew/API] Upsert crew success',
  props<{ crew: TeamAssignment }>()
);

export const loadAvailableCrewsSuccess = createAction(
  '[Crew/API] Load crews success',
  props<{ crews: TeamAssignment[] }>()
);

export const loadCrewMembersByStationSuccess = createAction(
  '[Crew/API] Load crew members success',
  props<{ crewMembers: CrewMember[] }>()
);

export const removeCrew = createAction(
  '[Crew/API] Remove specific crew from store',
  props<{ crewId: string }>()
);
export const updateSelectedCrewId = createAction(
  '[Crew/API] Update selectedCrewId crew from store',
  props<{ crewId: string | null }>()
);
