import { Injectable } from '@angular/core';
import {
  CreateTeamAssignmentInput,
  TeamAssignment,
  UpdateTeamAssignmentInput,
} from '@lm-apps/lmo/ui/data';
import { map } from 'rxjs/operators';
import {
  AllCrewMemberByStationGQL,
  AvailableTeamAssignmentsGQL,
  CreateTeamAssignmentGQL,
  DeleteTeamAssignmentGQL,
  DisableTeamSharingGQL,
  EnableTeamSharingGQL,
  OnlineStatusAllGQL,
  ReleaseTeamOwnershipGQL,
  TakeTeamOwnershipGQL,
  UpdateTeamAssignmentGQL,
} from './query';
import { TransformDates } from '../date-transformer.decorator';
import { MissingDataError } from '../custom-errors';

@Injectable({
  providedIn: 'root',
})
export class CrewMemberService {
  constructor(
    private _availableTeamAssignmentsGQL: AvailableTeamAssignmentsGQL,
    private _createTeamAssignmentGQL: CreateTeamAssignmentGQL,
    private _updateTeamAssignmentGQL: UpdateTeamAssignmentGQL,
    private _allCrewMemberByStationGQL: AllCrewMemberByStationGQL,
    private _deleteTeamAssignmentGQL: DeleteTeamAssignmentGQL,
    private _enableTeamSharingGQL: EnableTeamSharingGQL,
    private _disableTeamSharingGQL: DisableTeamSharingGQL,
    private _takeTeamOwnershipGQL: TakeTeamOwnershipGQL,
    private _releaseTeamOwnership: ReleaseTeamOwnershipGQL,
    private _onlineStatusAllGQL: OnlineStatusAllGQL
  ) {}
  @TransformDates()
  availableTeamAssignment(station: string) {
    return this._availableTeamAssignmentsGQL.fetch({ station: station }).pipe(
      map(({ data }) => {
        const temp: TeamAssignment[] = [];
        temp.push(...data.AvailableTeamAssignments.OwnedTeams);
        temp.push(...data.AvailableTeamAssignments.SharedOwnerTeams);
        temp.push(...data.AvailableTeamAssignments.AvailableSharedTeams);
        return temp;
      })
    );
  }

  onlineCrewMemberStatusAll() {
    return this._onlineStatusAllGQL.fetch().pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.OnlineStatusAll;
      })
    );
  }

  createTeamAssignment(crew: CreateTeamAssignmentInput) {
    return this._createTeamAssignmentGQL.mutate({ teamAssignment: crew }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.CreateTeamAssignment;
      })
    );
  }

  updateTeamAssignment(crew: UpdateTeamAssignmentInput) {
    return this._updateTeamAssignmentGQL.mutate({ teamAssignment: crew }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.UpdateTeamAssignment;
      })
    );
  }

  getAllCrewMemberByStation(request: string) {
    return this._allCrewMemberByStationGQL.fetch({ station: request }).pipe(
      map(({ data }) => {
        return data.AllCrewMemberByStation;
      })
    );
  }

  deleteTeamAssignment(request: string) {
    return this._deleteTeamAssignmentGQL.mutate({ teamId: request }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.DeleteTeamAssignment;
      })
    );
  }

  enableTeamSharing(request: string) {
    return this._enableTeamSharingGQL.mutate({ teamId: request }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.EnableTeamSharing;
      })
    );
  }

  disableTeamSharing(request: string) {
    return this._disableTeamSharingGQL.mutate({ teamId: request }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.DisableTeamSharing;
      })
    );
  }

  takeTeamOwnership(request: string) {
    return this._takeTeamOwnershipGQL.mutate({ teamId: request }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.TakeTeamOwnership;
      })
    );
  }

  releaseTeamOwnership(request: string) {
    return this._releaseTeamOwnership.mutate({ teamId: request }).pipe(
      map(({ data }) => {
        if (!data) {
          throw new MissingDataError();
        }
        return data.ReleaseTeamOwnership;
      })
    );
  }
}
