import {
  Flight,
  FlightNotification,
  Task,
  FlightsSearchCriteria,
  Arrival,
  Departure,
  Equipment,
} from './type-defs.model';

export interface FlightsSearchCriteriaModel {
  flightsSearchCriteria: FlightsSearchCriteria;
}

export interface FlightNotificationEntity
  extends Omit<FlightNotification, 'Task'> {
  IsPending: boolean;
  Task?: NotificationTaskEntity;
}
export type NotificationTaskEntity = Pick<
  Task,
  'Id' | 'AircraftId' | 'InboundFlightLegId'
>;
export interface TaskEntity extends Task {
  IsPending: boolean;
  IsNew?: boolean;
  RealTimeNotification?: boolean;
}

export interface FlightsEntity
  extends Omit<Flight, 'Notifications' | 'AllTasks'> {
  Notifications: FlightNotificationEntity[];
  AllTasks: TaskEntity[];
  HasDepartureToast: boolean;
  BackgroundRowColor: string;
}

export interface FlightLegDetail
  extends Pick<Arrival, 'ArrivalStation'>,
    Pick<Departure, 'DepartureStation'>,
    Pick<Equipment, 'NoseNumber' | 'AirlineCode'> {
  AircraftId?: Equipment['Id'] | null;
  ArrivalId: Arrival['Id'];
  DepartureId?: Departure['Id'];
}
