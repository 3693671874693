<table class="table table-condensed" *ngIf="selectedBowItem">
  <thead>
    <tr>
      <th>Station</th>
      <th>Scheduled Date</th>
      <th>Description</th>
      <th>BOW Status</th>
    </tr>
  </thead>
  <tbody class="attr">
    <tr>
      <ng-container *ngIf="selectedBowItem?.BowDetails as bowDetails">
        <td>{{ selectedBowItem.Station || '-' }}</td>
        <td>
          {{
            bowDetails.ScheduledDate
              ? (bowDetails.ScheduledDate | date: 'ddMMMyy' | uppercase)
              : '-'
          }}
        </td>
        <td>{{ selectedBowItem.Description || '-' }}</td>
        <td class="bold-7">
          <lm-apps-lmo-ui-bow-item-status
            [selectedBowItem]="selectedBowItem"
          ></lm-apps-lmo-ui-bow-item-status>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
