import { offsetDateTime } from './offset-date-time';

export function departureDate(
  flightNumber?: string | null,
  actualArrival?: Date | null,
  arrivalUTCOffset?: number | null,
  actualDeparture?: Date | null,
  departureUTCOffset?: number | null
) {
  let display = 'UNLINKED';

  if (flightNumber && actualDeparture) {
    const inboundDateDD =
      actualArrival && offsetDateTime(actualArrival, arrivalUTCOffset, 'DD');
    const outboundDateDD = offsetDateTime(
      actualDeparture,
      departureUTCOffset,
      'DD'
    );

    if (inboundDateDD && outboundDateDD !== inboundDateDD) {
      display = flightNumber + '/' + outboundDateDD;
    } else {
      display = flightNumber;
    }
  }
  return display;
}
