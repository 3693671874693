import { Pipe, PipeTransform } from '@angular/core';
import { FlightNotificationEntity, ItemType } from '@lm-apps/lmo/ui/data';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';
import {
  NotificationClasses,
  PriorityCssClasses,
} from '../../constants/notifications.constant';

@Pipe({
  name: 'getPriorityClassName',
  pure: true,
})
export class GetPriorityClassNamePipe implements PipeTransform {
  transform(noti: FlightNotificationEntity): string {
    const classArray = this.getPriorityClassName(
      noti.Type ?? ItemType.Undefined,
      noti.PriorityName ?? '',
      noti.CustomNotificationType ?? ''
    );
    if (noti.IsPending) {
      classArray.push('cc-italic');
    }
    if (noti.Label === SceptreLables.AirworthinessOPS.toString()) {
      classArray.push('airworthiness');
    }
    return classArray.join(' ');
  }

  getPriorityClassName = (
    type: ItemType,
    priorityName: string,
    customNotificationType: string
  ): string[] => {
    const classArray: string[] = [];

    const p = priorityName.toUpperCase().trim().split(' ').join('_');

    const styles = NotificationClasses[type] || {};

    classArray.push(styles.cssClass ?? '');
    classArray.push(PriorityCssClasses[p]?.cssClass ?? 'default');
    customNotificationType === 'melCycle' && classArray.push('melCycle');

    return classArray;
  };
}
