<div class="flt-detail">
  <hr class="cc-hr" />
  <table class="table table-condensed header-table printable-task">
    <thead>
      <tr>
        <th>A/C</th>
        <th>Registration</th>
        <th>Engine Type</th>
        <th>Fleet</th>
        <th>Sub Fleet</th>
        <th>ETOPS</th>
        <th>RON</th>
        <th>Origin|Dest</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr>
        <td>
          <a
            class="h3"
            [href]="safeAircraftUri + '/' + flight.Equipment.NoseNumber"
            [textContent]="flight.Equipment.NoseNumber || '-'"
            target="_blank"
          ></a>
        </td>
        <td class="h3">{{ flight.Equipment.RegistrationNumber || '-' }}</td>
        <td class="h3">{{ flight.Equipment.EngineType || '-' }}</td>
        <td class="h3">{{ flight.Equipment.FleetType || '-' }}</td>
        <td class="h3">{{ flight.Equipment.FleetSubType || '-' }}</td>
        <td class="h3">
          {{ flight.Equipment.IsEtopsCertified ? 'YES' : 'NO' }}
        </td>
        <td class="h3">
          {{ flight.Equipment.RONStation || '-' }}
        </td>
        <td class="h3">
          {{
            flight.Arrival?.DepartureStation ||
              flight.Departure?.DepartureStation ||
              '-'
          }}
          | {{ flight.Departure?.ArrivalStation?.trim() || '-' }}
        </td>
      </tr>
      <tr *ngIf="flight.Equipment.IsOutOfService">
        <th style="border-top: 2px"></th>
        <th style="border-top: 2px" colspan="5">Description</th>
        <th colspan="2">Local ETR</th>
      </tr>
      <tr *ngIf="flight.Equipment.IsOutOfService">
        <td class="h3"></td>
        <td class="h3" colspan="5" data-cy="aos-ac-remark">
          {{ flight.Equipment.Remark || '-' }}
        </td>
        <td class="h3" colspan="2" data-cy="aos-ac-etr">
          {{
            (flight.Equipment.EstimatedReturnToService
              | dateOffset
                : flight.Arrival?.ArrivalUtcAdjustment
                : 'DD MMM hh:mm') || '-'
          }}
        </td>
      </tr>
    </tbody>
  </table>
  <table class="table table-condensed attribute-table printable-task">
    <thead>
      <tr>
        <th>GATE arr|dep</th>
        <th>FLT arr|dep</th>
        <th>STA</th>
        <th>ETA</th>
        <th>ON</th>
        <th>IN</th>
        <th>STD</th>
        <th>ETD</th>
        <th>OUT</th>
        <th>OFF</th>
        <th>GND</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr>
        <td>
          {{ flight.Arrival?.ArrivalGate || '-' }} |
          {{ flight.Departure?.DepartureGate || '-' }}
        </td>
        <td>
          {{ flight.Arrival?.FlightNumber || '-' }} |
          {{ flight.Departure?.FlightNumber || '-' }}
        </td>
        <td>
          {{
            flight.Arrival?.FlightNumber
              ? (flight.Arrival?.ScheduledArrival
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')
              : '-'
          }}
        </td>
        <td>
          {{
            flight.Arrival?.FlightNumber
              ? (flight.Arrival?.EstimatedArrival
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')
              : '-'
          }}
        </td>
        <td>
          {{
            flight.Arrival?.FlightNumber
              ? (flight.Arrival?.ActualOn
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')
              : '-'
          }}
        </td>
        <td>
          {{
            flight.Arrival?.FlightNumber
              ? (flight.Arrival?.ActualIn
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')
              : '-'
          }}
        </td>
        <td>
          {{
            (flight.Departure?.FlightNumber &&
              (flight.Departure?.ScheduledDeparture
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')) ||
              '-'
          }}
        </td>
        <td>
          {{
            (flight.Departure?.FlightNumber &&
              (flight.Departure?.EstimatedDeparture
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')) ||
              '-'
          }}
        </td>
        <td>
          {{
            (flight.Departure?.ActualOut
              | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm') || '-'
          }}
        </td>
        <td>
          {{
            (flight.Departure?.ActualOff
              | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm') || '-'
          }}
        </td>
        <td>{{ flight.GroundTime }}</td>
      </tr>
    </tbody>
  </table>
</div>
