import { SourceType } from './source-type';

export enum NotificationPriorityType {
  NEF_QUICK_FIX = 'QNEF',
  MAINTENANCE_HOLD = 'Maintenance Hold',
  WAKE_UP = 'WKUP',
  ARMS = 'ARMS',
  CFMOIL = 'CFMOIL',
  MEL_FOLLOW_UP = 'MELFLW',
  MOC_REQUEST = 'MOC Request',
  TRANSIT = 'TRANSIT',
  MEET_GREET = 'MG',
  RIGHT_START_WAKE_UP = 'RSWK',
  RIGHT_START_MEET_GREET = 'RSMG',
  OTHER = 'Other',
  QUICK_FIX = 'QFIX',
  MEL_GENERAL = 'MELGEN',
  HERMES = 'HERMES',
  CONFIGURATION_DEVIATION_LIST = 'CDL',
  CUBA = 'CUBA',
  OIL = 'OIL',
  MON_FOLLOW_UP = 'MELFLWMON',
  OPEN = 'OPEN',
  PILOT_eMIC = 'PILOTeMIC',
  TERM = 'TERM',
}

export interface NotificationPriorityOrderModel {
  Description: string;
  Order: number;
  SourceType: SourceType;
  IsActive: boolean;
}

export const NotificationPriorityOrder: NotificationPriorityOrderModel[] = [
  {
    Description: 'RSMG',
    Order: 300,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'MG',
    Order: 300,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'TERM',
    Order: 400,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'WKUP',
    Order: 700,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'RSWK',
    Order: 800,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'CFMOIL',
    Order: 900,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'IFE',
    Order: 500,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'TIRECHK',
    Order: 500,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'TRANSCHK',
    Order: 500,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'I/B GATE',
    Order: 900,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'O/B GATE',
    Order: 1000,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
  {
    Description: 'CUBA',
    Order: 1100,
    SourceType: SourceType.INTERNAL,
    IsActive: true,
  },
];
