import { Scalars } from './type-defs.model';

export interface CreateMaintenanceTaskResponse {
  CreateManualTask: {
    TaskId: Scalars['UUID']['output'];
  };
}

export interface CreateRegularTaskResponse {
  CreateTask: {
    TaskId: Scalars['UUID']['output'];
  };
}
