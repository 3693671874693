import * as actions from './user.actions';
import { createReducer, on, Action } from '@ngrx/store';
import {
  SortableCrewColumn,
  DEFAULT_EMPLOYEE_ID,
  DEFAULT_FIRST_NAME,
  DEFAULT_LAST_NAME,
  DEFAULT_STATION,
  SortOrder,
  USER_FEATURE_KEY,
  defaultFilter,
} from '@lm-apps/lmo/ui/data';
import { UserState } from './user.interfaces';
import {} from '@lm-apps/lmo/shared/master';

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const initialState: UserState = {
  User: {
    EmployeeId: DEFAULT_EMPLOYEE_ID,
    FirstName: DEFAULT_FIRST_NAME,
    LastName: DEFAULT_LAST_NAME,
    DefaultStation: DEFAULT_STATION,
    PredefinedTasks: [],
    SelectedCrewId: null,
    Crew: null,
    Filter: defaultFilter,
  },
  SortingPreferences: {
    CrewMembers: {
      column: SortableCrewColumn.NAME,
      sortOrder: SortOrder.ASCENDING,
    },
  },
  Stations: [],
  Loaded: false,
  Error: null,
};

const userReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, Loaded: false, Error: null })),
  on(actions.loadUserSuccess, (state, { user }) => ({
    ...state,
    Loaded: true,
    Error: null,
    User: user,
  })),
  on(actions.updateStations, (state, { stations }) => ({
    ...state,
    Stations: stations.length === 0 ? [state.User.DefaultStation] : stations,
  })),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    Error: error,
    Loaded: false,
  })),
  on(actions.updateCurrentCrew, (state, { crew }) => {
    return {
      ...state,
      User: { ...state.User, Crew: crew, SelectedCrewId: crew?.Id },
    };
  }),
  on(actions.resetCurrentCrew, (state) => {
    const crew = state.User.Crew;
    return {
      ...state,
      User: { ...state.User, Crew: crew, SelectedCrewId: crew?.Id },
    };
  }),
  on(actions.sortCrew, (state, { column }) => {
    const sortOrder =
      column === state.SortingPreferences.CrewMembers.column
        ? state.SortingPreferences.CrewMembers.sortOrder === SortOrder.ASCENDING
          ? SortOrder.DESCENDING
          : SortOrder.ASCENDING
        : SortOrder.ASCENDING;
    return {
      ...state,
      SortingPreferences: {
        ...state.SortingPreferences,
        CrewMembers: {
          column,
          sortOrder,
        },
      },
    };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
