<section>
  <hr class="cc-hr" />
  <table class="table table-condensed header-table printable-task">
    <thead>
      <tr>
        <th>A/C</th>
        <th>Fleet</th>
        <th>Sub Fleet</th>
        <th>ETOPS</th>
        <th>Origin|Dest</th>
        <th>FLT arr|dep</th>
        <th>STA</th>
        <th>STD</th>
        <th>GND</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr>
        <td>
          <a
            class="h3"
            [href]="safeConfigURL + '/' + flight.Equipment.NoseNumber"
            [textContent]="flight.Equipment.NoseNumber || '-'"
            target="_blank"
          ></a>
        </td>
        <td class="h3">{{ flight.Equipment.FleetType || '-' }}</td>
        <td class="h3">{{ flight.Equipment.FleetSubType || '-' }}</td>
        <td class="h3">
          {{ flight.Equipment.IsEtopsCertified ? 'YES' : 'NO' }}
        </td>
        <td class="h3">
          {{
            flight.Arrival?.DepartureStation ||
              flight.Departure?.DepartureStation ||
              '-'
          }}
          | {{ flight.Departure?.ArrivalStation?.trim() || '-' }}
        </td>
        <td class="h3">
          {{ flight.Arrival?.FlightNumber || '-' }} |
          {{ flight.Departure?.FlightNumber || '-' }}
        </td>
        <td class="h3">
          {{
            flight.Arrival?.FlightNumber
              ? (flight.Arrival?.ScheduledArrival
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')
              : '-'
          }}
        </td>
        <td class="h3">
          {{
            (flight.Departure?.FlightNumber &&
              (flight.Departure?.ScheduledDeparture
                | dateOffset: flight.Arrival?.ArrivalUtcAdjustment:'HH:mm')) ||
              '-'
          }}
        </td>
        <td class="h3">{{ flight.GroundTime }}</td>
      </tr>
    </tbody>
  </table>
</section>
