import { State, adapter } from './flight.reducer';
import {
  Task,
  CallType,
  JobType,
  TaskState,
  SourceType,
  SourceSystem,
  ItemType,
  FlightNotificationEntity,
  TaskEntity,
  MutationCreateTaskArgs,
  FlightsEntity,
  KeyJobType,
} from '@lm-apps/lmo/ui/data';
import cloneDeep from 'lodash/cloneDeep';
import { EnumUtil } from '@lm-apps/lmo/shared/enums';
import dayjs from 'dayjs';
import { TaskState as TaskStateEnum } from '@lm-apps/lmo/shared/enums';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getBackgroundRowColor } from '@lm-apps/lmo/ui/common/services';
const convertToTaskType = (
  task: TaskEntity,
  notification: FlightNotificationEntity
): TaskEntity => {
  const keyServerEnum = EnumUtil.getKey(TaskStateEnum, task.TaskState);
  //the server enum key is generated enum value
  const keyclientEnum = EnumUtil.getKey(TaskState, keyServerEnum) || 'Empty';

  return {
    __typename: 'Task',
    Description: notification.Description,
    MaintenanceType: notification.Type,
    MaintenancePriority: notification.Priority,
    Label: notification.Label,
    AircraftId: task.AircraftId,
    AssignedMembers: task.AssignedMembers,
    ChangedBy: task.ChangedBy,
    ChangedOn: new Date(task.ChangedOn),
    Created: task.Created,
    CustomNotificationType: task.CustomNotificationType,
    DeferredReason: task.DeferredReason,
    Effectivity: task.Effectivity,
    ExternalItemId: task.ExternalItemId,
    Id: task.Id,
    InboundFlightLegId: task.InboundFlightLegId,
    InternalItemId: task.InternalItemId,
    IsAlternateTask: task.IsAlternateTask || false,
    IsAvionic: task.IsAvionic || false,
    IsManualTask: task.IsManualTask || false,
    IsNfnf: task.IsNfnf || false,
    IsOilService: task.IsOilService || false,
    JobType: task.JobType || <JobType>{},
    Location: task.Location,
    NotificationId: task.NotificationId,
    OutBoundFlightLegId: task.OutBoundFlightLegId,
    SpecialInstructions: task.SpecialInstructions || [],
    Station: task.Station,
    TaskState: TaskState[keyclientEnum as keyof typeof TaskState],
    TaskUserReferenceId: task.TaskUserReferenceId,
    To: task.To,
    ToEmployeeId: task.ToEmployeeId,
    UserDefinedItemId: task.UserDefinedItemId,
    UserId: task.UserId || '',
    UserName: task.UserName,
    CallType: task.CallType || <CallType>{},
    IsPending: false,
    IsOspCheck: false,
  };
};

const convertToManualFlightNotificationType = (
  notification: FlightNotificationEntity,
  task: TaskEntity
): FlightNotificationEntity => {
  return {
    __typename: 'FlightNotification',

    AircraftId: notification.AircraftId,
    AircraftHash: notification.AircraftHash,
    Discrepancy: notification.Discrepancy || '',
    HasTask: notification.HasTask || false,
    Id: notification.Id || '',
    IsClosed: notification.IsClosed || false,
    IsDeleted: notification.IsDeleted || false,
    Label: notification.Label || '',
    SourceKey: notification.SourceKey || '',
    SourceSystem: SourceSystem.TaskProvider,
    AirlineCode: notification.AirlineCode,
    SourceType: SourceType.UserDefined,
    AssignedToNames: notification.AssignedToNames,
    Created: notification.Created,
    CustomNotificationType: notification.CustomNotificationType,
    Description: notification.Description,
    DisplayName: notification.DisplayName,
    Effectivity: notification.Effectivity
      ? new Date(notification.Effectivity)
      : undefined,
    ExtendedAttributes: notification.ExtendedAttributes,
    FlighttHash: notification.FlighttHash,
    ForecastDate: notification.ForecastDate
      ? new Date(notification.ForecastDate)
      : undefined,
    InBoundFlightLegId: notification.InBoundFlightLegId,
    ItemClosed: notification.ItemClosed || null,
    ItemDeleted: notification.ItemDeleted || null,
    NoseNumber: notification.NoseNumber,
    OutBoundFlightLegId: notification.OutBoundFlightLegId,
    Priority: notification.Priority,
    PriorityName: notification.PriorityName,
    ProcessDate: notification.ProcessDate
      ? new Date(notification.ProcessDate)
      : undefined,
    Status: notification.Status,
    Task: {
      Id: task.Id,
      AircraftId: notification.AircraftId,
      InboundFlightLegId: notification.InBoundFlightLegId,
    } as Task,
    Type:
      task.JobType.KeyJobType === KeyJobType.Bow
        ? ItemType.Bow
        : ItemType.ManualTask,
    IsPending: false,
  };
};

export function mapper(
  flightFromStore: FlightsEntity,
  notification: FlightNotificationEntity,
  task: TaskEntity,
  realTimeNotification: boolean
) {
  const flight = cloneDeep(flightFromStore);
  const noti = flight.Notifications?.find((n) => n.Id == notification.Id);
  if (noti) {
    noti.HasTask = notification.HasTask || noti.HasTask;
    noti.IsClosed = notification.IsClosed || noti.IsClosed;
    noti.IsDeleted = notification.IsDeleted || noti.IsDeleted;
    noti.ItemClosed = notification.ItemClosed || noti.ItemClosed;
    noti.ItemDeleted = notification.ItemDeleted || noti.ItemDeleted;
    noti.Status = notification.Status || noti.Status;
    noti.IsPending = false;
    noti.AssignedToNames = notification.AssignedToNames;

    const existingTask = flight.AllTasks?.find((ta) => ta.Id == task.Id);
    if (existingTask) {
      if (
        typeof existingTask.ChangedOn === 'string' &&
        isDateString(existingTask.ChangedOn)
      ) {
        existingTask.ChangedOn = new Date(existingTask.ChangedOn);
      }
      if (
        existingTask.ChangedOn.getTime() > new Date(task.ChangedOn).getTime()
      ) {
        //if task is updated before signalR return old state
        return flight;
      }

      existingTask.AssignedMembers = task.AssignedMembers;
      existingTask.CallType = task.CallType || existingTask.CallType;
      existingTask.ChangedBy = task.ChangedBy || existingTask.ChangedBy;

      existingTask.ChangedOn = task.ChangedOn
        ? new Date(task.ChangedOn)
        : existingTask.ChangedOn;
      existingTask.CustomNotificationType =
        task.ChangedBy || existingTask.ChangedBy;
      existingTask.DeferredReason =
        task.DeferredReason || existingTask.DeferredReason;
      existingTask.IsNfnf = task.IsNfnf || existingTask.IsNfnf;
      existingTask.IsOilService =
        task.IsOilService || existingTask.IsOilService;

      existingTask.SpecialInstructions =
        task.SpecialInstructions || existingTask.SpecialInstructions;

      existingTask.IsPending = false;

      const keyServerEnum = EnumUtil.getKey(TaskStateEnum, task.TaskState);
      //the server enum key is generated enum value
      const keyclientEnum =
        EnumUtil.getKey(TaskState, keyServerEnum) || 'Empty';

      existingTask.TaskState =
        TaskState[keyclientEnum as keyof typeof TaskState];
      existingTask.To = task.To || existingTask.To;
      existingTask.UserId = task.UserId || existingTask.UserId;
      existingTask.UserName = task.UserName || existingTask.UserName;
      existingTask.JobType = task.JobType || existingTask.JobType;
      existingTask.IsAvionic = task.IsAvionic || existingTask.IsAvionic;
      existingTask.Description = noti.Description;
      existingTask.MaintenanceType = noti.Type;
      existingTask.MaintenancePriority = noti.Priority;
      existingTask.Label = noti.Label;
    } else {
      const taskClass = convertToTaskType(task, noti);
      taskClass.RealTimeNotification = realTimeNotification;
      flight.AllTasks?.push(taskClass);
    }
  } else if (task.JobType.KeyJobType === KeyJobType.Bow) {
    // When a BOW is created from a BOW tile, we need to push the generated notification into Notifications and
    // update the existing task that was initially created on the fly via the BOW API with the task we receive from the socketIO
    const bowNotification = convertToManualFlightNotificationType(
      notification,
      task
    );
    flight.Notifications.push(bowNotification);

    flight.AllTasks = flight.AllTasks.map((t) => {
      // We're using the work control number for comparison instead of the task Id because the Id varies between
      // dynamically created tasks and those inserted into the database
      if (
        t.JobType.KeyJobType === KeyJobType.Bow &&
        t.BowDetails &&
        notification.ExtendedAttributes?.WorkControlNumber ===
          t.BowDetails.WorkControlNumber
      ) {
        const actualTask = convertToTaskType(task, bowNotification);
        actualTask.BowDetails = {
          ...t.BowDetails,
          IsBowTaskCreatedOnTheFly: false,
        };
        return actualTask;
      }
      return t;
    });
  }

  //When task is created manually
  else {
    const notificationClass = convertToManualFlightNotificationType(
      notification,
      task
    );
    flight.Notifications?.push(notificationClass);
    const taskClass = convertToTaskType(task, notificationClass);
    taskClass.RealTimeNotification = realTimeNotification;
    flight.AllTasks?.push(taskClass);
  }

  // after all flight update done then check if departure is about to happen or not
  // if happening then need to show background color as pinkish in cc-view
  getBackgroundRowColor(flight);

  if (
    flight.Departure?.ActualOff &&
    flight.BackgroundRowColor.trim().length === 0
  ) {
    return null; //delete the flight from state
    //updatedState = adapter.removeOne(flight.Hash, state);
  }
  return flight;
}
// we need to remove it once interceptor start working

function isDateString(value: string): boolean {
  dayjs.extend(customParseFormat);

  return dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid(); //considering all dates will be in UTC

  // might need this expression in future
  // used this link  to get the expression https://regex101.com/r/ufFBTA/2
  // return /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|-)(?:\d{2}):?(?:\d{2}))/.test(
  //   value
  // );
}

export function updatePendingStatus(
  state: State,
  maintenanceTaskArgs: MutationCreateTaskArgs
) {
  const updatedState = cloneDeep(state);

  for (const flight of Object.values(updatedState.entities)) {
    if (flight == null) continue;
    if (
      flight.Equipment.Id === maintenanceTaskArgs.maintenanceTask.AircraftId
    ) {
      if (
        flight.Arrival?.Id ==
          maintenanceTaskArgs.maintenanceTask.InboundFlightLegId ||
        flight.Departure?.Id ==
          maintenanceTaskArgs.maintenanceTask.InboundFlightLegId
      ) {
        updateNotificationTaskPendingStatus(flight, maintenanceTaskArgs);
      }
    }
  }
  updatedState.selectedFlight.TaskId = undefined;
  updatedState.selectedFlight.FlightNotificationId = undefined;
  return updatedState;
}

export function updateNotificationTaskPendingStatus(
  flight: FlightsEntity,
  maintenanceTaskArgs: MutationCreateTaskArgs
) {
  const noti = flight.Notifications.find(
    (not) => not.Id == maintenanceTaskArgs.maintenanceTask.NotificationId
  );

  if (
    noti &&
    !maintenanceTaskArgs.maintenanceTask.TaskId &&
    flight.Arrival?.Id ===
      maintenanceTaskArgs.maintenanceTask.InboundFlightLegId
  ) {
    noti.IsPending = true;
  }

  const existingTask = flight.AllTasks.find(
    (ta) => ta.Id == maintenanceTaskArgs.maintenanceTask.TaskId
  );
  if (existingTask) {
    existingTask.IsPending = true;
  }
}
