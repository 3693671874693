import { Inject, Injectable } from '@angular/core';
import {
  CustomToastrService,
  FlightService,
} from '@lm-apps/lmo/ui/common/services';
import {
  FlightsEntity,
  KeyJobType,
  Scalars,
  TaskAction,
} from '@lm-apps/lmo/ui/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, interval, map, of, startWith, switchMap } from 'rxjs';
import * as actions from '../flight.actions';
import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
@Injectable()
export class FlightEffects {
  loadFlightsRequest$ = createEffect(() =>
    this.actions$.pipe(
      // effect is called after user is loaded
      ofType(actions.loadFlightsRequest),
      switchMap(({ searchCriteria }) =>
        interval(this.env.LMO_FLIGHTS_POLLING_INTERVAL_SECONDS * 1000)
          .pipe(startWith(0))
          .pipe(
            switchMap(() => {
              return this.flightService.flights(searchCriteria).pipe(
                switchMap((flights: FlightsEntity[]) => [
                  actions.loadFlightSuccess({ flights }),
                  actions.triggerDepartureToast({ flights }),
                ]),
                catchError((error) => {
                  return of(actions.throwError({ error }));
                })
              );
            })
          )
      )
    )
  );

  createManualTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createManualTask),
      switchMap((action) => {
        const taskAction =
          action.maintenanceTaskArgs.maintenanceTask.TaskAction;
        const isBow =
          action.maintenanceTaskArgs.maintenanceTask.JobType?.KeyJobType ===
          KeyJobType.Bow;

        return this.flightService
          .createManualTask(action.maintenanceTaskArgs)
          .pipe(
            map((taskId: Scalars['UUID']['output']) => {
              switch (taskAction) {
                case TaskAction.Update:
                  this.toastService.success('Task updated');
                  break;
                case TaskAction.Complete:
                  this.toastService.success(
                    `Task ${isBow ? 'updated' : 'completed'}`
                  );
                  break;
                default:
                  this.toastService.success(
                    `Task ${isBow ? 'updated' : 'created'}`
                  );
                  break;
              }
              return actions.createManualTaskSuccess({ taskId });
            }),
            catchError((error) => {
              switch (taskAction) {
                case TaskAction.Update:
                  this.toastService.error(
                    'Task update failed. Please retry or contact support if the issue persists.'
                  );
                  break;
                case TaskAction.Complete:
                  this.toastService.error(
                    'Task complete failed. Please retry or contact support if the issue persists.'
                  );
                  break;
                default:
                  this.toastService.error(
                    'Task creation failed. Please retry or contact support if the issue persists.'
                  );
                  break;
              }
              return of(actions.throwError({ error }));
            })
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private flightService: FlightService,
    private toastService: CustomToastrService,
    @Inject(PING_APP_ENVIRONMENT) private env: AppEnvironment
  ) {}
}
