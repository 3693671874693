import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class CreateRegularTaskGQL extends Mutation<
  Pick<data.Mutation, 'CreateTask'>,
  data.MutationCreateTaskArgs
> {
  override document = gql`
    mutation CreateTask($maintenanceTask: MaintenanceTaskInput!) {
      CreateTask(maintenanceTask: $maintenanceTask) {
        TaskId
      }
    }
  `;
}
