import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './notices.actions';
import {
  CustomToastrService,
  NoticesService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
import { Notice } from '@lm-apps/lmo/ui/data';

@Injectable()
export class NoticesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => [actions.getActiveNotices(), actions.getAllNotices()])
    )
  );

  saveNoticeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveNoticeSuccess),
      map(() => actions.init())
    )
  );

  getAllNotices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAllNotices),
      switchMap(() =>
        this.noticesService.getAllNotices().pipe(
          map((notices: Notice[]) => {
            return actions.loadAllNoticesSuccess({ notices });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load notices.');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  getActiveNotices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getActiveNotices),
      switchMap(() =>
        this.noticesService.getActiveNotices().pipe(
          map((notices: Notice[]) => {
            return actions.loadActiveNoticesSuccess({ notices });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load active notices.');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  deleteNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteNotice),
      switchMap((deleteNoticeInput) =>
        this.noticesService.deleteNotice(deleteNoticeInput.notice.Id).pipe(
          map((isDeleted) => {
            this.toastService.success('Deleted notice.');
            return actions.deleteNoticeSuccess({ isDeleted });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load active notices.');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  saveNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveNotice),
      switchMap((saveNoticeInput) =>
        this.noticesService.saveNotice(saveNoticeInput.notice).pipe(
          map((notice) => {
            this.toastService.success('Saved notice.');
            return actions.saveNoticeSuccess({ notice });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load active notices.');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private noticesService: NoticesService,
    private toastService: CustomToastrService
  ) {}
}
