import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AvailableTeamAssignmentsGQL extends Query<
  Pick<data.Query, 'AvailableTeamAssignments'>,
  data.QueryAvailableTeamAssignmentsArgs
> {
  override document = gql`
    query AvailableTeamAssignments($station: String) {
      AvailableTeamAssignments(station: $station) {
        AvailableSharedTeams {
          AssignedMembers {
            AssignedOn
            EmployeeId
            FirstName
            LastName
            Status
          }
          Created
          CurrentOwner {
            EmployeeId
            FirstName
            LastName
          }
          Id
          Name
          OriginalOwner {
            EmployeeId
            FirstName
            LastName
          }
          Shared
          Station
          Updated
        }
        OwnedTeams {
          AssignedMembers {
            AssignedOn
            EmployeeId
            FirstName
            LastName
            Status
          }
          Created
          CurrentOwner {
            EmployeeId
            FirstName
            LastName
          }
          Id
          Name
          OriginalOwner {
            EmployeeId
            FirstName
            LastName
          }
          Shared
          Station
          Updated
        }
        SharedOwnerTeams {
          AssignedMembers {
            AssignedOn
            EmployeeId
            FirstName
            LastName
            Status
          }
          Created
          CurrentOwner {
            EmployeeId
            FirstName
            LastName
          }
          Id
          Name
          OriginalOwner {
            EmployeeId
            FirstName
            LastName
          }
          Shared
          Station
          Updated
        }
      }
    }
  `;
}
