import {
  FlightsEntity,
  FlightsSearchCriteria,
  MutationCreateManualTaskArgs,
  MutationCreateTaskArgs,
  Scalars,
  SelectedFlight,
  SortDetail,
  TaskState,
  FlightNotificationEntity,
  TaskEntity,
} from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Flights] Init');

export const throwError = createAction(
  '[Flights/API] API request failed',
  props<{ error: any }>()
);

export const loadFlightsRequest = createAction(
  '[Flights] Flights request',
  props<{ searchCriteria: FlightsSearchCriteria }>()
);

export const loadFlightSuccess = createAction(
  '[Flights/API] Load flights success',
  props<{ flights: FlightsEntity[] }>()
);

export const setSortDetail = createAction(
  '[Flights] Update sort details',
  props<{ sortDetail: SortDetail }>()
);

export const createManualTask = createAction(
  '[Flights] Create manual task request',
  props<{ maintenanceTaskArgs: MutationCreateManualTaskArgs }>()
);

export const createManualTaskSuccess = createAction(
  '[Flights/API] Create manual task success',
  props<{ taskId: Scalars['UUID']['output'] }>()
);

export const createTask = createAction(
  '[Flights/API] Create regular task request',
  props<{ maintenanceTaskArgs: MutationCreateTaskArgs }>()
);

export const createTaskSuccess = createAction(
  '[Flights/API] Create regular task success',
  props<{ taskId: Scalars['UUID']['output'] }>()
);

export const setSelectedFlight = createAction(
  '[Flights] Set selected flight',
  props<{ selectedFlight: SelectedFlight }>()
);

export const resetSelectedFlight = createAction(
  '[Flights] Reset selected flight'
);

export const setSelectedMyTasksFlight = createAction(
  '[Flights] Set selected My Tasks flight',
  props<{ selectedFlight: SelectedFlight }>()
);

export const updateFlightData = createAction(
  '[Flights] Update Flight Details',
  props<{
    aircraftId: string;
    flightLegId: string;
    notification: FlightNotificationEntity;
    task: TaskEntity;
    realTimeNotification: boolean;
  }>()
);

export const updateTaskState = createAction(
  '[Flights] Update Task State',
  props<{
    taskId: string;
    taskState: TaskState;
  }>()
);

export const loadUpdateTaskStateSuccess = createAction(
  '[Flights/API] Update task state success',
  props<{ taskId: string; taskState: TaskState }>()
);

export const triggerDepartureToast = createAction(
  '[Flights] Should trigger the Departure toast',
  props<{ flights: FlightsEntity[] }>()
);

export const setMyTasksSortDetail = createAction(
  '[Flights] Update My Tasks sort details',
  props<{ sortDetail: SortDetail }>()
);

export const setSelectedBowItem = createAction(
  '[Flights] Set selected BOW item',
  props<{ selectedBowItem: TaskEntity }>()
);

export const clearSelectedBowItem = createAction(
  '[Flights] Reset selected BOW item'
);
