import { Flight, NON_DST_Stations } from '@lm-apps/lmo/ui/data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {
  offsetDateTime,
  offsetDateTimeDayjs,
} from '@lm-apps/lmo/shared/utilities';

export function isRightStartFlight(flight: Flight): boolean {
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(isSameOrAfter);
  let isRightStart = false;

  if (
    flight.Departure &&
    flight.Departure.ArrivalStation?.trim() &&
    flight.Departure.ScheduledDeparture
  ) {
    //default to 5am-9am for all DST stations
    const startHour = 5;
    const endHour = 9;
    const endHourDst = 8;

    const offsetScheduleDate = <Date>(
      offsetDateTime(
        flight.Departure.ScheduledDeparture,
        flight.Departure.DepartureUtcAdjustment
      )
    );

    const scheduleDate = offsetDateTimeDayjs(
      flight.Departure.ScheduledDeparture,
      flight.Departure.DepartureUtcAdjustment
    )?.startOf('day');
    const start = scheduleDate?.add(startHour, 'hour');
    let end = scheduleDate?.add(endHour, 'hour').add(1, 'minute'); //09:01am

    if (
      scheduleDate &&
      NON_DST_Stations.some(
        (t) =>
          t.toLowerCase() == flight.Departure?.DepartureStation?.toLowerCase()
      )
    ) {
      const dateFwd = dayjs(new Date(scheduleDate.year(), 2, 1)); // In Date() month start with index 0, 0 is Jan

      const springForwardDate = dayjs(nthDayOfMonth(dateFwd, 2, 0))
        .add(3, 'hour')
        .toDate(); //2nd sunday of march clock forward 2am to 3am;

      const dateBck = dayjs(new Date(scheduleDate.year(), 10, 1)); // In Date() month start with index 0, 0 is Jan

      const fallBackDate = dayjs(nthDayOfMonth(dateBck, 1, 0))
        .add(1, 'hour')
        .toDate(); //1st sunday of november clock backward 2am to 1am;

      if (
        springForwardDate <= offsetScheduleDate &&
        offsetScheduleDate <= fallBackDate
      ) {
        //DST period
        end = scheduleDate.add(endHourDst, 'hour').add(1, 'minute'); //8:01am summer time for non-DST stations
      }
    }
    const afterFive = dayjs(offsetScheduleDate).isSameOrAfter(start);
    const beforeNine = dayjs(offsetScheduleDate).isBefore(end);

    isRightStart = afterFive && beforeNine;
  }
  return isRightStart;
}

export function nthDayOfMonth(
  currentDate: dayjs.Dayjs,
  occurrence: number,
  day: number
): Date | null {
  let returnDate = null;

  if (currentDate) {
    const date =
      dayjs(currentDate).weekday() == day
        ? currentDate
        : currentDate?.add(day - currentDate.weekday(), 'day');
    if (date.month() < currentDate.month()) {
      occurrence += 1;
    }
    returnDate = date.add(7 * (occurrence - 1), 'day').toDate();
  }
  return returnDate;
}

// ! This function needs work. It does not appear to be working as expected.
// ! The commented code fixes one of the test cases, but still fails the one related to occurrence overflow.
// export function nthDayOfMonth(currentDate: dayjs.Dayjs, occurrence: number, day: number): Date | null {
//   let returnDate = null;

//   if (currentDate) {
//     const date = dayjs(currentDate).weekday() === day ? currentDate : currentDate.add((day + 7 - currentDate.weekday()) % 7, 'day');
//     returnDate = date.add(7 * (occurrence - 1), 'day').toDate();
//   }
//   return returnDate;
// }
