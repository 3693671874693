import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  AlternateTaskHistoryInput,
  AutomaticHoldHistoryInput,
  Flight,
  PairsHistoryFilterInput,
  ArrivalStatus as AStatus,
  DepartureStatus as DStatus,
  TaskHistory,
  TaskHistoryDisplay,
} from '@lm-apps/lmo/ui/data';

import { ArrivalStatus, DepartureStatus } from '@lm-apps/lmo/shared/enums';
import {
  AutomaticHoldHistoryGQL,
  AlternateTaskHistoryGQL,
  PairsHistoryGQL,
  TaskHistoryGQL,
} from './query';
import { Observable } from 'rxjs';
import { PairsHistory } from '@lm-apps/lmo/ui/data';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  constructor(
    private _alternateTasksHistory: AlternateTaskHistoryGQL,
    private _mtHoldHistory: AutomaticHoldHistoryGQL,
    private _pairsHistory: PairsHistoryGQL,
    private _taskHistory: TaskHistoryGQL
  ) {}

  getAlternateTaskHistoryDetails(
    alternateTaskHistoryInput: AlternateTaskHistoryInput
  ) {
    return this._alternateTasksHistory
      .fetch({
        alternateTaskHistoryInput,
      })
      .pipe(map(({ data }) => data.AlternateTaskHistoryDetails));
  }

  getMTHoldHistoryDetails(
    automaticHoldHistoryInput: AutomaticHoldHistoryInput
  ) {
    return this._mtHoldHistory
      .fetch({
        automaticHoldHistoryInput,
      })
      .pipe(map(({ data }) => data.AutomaticHoldHistory));
  }

  getFlightHistoryDetails(filterInput: PairsHistoryFilterInput) {
    return this._pairsHistory
      .fetch({
        filterInput,
      })
      .pipe(map(({ data }) => data.PairsHistory));
  }

  getPairsHistoryDetails(
    filterInput: PairsHistoryFilterInput
  ): Observable<PairsHistory[]> {
    return this._pairsHistory
      .fetch({
        filterInput,
      })
      .pipe(
        map(({ data }) => {
          return this.getMappedPairsHistoryDetails(data.PairsHistory);
        })
      );
  }

  getTaskHistoryDetails(taskId: string): Observable<TaskHistoryDisplay[]> {
    return this._taskHistory
      .fetch({
        taskId,
      })
      .pipe(
        map(({ data }) => {
          return this.getMappedTaskHistoryDetails(data.TaskHistory);
        })
      );
  }

  public getMappedPairsHistoryDetails(flights: Flight[]): PairsHistory[] {
    const result: PairsHistory[] = [];
    flights.forEach((flight) => {
      if (flight.Notifications) {
        flight.Notifications.map((notification) => {
          const pairsHistoryItem: PairsHistory = {
            Arrival: flight.Arrival,
            Departure: flight.Departure,
            Label: notification.Label,
            NoseNumber: notification.NoseNumber,
            SourceKey: isNaN(Number(notification.SourceKey))
              ? ''
              : notification.SourceKey,
            IsClosed: notification.IsClosed,
            Created: notification.Created,
            Discrepancy: notification.Discrepancy,
            TaskId: notification.Task?.Id,
            TaskState: notification.Task?.TaskState,
            Id: uuidv4(),
            ArrivalStatus: flight.Arrival
              ? this.getArrivalStatus(flight.Arrival.ArrivalStatus)
              : '',
            DepartureStatus: flight.Departure
              ? this.getDepartureStatus(flight.Departure.DepartureStatus)
              : '',
            CallType: notification.Task?.CallType.DisplayCallType || '',
            Status: notification.Task?.TaskState || 'Notification',
          };
          result.push(pairsHistoryItem);
        });
      }
    });
    return result;
  }

  public getArrivalStatus(status: string): string {
    switch (status) {
      case AStatus.InGate:
        return ArrivalStatus.IN_GATE;
      case AStatus.OnGround:
        return ArrivalStatus.ON_GROUND;
      default:
        return '';
    }
  }

  public getDepartureStatus(status: string): string {
    switch (status) {
      case DStatus.OffGround:
        return DepartureStatus.OFF_GROUND;
      case DStatus.OutFromGate:
        return DepartureStatus.OUT_FROM_GATE;
      case DStatus.ScheduledTimeOfDeparture:
        return DepartureStatus.SCHEDULED_TIME_OF_DEPARTURE;
      default:
        return '';
    }
  }

  public getMappedTaskHistoryDetails(
    taskHistory: TaskHistory[]
  ): TaskHistoryDisplay[] {
    const result: TaskHistoryDisplay[] = [];
    taskHistory.forEach((task) => {
      const assignedTo: string[] = [];
      let ackOn: Date | null = null;
      let arrOn: Date | null = null;
      if (task.AssignedMembers && task.AssignedMembers.length > 0) {
        for (let i = 0; i < task.AssignedMembers.length; i++) {
          const strAssignedTo =
            task.AssignedMembers[i].LastName +
            ', ' +
            task.AssignedMembers[i].FirstName +
            '[' +
            task.AssignedMembers[i].EmployeeId +
            ']';
          assignedTo.push(strAssignedTo);

          const ackDate =
            task.AssignedMembers[i].AcknowledgedOn instanceof Date
              ? task.AssignedMembers[i].AcknowledgedOn
              : null;

          if (ackDate && (!ackOn || (ackDate && ackDate < ackOn))) {
            ackOn = ackDate;
          }

          const arrDate =
            task.AssignedMembers[i].ArrivedOn instanceof Date
              ? task.AssignedMembers[i].ArrivedOn
              : null;
          if (arrDate && (!arrOn || (arrDate && arrDate < arrOn))) {
            arrOn = arrDate;
          }
        }
      }

      const taskAssignedByName = task.ChangedByName ?? '';
      const taskHistoryItem: TaskHistoryDisplay = {
        Status: task.TaskState ?? '',
        ChangedOn: task.ChangedOn,
        By: task.ChangedBy
          ? taskAssignedByName + '[' + task.ChangedBy + ']'
          : '',
        AcknowledgedOn: ackOn,
        ArrivedOn: arrOn,
        To: assignedTo,
      };
      result.push(taskHistoryItem);
    });
    return result;
  }
}
