import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.interfaces';
import { AssignedMember, Crew, USER_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

export const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectUser = createSelector(getUserState, (state) => state.User);

export const selectEmployeeId = createSelector(
  getUserState,
  (state) => state.User.EmployeeId
);

export const selectFilter = createSelector(
  getUserState,
  (state) => state.User.Filter
);

export const selectDefaultStation = createSelector(
  getUserState,
  (state) => state.User.DefaultStation
);

export const selectStations = createSelector(
  getUserState,
  (state) => state.Stations || []
);

export const isUserLoaded = createSelector(
  getUserState,
  (state) => state.Loaded
);

export const selectPredefinedTask = createSelector(getUserState, (state) => {
  const preDefinedTask = state.User.PredefinedTasks.filter((x) => x);
  if (preDefinedTask.length > 0) {
    preDefinedTask.sort((a, b) =>
      a.Title.localeCompare(b.Title, 'en', { numeric: true })
    );
  }
  return preDefinedTask;
});

export const selectActivePredefinedTasks = createSelector(
  getUserState,
  (state) => {
    const preDefinedTask = state.User.PredefinedTasks.filter((x) => x.IsActive);
    if (preDefinedTask.length > 0) {
      preDefinedTask.sort((a, b) =>
        a.Title.localeCompare(b.Title, 'en', { numeric: true })
      );
    }
    return preDefinedTask;
  }
);

export const selectCurrentCrew = createSelector(
  getUserState,
  (state) => state.User.Crew || null
);

export const selectSortCrewState = createSelector(
  getUserState,
  (state) => state.SortingPreferences.CrewMembers
);

export const selectCurrentCrewMapped = createSelector(
  selectCurrentCrew,
  (crew) => {
    if (!crew) return null;

    const assignedMembers: AssignedMember[] = [];
    crew.AssignedMembers.forEach((member) => {
      assignedMembers.push({
        EmployeeId: member.EmployeeId,
        FirstName: member.FirstName,
        LastName: member.LastName,
        Status: member.Status,
      });
    });
    const crewMapped: Crew = {
      CrewName: crew.Name,
      Station: crew.Station,
      CrewDisplayName: '',
      Members: assignedMembers,
    };
    return crewMapped;
  }
);

export const selectCurrentCrewId = createSelector(
  getUserState,
  (state) => state.User.SelectedCrewId || null
);

export const selectStationToSetForCrewModal = createSelector(
  selectCurrentCrew,
  selectStations,
  (currentCrew, selectedStationIds) => {
    const stationIncluded =
      currentCrew && selectedStationIds.includes(currentCrew.Station);
    return stationIncluded ? currentCrew.Station : selectedStationIds[0];
  }
);
