import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceItemComponent } from './maintenance-item/maintenance-item.component';
import { MaintenanceItemsComponent } from './maintenance-items/maintenance-items.component';
import { BlackNotificationsDataPipe } from './maintenance-items/maintenance-items.pipe';
import { ValidNotificationsDataPipe } from './maintenance-items/valid-notification-data.pipe';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaintenanceTaskComponent } from './maintenance-task/maintenance-task.component';

@NgModule({
  imports: [CommonModule, LmoUiCommonPipesModule, FontAwesomeModule],
  declarations: [
    MaintenanceItemComponent,
    MaintenanceItemsComponent,
    MaintenanceTaskComponent,
    BlackNotificationsDataPipe,
    ValidNotificationsDataPipe,
  ],
  exports: [
    MaintenanceItemComponent,
    MaintenanceItemsComponent,
    MaintenanceTaskComponent,
  ],
})
export class LmoSharedCommonModule {}
