import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  NotificationEvent,
  FlightNotificationEntity,
} from '@lm-apps/lmo/ui/data';
import { ItemType } from '@lm-apps/lmo/ui/data';
import { faOilCanDrip } from '@fortawesome/pro-solid-svg-icons';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';

@Component({
  selector: 'lm-apps-lmo-ui-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  faOilCanDrip = faOilCanDrip;
  @Input()
  notification!: FlightNotificationEntity;
  @Input() isRhs = false;
  @Output() selectedNotification = new EventEmitter<NotificationEvent>();
  ItemType = ItemType;

  onClick(event: Event) {
    if (this.notification.Label === SceptreLables.AirworthinessOPS.toString()) {
      return;
    }

    this.selectedNotification.emit({
      event: event,
      Notification: this.notification,
    });
  }
}
