<ng-template #lhsTemplate>
  <lm-apps-lmo-ui-cc-view-header
    (flightNumber)="filteredFlightNumber($event)"
    (searchNoseNumber)="getNoseNumbers($event)"
    (updateFilteredStations)="updateFilteredStations($event)"
    [aircraftSearchResults]="(aircraftSearchResults$ | async) || []"
    [formattedStationInfo]="(formattedStationInfo$ | async) || []"
    [currentCrew]="currentCrew$ | async"
    [filteredStations]="(filteredStations$ | async) || []"
    [defaultStation]="defaultStation$ | async"
    [roles]="roles"
    [crewManagerManpowerFlag]="crewManagerManpowerFlag$ | async"
  ></lm-apps-lmo-ui-cc-view-header>
  <hr class="cc-hr" />
  <lm-apps-lmo-ui-cc-view-flight-detail
    [flightsPair]="(flightsPair$ | async) || []"
    [searchFlightNumber]="flightNumberFromFilter"
    [searchNoseNumber]="noseNumberFromFilter"
    [currentCrew]="currentCrew$ | async"
    [sortDetail]="sortDetail$ | async"
    [maintHoldToolTip]="mic$ | async"
    [selectedRow]="selectedFlightData$ | async"
    [roles]="roles"
    [isFlightsLoaded]="(isFlightsLoaded$ | async) || false"
    [defferalMels]="(defferalMels$ | async) || []"
    (selectedMaintHoldNose)="getMaintHoldMics($event)"
    (unloadMaintHold)="unloadMaintHoldMics()"
    (selectedFlightPair)="setSelectedFlight($event)"
    (sortFlightPair)="sortDetail($event)"
  ></lm-apps-lmo-ui-cc-view-flight-detail>
  <hr class="cc-hr" />
  <!-- by default right panel will come below. so below div will take entire screen and right panel will be out of screen -->
  <div class="cc-table"></div>
</ng-template>

<ng-template #rhsTemplate>
  <lm-apps-lmo-ui-cc-view-rhs-home
    (scrollRhsEvent)="scrollToTop($event)"
  ></lm-apps-lmo-ui-cc-view-rhs-home>
</ng-template>

<lm-apps-side-panel-layout
  [lhsComponent]="lhsTemplate"
  [rhsComponent]="rhsTemplate"
  [scrollRhs]="scrollId"
></lm-apps-side-panel-layout>
