<section>
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-header filter-header">
      <span class="">Filters</span>
      <p-button
        icon="pi pi-times"
        styleClass="p-button-secondary p-button-text cross"
        (onClick)="close()"
      ></p-button>
    </div>

    <div class="modal-body d-flex flex-column gap-2 aa-m-4">
      <div>
        <div class="sub-heading">
          <span>Range</span>
        </div>
        <div class="col-12">
          <div *ngFor="let r of rangeList">
            <p-radioButton
              [value]="r"
              [formControl]="form.controls.rangeFrmCtrl"
              styleClass="aa-mb-1"
              [label]="r.toString()"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Fleet</span>
          <a
            (click)="onSelectDeselectAll(!selectAll.fleet, SelectAllType.Fleet)"
            class="float-right"
            >{{ selectAll.fleet ? DESELECT_ALL : SELECT_ALL }}</a
          >
        </div>
        <div class="col-12">
          <div *ngFor="let fleet of fleetsDisplayList" class="field-checkbox">
            <p-checkbox
              [formControl]="form.controls.fleetFrmCtrl"
              [label]="fleet"
              name="fleetGroup"
              [value]="fleet"
              (click)="displaySelectDeselectAll(SelectAllType.Fleet)"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Zones</span>
          <a
            class="float-right"
            (click)="onSelectDeselectAll(!selectAll.zone, SelectAllType.Zone)"
            >{{ selectAll.zone ? DESELECT_ALL : SELECT_ALL }}</a
          >
        </div>
        <div class="col-12">
          <div *ngFor="let zone of zoneNameList">
            <!-- using [formControl] ranther then formControlName because checkbox is not working with formControlName, it was only taking the latest value and replaced older one -->
            <p-checkbox
              name="zoneGroup"
              [value]="zone"
              [formControl]="form.controls.zonesFrmCtrl"
              [label]="zone"
              (click)="displaySelectDeselectAll(SelectAllType.Zone)"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="modal-footer aa-py-1">
        <p-button
          label="Select All"
          styleClass="p-button-success p-button-raised"
          (onClick)="setDefaultFilter()"
        ></p-button>
        <p-button
          label="Cancel"
          styleClass="p-button-danger p-button-raised aa-mx-2"
          (onClick)="close()"
        ></p-button>
        <p-button
          type="submit"
          label="Save"
          styleClass="p-button-raised"
          (click)="save()"
        ></p-button>
      </div>
    </div>
  </form>
</section>
