import { ItemType } from '@lm-apps/lmo/ui/data';
import { FlightPair, Notification } from '@lm-apps/lmo/wall/shared';

export const CUBA_STATIONS = ['HAV', 'SNU', 'CMW', 'VRA', 'HOG', 'SCU'];

export function CreateCubaNotificationTemplate(): Notification {
  return {
    aircraftHash: '',
    displayName: 'CUBA',
    maintenanceId: '',
    closed: false,
    type: ItemType.Cuba,
    priorityName: 'CUBA',
    hasTask: false,
  };
}

export function AddCubaNotification(flightPairs: FlightPair[]): FlightPair[] {
  const mockCubaNots: Notification = CreateCubaNotificationTemplate();
  flightPairs.forEach((pair) => {
    mockCubaNots.aircraftHash = pair.equipment.noseNumber;

    if (CUBA_STATIONS.includes(pair.departure?.aIata?.toUpperCase())) {
      if (pair.allTasks?.length > 0) {
        pair.allTasks.findIndex((t) => t.label == 'CUBA') == -1
          ? pair.nots.push(mockCubaNots)
          : console.log(
              'AlreadyHaveCuba Task in AC: ' + pair.equipment.noseNumber
            );
      } else {
        pair.nots.push(mockCubaNots);
      }
    }
  });
  return flightPairs;
}
