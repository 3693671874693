<p-confirmDialog [style]="{ width: '50vh' }"></p-confirmDialog>

<div class="aa-mt-2 aa-mr-4">
  <div class="d-flex justify-content-between">
    <button
      pButton
      type="button"
      (click)="openModal()"
      class="aa-mb-2 p-button-text p-button-secondary"
      icon="fa fa-plus"
    >
      <span class="aa-pl-2">Add new</span>
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="saveChanges()">
    <div formArrayName="tableRows">
      <p-table
        [value]="getTableRowsFormControls.controls"
        dataKey="id.value"
        editMode="row"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="width-5"></th>
            <th class="width-15">
              <h5 class="aa-m-0">Title</h5>
            </th>
            <th class="width-40">
              <h5 class="aa-m-0">Description</h5>
            </th>
            <th class="width-15">
              <h5 class="aa-m-0">Display</h5>
            </th>
            <th class="width-15">
              <h5 class="aa-m-0">Downtime</h5>
            </th>
            <th class="width-5"></th>
          </tr>
        </ng-template>
        <hr class="cc-hr" />
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr [formGroup]="row">
            <td>
              <div class="flex justify-content-center gap-2">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-pencil"
                  (click)="openModal(row.value)"
                  class="p-button-rounded p-button-text"
                ></button>
              </div>
            </td>
            <td>
              {{ row.value.Title }}
            </td>
            <td>
              {{ row.value.Description }}
            </td>
            <td>
              <ng-container
                *ngIf="
                  row.value.DisplayStart || row.value.DisplayEnd;
                  else emptyTableCell
                "
              >
                <h5 class="admin-notice-sub-header">Start</h5>
                {{
                  row.value.DisplayStart | date: 'M/d/yy h:mm:ss a z':'local'
                }}
                <h5 class="admin-notice-sub-header aa-mt-4">End</h5>
                {{ row.value.DisplayEnd | date: 'M/d/yy h:mm:ss a z':'local' }}
              </ng-container>
            </td>
            <td>
              <ng-container
                *ngIf="
                  row.value.DowntimeStart || row.value.DowntimeEnd;
                  else emptyTableCell
                "
              >
                <h5 class="admin-notice-sub-header">Start</h5>
                {{
                  row.value.DowntimeStart | date: 'M/d/yy h:mm:ss a z':'local'
                }}
                <h5 class="admin-notice-sub-header aa-mt-4">End</h5>
                {{ row.value.DowntimeEnd | date: 'M/d/yy h:mm:ss a z':'local' }}
              </ng-container>
            </td>
            <td>
              <button
                pButton
                (click)="onDeleteNotice(row)"
                type="button"
                class="p-button-text p-button-secondary aa-ml-2"
                icon="pi pi-trash"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </form>
</div>
<ng-template #emptyTableCell> - </ng-template>
