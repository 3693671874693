<ng-container *ngIf="selectedBowItem?.BowDetails as bowDetails">
  <div
    class="d-flex align-items-center justify-content-center"
    *ngIf="bowDetails.IsBowCompleted; else notCompleted"
    [tooltip]="completedBowItemTooltipText"
  >
    <span class="aa-dark-green">{{ listOfBowItemStatuses.COMPLETED }}</span>
    <fa-icon
      [icon]="faInfoCircleIcon"
      class="info-icon aa-ml-1 aa-medium-gray"
    ></fa-icon>
  </div>
  <ng-template #notCompleted>
    {{ listOfBowItemStatuses.SENT_TO_TSX }}
  </ng-template>
</ng-container>
