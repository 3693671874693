<div
  class="text-right"
  [ngClass]="etopClassValueBorder"
  data-cy="cc-view-outbd-etop-red-border"
>
  <div class="h3-1" data-cy="cc-view-outbd-departure-date">
    {{ departureDate }}
  </div>
  <div>
    <strong
      [ngClass]="
        isEtopMismatch === true
          ? ['redBackgroundWhiteText']
          : departure.EtopsCheckCompleted === true
          ? ['aa-green']
          : ['aa-dark-blue']
      "
      data-cy="cc-view-outbd-etops"
      *ngIf="departure.IsEtopsFlight"
      >{{ isEtopMismatch ? NON_ETOPS_AC : ETOPS_FLT }}<br
    /></strong>
  </div>
  <div class="font-weight-bold text-muted">
    <span data-cy="cc-view-outbd-departure-station">{{
      departure.DepartureStation
    }}</span>
    -
    <span data-cy="cc-view-outbd-arrival-station">{{
      departure.ArrivalStation
    }}</span>
  </div>
</div>
