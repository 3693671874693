import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStations from './+state/stations.reducer';
import { StationsEffects } from './+state/stations.effects';
import { STATIONS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(STATIONS_FEATURE_KEY, fromStations.reducer),
    EffectsModule.forFeature([StationsEffects]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 0,
      preventDuplicates: true,
      newestOnTop: false,
    }),
  ],
})
export class LmoUiDataAccessStationsModule {}
