/**
 * Sorts an array of enum values, with a specific enum key moved to the top.
 *
 * @template T The type of the enum values.
 * @param {Record<string, T>} enumObject The object representing the enum.
 * @param {string} enumValueToShowOnTop The key of the enum to show on top.
 * @returns {T[]} The sorted array of enum values.
 */
export function moveSpecificItemToTopThenSort<T extends string>(
  enumObject: Record<string, T>,
  enumValueToShowOnTop: string
): T[] {
  const values = Object.values(enumObject);
  values.sort((a, b) => {
    if (a === enumObject[enumValueToShowOnTop]) {
      return -1;
    }
    if (b === enumObject[enumValueToShowOnTop]) {
      return 1;
    }
    return String(a).localeCompare(String(b));
  });
  return values;
}
