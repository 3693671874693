import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { map } from 'rxjs/operators';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class FlightByAircraftGQL extends Query<
  Pick<data.Query, 'FlightsByAircraft'>,
  data.QueryFlightsByAircraftArgs
> {
  override document = gql`
    query FlightsByAircraft($noseNumber: String!) {
      FlightsByAircraft(noseNumber: $noseNumber) {
        Id

        AircraftId
        ArrivalStation
        ArrivalUtcAdjustment
        DepartureStation
        DepartureStatusCode
        ArrivalStatusCode
        DepartureUtcAdjustment
        Designator {
          FlightNumber
          LegOriginDate
        }
        Equipment {
          NoseNumber
          AirlineCode
        }

        ActualIn
        ActualOn
        EstimatedArrival
        ScheduledArrival

        ActualOff
        ActualOut
        EstimatedDeparture
        ScheduledDeparture

        ActualArrival
        ActualDeparture
      }
    }
  `;
}
@Injectable({
  providedIn: 'root',
})
export class FlightByAircraftService {
  constructor(private _flight: FlightByAircraftGQL) {}
  flightsByAircraft(noseNumber: string) {
    return this._flight
      .fetch({ noseNumber: noseNumber })
      .pipe(map(({ data }) => data.FlightsByAircraft));
  }
}
