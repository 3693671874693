import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as data from '@lm-apps/lmo/ui/data';
import { Observable } from 'rxjs';
import { TaskState } from '@lm-apps/lmo/ui/data';
import { CreateRegularTaskGQL, UpdateTaskStateGQL } from './query';

@Injectable({
  providedIn: 'root',
})
export class RegularTaskService {
  constructor(
    private createRegularTask: CreateRegularTaskGQL,
    private updateTaskStateGQL: UpdateTaskStateGQL
  ) {}
  createTask(
    maintenanceTaskArgs: data.MutationCreateTaskArgs
  ): Observable<data.Scalars['UUID']['output']> {
    return this.createRegularTask.mutate(maintenanceTaskArgs).pipe(
      map((response) => {
        if (response.data?.CreateTask?.TaskId) {
          return response.data.CreateTask.TaskId;
        } else {
          throw new Error();
        }
      })
    );
  }

  updateTaskState(
    newTaskState: TaskState,
    taskId: string
  ): Observable<{
    taskState: data.TaskState;
    taskId: data.Scalars['UUID']['output'];
  }> {
    return this.updateTaskStateGQL.mutate({ newTaskState, taskId }).pipe(
      map((response) => {
        const updatedTaskState = response.data?.UpdateTaskState;
        if (updatedTaskState?.Id && updatedTaskState?.TaskState) {
          return {
            taskState: updatedTaskState.TaskState,
            taskId: updatedTaskState.Id,
          };
        } else {
          throw new Error('Invalid response: Missing task ID or task state');
        }
      })
    );
  }
}
