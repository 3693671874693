<span class="tw-relative tw-inline-flex"
  ><button
    type="button"
    class="tw-inline-flex tw-items-center tw-leading-6 tw-text-sky-500 tw-ring-1 tw-ring-gray-900/10 tw-transition tw-duration-150 tw-ease-in-out dark:bg-white/5 dark:ring-white/20 maint-task text-clickable"
    [class.rhs-tile-format]="isRhs"
    [ngClass]="task | getTaskPriorityClassName"
    (click)="onClick($event)"
    [title]="title"
  >
    @if (task.JobType.KeyJobType !== keyJobType.Bow) {
    <i
      *ngIf="task.TaskState === TaskState.Acknowledged && isRhs === false"
      class="fa fa-thumbs-up"
    ></i>
    <i
      *ngIf="task.TaskState === TaskState.Arrived && isRhs === false"
      class="fa fa-plane"
    ></i>
    }

    <!-- This condition should only show for oil check from external sources -->
    <span *ngIf="task.MaintenanceType === ItemType.Oil">
      <span
        *ngIf="
          (task.TaskState === TaskState.Acknowledged ||
            task.TaskState === TaskState.Arrived) &&
          isRhs === false &&
          !task.IsNfnf
        "
        >&nbsp;</span
      >
      <i class="fa fa-tint vertical-align-middle"> </i>
    </span>

    <span
      class="cuba-flag-svg-icon"
      *ngIf="task.MaintenanceType === ItemType.Cuba"
    ></span>
    <!-- Show icons on the tile based on the task state for BOW tasks-->
    @if (task.JobType.KeyJobType === keyJobType.Bow && task.TaskState &&
    task.TaskState !== TaskState.Unassigned) {
    <lm-apps-lmo-ui-bow-task-status-icon
      [taskState]="task.TaskState"
    ></lm-apps-lmo-ui-bow-task-status-icon>
    }

    {{
      task.IsNfnf === true && isRhs === true
        ? 'NFNF'
        : (task | getTaskPriorityClassName | getLabelPrefix) + task.Label
    }}

    <!-- IsOilService is flag on create manual task. it will not show as true for oil tasks from external or internal sources -->
    <fa-icon *ngIf="task.IsOilService" [icon]="faOilCanDrip"></fa-icon>
    <span *ngIf="task.IsOilService && task.IsAvionic">&nbsp;</span>

    <i
      *ngIf="task.IsAvionic === true && isRhs === false"
      class="fa fa-bolt"
    ></i></button
  ><span
    *ngIf="task.RealTimeNotification"
    class="tw-absolute tw-top-0 tw-right-0 tw--mt-1 tw--mr-1 tw-flex tw-size-2"
    ><span
      class="tw-absolute tw-inline-flex tw-h-full tw-w-full tw-animate-ping tw-rounded-full tw-bg-sky-400 tw-opacity-75"
    ></span
    ><span
      class="tw-relative tw-inline-flex tw-size-2 tw-rounded-full tw-bg-sky-500"
    ></span></span
></span>
