<div
  *ngIf="
    flightsPair
      | filterByNoseNumber: searchNoseNumber
      | filterByFlightNumber: searchFlightNumber as filteredPairs
  "
>
  <div
    class="scrollArea cc-table"
    *ngIf="filteredPairs.length > 0 && isFlightsLoaded"
  >
    <p-table
      [value]="filteredPairs"
      [scrollable]="true"
      scrollHeight="flex"
      [rowTrackBy]="trackByFunction"
      tableStyleClass=""
    >
      <ng-template pTemplate="header">
        <tr
          class="small text-right aa-column-header aa-caret-sort th-inner p-0"
        >
          <th
            class="text-right"
            data-cy="cc-view-tb-ac"
            title="AIRCRAFT"
            (click)="sortData(AIRCRAFT)"
          >
            A/C<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === AIRCRAFT &&
                    sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === AIRCRAFT &&
                    sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th
            class="text-right"
            data-cy="cc-view-tb-inbd"
            title="INBOUND"
            (click)="sortData(INBOUND)"
          >
            {{ INBOUND
            }}<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === INBOUND &&
                    sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === INBOUND &&
                    sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th
            class="text-right"
            data-cy="cc-view-tb-arr"
            title="ARRIVAL"
            (click)="sortData(ARRIVAL)"
          >
            {{ ARRIVAL
            }}<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === ARRIVAL &&
                    sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === ARRIVAL &&
                    sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th
            class="text-right"
            data-cy="cc-view-tb-gate"
            title="GATE"
            (click)="sortData(GATE)"
          >
            {{ GATE
            }}<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === GATE && sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === GATE && sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th
            class="text-right"
            data-cy="cc-view-tb-outbd"
            title="OUTBOUND"
            (click)="sortData(OUTBOUND)"
          >
            {{ OUTBOUND
            }}<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === OUTBOUND &&
                    sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === OUTBOUND &&
                    sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th
            class="text-right"
            data-cy="cc-view-tb-dep"
            title="DEPARTURE"
            (click)="sortData(DEPARTURE)"
          >
            {{ DEPARTURE
            }}<span
              ><i
                class="fa pl-1"
                [ngClass]="{
                  'fa-caret-up':
                    sortDetail.column === DEPARTURE &&
                    sortDetail.sortOrder === ASC,
                  'fa-caret-down':
                    sortDetail.column === DEPARTURE &&
                    sortDetail.sortOrder === DESC
                }"
              ></i
            ></span>
          </th>
          <th class="nots" data-cy="cc-view-tb-nots" title="Notifications">
            {{ NOTIFICATIONS }}
          </th>
          <th class="assign" data-cy="cc-view-tb-unassign" title="Unassigned">
            {{ UNASSIGNED }}
          </th>
          <th class="assign" data-cy="cc-view-tb-assigned" title="Assigned">
            {{ ASSIGNED }}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" *ngIf="filteredPairs.length > 0" let-flight>
        <tr
          class="cc-item"
          [ngClass]="{
            active: flight === selectedRow?.flight,
            'delay-flight-with-task':
              flight.BackgroundRowColor.trim().length > 0
          }"
          (click)="clickFlightPair(flight)"
          data-cy="cc-view-tbody-tr"
        >
          <td class="text-right" data-cy="cc-view-td-ac">
            <div>
              <a
                class="create-new-task"
                (click)="openNewTaskModal(flight)"
                title="Create New Task"
                data-cy="cc-view-create-new-task"
                ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
              ></a>
              <span
                class="ots-stop-sign ml-2"
                *ngIf="flight.Equipment?.IsOutOfService"
                data-cy="cc-view-ots"
              ></span>

              <ng-template #toolTemplate>{{ maintHoldToolTip }}</ng-template>

              <span
                class="maintenance-hold-sign ml-2"
                *ngIf="
                  flight.Equipment?.IsMaintenanceHold &&
                  !flight.Equipment?.IsOutOfService
                "
                data-cy="cc-view-hold"
                (mouseover)="loadMaintHoldMics(flight.Equipment?.NoseNumber)"
                (mouseleave)="unloadMaintHoldMics()"
                [tooltip]="toolTemplate"
                container="body"
                placement="right"
              ></span>
              <lm-apps-nose-number
                class="text-right"
                [noseNumber]="flight.Equipment?.NoseNumber"
                [safeAircraftUri]="config.SAFE_URL"
                [fleetType]="flight.Equipment?.FleetType"
                [isEtopsCertified]="flight.Equipment?.IsEtopsCertified"
                [isEAMLEnabled]="flight.Equipment?.IsEAMLEnabled ?? false"
              ></lm-apps-nose-number>
            </div>
          </td>
          <td class="text-right">
            <lm-apps-lmo-ui-cc-view-inbd
              [arrival]="flight.Arrival"
              [arrivalDate]="
                arrivalDate(
                  flight.Arrival?.FlightNumber,
                  flight.Arrival?.ActualArrival,
                  flight.Arrival?.ArrivalUtcAdjustment,
                  flight.Departure?.ActualDeparture,
                  flight.Departure?.DepartureUtcAdjustment
                )
              "
            ></lm-apps-lmo-ui-cc-view-inbd>
          </td>
          <td class="text-right" data-cy="cc-view-td-arr">
            <lm-apps-lmo-ui-cc-view-arrival
              [arrival]="flight.Arrival"
              [arrivalTime]="
                arrivalTime(
                  flight.Arrival?.ActualArrival,
                  flight.Arrival?.ArrivalUtcAdjustment
                )
              "
            ></lm-apps-lmo-ui-cc-view-arrival>
          </td>
          <td class="text-right">
            <lm-apps-lmo-ui-cc-view-gate [flightPair]="flight">
            </lm-apps-lmo-ui-cc-view-gate>
          </td>
          <td class="text-right" data-cy="cc-view-td-outbd">
            <lm-apps-lmo-ui-cc-view-outbound
              [departure]="flight.Departure"
              [departureDate]="
                departureDate(
                  flight.Departure?.FlightNumber,
                  flight.Arrival?.ActualArrival,
                  flight.Arrival?.ArrivalUtcAdjustment,
                  flight.Departure?.ActualDeparture,
                  flight.Departure?.DepartureUtcAdjustment
                )
              "
              [isEtopsCertified]="flight.Equipment?.IsEtopsCertified"
            ></lm-apps-lmo-ui-cc-view-outbound>
          </td>
          <td class="text-right">
            <lm-apps-lmo-ui-cc-view-departure
              [departure]="flight.Departure"
              [departureTime]="
                departureTime(
                  flight.Arrival?.FlightNumber,
                  flight.Departure?.FlightNumber,
                  flight.Departure?.ActualDeparture,
                  flight.Arrival?.LegStatus,
                  flight.Departure?.EstimatedDeparture,
                  flight.Departure?.DepartureUtcAdjustment
                )
              "
            ></lm-apps-lmo-ui-cc-view-departure>
          </td>
          <td>
            <lm-apps-lmo-ui-notification
              [notifications]="flight.Notifications"
              [defferalMels]="defferalMels"
              [fleetType]="flight.Equipment?.FleetType"
              (selectedNotification)="clickNotification($event, flight)"
            ></lm-apps-lmo-ui-notification>
          </td>
          <td>
            <lm-apps-lmo-ui-unassigned
              [flight]="flight"
              (selectedTask)="clickTask($event, flight)"
            ></lm-apps-lmo-ui-unassigned>
          </td>
          <td>
            <lm-apps-lmo-ui-assigned
              [flight]="flight"
              (selectedTask)="clickTask($event, flight)"
            ></lm-apps-lmo-ui-assigned>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <blockquote
    class="text-danger cc-no-result"
    *ngIf="filteredPairs?.length === 0 && isFlightsLoaded"
    data-cy="cc-view-no-result"
  >
    <p>No Results. Please check your search or filter selections.</p>
  </blockquote>
</div>
<div *ngIf="!isFlightsLoaded">
  <ngx-skeleton-loader
    loadingText="Item is loading"
    count="25"
    [theme]="loaderTheme"
  ></ngx-skeleton-loader>
</div>
