import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusAllGQL extends Query<
  Pick<data.Query, 'OnlineStatusAll'>
> {
  override document = gql`
    query OnlineStatusAll {
      OnlineStatusAll {
        EmployeeId
        IsOnline
        Station
      }
    }
  `;
}
