import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ArrivalStatusCodePipe,
  ArrivalStatusPipe,
  DepartureStatusCodePipe,
  DepartureStatusPipe,
  FilterByFlightNumberPipe,
  FilterByNoseNumberPipe,
  FilterNotificationsToBeHiddenDataPipe,
  TaskStatePipe,
  orderByPipe,
  GetPriorityClassNamePipe,
  GetTaskPriorityClassNamePipe,
  FormatToShortNamePipe,
  MechanicStatusPipe,
  DateOffsetPipe,
  AssignedTaskPipe,
  UnassignedTaskPipe,
  NotificationsWithoutTaskPipe,
  CrewsAdjustmentPipe,
  FilterArrayPipe,
  IsIncludedinArrayPipe,
  ArraysEqualPipe,
  GetWallPriorityClassNamePipe,
  GetOnlineStatusPipe,
  GetLabelPrefixPipe,
  FilterAllTasksByJobTypePipe,
  TaskFilterByJobTypePipe,
  GetIndividualAmtStatusPipe,
  GetTaskStatusLabelPipe,
  TrimBadgeNumberPipe,
  ParseBadgeNumberPipe,
} from './pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ArrivalStatusCodePipe,
    ArrivalStatusPipe,
    DepartureStatusCodePipe,
    DepartureStatusPipe,
    FilterByFlightNumberPipe,
    FilterByNoseNumberPipe,
    FilterNotificationsToBeHiddenDataPipe,
    TaskStatePipe,
    orderByPipe,
    GetPriorityClassNamePipe,
    GetTaskPriorityClassNamePipe,
    FormatToShortNamePipe,
    MechanicStatusPipe,
    DateOffsetPipe,
    AssignedTaskPipe,
    UnassignedTaskPipe,
    NotificationsWithoutTaskPipe,
    CrewsAdjustmentPipe,
    FilterArrayPipe,
    IsIncludedinArrayPipe,
    ArraysEqualPipe,
    GetWallPriorityClassNamePipe,
    GetOnlineStatusPipe,
    GetLabelPrefixPipe,
    FilterAllTasksByJobTypePipe,
    TaskFilterByJobTypePipe,
    GetIndividualAmtStatusPipe,
    GetTaskStatusLabelPipe,
    TrimBadgeNumberPipe,
    ParseBadgeNumberPipe,
  ],
  exports: [
    ArrivalStatusCodePipe,
    ArrivalStatusPipe,
    DepartureStatusCodePipe,
    DepartureStatusPipe,
    FilterByFlightNumberPipe,
    FilterByNoseNumberPipe,
    FilterNotificationsToBeHiddenDataPipe,
    TaskStatePipe,
    orderByPipe,
    GetPriorityClassNamePipe,
    GetTaskPriorityClassNamePipe,
    FormatToShortNamePipe,
    MechanicStatusPipe,
    DateOffsetPipe,
    AssignedTaskPipe,
    UnassignedTaskPipe,
    NotificationsWithoutTaskPipe,
    CrewsAdjustmentPipe,
    FilterArrayPipe,
    IsIncludedinArrayPipe,
    ArraysEqualPipe,
    GetWallPriorityClassNamePipe,
    GetOnlineStatusPipe,
    GetLabelPrefixPipe,
    FilterAllTasksByJobTypePipe,
    TaskFilterByJobTypePipe,
    GetIndividualAmtStatusPipe,
    GetTaskStatusLabelPipe,
    TrimBadgeNumberPipe,
    ParseBadgeNumberPipe,
  ],
})
export class LmoUiCommonPipesModule {}
