import { Notice, NoticeInput } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Notices] Init');

export const getAllNotices = createAction('[Notices] Get all notices');

export const getActiveNotices = createAction('[Notices] Get active notices');

export const throwError = createAction(
  '[Notices/API] API request failed',
  props<{ error: any }>()
);

export const loadAllNoticesSuccess = createAction(
  '[Notices/API] Load all notices success',
  props<{ notices: Notice[] }>()
);

export const loadActiveNoticesSuccess = createAction(
  '[Notices/API] Load active notices success',
  props<{ notices: Notice[] }>()
);

export const loadNoticesSuccess = createAction(
  '[Notices/API] Load notice success',
  props<{ notices: Notice[] }>()
);

export const saveNoticeSuccess = createAction(
  '[Notice/API] Save notice success',
  props<{ notice: Notice }>()
);

export const deleteNoticeSuccess = createAction(
  '[Notice/API] Delete notice success',
  props<{ isDeleted: boolean }>()
);

export const deleteNotice = createAction(
  '[Notice] Delete notice',
  props<{ notice: Notice }>()
);

export const saveNotice = createAction(
  '[Notice] Save notice',
  props<{ notice: NoticeInput }>()
);

export const acknowledgeNotice = createAction(
  '[Notice] Acknowledge notice',
  props<{ notice: Notice }>()
);
