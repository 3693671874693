import { Observable, of, Subject } from 'rxjs';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';

export class MockFeatureFlagsService {
  onFeatureChanged = new Subject<FeatureFlag>();
  constructor(private features: FeatureFlag[]) {}
  getFeatureFlags() {
    return of(this.features);
  }
  updateFeatureFlag(name: string, enabled: boolean) {
    const featureFlag = this.features.find((f) => f.name === name);
    if (featureFlag && featureFlag.enabled != enabled) {
      featureFlag.enabled = enabled;
      this.onFeatureChanged.next(featureFlag);
    }
  }

  onFeatureChange(): Observable<FeatureFlag> {
    return of(this.features[0]);
  }
}
