export interface FeatureFlag {
  name: string;
  enabled: boolean;
}
export interface FeatureFlagChanged {
  flag: FeatureFlag;
}
export interface FeatureFlagChangedResponse {
  featureFlagChanged: FeatureFlag;
}

export interface FeatureFlagResponse {
  featureFlags: FeatureFlag[];
}
export interface FeatureFlagParams {
  appName: string;
  envName: string;
}
