import {
  CREW_MANPOWER_FEATURE_KEY,
  ManpowerCrew,
  FEATURE_FLAG_FEATURE_KEY,
} from '@lm-apps/lmo/ui/data';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './crew-manpower.actions';
export interface State extends EntityState<ManpowerCrew> {
  loaded: boolean; // is api call in progress
  error?: string | null; // last known error (if any)
}

export interface CrewManpowerPartialState {
  readonly [CREW_MANPOWER_FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<ManpowerCrew> =
  createEntityAdapter<ManpowerCrew>({
    selectId: (crew: ManpowerCrew) => crew.id,
  });

export const initialState: State = adapter.getInitialState({
  // set initial required properties
  ids: [],
  entities: Array<ManpowerCrew>(),
  loaded: false,
  error: null,
});

const crewManpowerReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(actions.loadCrewManpowerSuccess, (state, { crews }) =>
    adapter.setAll(crews, { ...state, loaded: true })
  ),
  on(actions.upsertCrewMember, (state, { crew }) =>
    adapter.upsertOne(crew, { ...state, loaded: true })
  ),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return crewManpowerReducer(state, action);
}
