import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCrewMemberStatus from './+state/crew-member-status.reducer';
import { CrewMemberStatusEffects } from './+state/crew-member-status.effects';
import { ToastrModule } from 'ngx-toastr';
import { CREW_MEMBER_STATUS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      CREW_MEMBER_STATUS_FEATURE_KEY,
      fromCrewMemberStatus.reducer
    ),
    EffectsModule.forFeature([CrewMemberStatusEffects]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 0,
      preventDuplicates: true,
      newestOnTop: false,
    }),
  ],
})
export class LmoUiDataAccessCrewMemberStatusModule {}
