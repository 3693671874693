import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as actions from './user.actions';
import { catchError, map, Observable, switchMap } from 'rxjs';
import {
  CustomToastrService,
  UserService,
} from '@lm-apps/lmo/ui/common/services';
import { SaveUserSource, User } from '@lm-apps/lmo/ui/data';

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private userService: UserService,
    private toastService: CustomToastrService
  ) {}

  loadUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => {
        return this.userService.getUser().pipe(
          switchMap((user: User) => [
            actions.loadUserSuccess({ user }),
            actions.updateStations({ stations: [] }),
          ]),
          catchError((error) => {
            saveUserToasterError(SaveUserSource.REGULAR, this.toastService);
            return [actions.throwError({ error })];
          })
        );
      })
    )
  );

  saveUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateUser),
      switchMap(({ saveUserInput, source }) => {
        return this.userService.saveUser(saveUserInput).pipe(
          switchMap((user) => {
            saveUserToasterSuccess(source, this.toastService, user);
            return [actions.loadUserSuccess({ user })];
          }),
          catchError((error) => {
            saveUserToasterError(source, this.toastService);
            return [actions.throwError({ error })];
          })
        );
      })
    )
  );

  acknowledgeNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.userAcknowledgeNotice),
      switchMap((action) =>
        this.userService.userAcknowledgeNotice(action.noticeId).pipe(
          map(() => {
            return actions.loadUserAcknowledgeNoticeSuccess();
          }),
          catchError((error) => {
            this.toastService.error('Failed to acknowledge notice.');
            return [
              actions.throwError({ error: error.message || 'Unknown error' }),
            ];
          })
        )
      )
    )
  );
}

export function saveUserToasterSuccess(
  source: SaveUserSource,
  service: CustomToastrService,
  user: User
) {
  switch (source) {
    case SaveUserSource.UPDATE_FILTER:
      service.success('Filter was updated', 'Success');
      break;
    case SaveUserSource.CREW_MANAGER:
      service.success('Saved crew', user.Crew?.Name);
      break;
    case SaveUserSource.PREDEFINED_TASKS:
      service.success('Predefined task was updated', 'Success');
      break;
    default:
      break;
  }
}

export function saveUserToasterError(
  source: SaveUserSource,
  service: CustomToastrService
) {
  switch (source) {
    case SaveUserSource.UPDATE_FILTER:
      service.error('Failed Updating Filter');
      break;
    case SaveUserSource.CREW_MANAGER:
      service.error('Failed on Crew Update');
      break;
    case SaveUserSource.PREDEFINED_TASKS:
      service.error('Failed to update predefined task');
      break;
    default:
      service.error('Failed on Get/Update User');
      break;
  }
}
