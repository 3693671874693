import * as data from '@lm-apps/lmo/ui/data';
import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteTeamAssignmentGQL extends Mutation<
  Pick<data.Mutation, 'DeleteTeamAssignment'>,
  data.MutationDeleteTeamAssignmentArgs
> {
  override document = gql`
    mutation DeleteTeamAssignment($teamId: String!) {
      DeleteTeamAssignment(teamId: $teamId) {
        AssignedMembers {
          AssignedOn
          EmployeeId
          FirstName
          LastName
          Status
        }
        Created
        CurrentOwner {
          EmployeeId
          FirstName
          LastName
        }
        Id
        Name
        OriginalOwner {
          EmployeeId
          FirstName
          LastName
        }
        Shared
        Station
        Updated
      }
    }
  `;
}
