import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
@Component({
  selector: 'lm-apps-search-nose-number',
  templateUrl: './search-nose-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchNoseNumberComponent implements OnInit {
  @Input() inputId = 'searchNoseNumber';
  @Output() noseChange: EventEmitter<string> = new EventEmitter();
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});
  noseNumber = '';
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      noseNumber: new UntypedFormControl(this.noseNumber),
    });
  }

  getFlightLegs() {
    if (this.formGroup.valid) {
      this.noseChange.emit(this.formGroup.get('noseNumber')?.value);
    }
  }
}
