import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './aircraft.actions';
import { AircraftService } from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
import { ActiveAircraft } from '@lm-apps/lmo/ui/data';

@Injectable()
export class AircraftEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() =>
        this.aircraftService.aircrafts().pipe(
          map((data: ActiveAircraft[]) => {
            return actions.loadAircraftSuccess({ aircrafts: data });
          }),
          catchError((error) => {
            return of(actions.throwError({ error }));
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private aircraftService: AircraftService
  ) {}
}
