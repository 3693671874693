import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { CrewMember } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-all-crew-mem',
  templateUrl: './all-crew-mem.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllCrewMemComponent implements OnChanges {
  @Input() allCrewMem: CrewMember[] = [];
  @Output() dragStartEvent: EventEmitter<CrewMember> = new EventEmitter();
  @Output() dragEndEvent: EventEmitter<boolean> = new EventEmitter();

  faGripDotsVertical = faGripDotsVertical;
  dynamicMaxLength = 6;

  ngOnChanges(): void {
    if (this.allCrewMem) {
      const tempAllCrewMem = [...this.allCrewMem];
      this.allCrewMem = tempAllCrewMem.sort((a, b) =>
        a.LastName.localeCompare(b.LastName)
      );
    }
  }
  dragStart(crewMem: CrewMember) {
    this.dragStartEvent.emit(crewMem);
  }
  dragEnd() {
    this.dragEndEvent.emit();
  }
  setMaxLength(value: string): void {
    this.dynamicMaxLength = /^\d+$/.test(value) ? 6 : 240;
  }
}
