export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date; output: Date };
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: string; output: string };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
};

export type ActiveAircraft = {
  __typename?: 'ActiveAircraft';
  AirlineCode: CarrierType;
  FleetType: Scalars['String']['output'];
  Id: Scalars['UUID']['output'];
  IsActive: Scalars['Boolean']['output'];
  IsEAMLEnabled?: Maybe<Scalars['Boolean']['output']>;
  IsEtopsCertified: Scalars['Boolean']['output'];
  IsMaintenanceHold: Scalars['Boolean']['output'];
  NoseNumber: Scalars['String']['output'];
  RegistrationNumber: Scalars['String']['output'];
};

export type Aircraft = {
  __typename?: 'Aircraft';
  AirlineCode: CarrierType;
  AutomaticMH?: Maybe<Scalars['Boolean']['output']>;
  DeliveryAirline?: Maybe<CarrierType>;
  EngineType?: Maybe<Scalars['String']['output']>;
  FleetSubType?: Maybe<Scalars['String']['output']>;
  FleetType: Scalars['String']['output'];
  Id: Scalars['UUID']['output'];
  IsActive: Scalars['Boolean']['output'];
  IsDeleted?: Maybe<Scalars['Boolean']['output']>;
  IsEAMLEnabled?: Maybe<Scalars['Boolean']['output']>;
  IsEtopsCertified: Scalars['Boolean']['output'];
  IsMaintenanceHold: Scalars['Boolean']['output'];
  MaintenanceHoldUpdateTime?: Maybe<Scalars['DateTime']['output']>;
  Manufacturer?: Maybe<Scalars['String']['output']>;
  NoseNumber: Scalars['String']['output'];
  RONFlightId?: Maybe<Scalars['String']['output']>;
  RONStation?: Maybe<Scalars['String']['output']>;
  RegistrationNumber: Scalars['String']['output'];
};

export type AircraftDesignator = {
  __typename?: 'AircraftDesignator';
  AirlineCode: CarrierType;
  NoseNumber: Scalars['String']['output'];
};

export type AircraftDesignatorInput = {
  AirlineCode: CarrierType;
  NoseNumber: Scalars['String']['input'];
};

export type AircraftLockItem = {
  __typename?: 'AircraftLockItem';
  TTG?: Maybe<Scalars['String']['output']>;
  ataCode: Scalars['String']['output'];
  category?: Maybe<Scalars['String']['output']>;
  createdDate: Scalars['String']['output'];
  daysOld: Scalars['NonNegativeInt']['output'];
  deferralCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  discrepancyDate: Scalars['String']['output'];
  employeeID?: Maybe<Scalars['String']['output']>;
  employeeName: Scalars['String']['output'];
  lastDeferDate?: Maybe<Scalars['String']['output']>;
  lockedStatus?: Maybe<Scalars['Boolean']['output']>;
  melReference?: Maybe<Scalars['String']['output']>;
  micNumber: Scalars['String']['output'];
  micStatus?: Maybe<Scalars['String']['output']>;
  noseNumber: Scalars['String']['output'];
  stationCode: Scalars['String']['output'];
};

export type AlternateTask = {
  __typename?: 'AlternateTask';
  AircraftId?: Maybe<Scalars['UUID']['output']>;
  AssignedMembers?: Maybe<Array<AssignedMember>>;
  BowDetails?: Maybe<BillOfWorkDetails>;
  CallType: CallType;
  ChangedBy?: Maybe<Scalars['String']['output']>;
  ChangedByName?: Maybe<Scalars['String']['output']>;
  ChangedOn: Scalars['DateTime']['output'];
  CompletedOn?: Maybe<Scalars['DateTime']['output']>;
  Created: Scalars['DateTime']['output'];
  CustomNotificationType?: Maybe<Scalars['String']['output']>;
  DeferredReason?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  ExternalItemId?: Maybe<Scalars['String']['output']>;
  Id: Scalars['UUID']['output'];
  InboundFlightLegId?: Maybe<Scalars['String']['output']>;
  InternalItemId?: Maybe<Scalars['UUID']['output']>;
  IsAlternateTask: Scalars['Boolean']['output'];
  IsAvionic: Scalars['Boolean']['output'];
  IsManualTask: Scalars['Boolean']['output'];
  IsNfnf: Scalars['Boolean']['output'];
  IsOilService: Scalars['Boolean']['output'];
  IsOspCheck: Scalars['Boolean']['output'];
  JobType: JobType;
  Label?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  MaintenancePriority?: Maybe<Priority>;
  MaintenanceType?: Maybe<ItemType>;
  NoseNumber?: Maybe<Scalars['String']['output']>;
  NotificationId?: Maybe<Scalars['UUID']['output']>;
  OutBoundFlightLegId?: Maybe<Scalars['UUID']['output']>;
  SpecialInstructions: Array<SpecialInstruction>;
  Station?: Maybe<Scalars['String']['output']>;
  TaskState?: Maybe<TaskState>;
  TaskUserReferenceId?: Maybe<Scalars['Int']['output']>;
  To?: Maybe<Scalars['String']['output']>;
  ToEmployeeId?: Maybe<Scalars['String']['output']>;
  UserDefinedItemId?: Maybe<Scalars['UUID']['output']>;
  UserId: Scalars['String']['output'];
  UserName?: Maybe<Scalars['String']['output']>;
};

export type AlternateTaskHistoryDetails = {
  __typename?: 'AlternateTaskHistoryDetails';
  ChangedBy: Scalars['String']['output'];
  Created: Scalars['DateTime']['output'];
  Id: Scalars['UUID']['output'];
  IsAvionic: Scalars['Boolean']['output'];
  IsOilService: Scalars['Boolean']['output'];
  Location?: Maybe<Scalars['String']['output']>;
  Nfnf: Scalars['Boolean']['output'];
  NoseNumber: Scalars['String']['output'];
  Station: Scalars['String']['output'];
  TaskState: Scalars['String']['output'];
};

export type AlternateTaskHistoryInput = {
  From: Scalars['DateTime']['input'];
  NoseNumber?: InputMaybe<Scalars['String']['input']>;
  Station: Scalars['String']['input'];
};

export enum AlternateTasksFilter {
  Aircraft = 'AIRCRAFT',
  All = 'ALL',
  None = 'NONE',
  NonAircraft = 'NON_AIRCRAFT',
}

export type Arrival = {
  __typename?: 'Arrival';
  ActualArrival?: Maybe<Scalars['DateTime']['output']>;
  ActualIn?: Maybe<Scalars['DateTime']['output']>;
  ActualOn?: Maybe<Scalars['DateTime']['output']>;
  ArrivalDelayOrEarlyStatus?: Maybe<Scalars['String']['output']>;
  ArrivalDelayOrEarlyStatusCode?: Maybe<Scalars['String']['output']>;
  ArrivalGate?: Maybe<Scalars['String']['output']>;
  ArrivalStation?: Maybe<Scalars['String']['output']>;
  ArrivalStatus: ArrivalStatus;
  ArrivalStatusCode: ArrivalStatusCode;
  ArrivalUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  DepartureStation?: Maybe<Scalars['String']['output']>;
  EstimatedArrival?: Maybe<Scalars['DateTime']['output']>;
  EtopsCheckCompleted?: Maybe<Scalars['Boolean']['output']>;
  FlightNumber?: Maybe<Scalars['String']['output']>;
  Hash?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['UUID']['output']>;
  IsEtopsFlight: Scalars['Boolean']['output'];
  LegOriginDate?: Maybe<Scalars['String']['output']>;
  LegStatus?: Maybe<Scalars['String']['output']>;
  ScheduledArrival?: Maybe<Scalars['DateTime']['output']>;
};

export enum ArrivalStatus {
  Delay = 'DELAY',
  Empty = 'EMPTY',
  EstimatedTimeOfArrival = 'ESTIMATED_TIME_OF_ARRIVAL',
  InGate = 'IN_GATE',
  OnGround = 'ON_GROUND',
  ScheduledTimeOfArrival = 'SCHEDULED_TIME_OF_ARRIVAL',
}

export enum ArrivalStatusCode {
  AtcDelay = 'ATC_DELAY',
  ChangeoverReceived = 'CHANGEOVER_RECEIVED',
  Early = 'EARLY',
  Empty = 'EMPTY',
  Estimated = 'ESTIMATED',
  FlightPlan = 'FLIGHT_PLAN',
  Holding = 'HOLDING',
  IndefDelay = 'INDEF_DELAY',
  InGate = 'IN_GATE',
  OnGround = 'ON_GROUND',
  Scheduled = 'SCHEDULED',
}

export type AssignedMember = {
  __typename?: 'AssignedMember';
  AcknowledgedDepartureAlert?: Maybe<Scalars['DateTime']['output']>;
  AcknowledgedOn?: Maybe<Scalars['DateTime']['output']>;
  ArrivedOn?: Maybe<Scalars['DateTime']['output']>;
  AssignedBy?: Maybe<Scalars['String']['output']>;
  AssignedByName?: Maybe<Scalars['String']['output']>;
  AssignedOn?: Maybe<Scalars['DateTime']['output']>;
  ChangedBy?: Maybe<Scalars['String']['output']>;
  ChangedOn?: Maybe<Scalars['DateTime']['output']>;
  CompletedOn?: Maybe<Scalars['DateTime']['output']>;
  DeliveredOn?: Maybe<Scalars['DateTime']['output']>;
  EmployeeId: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type AutomaticHoldHistory = {
  __typename?: 'AutomaticHoldHistory';
  AutomaticHoldStatus: Scalars['Boolean']['output'];
  Created: Scalars['DateTime']['output'];
  DepartureStation: Scalars['String']['output'];
  FlightNumber: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  NoseNumber: Scalars['String']['output'];
  NotificationId?: Maybe<Scalars['String']['output']>;
  OriginDate: Scalars['String']['output'];
  SourceKey: Scalars['String']['output'];
  Station: Scalars['String']['output'];
  TaskId?: Maybe<Scalars['String']['output']>;
};

export type AutomaticHoldHistoryInput = {
  NoseNumber: Scalars['String']['input'];
  Station: Scalars['String']['input'];
};

export type AvailableTeamAssignments = {
  __typename?: 'AvailableTeamAssignments';
  AvailableSharedTeams: Array<TeamAssignment>;
  OwnedTeams: Array<TeamAssignment>;
  SharedOwnerTeams: Array<TeamAssignment>;
};

export type BillOfWorkDetails = {
  __typename?: 'BillOfWorkDetails';
  IsBowCompleted: Scalars['Boolean']['output'];
  IsBowTaskCreatedOnTheFly: Scalars['Boolean']['output'];
  ScheduledDate?: Maybe<Scalars['DateTime']['output']>;
  Status: Scalars['String']['output'];
  WorkControlNumber?: Maybe<Scalars['String']['output']>;
};

export type BowJobContent = {
  __typename?: 'BowJobContent';
  actionType?: Maybe<Scalars['String']['output']>;
  aircraftNoseNumber?: Maybe<Scalars['String']['output']>;
  ataCode?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  dateAccomplished?: Maybe<Scalars['DateTime']['output']>;
  dateLastChange?: Maybe<Scalars['DateTime']['output']>;
  deferralCorrectionCode?: Maybe<Scalars['String']['output']>;
  deferralReasonCode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  isWorkCardPrinted?: Maybe<Scalars['Boolean']['output']>;
  jobNumber?: Maybe<Scalars['String']['output']>;
  lockIndicator?: Maybe<Scalars['String']['output']>;
  manufacturingSerialNumberOff?: Maybe<Scalars['String']['output']>;
  materialLaborIndicator?: Maybe<Scalars['String']['output']>;
  mePartNumber?: Maybe<Scalars['String']['output']>;
  meSerialNumber?: Maybe<Scalars['String']['output']>;
  micNumber?: Maybe<Scalars['String']['output']>;
  penDetectDesignator?: Maybe<Scalars['String']['output']>;
  planningPriority?: Maybe<Scalars['String']['output']>;
  positionDescription?: Maybe<Scalars['String']['output']>;
  screenType?: Maybe<Scalars['String']['output']>;
  signOffStation?: Maybe<Scalars['String']['output']>;
  taskStatus?: Maybe<Scalars['String']['output']>;
  trackingNumberOn?: Maybe<Scalars['String']['output']>;
  workControlStation?: Maybe<Scalars['String']['output']>;
};

export type BowJobsList = {
  __typename?: 'BowJobsList';
  bowContents: Array<BowJobContent>;
  errorMsg?: Maybe<Scalars['String']['output']>;
};

export type CallType = {
  __typename?: 'CallType';
  DisplayCallType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['NonNegativeInt']['output']>;
  IsActive?: Maybe<Scalars['Boolean']['output']>;
  KeyCallType: KeyCallType;
};

export type CallTypeInput = {
  DisplayCallType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  KeyCallType: KeyCallType;
};

export enum CarrierType {
  Aa = 'AA',
  Us = 'US',
  Xa = 'XA',
}

export type CommentDetailsEntity = {
  __typename?: 'CommentDetailsEntity';
  comment: Scalars['String']['output'];
  commentBy: Scalars['String']['output'];
};

export type CreateTaskResponse = {
  __typename?: 'CreateTaskResponse';
  AircraftId: Scalars['UUID']['output'];
  NotificationId: Scalars['UUID']['output'];
  TaskId: Scalars['UUID']['output'];
};

export type CreateTeamAssignedCrewMemberInput = {
  EmployeeId: Scalars['String']['input'];
  FirstName: Scalars['String']['input'];
  LastName: Scalars['String']['input'];
  Status: Scalars['String']['input'];
};

export type CreateTeamAssignmentInput = {
  AssignedMembers: Array<CreateTeamAssignedCrewMemberInput>;
  EmployeeId: Scalars['String']['input'];
  Name: Scalars['String']['input'];
  Shared: Scalars['Boolean']['input'];
  Station: Scalars['String']['input'];
};

export type CrewMember = {
  __typename?: 'CrewMember';
  AcknowledgedOn?: Maybe<Scalars['DateTime']['output']>;
  ArrivedOn?: Maybe<Scalars['DateTime']['output']>;
  AssignedOn?: Maybe<Scalars['DateTime']['output']>;
  ChangedBy?: Maybe<Scalars['String']['output']>;
  ChangedOn?: Maybe<Scalars['DateTime']['output']>;
  ClosedOn?: Maybe<Scalars['DateTime']['output']>;
  CompletedOn?: Maybe<Scalars['DateTime']['output']>;
  CrewMemberState?: Maybe<Scalars['String']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  EmployeeId: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  Id: Scalars['String']['output'];
  IsDeleted: Scalars['Boolean']['output'];
  IsOnline: Scalars['Boolean']['output'];
  LastName: Scalars['String']['output'];
  Position?: Maybe<Scalars['String']['output']>;
  SourceCode: Scalars['String']['output'];
  Station: Scalars['String']['output'];
  Status: Scalars['String']['output'];
  TabletAccessedOn?: Maybe<Scalars['DateTime']['output']>;
  TabletOnline?: Maybe<Scalars['Boolean']['output']>;
};

export type DeferralItemType = {
  __typename?: 'DeferralItemType';
  Fleet: Scalars['String']['output'];
  MelType: MelTypes;
  Name: Scalars['String']['output'];
};

export enum DelaysFilter {
  Arrivals = 'ARRIVALS',
  Departures = 'DEPARTURES',
  ShowAllAc = 'SHOW_ALL_AC',
}

export type Departure = {
  __typename?: 'Departure';
  ActualDeparture?: Maybe<Scalars['DateTime']['output']>;
  ActualOff?: Maybe<Scalars['DateTime']['output']>;
  ActualOut?: Maybe<Scalars['DateTime']['output']>;
  ArrivalStation?: Maybe<Scalars['String']['output']>;
  DepartureDelayOrEarlyStatus?: Maybe<Scalars['String']['output']>;
  DepartureDelayOrEarlyStatusCode?: Maybe<Scalars['String']['output']>;
  DepartureGate?: Maybe<Scalars['String']['output']>;
  DepartureStation?: Maybe<Scalars['String']['output']>;
  DepartureStatus: DepartureStatus;
  DepartureStatusCode: DepartureStatusCode;
  DepartureUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  DuplicateDepartureNumber?: Maybe<Scalars['Float']['output']>;
  EstimatedDeparture?: Maybe<Scalars['DateTime']['output']>;
  EtopsCheckCompleted?: Maybe<Scalars['Boolean']['output']>;
  FlightNumber?: Maybe<Scalars['String']['output']>;
  Hash?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['UUID']['output']>;
  IsEtopsFlight: Scalars['Boolean']['output'];
  IsRightStart?: Maybe<Scalars['Boolean']['output']>;
  LegOriginDate?: Maybe<Scalars['String']['output']>;
  LegStatus?: Maybe<Scalars['String']['output']>;
  ScheduledDeparture?: Maybe<Scalars['DateTime']['output']>;
};

export type DepartureDelayAlertDetails = {
  __typename?: 'DepartureDelayAlertDetails';
  AssignmentId: Array<Scalars['String']['output']>;
  NoseNumber: Scalars['String']['output'];
  PushNotificationTone: Scalars['String']['output'];
  UserId: Scalars['String']['output'];
};

export enum DepartureStatus {
  Delay = 'DELAY',
  Empty = 'EMPTY',
  EstimatedTimeOfDeparture = 'ESTIMATED_TIME_OF_DEPARTURE',
  OffGround = 'OFF_GROUND',
  OutFromGate = 'OUT_FROM_GATE',
  ScheduledTimeOfDeparture = 'SCHEDULED_TIME_OF_DEPARTURE',
}

export enum DepartureStatusCode {
  Cancelled = 'CANCELLED',
  Decision = 'DECISION',
  Deleted = 'DELETED',
  Early = 'EARLY',
  Empty = 'EMPTY',
  Estimated = 'ESTIMATED',
  IndefDelay = 'INDEF_DELAY',
  OffGround = 'OFF_GROUND',
  OutFromGate = 'OUT_FROM_GATE',
  Scheduled = 'SCHEDULED',
}

export enum EmployeeShiftStatus {
  Off = 'OFF',
  On = 'ON',
}

export type Equipment = {
  __typename?: 'Equipment';
  AirlineCode: CarrierType;
  AutomaticMH?: Maybe<Scalars['Boolean']['output']>;
  DeliveryAirline?: Maybe<CarrierType>;
  EngineType?: Maybe<Scalars['String']['output']>;
  EstimatedReturnToService?: Maybe<Scalars['DateTime']['output']>;
  FleetSubType?: Maybe<Scalars['String']['output']>;
  FleetType: Scalars['String']['output'];
  Id: Scalars['UUID']['output'];
  IsActive: Scalars['Boolean']['output'];
  IsDeleted?: Maybe<Scalars['Boolean']['output']>;
  IsEAMLEnabled?: Maybe<Scalars['Boolean']['output']>;
  IsEtopsCertified: Scalars['Boolean']['output'];
  IsMaintenanceHold: Scalars['Boolean']['output'];
  IsOutOfService?: Maybe<Scalars['Boolean']['output']>;
  MaintenanceHoldUpdateTime?: Maybe<Scalars['DateTime']['output']>;
  Manufacturer?: Maybe<Scalars['String']['output']>;
  NoseNumber: Scalars['String']['output'];
  RONFlightId?: Maybe<Scalars['String']['output']>;
  RONStation?: Maybe<Scalars['String']['output']>;
  RegistrationNumber: Scalars['String']['output'];
  Remark?: Maybe<Scalars['String']['output']>;
};

export type ExtendedAttributes = {
  __typename?: 'ExtendedAttributes';
  AirlineCode?: Maybe<Scalars['String']['output']>;
  ArmCode?: Maybe<Scalars['String']['output']>;
  AtaCode?: Maybe<Scalars['String']['output']>;
  ChapterCode?: Maybe<Scalars['String']['output']>;
  Classification?: Maybe<Scalars['String']['output']>;
  DeferralReason?: Maybe<Scalars['String']['output']>;
  DeferralReasonCode?: Maybe<Scalars['String']['output']>;
  DeferralReasonCodeDescription?: Maybe<Scalars['String']['output']>;
  DepartureStation?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DestinationStation?: Maybe<Scalars['String']['output']>;
  DiscrepancyDate?: Maybe<Scalars['DateTime']['output']>;
  DtLine?: Maybe<Scalars['String']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  Equipment?: Maybe<ExtendedEquipmentAttributes>;
  FlightNumber?: Maybe<Scalars['String']['output']>;
  ForecastDate?: Maybe<Scalars['DateTime']['output']>;
  IsClosed?: Maybe<Scalars['Boolean']['output']>;
  IsCustomerImpact?: Maybe<Scalars['Boolean']['output']>;
  IsDailyCheck?: Maybe<Scalars['Boolean']['output']>;
  IsFlightCheck?: Maybe<Scalars['Boolean']['output']>;
  IsMON?: Maybe<Scalars['Boolean']['output']>;
  IsOPS?: Maybe<Scalars['Boolean']['output']>;
  IsQuickFix?: Maybe<Scalars['Boolean']['output']>;
  IsROB?: Maybe<Scalars['Boolean']['output']>;
  IsSAD?: Maybe<Scalars['Boolean']['output']>;
  Label?: Maybe<Scalars['String']['output']>;
  MaintenancePriority?: Maybe<Priority>;
  MaintenanceType?: Maybe<ItemType>;
  MelNumber?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  MicNumber?: Maybe<Scalars['String']['output']>;
  NoseNumber?: Maybe<Scalars['String']['output']>;
  PositionCode?: Maybe<Scalars['String']['output']>;
  ReferenceNumber?: Maybe<Scalars['String']['output']>;
  RegistrationNumber?: Maybe<Scalars['String']['output']>;
  RemoteGroundStation?: Maybe<Scalars['String']['output']>;
  SourceSystem?: Maybe<Scalars['String']['output']>;
  SourceTimeStamp?: Maybe<Scalars['DateTime']['output']>;
  StandardMessageIdentifier?: Maybe<Scalars['String']['output']>;
  StationReported?: Maybe<Scalars['String']['output']>;
  UpOrDownLink?: Maybe<Scalars['String']['output']>;
};

export type ExtendedEquipmentAttributes = {
  __typename?: 'ExtendedEquipmentAttributes';
  AirlineCode?: Maybe<Scalars['String']['output']>;
  NoseNumber?: Maybe<Scalars['String']['output']>;
};

export type ExtendedFlightAttributes = {
  __typename?: 'ExtendedFlightAttributes';
  ArmCode?: Maybe<Scalars['String']['output']>;
  Classification?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  MelNumber?: Maybe<Scalars['String']['output']>;
  MicNumber?: Maybe<Scalars['String']['output']>;
  ReferenceNumber?: Maybe<Scalars['String']['output']>;
  ScheduledDate?: Maybe<Scalars['DateTime']['output']>;
  WorkControlNumber?: Maybe<Scalars['String']['output']>;
};

export enum ExternalEventSourceType {
  AircraftMaintenance = 'AIRCRAFT_MAINTENANCE',
  FlightHub = 'FLIGHT_HUB',
  Hermes = 'HERMES',
  OspOil = 'OSP_OIL',
  Sceptre = 'SCEPTRE',
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type FetchBowJobsByInput = {
  noseNumber?: InputMaybe<Scalars['String']['input']>;
  workControlNumber: Scalars['String']['input'];
};

export type Filter = {
  __typename?: 'Filter';
  Delay: DelaysFilter;
  Fleets: Array<Scalars['String']['output']>;
  GateOptions: Scalars['Boolean']['output'];
  Gates: Array<Scalars['String']['output']>;
  IncludeTasks: Scalars['Boolean']['output'];
  Miscellaneous: MiscFilter;
  NoiseToaster: Scalars['Boolean']['output'];
  NotificationType: Array<NotificationFilter>;
  OnlyMyTasks: Scalars['Boolean']['output'];
  OtherToaster: Scalars['Boolean']['output'];
  Range: Scalars['Int']['output'];
  RedToaster: Scalars['Boolean']['output'];
  TaskStatus: Array<TaskStatusFilter>;
  Zones: Array<Scalars['String']['output']>;
};

export type FilterInput = {
  Delay: DelaysFilter;
  Fleets: Array<Scalars['String']['input']>;
  GateOptions: Scalars['Boolean']['input'];
  Gates: Array<Scalars['String']['input']>;
  IncludeTasks: Scalars['Boolean']['input'];
  Miscellaneous: MiscFilter;
  NoiseToaster: Scalars['Boolean']['input'];
  NotificationType: Array<NotificationFilter>;
  OnlyMyTasks: Scalars['Boolean']['input'];
  OtherToaster: Scalars['Boolean']['input'];
  Range: Scalars['Int']['input'];
  RedToaster: Scalars['Boolean']['input'];
  TaskStatus: Array<TaskStatusFilter>;
  Zones: Array<Scalars['String']['input']>;
};

export type Flight = {
  __typename?: 'Flight';
  AllTasks?: Maybe<Array<Task>>;
  Arrival?: Maybe<Arrival>;
  Departure?: Maybe<Departure>;
  Equipment: Equipment;
  GroundTime?: Maybe<Scalars['String']['output']>;
  Hash: Scalars['String']['output'];
  Notifications?: Maybe<Array<FlightNotification>>;
  Station: Scalars['String']['output'];
};

export type FlightDesignator = {
  __typename?: 'FlightDesignator';
  AirlineCode?: Maybe<CarrierType>;
  DepartureStation: Scalars['String']['output'];
  DuplicateDepartureNumber: Scalars['Int']['output'];
  FlightNumber: Scalars['String']['output'];
  LegOriginDate?: Maybe<Scalars['String']['output']>;
};

export type FlightDesignatorInput = {
  AirlineCode?: InputMaybe<CarrierType>;
  DepartureStation: Scalars['String']['input'];
  DuplicateDepartureNumber: Scalars['Int']['input'];
  FlightNumber: Scalars['String']['input'];
  LegOriginDate?: InputMaybe<Scalars['String']['input']>;
};

export type FlightLeg = {
  __typename?: 'FlightLeg';
  ActualArrival?: Maybe<Scalars['DateTime']['output']>;
  ActualDeparture?: Maybe<Scalars['DateTime']['output']>;
  ActualIn?: Maybe<Scalars['DateTime']['output']>;
  ActualOff?: Maybe<Scalars['DateTime']['output']>;
  ActualOn?: Maybe<Scalars['DateTime']['output']>;
  ActualOut?: Maybe<Scalars['DateTime']['output']>;
  AircraftId?: Maybe<Scalars['UUID']['output']>;
  ArrivalGate?: Maybe<Scalars['String']['output']>;
  ArrivalStation?: Maybe<Scalars['String']['output']>;
  ArrivalStatus?: Maybe<ArrivalStatus>;
  ArrivalStatusCode?: Maybe<ArrivalStatusCode>;
  ArrivalUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  DepartureGate?: Maybe<Scalars['String']['output']>;
  DepartureStation?: Maybe<Scalars['String']['output']>;
  DepartureStatus?: Maybe<DepartureStatus>;
  DepartureStatusCode?: Maybe<DepartureStatusCode>;
  DepartureUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  Designator?: Maybe<FlightDesignator>;
  DuplicateArrivalNumber?: Maybe<Scalars['Float']['output']>;
  DuplicateDepartureNumber?: Maybe<Scalars['Float']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  Equipment?: Maybe<AircraftDesignator>;
  EstimatedArrival?: Maybe<Scalars['DateTime']['output']>;
  EstimatedDeparture?: Maybe<Scalars['DateTime']['output']>;
  EstimatedOn?: Maybe<Scalars['DateTime']['output']>;
  EtopsCheckCompleted?: Maybe<Scalars['Boolean']['output']>;
  EtopsCheckNeeded?: Maybe<Scalars['Boolean']['output']>;
  FlightNumber?: Maybe<Scalars['String']['output']>;
  FosPartition?: Maybe<Scalars['String']['output']>;
  Hash?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['UUID']['output']>;
  KickOffFlight: Scalars['Boolean']['output'];
  LegOriginDate?: Maybe<Scalars['String']['output']>;
  LegStatus?: Maybe<Scalars['String']['output']>;
  NextFlight?: Maybe<FlightLegBase>;
  PreviousFlight?: Maybe<FlightLegBase>;
  ScheduledArrival?: Maybe<Scalars['DateTime']['output']>;
  ScheduledDeparture?: Maybe<Scalars['DateTime']['output']>;
  StubFlightNumber?: Maybe<Scalars['String']['output']>;
};

export type FlightLegBase = {
  __typename?: 'FlightLegBase';
  ActualIn?: Maybe<Scalars['DateTime']['output']>;
  ActualOff?: Maybe<Scalars['DateTime']['output']>;
  ActualOn?: Maybe<Scalars['DateTime']['output']>;
  ActualOut?: Maybe<Scalars['DateTime']['output']>;
  AircraftId?: Maybe<Scalars['UUID']['output']>;
  ArrivalGate?: Maybe<Scalars['String']['output']>;
  ArrivalStation?: Maybe<Scalars['String']['output']>;
  ArrivalStatus?: Maybe<ArrivalStatus>;
  ArrivalStatusCode?: Maybe<ArrivalStatusCode>;
  ArrivalUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  DepartureGate?: Maybe<Scalars['String']['output']>;
  DepartureStation?: Maybe<Scalars['String']['output']>;
  DepartureStatus?: Maybe<DepartureStatus>;
  DepartureStatusCode?: Maybe<DepartureStatusCode>;
  DepartureUtcAdjustment?: Maybe<Scalars['Float']['output']>;
  Designator?: Maybe<FlightDesignator>;
  DuplicateArrivalNumber?: Maybe<Scalars['Float']['output']>;
  DuplicateDepartureNumber?: Maybe<Scalars['Float']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  Equipment?: Maybe<AircraftDesignator>;
  EstimatedArrival?: Maybe<Scalars['DateTime']['output']>;
  EstimatedDeparture?: Maybe<Scalars['DateTime']['output']>;
  EstimatedOn?: Maybe<Scalars['DateTime']['output']>;
  EtopsCheckCompleted?: Maybe<Scalars['Boolean']['output']>;
  EtopsCheckNeeded?: Maybe<Scalars['Boolean']['output']>;
  FlightNumber?: Maybe<Scalars['String']['output']>;
  FosPartition?: Maybe<Scalars['String']['output']>;
  Hash?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['UUID']['output']>;
  KickOffFlight: Scalars['Boolean']['output'];
  LegOriginDate?: Maybe<Scalars['String']['output']>;
  LegStatus?: Maybe<Scalars['String']['output']>;
  ScheduledArrival?: Maybe<Scalars['DateTime']['output']>;
  ScheduledDeparture?: Maybe<Scalars['DateTime']['output']>;
  StubFlightNumber?: Maybe<Scalars['String']['output']>;
};

export type FlightNotification = {
  __typename?: 'FlightNotification';
  AircraftHash?: Maybe<Scalars['String']['output']>;
  AircraftId: Scalars['UUID']['output'];
  AirlineCode?: Maybe<Scalars['String']['output']>;
  AssignedToNames?: Maybe<Array<AssignedMember>>;
  ChangedOn?: Maybe<Scalars['DateTime']['output']>;
  Created: Scalars['DateTime']['output'];
  CustomNotificationType?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Discrepancy: Scalars['String']['output'];
  DisplayName?: Maybe<Scalars['String']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  ExtendedAttributes?: Maybe<ExtendedFlightAttributes>;
  FlighttHash?: Maybe<Scalars['String']['output']>;
  ForecastDate?: Maybe<Scalars['DateTime']['output']>;
  HasTask: Scalars['Boolean']['output'];
  Id: Scalars['String']['output'];
  InBoundFlightLegId?: Maybe<Scalars['String']['output']>;
  IsClosed: Scalars['Boolean']['output'];
  IsDeleted: Scalars['Boolean']['output'];
  ItemClosed?: Maybe<Scalars['DateTime']['output']>;
  ItemDeleted?: Maybe<Scalars['DateTime']['output']>;
  Label: Scalars['String']['output'];
  NoseNumber?: Maybe<Scalars['String']['output']>;
  OutBoundFlightLegId?: Maybe<Scalars['UUID']['output']>;
  Priority?: Maybe<Priority>;
  PriorityName?: Maybe<Scalars['String']['output']>;
  ProcessDate?: Maybe<Scalars['DateTime']['output']>;
  SourceKey: Scalars['String']['output'];
  SourceSystem: SourceSystem;
  SourceType: SourceType;
  Status?: Maybe<Scalars['String']['output']>;
  Task?: Maybe<Task>;
  Type?: Maybe<ItemType>;
};

export enum FlightStatusFilter {
  All = 'ALL',
  Delayed = 'DELAYED',
  OnTime = 'ON_TIME',
  Scheduled = 'SCHEDULED',
}

export type FlightsSearchCriteria = {
  Delay: DelaysFilter;
  Fleets: Array<Scalars['String']['input']>;
  GateOptions: Scalars['Boolean']['input'];
  Gates: Array<Scalars['String']['input']>;
  IncludeTasks: Scalars['Boolean']['input'];
  Miscellaneous: MiscFilter;
  NoiseToaster: Scalars['Boolean']['input'];
  NotificationType: Array<NotificationFilter>;
  OnlyMyTasks: Scalars['Boolean']['input'];
  OtherToaster: Scalars['Boolean']['input'];
  Range: Scalars['Int']['input'];
  RedToaster: Scalars['Boolean']['input'];
  Stations: Array<Scalars['String']['input']>;
  TaskStatus: Array<TaskStatusFilter>;
  Zones: Array<Scalars['String']['input']>;
};

export type Gate = {
  __typename?: 'Gate';
  Name?: Maybe<Scalars['String']['output']>;
};

export enum HistoryKeyCallTypeFilter {
  All = 'ALL',
  Diversion = 'DIVERSION',
  GateReturn = 'GATE_RETURN',
  MocIoc = 'MOC_IOC',
  Planning = 'PLANNING',
  Radio = 'RADIO',
  Ramp = 'RAMP',
  Rtf = 'RTF',
  SystemGenerated = 'SYSTEM_GENERATED',
  Telephone = 'TELEPHONE',
  Vendor = 'VENDOR',
  Verbal = 'VERBAL',
  Vhf = 'VHF',
}

export enum HistoryNotificationTypeFilter {
  All = 'ALL',
  Cfmoil = 'CFMOIL',
  Hermes = 'HERMES',
  Manual = 'MANUAL',
  MeetGreet = 'MEET_GREET',
  MelFollowUp = 'MEL_FOLLOW_UP',
  MelGeneral = 'MEL_GENERAL',
  NefQuickFix = 'NEF_QUICK_FIX',
  Other = 'OTHER',
  QuickFix = 'QUICK_FIX',
  RightStartMeetGreet = 'RIGHT_START_MEET_GREET',
  RightStartWakeUp = 'RIGHT_START_WAKE_UP',
  Term = 'TERM',
  Transit = 'TRANSIT',
  WakeUp = 'WAKE_UP',
}

export enum HistoryTaskStatusFilter {
  All = 'ALL',
  Assigned = 'ASSIGNED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED',
  Suppressed = 'SUPPRESSED',
  SystemClosed = 'SYSTEM_CLOSED',
  Unassigned = 'UNASSIGNED',
}

export enum ItemType {
  AircraftWash = 'AIRCRAFT_WASH',
  Arms = 'ARMS',
  Bow = 'BOW',
  Cfp = 'CFP',
  Crc = 'CRC',
  Cuba = 'CUBA',
  DailyCheck = 'DAILY_CHECK',
  DailyCheckX = 'DAILY_CHECK_X',
  Deferral = 'DEFERRAL',
  Hermes = 'HERMES',
  Ibgate = 'IBGATE',
  IfeCheck = 'IFE_CHECK',
  ManualCreated = 'MANUAL_CREATED',
  ManualCubaTask = 'MANUAL_CUBA_TASK',
  ManualTask = 'MANUAL_TASK',
  Mon = 'MON',
  Obgate = 'OBGATE',
  Oil = 'OIL',
  OilCheck = 'OIL_CHECK',
  Ops = 'OPS',
  PostArrivalCheck = 'POST_ARRIVAL_CHECK',
  PreDepartureCheck = 'PRE_DEPARTURE_CHECK',
  Rem = 'REM',
  Rob = 'ROB',
  Tii = 'TII',
  TireCheck = 'TIRE_CHECK',
  TransitCheck = 'TRANSIT_CHECK',
  Undefined = 'UNDEFINED',
  X = 'X',
}

export type JobType = {
  __typename?: 'JobType';
  DisplayJobType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['NonNegativeInt']['output']>;
  IsActive?: Maybe<Scalars['Boolean']['output']>;
  IsSelection?: Maybe<Scalars['Boolean']['output']>;
  KeyJobType: KeyJobType;
};

export type JobTypeInput = {
  DisplayJobType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  IsActive?: InputMaybe<Scalars['Boolean']['input']>;
  IsSelection?: InputMaybe<Scalars['Boolean']['input']>;
  KeyJobType: KeyJobType;
};

export enum KeyCallType {
  Diversion = 'DIVERSION',
  GateReturn = 'GATE_RETURN',
  MocIoc = 'MOC_IOC',
  Planning = 'PLANNING',
  Radio = 'RADIO',
  Ramp = 'RAMP',
  Rtf = 'RTF',
  SystemGenerated = 'SYSTEM_GENERATED',
  Telephone = 'TELEPHONE',
  Vendor = 'VENDOR',
  Verbal = 'VERBAL',
  Vhf = 'VHF',
}

export enum KeyJobType {
  AcMove = 'AC_MOVE',
  Arms = 'ARMS',
  Bow = 'BOW',
  CdlFollowUp = 'CDL_FOLLOW_UP',
  Check = 'CHECK',
  Cuba = 'CUBA',
  DailyCheck = 'DAILY_CHECK',
  Discrepency = 'DISCREPENCY',
  EtopsCheck = 'ETOPS_CHECK',
  EtopsOilCheck = 'ETOPS_OIL_CHECK',
  Hermes = 'HERMES',
  MeetGreet = 'MEET_GREET',
  MelFollowUp = 'MEL_FOLLOW_UP',
  MelGeneral = 'MEL_GENERAL',
  MelQuickFix = 'MEL_QUICK_FIX',
  MocRequest = 'MOC_REQUEST',
  MonFollowUp = 'MON_FOLLOW_UP',
  NefFollowUp = 'NEF_FOLLOW_UP',
  NefQuickFix = 'NEF_QUICK_FIX',
  Open = 'OPEN',
  Other = 'OTHER',
  ReservedItem = 'RESERVED_ITEM',
  RightStartMeetGreet = 'RIGHT_START_MEET_GREET',
  RightStartWakeUp = 'RIGHT_START_WAKE_UP',
  Servicing = 'SERVICING',
  TacFollowUp = 'TAC_FOLLOW_UP',
  TransitCheck = 'TRANSIT_CHECK',
  WakeUp = 'WAKE_UP',
}

export type LockedItemsResponse = {
  __typename?: 'LockedItemsResponse';
  comments?: Maybe<Array<CommentDetailsEntity>>;
  lockedItems?: Maybe<Array<AircraftLockItem>>;
};

export type MaintenanceTaskInput = {
  Aircraft?: InputMaybe<AircraftDesignatorInput>;
  AircraftId?: InputMaybe<Scalars['UUID']['input']>;
  AssignedToIds: Array<Scalars['String']['input']>;
  CallType?: InputMaybe<TaskCallTypeInput>;
  DeferredReason?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  IataCode: Scalars['String']['input'];
  InboundFlightDesignator?: InputMaybe<FlightDesignatorInput>;
  InboundFlightLegId?: InputMaybe<Scalars['String']['input']>;
  IsAlternateTask: Scalars['Boolean']['input'];
  IsAvionic: Scalars['Boolean']['input'];
  IsManualTask: Scalars['Boolean']['input'];
  IsNfnf: Scalars['Boolean']['input'];
  IsOilService: Scalars['Boolean']['input'];
  IsUpdate: Scalars['Boolean']['input'];
  JobType?: InputMaybe<TaskJobTypeInput>;
  JsonAttribute?: InputMaybe<Scalars['String']['input']>;
  Label?: InputMaybe<Scalars['String']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  MaintenancePriority: PriorityInput;
  MaintenanceType?: InputMaybe<ItemType>;
  NotificationId?: InputMaybe<Scalars['UUID']['input']>;
  OutBoundFlightLegId?: InputMaybe<Scalars['UUID']['input']>;
  PredefinedTask?: InputMaybe<PredefinedTaskInput>;
  ScheduledDate?: InputMaybe<Scalars['DateTime']['input']>;
  SpecialInstruction?: InputMaybe<Scalars['String']['input']>;
  TaskAction: TaskAction;
  TaskId?: InputMaybe<Scalars['UUID']['input']>;
  TaskState?: InputMaybe<TaskState>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  WorkControlNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ManpowerCrew = {
  __typename?: 'ManpowerCrew';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  crew: Scalars['String']['output'];
  id: Scalars['String']['output'];
  members?: Maybe<ManpowerCrewMembers>;
  name: Scalars['String']['output'];
  station: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ManpowerCrewMember = {
  __typename?: 'ManpowerCrewMember';
  additionalTeams?: Maybe<Array<Scalars['String']['output']>>;
  assignedTeam?: Maybe<Scalars['String']['output']>;
  clockInTime?: Maybe<Scalars['DateTime']['output']>;
  clockOutTime?: Maybe<Scalars['DateTime']['output']>;
  codes?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employeeNumber: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  jobDescription: Scalars['String']['output'];
  jobName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  qualifications?: Maybe<Array<Qualification>>;
  scheduledTeam?: Maybe<Scalars['String']['output']>;
  station: Scalars['String']['output'];
  status: EmployeeShiftStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workbrainTeam: Scalars['String']['output'];
};

export type ManpowerCrewMembers = {
  __typename?: 'ManpowerCrewMembers';
  employees: Array<ManpowerCrewMember>;
};

export enum MelTypes {
  BlackMel = 'BLACK_MEL',
  OneCycleMel = 'ONE_CYCLE_MEL',
}

export enum MiscFilter {
  EtopsAc = 'ETOPS_AC',
  EtopsFlights = 'ETOPS_FLIGHTS',
  HideAosAc = 'HIDE_AOS_AC',
  HideUnlinkedAc = 'HIDE_UNLINKED_AC',
  HideUnlinkedWithoutNotifications = 'HIDE_UNLINKED_WITHOUT_NOTIFICATIONS',
  MismatchGates = 'MISMATCH_GATES',
  RightStartFlights = 'RIGHT_START_FLIGHTS',
  ShowAllAc = 'SHOW_ALL_AC',
  ShowAosAc = 'SHOW_AOS_AC',
  ShowEamlEnabledAc = 'SHOW_EAML_ENABLED_AC',
  TurnTime_45 = 'TURN_TIME_45',
  TurnTime_60 = 'TURN_TIME_60',
}

export type Mutation = {
  __typename?: 'Mutation';
  CreateErrorEventProcessorTrigger: ProcessorErrorEventTrigger;
  CreateManualTask: CreateTaskResponse;
  CreateTask: CreateTaskResponse;
  CreateTeamAssignment: TeamAssignment;
  DeleteNotices: Scalars['Boolean']['output'];
  DeleteTeamAssignment: TeamAssignment;
  DisableTeamSharing: TeamAssignment;
  EnableTeamSharing: TeamAssignment;
  ReleaseTeamOwnership: TeamAssignment;
  SaveNotices: Array<Notice>;
  SaveTabletTone: TabletTone;
  SaveUser: User;
  TakeTeamOwnership: TeamAssignment;
  UpdateSignInDetails: UserStatusDetails;
  UpdateTaskState: Task;
  UpdateTeamAssignment: TeamAssignment;
  UserAcknowledgeNotice: Scalars['Boolean']['output'];
};

export type MutationCreateErrorEventProcessorTriggerArgs = {
  trigger: ProcessorErrorEventTriggerInput;
};

export type MutationCreateManualTaskArgs = {
  maintenanceTask: MaintenanceTaskInput;
};

export type MutationCreateTaskArgs = {
  maintenanceTask: MaintenanceTaskInput;
};

export type MutationCreateTeamAssignmentArgs = {
  teamAssignment: CreateTeamAssignmentInput;
};

export type MutationDeleteNoticesArgs = {
  ids: Array<Scalars['UUID']['input']>;
};

export type MutationDeleteTeamAssignmentArgs = {
  teamId: Scalars['String']['input'];
};

export type MutationDisableTeamSharingArgs = {
  teamId: Scalars['String']['input'];
};

export type MutationEnableTeamSharingArgs = {
  teamId: Scalars['String']['input'];
};

export type MutationReleaseTeamOwnershipArgs = {
  teamId: Scalars['String']['input'];
};

export type MutationSaveNoticesArgs = {
  notices: Array<NoticeInput>;
};

export type MutationSaveTabletToneArgs = {
  tabletTone: TabletToneInput;
};

export type MutationSaveUserArgs = {
  user: SaveUserInput;
};

export type MutationTakeTeamOwnershipArgs = {
  teamId: Scalars['String']['input'];
};

export type MutationUpdateSignInDetailsArgs = {
  employeeId: Scalars['String']['input'];
  userStatusEvent: UserStatusEvents;
};

export type MutationUpdateTaskStateArgs = {
  newTaskState: TaskState;
  taskId: Scalars['String']['input'];
};

export type MutationUpdateTeamAssignmentArgs = {
  teamAssignment: UpdateTeamAssignmentInput;
};

export type MutationUserAcknowledgeNoticeArgs = {
  noticeId: Scalars['String']['input'];
};

export type Notice = {
  __typename?: 'Notice';
  Created: Scalars['DateTime']['output'];
  Description: Scalars['NonEmptyString']['output'];
  DisplayEnd?: Maybe<Scalars['DateTime']['output']>;
  DisplayStart?: Maybe<Scalars['DateTime']['output']>;
  DowntimeEnd?: Maybe<Scalars['DateTime']['output']>;
  DowntimeStart?: Maybe<Scalars['DateTime']['output']>;
  Id: Scalars['String']['output'];
  Title: Scalars['NonEmptyString']['output'];
  Updated: Scalars['DateTime']['output'];
};

export type NoticeInput = {
  Created?: InputMaybe<Scalars['DateTime']['input']>;
  Description: Scalars['NonEmptyString']['input'];
  DisplayEnd?: InputMaybe<Scalars['DateTime']['input']>;
  DisplayStart?: InputMaybe<Scalars['DateTime']['input']>;
  DowntimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  DowntimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Title: Scalars['NonEmptyString']['input'];
  Updated?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Notification = {
  __typename?: 'Notification';
  AircraftId: Scalars['UUID']['output'];
  ChangedOn?: Maybe<Scalars['DateTime']['output']>;
  Created: Scalars['DateTime']['output'];
  Discrepancy: Scalars['String']['output'];
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  ExtendedAttributes?: Maybe<ExtendedAttributes>;
  ForecastDate?: Maybe<Scalars['DateTime']['output']>;
  Id: Scalars['String']['output'];
  InBoundFlightLegId?: Maybe<Scalars['String']['output']>;
  IsClosed: Scalars['Boolean']['output'];
  IsDeleted: Scalars['Boolean']['output'];
  ItemClosed?: Maybe<Scalars['DateTime']['output']>;
  ItemDeleted?: Maybe<Scalars['DateTime']['output']>;
  Label: Scalars['String']['output'];
  OutBoundFlightLegId?: Maybe<Scalars['UUID']['output']>;
  Priority?: Maybe<Priority>;
  ProcessDate?: Maybe<Scalars['DateTime']['output']>;
  SourceKey: Scalars['String']['output'];
  SourceSystem: SourceSystem;
  SourceType: SourceType;
  Type?: Maybe<ItemType>;
};

export enum NotificationFilter {
  Cdl = 'CDL',
  Cfmoil = 'CFMOIL',
  FollowUpMon = 'FOLLOW_UP_MON',
  Hermes = 'HERMES',
  MaintenanceHold = 'MAINTENANCE_HOLD',
  Melflw = 'MELFLW',
  Melgen = 'MELGEN',
  Mg = 'MG',
  MocRequest = 'MOC_REQUEST',
  Open = 'OPEN',
  Other = 'OTHER',
  PiloTeMic = 'PILOTeMIC',
  Qfix = 'QFIX',
  Qnef = 'QNEF',
  Rsmg = 'RSMG',
  Rswk = 'RSWK',
  ShowAllAc = 'SHOW_ALL_AC',
  Transit = 'TRANSIT',
  WithNoNotification = 'WITH_NO_NOTIFICATION',
  Wkup = 'WKUP',
}

export type NotificationPriority = {
  __typename?: 'NotificationPriority';
  Description: Scalars['String']['output'];
  Id: Scalars['Int']['output'];
  IsActive: Scalars['Boolean']['output'];
  Order: Scalars['Int']['output'];
  SourceType: NotificationPrioritySourceType;
  Type: NotificationPriorityType;
};

export enum NotificationPrioritySourceType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
  Lmo = 'LMO',
  Other = 'OTHER',
  UserDefined = 'USER_DEFINED',
}

export enum NotificationPriorityType {
  Arms = 'ARMS',
  Cfmoil = 'CFMOIL',
  ConfigurationDeviationList = 'CONFIGURATION_DEVIATION_LIST',
  Cuba = 'CUBA',
  Hermes = 'HERMES',
  MaintenanceHold = 'MAINTENANCE_HOLD',
  MeetGreet = 'MEET_GREET',
  MelFollowUp = 'MEL_FOLLOW_UP',
  MelGeneral = 'MEL_GENERAL',
  MocRequest = 'MOC_REQUEST',
  MonFollowUp = 'MON_FOLLOW_UP',
  NefQuickFix = 'NEF_QUICK_FIX',
  Oil = 'OIL',
  Open = 'OPEN',
  Other = 'OTHER',
  PilotEMic = 'PILOT_eMIC',
  QuickFix = 'QUICK_FIX',
  RightStartMeetGreet = 'RIGHT_START_MEET_GREET',
  RightStartWakeUp = 'RIGHT_START_WAKE_UP',
  Term = 'TERM',
  Transit = 'TRANSIT',
  WakeUp = 'WAKE_UP',
}

export type OnlineStatus = {
  __typename?: 'OnlineStatus';
  EmployeeId: Scalars['String']['output'];
  IsOnline: Scalars['Boolean']['output'];
  Station: Scalars['String']['output'];
};

export type PairsHistoryFilterInput = {
  AlternateTasks?: InputMaybe<AlternateTasksFilter>;
  AutomatedMaintenanceHold?: InputMaybe<Scalars['Boolean']['input']>;
  DepartureFlightNumber?: InputMaybe<Scalars['String']['input']>;
  EmployeeId?: InputMaybe<Scalars['String']['input']>;
  FlightStatus?: InputMaybe<FlightStatusFilter>;
  From: Scalars['DateTime']['input'];
  NoseNumber?: InputMaybe<Scalars['String']['input']>;
  NotificationType?: InputMaybe<HistoryNotificationTypeFilter>;
  SourceKey?: InputMaybe<Scalars['String']['input']>;
  Station: Scalars['String']['input'];
  TaskCallType?: InputMaybe<HistoryKeyCallTypeFilter>;
  TaskStatus?: InputMaybe<HistoryTaskStatusFilter>;
};

export type PredefinedTask = {
  __typename?: 'PredefinedTask';
  Description: Scalars['String']['output'];
  Id?: Maybe<Scalars['Int']['output']>;
  IsActive: Scalars['Boolean']['output'];
  TaskCallType?: Maybe<CallType>;
  TaskJobType?: Maybe<JobType>;
  Title: Scalars['String']['output'];
};

export type PredefinedTaskInput = {
  Description: Scalars['String']['input'];
  Id?: InputMaybe<Scalars['Int']['input']>;
  IsActive: Scalars['Boolean']['input'];
  TaskCallType?: InputMaybe<CallTypeInput>;
  TaskJobType?: InputMaybe<JobTypeInput>;
  Title: Scalars['String']['input'];
};

export type Priority = {
  __typename?: 'Priority';
  Description?: Maybe<Scalars['String']['output']>;
  Order?: Maybe<Scalars['NonNegativeInt']['output']>;
};

export type PriorityInput = {
  Description?: InputMaybe<Scalars['String']['input']>;
  Order?: InputMaybe<Scalars['NonNegativeInt']['input']>;
};

export type ProcessorErrorEventTrigger = {
  __typename?: 'ProcessorErrorEventTrigger';
  EndDate?: Maybe<Scalars['DateTime']['output']>;
  ErrorMessage?: Maybe<Scalars['String']['output']>;
  FlightDesignator?: Maybe<FlightDesignator>;
  MaximumNumberOfTries: Scalars['Float']['output'];
  MicNumber?: Maybe<Scalars['String']['output']>;
  NoseNumber?: Maybe<Scalars['String']['output']>;
  ProcessorEventErrorId?: Maybe<Scalars['UUID']['output']>;
  RegistrationNumber?: Maybe<Scalars['String']['output']>;
  Source: ExternalEventSourceType;
  StartDate?: Maybe<Scalars['DateTime']['output']>;
};

export type ProcessorErrorEventTriggerInput = {
  EndDate?: InputMaybe<Scalars['DateTime']['input']>;
  ErrorMessage?: InputMaybe<Scalars['String']['input']>;
  FlightDesignator?: InputMaybe<FlightDesignatorInput>;
  MaximumNumberOfTries: Scalars['Float']['input'];
  MicNumber?: InputMaybe<Scalars['String']['input']>;
  NoseNumber?: InputMaybe<Scalars['String']['input']>;
  ProcessorEventErrorId?: InputMaybe<Scalars['UUID']['input']>;
  RegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  Source: ExternalEventSourceType;
  StartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Qualification = {
  __typename?: 'Qualification';
  bold?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  italic?: Maybe<Scalars['Boolean']['output']>;
  sourceCode?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
  title: Scalars['String']['output'];
  types?: Maybe<Scalars['String']['output']>;
  underline?: Maybe<Scalars['Boolean']['output']>;
  upperCase?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  ActiveAircrafts: Array<ActiveAircraft>;
  ActiveNotices: Array<Notice>;
  Aircraft: Aircraft;
  AircraftLockItems?: Maybe<LockedItemsResponse>;
  AllCrewMemberByStation: Array<CrewMember>;
  AllTeamAssignmentsByStation: Array<TeamAssignment>;
  AlternateTaskHistoryDetails: Array<AlternateTaskHistoryDetails>;
  AlternateTasks: Array<AlternateTask>;
  AutomaticHoldHistory: Array<AutomaticHoldHistory>;
  AvailableTeamAssignments: AvailableTeamAssignments;
  CallType: CallType;
  CallTypeByKey: CallType;
  CallTypes: Array<CallType>;
  DefaultPredefinedTasks: Array<PredefinedTask>;
  DeferralItemType: Array<DeferralItemType>;
  DepartureDelayAlertDetails: Array<Maybe<DepartureDelayAlertDetails>>;
  FeatureFlags: Array<FeatureFlag>;
  FetchBOWJobs: BowJobsList;
  Flights: Array<Flight>;
  FlightsByAircraft: Array<FlightLeg>;
  GetMyTeams: Array<ManpowerCrew>;
  JobType: JobType;
  JobTypeByKey: JobType;
  JobTypes: Array<JobType>;
  Notice: Notice;
  Notices: Array<Notice>;
  Notification: Notification;
  NotificationPriorities: Array<NotificationPriority>;
  OnlineStatusAll: Array<OnlineStatus>;
  OnlineStatusByStations: Array<StationOnlineStatus>;
  PairsHistory: Array<Flight>;
  PingUser: User;
  Regions: Array<Region>;
  RegionsStations: Array<RegionStations>;
  Roles: Array<UserRole>;
  Stations: Array<Station>;
  StationsZonesGates: Array<StationZones>;
  TabletTones: Array<TabletTone>;
  TabletUserStateHistory: TabletUserStateHistory;
  Task: Task;
  TaskHistory: Array<TaskHistory>;
  User: User;
  UsersYetToAcknowledge: Array<UserYetToAcknowledge>;
  Zones: Array<Zone>;
  ZonesGates: Array<Zone>;
};

export type QueryAircraftArgs = {
  noseNumber: Scalars['String']['input'];
};

export type QueryAircraftLockItemsArgs = {
  noseNumber: Scalars['String']['input'];
};

export type QueryAllCrewMemberByStationArgs = {
  station: Scalars['String']['input'];
};

export type QueryAllTeamAssignmentsByStationArgs = {
  station: Scalars['String']['input'];
};

export type QueryAlternateTaskHistoryDetailsArgs = {
  alternateTaskHistoryInput: AlternateTaskHistoryInput;
};

export type QueryAlternateTasksArgs = {
  stations: Array<Scalars['String']['input']>;
};

export type QueryAutomaticHoldHistoryArgs = {
  automaticHoldHistoryInput: AutomaticHoldHistoryInput;
};

export type QueryAvailableTeamAssignmentsArgs = {
  station?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCallTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCallTypeByKeyArgs = {
  key: Scalars['String']['input'];
};

export type QueryDeferralItemTypeArgs = {
  melType: MelTypes;
};

export type QueryDepartureDelayAlertDetailsArgs = {
  thresholdMinutes: Scalars['Int']['input'];
};

export type QueryFetchBowJobsArgs = {
  input: FetchBowJobsByInput;
};

export type QueryFlightsArgs = {
  flightsSearchCriteria: FlightsSearchCriteria;
};

export type QueryFlightsByAircraftArgs = {
  noseNumber: Scalars['String']['input'];
};

export type QueryGetMyTeamsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryJobTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryJobTypeByKeyArgs = {
  key: Scalars['String']['input'];
};

export type QueryNoticeArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryNotificationArgs = {
  id: Scalars['String']['input'];
};

export type QueryOnlineStatusByStationsArgs = {
  stations: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryPairsHistoryArgs = {
  filterInput: PairsHistoryFilterInput;
};

export type QueryRegionsArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryRegionsStationsArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryStationsArgs = {
  stationsSearchInput?: InputMaybe<StationsSearchInput>;
};

export type QueryStationsZonesGatesArgs = {
  stationsSearchInput?: InputMaybe<StationsSearchInput>;
};

export type QueryTabletUserStateHistoryArgs = {
  employeeId: Scalars['String']['input'];
  thresholdDays: Scalars['Int']['input'];
};

export type QueryTaskArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryTaskHistoryArgs = {
  taskId: Scalars['UUID']['input'];
};

export type QueryUsersYetToAcknowledgeArgs = {
  thresholdMinutes: Scalars['Int']['input'];
};

export type QueryZonesArgs = {
  stations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryZonesGatesArgs = {
  stations?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Region = {
  __typename?: 'Region';
  DisplayOrder: Scalars['Float']['output'];
  Name: Scalars['String']['output'];
};

export type RegionStations = {
  __typename?: 'RegionStations';
  DisplayOrder: Scalars['Float']['output'];
  Name: Scalars['String']['output'];
  Stations: Array<Station>;
};

export type SaveUserInput = {
  DefaultStation?: InputMaybe<Scalars['NonEmptyString']['input']>;
  EmployeeId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  Filter?: InputMaybe<FilterInput>;
  FirstName?: InputMaybe<Scalars['NonEmptyString']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['NonEmptyString']['input']>;
  NoticeAcknowledgedDate?: InputMaybe<Scalars['DateTime']['input']>;
  PredefinedTasks?: InputMaybe<Array<InputMaybe<PredefinedTaskInput>>>;
  Preferences?: InputMaybe<UserPreferencesInput>;
  SelectedCrewId?: InputMaybe<Scalars['String']['input']>;
};

export enum SourceSystem {
  Fh = 'FH',
  FlightCheck = 'FLIGHT_CHECK',
  FlightHubRon = 'FLIGHT_HUB_RON',
  Hermes = 'HERMES',
  Lmo = 'LMO',
  Osp = 'OSP',
  Sceptre = 'SCEPTRE',
  System = 'SYSTEM',
  TaskProvider = 'TASK_PROVIDER',
}

export enum SourceType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
  UserDefined = 'USER_DEFINED',
}

export type SpecialInstruction = {
  __typename?: 'SpecialInstruction';
  Created: Scalars['DateTime']['output'];
  EmployeeId: Scalars['String']['output'];
  EmployeeName: Scalars['String']['output'];
  Instructions: Scalars['String']['output'];
};

export type Station = {
  __typename?: 'Station';
  City?: Maybe<Scalars['String']['output']>;
  CountryCodeNumber?: Maybe<Scalars['Float']['output']>;
  CountryState?: Maybe<Scalars['String']['output']>;
  DSTimeZoneOffset: Scalars['Int']['output'];
  Displayed: Scalars['Boolean']['output'];
  HubStationDisplayOrder?: Maybe<Scalars['NonNegativeInt']['output']>;
  IataCode: Scalars['String']['output'];
  IsExtraBaseStation: Scalars['Boolean']['output'];
  IsHubStation: Scalars['Boolean']['output'];
  IsROD: Scalars['Boolean']['output'];
  IsRON: Scalars['Boolean']['output'];
  IsStaffed: Scalars['Boolean']['output'];
  Region: Scalars['String']['output'];
  TimeZoneOffset: Scalars['Int']['output'];
};

export type StationOnlineStatus = {
  __typename?: 'StationOnlineStatus';
  OnlineStatus: Array<OnlineStatus>;
  Station: Scalars['String']['output'];
};

export type StationZones = {
  __typename?: 'StationZones';
  City?: Maybe<Scalars['String']['output']>;
  CountryCodeNumber?: Maybe<Scalars['Float']['output']>;
  CountryState?: Maybe<Scalars['String']['output']>;
  DSTimeZoneOffset: Scalars['Int']['output'];
  Displayed: Scalars['Boolean']['output'];
  HubStationDisplayOrder?: Maybe<Scalars['NonNegativeInt']['output']>;
  IataCode: Scalars['String']['output'];
  IsExtraBaseStation: Scalars['Boolean']['output'];
  IsHubStation: Scalars['Boolean']['output'];
  IsROD: Scalars['Boolean']['output'];
  IsRON: Scalars['Boolean']['output'];
  IsStaffed: Scalars['Boolean']['output'];
  Region: Scalars['String']['output'];
  TimeZoneOffset: Scalars['Int']['output'];
  Zones: Array<Maybe<Zone>>;
};

export type StationsSearchInput = {
  Codes?: InputMaybe<Array<Scalars['NonEmptyString']['input']>>;
  Regions?: InputMaybe<Array<Scalars['NonEmptyString']['input']>>;
};

export type TabletSignInDetails = {
  __typename?: 'TabletSignInDetails';
  EmployeeId: Scalars['String']['output'];
  SignInActivityTime: Scalars['DateTime']['output'];
  SignInActivityType: Scalars['String']['output'];
};

export type TabletTone = {
  __typename?: 'TabletTone';
  DisplayName: Scalars['NonEmptyString']['output'];
  FileName: Scalars['NonEmptyString']['output'];
};

export type TabletToneInput = {
  DisplayName: Scalars['NonEmptyString']['input'];
  FileName: Scalars['NonEmptyString']['input'];
};

export type TabletUserStateHistory = {
  __typename?: 'TabletUserStateHistory';
  TabletAccessedOn: Array<Scalars['DateTime']['output']>;
  TabletSignInDetails: Array<Maybe<TabletSignInDetails>>;
  TaskAssignments: Array<Maybe<TaskAssignment>>;
  TaskAssignmentsHistory: Array<Maybe<TaskAssignment>>;
  UserPreferences: UserPreferences;
};

export type Task = {
  __typename?: 'Task';
  AircraftId?: Maybe<Scalars['UUID']['output']>;
  AssignedMembers?: Maybe<Array<AssignedMember>>;
  BowDetails?: Maybe<BillOfWorkDetails>;
  CallType: CallType;
  ChangedBy?: Maybe<Scalars['String']['output']>;
  ChangedByName?: Maybe<Scalars['String']['output']>;
  ChangedOn: Scalars['DateTime']['output'];
  CompletedOn?: Maybe<Scalars['DateTime']['output']>;
  Created: Scalars['DateTime']['output'];
  CustomNotificationType?: Maybe<Scalars['String']['output']>;
  DeferredReason?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  ExternalItemId?: Maybe<Scalars['String']['output']>;
  Id: Scalars['UUID']['output'];
  InboundFlightLegId?: Maybe<Scalars['String']['output']>;
  InternalItemId?: Maybe<Scalars['UUID']['output']>;
  IsAlternateTask: Scalars['Boolean']['output'];
  IsAvionic: Scalars['Boolean']['output'];
  IsManualTask: Scalars['Boolean']['output'];
  IsNfnf: Scalars['Boolean']['output'];
  IsOilService: Scalars['Boolean']['output'];
  IsOspCheck: Scalars['Boolean']['output'];
  JobType: JobType;
  Label?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  MaintenancePriority?: Maybe<Priority>;
  MaintenanceType?: Maybe<ItemType>;
  NotificationId?: Maybe<Scalars['UUID']['output']>;
  OutBoundFlightLegId?: Maybe<Scalars['UUID']['output']>;
  SpecialInstructions: Array<SpecialInstruction>;
  Station?: Maybe<Scalars['String']['output']>;
  TaskState?: Maybe<TaskState>;
  TaskUserReferenceId?: Maybe<Scalars['Int']['output']>;
  To?: Maybe<Scalars['String']['output']>;
  ToEmployeeId?: Maybe<Scalars['String']['output']>;
  UserDefinedItemId?: Maybe<Scalars['UUID']['output']>;
  UserId: Scalars['String']['output'];
  UserName?: Maybe<Scalars['String']['output']>;
};

export enum TaskAction {
  Complete = 'COMPLETE',
  Create = 'CREATE',
  Defer = 'DEFER',
  Reopen = 'REOPEN',
  Update = 'UPDATE',
}

export type TaskAssignment = {
  __typename?: 'TaskAssignment';
  AcknowledgedOn?: Maybe<Scalars['DateTime']['output']>;
  ArrivedOn?: Maybe<Scalars['DateTime']['output']>;
  AssignedOn: Scalars['DateTime']['output'];
  TaskId: Scalars['String']['output'];
};

export type TaskCallTypeInput = {
  DisplayCallType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  KeyCallType: KeyCallType;
};

export type TaskHistory = {
  __typename?: 'TaskHistory';
  AircraftId?: Maybe<Scalars['UUID']['output']>;
  AssignedMembers?: Maybe<Array<AssignedMember>>;
  BowDetails?: Maybe<BillOfWorkDetails>;
  CallType: CallType;
  ChangedBy?: Maybe<Scalars['String']['output']>;
  ChangedByName?: Maybe<Scalars['String']['output']>;
  ChangedOn: Scalars['DateTime']['output'];
  CompletedOn?: Maybe<Scalars['DateTime']['output']>;
  Created: Scalars['DateTime']['output'];
  CustomNotificationType?: Maybe<Scalars['String']['output']>;
  DeferredReason?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Effectivity?: Maybe<Scalars['DateTime']['output']>;
  ExternalItemId?: Maybe<Scalars['String']['output']>;
  Id: Scalars['UUID']['output'];
  InboundFlightLegId?: Maybe<Scalars['String']['output']>;
  InternalItemId?: Maybe<Scalars['UUID']['output']>;
  IsAlternateTask: Scalars['Boolean']['output'];
  IsAvionic: Scalars['Boolean']['output'];
  IsManualTask: Scalars['Boolean']['output'];
  IsNfnf: Scalars['Boolean']['output'];
  IsOilService: Scalars['Boolean']['output'];
  IsOspCheck: Scalars['Boolean']['output'];
  JobType: JobType;
  Label?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  MaintenancePriority?: Maybe<Priority>;
  MaintenanceType?: Maybe<ItemType>;
  NotificationId?: Maybe<Scalars['UUID']['output']>;
  OutBoundFlightLegId?: Maybe<Scalars['UUID']['output']>;
  SpecialInstructions: Array<SpecialInstruction>;
  Station?: Maybe<Scalars['String']['output']>;
  TaskId: Scalars['UUID']['output'];
  TaskState?: Maybe<TaskState>;
  TaskUserReferenceId?: Maybe<Scalars['Int']['output']>;
  Timestamp: Scalars['DateTime']['output'];
  To?: Maybe<Scalars['String']['output']>;
  ToEmployeeId?: Maybe<Scalars['String']['output']>;
  UserDefinedItemId?: Maybe<Scalars['UUID']['output']>;
  UserId: Scalars['String']['output'];
  UserName?: Maybe<Scalars['String']['output']>;
};

export type TaskJobTypeInput = {
  DisplayJobType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['NonNegativeInt']['input']>;
  KeyJobType: KeyJobType;
};

export enum TaskState {
  Acknowledged = 'ACKNOWLEDGED',
  Arrived = 'ARRIVED',
  Assigned = 'ASSIGNED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  Empty = 'EMPTY',
  Suppressed = 'SUPPRESSED',
  SystemClosed = 'SYSTEM_CLOSED',
  Unassigned = 'UNASSIGNED',
}

export enum TaskStatusFilter {
  Assigned = 'ASSIGNED',
  Avionics = 'AVIONICS',
  Completed = 'COMPLETED',
  Deferred = 'DEFERRED',
  NewTask = 'NEW_TASK',
  NotCompleted = 'NOT_COMPLETED',
  ShowAllAc = 'SHOW_ALL_AC',
  Unassigned = 'UNASSIGNED',
  WithNoTask = 'WITH_NO_TASK',
}

export type TeamAssignedCrewMember = {
  __typename?: 'TeamAssignedCrewMember';
  AssignedOn?: Maybe<Scalars['DateTime']['output']>;
  EmployeeId: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

export type TeamAssignment = {
  __typename?: 'TeamAssignment';
  AssignedMembers: Array<TeamAssignedCrewMember>;
  Created: Scalars['DateTime']['output'];
  CurrentOwner?: Maybe<TeamOwner>;
  Id: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OriginalOwner: TeamOwner;
  Shared: Scalars['Boolean']['output'];
  Station: Scalars['String']['output'];
  Updated: Scalars['DateTime']['output'];
};

export type TeamOwner = {
  __typename?: 'TeamOwner';
  EmployeeId: Scalars['String']['output'];
  FirstName: Scalars['String']['output'];
  LastName: Scalars['String']['output'];
};

export type UpdateTeamAssignedCrewMemberInput = {
  AssignedOn?: InputMaybe<Scalars['DateTime']['input']>;
  EmployeeId: Scalars['String']['input'];
  FirstName: Scalars['String']['input'];
  LastName: Scalars['String']['input'];
  Status: Scalars['String']['input'];
};

export type UpdateTeamAssignmentInput = {
  AssignedMembers: Array<InputMaybe<UpdateTeamAssignedCrewMemberInput>>;
  Id: Scalars['String']['input'];
  Name?: InputMaybe<Scalars['String']['input']>;
  Station?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  Crew?: Maybe<TeamAssignment>;
  DefaultStation: Scalars['NonEmptyString']['output'];
  EmployeeId: Scalars['NonEmptyString']['output'];
  Filter: Filter;
  FirstName: Scalars['NonEmptyString']['output'];
  Id?: Maybe<Scalars['String']['output']>;
  LastName: Scalars['NonEmptyString']['output'];
  NoticeAcknowledgedDate?: Maybe<Scalars['DateTime']['output']>;
  PredefinedTasks: Array<PredefinedTask>;
  Preferences?: Maybe<UserPreferences>;
  SelectedCrewId?: Maybe<Scalars['String']['output']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  PushNotificationTone: Scalars['String']['output'];
};

export type UserPreferencesInput = {
  PushNotificationTone: Scalars['String']['input'];
};

export type UserRole = {
  __typename?: 'UserRole';
  Action: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  RoleID?: Maybe<Scalars['String']['output']>;
  TypeID: Scalars['String']['output'];
};

export type UserStatusDetails = {
  __typename?: 'UserStatusDetails';
  EmployeeId: Scalars['NonEmptyString']['output'];
  IsOnline: Scalars['Boolean']['output'];
  Station: Scalars['String']['output'];
};

export enum UserStatusEvents {
  SignIn = 'SIGN_IN',
  SignOut = 'SIGN_OUT',
}

export type UserYetToAcknowledge = {
  __typename?: 'UserYetToAcknowledge';
  PushNotificationTone: Scalars['NonEmptyString']['output'];
  TaskCount: Scalars['Int']['output'];
  UserId: Scalars['NonEmptyString']['output'];
};

export type Zone = {
  __typename?: 'Zone';
  City?: Maybe<Scalars['String']['output']>;
  CountryState?: Maybe<Scalars['String']['output']>;
  GateName?: Maybe<Scalars['String']['output']>;
  Gates?: Maybe<Array<Gate>>;
  IataCode: Scalars['String']['output'];
  ZoneName: Scalars['String']['output'];
};
