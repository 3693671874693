<div>
  <div class="h3-1" data-cy="cc-view-gate">
    {{
      getGate(
        flightPair.Arrival?.ArrivalGate || '',
        flightPair.Departure?.DepartureGate || ''
      )
    }}
  </div>
  <div>
    <strong
      [ngClass]="className ? ['ground-time ' + className] : ['ground-time']"
      data-cy="cc-view-ground-time-remaining"
      >{{ text }}</strong
    >
  </div>
  <div>
    <strong
      *ngIf="flightPair.Arrival?.LegStatus === LegStatus.GROUND_INTERRUPT"
      [ngClass]="LegStatus.GROUND_INTERRUPT"
      data-cy="cc-view-ground-interrupt"
      >{{ LegStatus.RETURN_TO_GATE }}</strong
    >
    <strong
      *ngIf="flightPair.Arrival?.LegStatus === LegStatus.AIR_INTERRUPT"
      [ngClass]="LegStatus.AIR_INTERRUPT"
      data-cy="cc-view-air-interrupt"
      >{{ LegStatus.RETURN_TO_FIELD }}</strong
    >
  </div>
</div>
