export enum NotificationsDisplayName {
  /**
   * Transit_Check
   */
  Transit_Check = 'TI CK',
  /**
   * Transit Check Description
   */
  Transit_Check_Desc = 'Perform TRANSIT Check',
}

export enum NotificationType {
  /**
   * Oil Check
   */
  Oil_Check = 'OIL CHK',
  Oil_Top_Off = 'OIL TOP OFF',
}
