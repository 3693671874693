import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  Inject,
} from '@angular/core';
import { FlightsEntity, MaintenanceTaskDisplay } from '@lm-apps/lmo/ui/data';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-aircraft-attribute',
  templateUrl: './aircraft-attribute.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AircraftAttributeComponent {
  @Input() flight!: FlightsEntity;
  @Input() isMyTasks = false;

  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  maintenanceTaskDisplay!: MaintenanceTaskDisplay;
  constructor(@Inject(PING_APP_ENVIRONMENT) public config: AppEnvironment) {}

  close() {
    this.closeEvent.emit();
  }
}
