import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  DeferralItemType,
  FlightNotificationEntity,
  NotificationEvent,
} from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notifications!: FlightNotificationEntity[];
  @Input() defferalMels: DeferralItemType[] = [];
  @Input() fleetType = '';

  @Output() selectedNotification = new EventEmitter<NotificationEvent>();

  notiClicked(notiEvent: NotificationEvent) {
    this.selectedNotification.emit(notiEvent);
  }
}
