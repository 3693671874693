import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

@Injectable()
export class DateInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          this.convertDates(body);
          return event.clone({ body });
        }
        return event;
      })
    );
  }

  public convertDates(data: unknown): void {
    if (Array.isArray(data)) {
      data.forEach((item) => this.convertDates(item));
    } else if (typeof data === 'object' && data !== null) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'string' && this.isDateString(data[key])) {
            data[key] = new Date(data[key]);
          } else {
            this.convertDates(data[key]);
          }
        }
      }
    }
  }

  public isDateString(value: string): boolean {
    dayjs.extend(customParseFormat);

    return dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid(); //considering all dates will be in UTC

    // might need this expression in future
    // used this link  to get the expression https://regex101.com/r/ufFBTA/2
    // return /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|-)(?:\d{2}):?(?:\d{2}))/.test(
    //   value
    // );
  }
}
