<div class="h3-1" data-cy="cc-view-arr-time">
  {{ arrivalTime }}
</div>

<div *ngIf="arrival">
  <div>
    <strong
      [ngClass]="arrival.ArrivalDelayOrEarlyStatusCode"
      *ngIf="
        arrival.ArrivalDelayOrEarlyStatus &&
        arrival.ArrivalDelayOrEarlyStatusCode !== ''
      "
      data-cy="cc-view-arr-delay-or-early"
      >{{ arrival.ArrivalDelayOrEarlyStatus }}</strong
    >
  </div>
  <div>
    <strong
      [ngClass]="arrival.ArrivalStatusCode | arrivalStatusCode"
      data-cy="cc-view-arr-status"
      >{{ arrival.ArrivalStatus | arrivalStatus }}</strong
    >
  </div>
</div>
