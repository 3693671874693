export const DEFAULT = 'DEFAULT';
export const DEFAULT_STATION = 'DFW';
export const DEFAULT_RANGE = 4;
export const DEFAULT_FLEETS: string[] = [];
export const DEFAULT_ZONES: string[] = [];
export const DEFAULT_EMPLOYEE_ID = '00123456';
export const DEFAULT_FIRST_NAME = 'John';
export const DEFAULT_LAST_NAME = 'Smith';

//#region Store Feature Key Constants
export const USER_FEATURE_KEY = 'User';
export const ALTERNATE_TASKS_FEATURE_KEY = 'AlternateTasks';
export const HISTORY_FEATURE_KEY = 'History';
export const AIRCRAFT_FEATURE_KEY = 'Aircrafts';
export const CREW_FEATURE_KEY = 'Crew';
export const FLIGHT_FEATURE_KEY = 'Flights';
export const STATIONS_FEATURE_KEY = 'Stations';
export const NOTICES_FEATURE_KEY = 'Notices';
export const CREW_MEMBER_STATUS_FEATURE_KEY = 'CrewMemberStatus';
export const IMMUTABLE_CONTENT_FEATURE_KEY = 'ImmutableContent';
export const FEATURE_FLAG_FEATURE_KEY = 'FeatureFlag';
export const CREW_MANPOWER_FEATURE_KEY = 'CrewManpower';
//#endregion Store Feature Key Constants

//#region Old Crew Manager Constants
export const DELETE_CREW_HEADER = 'Delete Crew';
export const DELETE_CREW_MESSAGE = 'Are you sure you want to Delete this crew?';
export const SHARE_CREW_HEADER = 'Share Crew';
export const SHARE_CREW_MESSAGE = 'Are you sure you want to share this crew?';
export const UNSHARE_CREW_HEADER = 'Do not share';
export const UNSHARE_CREW_MESSAGE = `Are you sure you don't want to share this crew?`;
export const TAKE_OWNERSHIP_HEADER = 'Take Ownership';
export const TAKE_OWNERSHIP_MESSAGE =
  'Are you sure you want to take ownership of this crew?';
export const RELEASE_OWNERSHIP_HEADER = 'Release Ownership';
export const RELEASE_OWNERSHIP_MESSAGE = `Are you sure you want to release ownership of this crew?`;
//#endregion Old Crew Manager Constants

//#region Feature Flags Constants
export const MANPOWER_CREW_MANAGER = 'manpower-crew-manager';
export const NPS_ENABLED = 'nps-enabled';
export const ENABLE_SUPER_USER = 'enable-super-user'; // Used to disable redirection for specific users. eg: enable-pilot-user-0012345

//#endregion Feature Flags Constants

//#region Local Storage Key
export const EMPLOYEE_ID_FOR_MANPOWER = 'EmployeeIdForManpower';

//#endregion Local Storage Key

export const SOCKETIO_UPDATED = 'SOCKETIO UPDATED';
export const MANUAL_TASK = 'Manual Task';
export const MOC_REQUEST = 'MOC Request';
export const MANUAL_TASK_SMALL_CAP = 'manualtask';
export const MOC_REQUEST_SMALL_CAP = 'mocrequest';
export const CREW_CHIEF = 'crew-chief';
export const TOAST_CONTAINER = 'toast-container';
export const CC_VIEW_ONLY_CSS = 'cc-view-only';
export const ALERT_AUDIO_SRC = './assets/sounds/alert.mp3';
export const DELAY_ALERT_LOCAL_STORAGE_KEY = 'delay-alert';

//#region Array Constants
export const NON_DST_Stations = [
  'ITO',
  'HNL',
  'OGG',
  'KOA',
  'LIH',
  'FLG',
  'PHX',
  'TUS',
  'YUM',
  'PPG',
  'FTI',
  'OFU',
  'PBJ',
  'GUM',
  'UAM',
  'SPN',
  'ROP',
  'TIQ',
  'BQN',
  'RVR',
  'PSE',
  'SJU',
  'SIG',
  'VQS',
  'CPX',
  'MAZ',
  'ARE',
  'FAJ',
  'HUC',
  'STT',
  'STX',
  'SPB',
  'SSB',
];
export const preDefinedTaskLabels = [
  'hermes',
  'mg',
  'rsmg',
  'term',
  'oil chk',
  'wkup',
  'rswk',
  'ti ck',
  'moc request',
  'pilot emic',
  'dly-trn',
  'daily chk',
  'cuba',
  'open mic',
  'open eawr',
  'open rob',
  'open rem',
  'fr mon',
  'i/b gate',
  'o/b gate',
];
//#endregion
