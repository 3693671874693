import { Injectable } from '@angular/core';
import { BowJobContent, FetchBowJobsByInput } from '@lm-apps/lmo/ui/data';
import { map, Observable } from 'rxjs';
import { FetchBOWJobsGQL } from './query/bow-jobs.query';
import { MissingDataError } from '../custom-errors';

@Injectable({
  providedIn: 'root',
})
export class BillOfWorkService {
  constructor(private bowJobsGQL: FetchBOWJobsGQL) {}

  public listOfBowJobs(
    input: FetchBowJobsByInput
  ): Observable<BowJobContent[]> {
    return this.bowJobsGQL.fetch({ input }).pipe(
      map(({ data }) => {
        if (
          !data ||
          !data.FetchBOWJobs?.bowContents ||
          !data.FetchBOWJobs.bowContents?.length
        ) {
          throw new MissingDataError();
        }
        return data.FetchBOWJobs.bowContents;
      })
    );
  }
}
