import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetAllNoticesGQL extends Query<Pick<data.Query, 'Notices'>> {
  override document = gql`
    query Notices {
      Notices {
        Created
        Description
        DisplayEnd
        DisplayStart
        DowntimeEnd
        DowntimeStart
        Id
        Title
        Updated
      }
    }
  `;
}
