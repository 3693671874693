<div class="amt vertical-scroll-boundary navbar-default">
  <div class="cc-left-panel" [style.width.px]="leftPanelWidth">
    <div class="vpanel-container cc-of-x">
      <div class="vpanel">
        <ng-container *ngTemplateOutlet="lhsComponent"></ng-container>
        <hr class="cc-hr" />
        <!-- by default right panel will come below. so below div will take entire screen and right panel will be out of screen -->
        <div class="cc-table"></div>
      </div>
    </div>
  </div>
  <!-- rightPanelWidth - 1: to handle the certain zooming case where right panel is not coming up -->
  <div
    id="cc-right-panel"
    class="cc-right-panel"
    [style.width.px]="rightPanelWidth - 1"
  >
    <div class="vpanel-container">
      <div class="vpanel cc-right-panel-style" #rightSec>
        <ng-container *ngTemplateOutlet="rhsComponent"></ng-container>
      </div>
    </div>
  </div>
</div>
<div>
  <div
    [class.cc-resizer]="!isMobile && isItemSelected"
    [style.right.px]="resizerPos"
    #resizerHandle
  >
    <div class="cc-resizer-dragger aa-white">
      <fa-icon [icon]="faArrowsLeftRight"></fa-icon>
    </div>
  </div>
</div>
