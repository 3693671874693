import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './feature-flag.action';
import { CustomToastrService } from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
import { FeatureFlagsService } from '@aa-techops-ui/feature-flags';

@Injectable()
export class FeatureFlagEffects {
  constructor(
    private readonly actions$: Actions,
    private featureFlagsService: FeatureFlagsService,
    private toastService: CustomToastrService
  ) {}

  getFeatureFlags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => {
        return this.featureFlagsService.getFeatureFlags().pipe(
          map((flags) => {
            return actions.loadFeatureFlagSuccess({ flags });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load feature flags. Please try again later.'
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );
}
