import { Pipe, PipeTransform } from '@angular/core';
import { TeamAssignmentDisplay } from '@lm-apps/lmo/ui/data';
@Pipe({
  name: 'crewsAdjustment',
})
export class CrewsAdjustmentPipe implements PipeTransform {
  transform(
    allCrews: TeamAssignmentDisplay[],
    currentCrewId: string
  ): TeamAssignmentDisplay[] {
    this.currentCrewInTop(allCrews, currentCrewId);
    const mapped = allCrews.map((x) => ({
      ...x,
      DisplayName: displayName(x),
    }));
    return mapped;
  }

  currentCrewInTop(allCrews: TeamAssignmentDisplay[], currentCrewId: string) {
    const indexOfObjectToMove = allCrews.findIndex(
      (crew) => crew.Id === currentCrewId
    );
    if (indexOfObjectToMove !== -1) {
      const objectToMove = allCrews.splice(indexOfObjectToMove, 1)[0];
      allCrews.unshift(objectToMove);
    }
  }
}
export function displayName(teamAssignment: TeamAssignmentDisplay) {
  let crewNameDisplayFormat = teamAssignment.CurrentOwner
    ? `${teamAssignment.Name} [${teamAssignment.CurrentOwner.LastName}, ${
        teamAssignment.CurrentOwner.FirstName
      }] (${trimLeadingZeros(teamAssignment.CurrentOwner.EmployeeId)})`
    : `${teamAssignment.Name} [${teamAssignment.OriginalOwner.LastName}, ${
        teamAssignment.OriginalOwner.FirstName
      }] (${trimLeadingZeros(teamAssignment.OriginalOwner.EmployeeId)})`;

  crewNameDisplayFormat += teamAssignment.Shared
    ? ` (Shared by ${trimLeadingZeros(
        teamAssignment.OriginalOwner.EmployeeId
      )})`
    : '';
  return crewNameDisplayFormat;
}

export function trimLeadingZeros(value: string): string {
  return value ? value.trim().replace(/^0+/, '') : '';
}
