import { Pipe, PipeTransform } from '@angular/core';
import { MaintenancePriorities } from '@lm-apps/lmo/shared/enums';
import { Notification, Task } from '@lm-apps/lmo/wall/shared';

@Pipe({
  name: 'validNotificationsDataPipe',
  pure: true,
})
export class ValidNotificationsDataPipe implements PipeTransform {
  transform(notifications: Notification[], allTasks: Task[]): Notification[] {
    const validNotifications = notifications.filter(function (not) {
      if (not.hasTask === true) {
        let validNotification = false;
        allTasks.forEach(function (task) {
          if (task.maintenanceId === not.maintenanceId) {
            validNotification =
              task.taskState !== 'Completed' &&
              task.taskState !== 'Deferred' &&
              task.taskState !== 'Closed' &&
              task.taskState !== 'SystemClosed';
          }
        });

        return (
          validNotification &&
          !not.closed &&
          not.priorityName !== MaintenancePriorities.BOW
        );
      } else return !not.hasTask && !not.closed && not.priorityName !== MaintenancePriorities.BOW;
    });
    return validNotifications;
  }
}
