import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NOTICES_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { NoticesState } from './notices.interfaces';

export const getNoticeState =
  createFeatureSelector<NoticesState>(NOTICES_FEATURE_KEY);

export const selectAllNotices = createSelector(
  getNoticeState,
  (state) => state.allNotices
);
export const selectActiveNotices = createSelector(
  getNoticeState,
  (state) => state.activeNotices
);
