import { RETIRED_FLEET_TYPE } from '@lm-apps/lmo/shared/master';
import { AIRCRAFT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAircraft from './aircraft.reducer';
export interface State {
  aircrafts: fromAircraft.State;
}

export const selectAircraftState =
  createFeatureSelector<fromAircraft.State>(AIRCRAFT_FEATURE_KEY);

export const selectAircraftIds = createSelector(
  selectAircraftState,
  fromAircraft.selectAircraftIds // shorthand for aircraftState => fromAircraft.selectAircraftIds(aircraftState)
);

export const selectAircraftEntities = createSelector(
  selectAircraftState,
  fromAircraft.selectAircraftEntities
);

export const selectAllAircrafts = createSelector(
  selectAircraftState,
  fromAircraft.selectAllAircrafts
);

export const selectAircraftTotal = createSelector(
  selectAircraftState,
  fromAircraft.selectAircraftTotal
);

export const selectCurrentAircraftId = createSelector(
  selectAircraftState,
  fromAircraft.getSelectedAircraftId
);

export const selectCurrentAircraft = createSelector(
  selectAircraftEntities,
  selectCurrentAircraftId,
  (aircraftEntities, aircraftId) => aircraftId && aircraftEntities[aircraftId]
);

export const selectAircraftError = createSelector(
  selectAircraftState,
  (state) => state.error
);

export const selectAircraftLoaded = createSelector(
  selectAircraftState,
  (state) => state.loaded
);

export const selectFilteredNoseNumbers = (aircraftNumber: string) =>
  createSelector(selectAllAircrafts, (aircrafts) =>
    aircrafts
      .filter((aircraft) =>
        aircraft.NoseNumber.toLowerCase().startsWith(
          aircraftNumber?.toLowerCase()
        )
      )
      .map((aircraft) => aircraft.NoseNumber)
  );

export const selectAllFleets = createSelector(
  selectAllAircrafts,
  (aircrafts) => {
    const fleets: string[] = [];
    aircrafts.map(
      (a) =>
        a.FleetType.trim().toUpperCase().length >= 4 &&
        a.IsActive &&
        !RETIRED_FLEET_TYPE.includes(a.FleetType) &&
        fleets.push(a.FleetType)
    );
    return Array.from(new Set(fleets)).sort();
  }
);
