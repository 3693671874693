export enum LMXFlightStatus {
  SCHEDULED = 'S',
  FLIGHT_PLAN = 'P',
  ESTIMATED = 'E',
  CHANGEOVER_RECEIVED = 'C',
  HOLDING = 'H',
  ATC_DELAY = 'A',
  ON_GROUND = 'O',
  IN_GATE = 'I',
  INDEFINITE_DELAY = 'N',
  DECISION = 'D',
  OFF_GROUND = 'F',
  DELETED = 'X',
}
