import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as data from '@lm-apps/lmo/ui/data';
import { DeferralMelGql } from './query';
import { MissingDataError } from '../custom-errors';

@Injectable({
  providedIn: 'root',
})
export class MelService {
  constructor(private _deferralMel: DeferralMelGql) {}
  deferralMelItem() {
    return this._deferralMel.fetch({ melType: data.MelTypes.BlackMel }).pipe(
      map(({ data }) => {
        if (!data || !data.DeferralItemType || !data.DeferralItemType.length) {
          throw new MissingDataError();
        }
        return data.DeferralItemType;
      })
    );
  }
}
