import {
  PairsHistory,
  TaskHistory,
  TaskHistoryDisplay,
} from '@lm-apps/lmo/ui/data';
import {
  AlternateTaskHistoryDetails,
  AutomaticHoldHistory,
} from '@lm-apps/lmo/ui/data';

export interface HistoryState {
  alternateTaskHistory: AlternateTaskHistoryDetails[] | null;
  automaticHoldHistory: AutomaticHoldHistory[] | null;
  pairsHistory: PairsHistory[] | null;
  taskHistoryDisplay: TaskHistoryDisplay[];
  loaded: boolean;
  error?: string | null | unknown;
}
