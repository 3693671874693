/**
 * @enum { String } FlightEvents
 */
export enum FlightEvents {
  CYCLE = 'CYCLE',
  CREATE = 'CREATE',
  CONTINUATION = 'CONTINUATION',
  STUB_STA = 'STUBSTA',
  UNSTUB_STA = 'UNSTUBSTA',
  UNSTUB = 'UNSTUB',
  STUB = 'STUB',
  /**
   * Out Of Service
   */
  OTS = 'OTS',
  MAINTHOLD = 'MAINTHOLD',
  DIVERSION = 'DIVERSION',
  RETURN = 'RETURN',
  /**
   * Equipment Substitution
   */
  EQ_SUB = 'EQSUB',
  /**
   * Equipment Linking
   */
  EQ_LINK = 'EQLINK',
  /**
   * Rest Over Night
   */
  RON = 'RON',
  /**
   * Ground Interrupt
   */
  RTD = 'RTD',
  /**
   * Estimated Time of Departure
   */
  ETD = 'ETD',
  /**
   * Estimated Time of off
   */
  ETO = 'ETO',
  /**
   * Estimated Time of Arrival
   */
  ETA = 'ETA',
  /**
   * Estimated Time on
   */
  EON = 'EON',
  /**
   * Completed at gate
   */
  IN = 'IN',
  /**
   * Taken off
   */
  OFF = 'OFF',
  /**
   * Landed
   */
  ON = 'ON',
  /**
   * Departure at gate
   */
  OUT = 'OUT',
  /**
   * Gate assignment
   */
  GATE = 'GATE',
  /**
   * Decision
   */
  DCSN = 'DCSN',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  EROWUPDT = 'EROWUPDT',
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
  REINSTATE = 'REINSTATE',
  /**
   * OVER POINT - The actual times aircraft is over a point on the flight plan
   */
  OVR = 'OVR',
  /**
   * Change in Scheduled times
   */
  SKDCHNG = 'SKDCHNG',
  /**
   * To clear times
   */
  UNFLY = 'UNFLY',
}

export enum CreateEvents {
  CREATE = 'CREATE',
  CYCLE = 'CYCLE',
  CONTINUATION = 'CONTINUATION',
}

export enum SpecialEvents {
  STUB_STA = 'STUBSTA',
  UNSTUB_STA = 'UNSTUBSTA',
}

export enum GeneralEvents {
  /**
   * Ground Interrupt
   */
  RTD = 'RTD',
  /**
   * Estimated Time of Departure
   */
  ETD = 'ETD',
  /**
   * Estimated Time of off
   */
  ETO = 'ETO',
  /**
   * Estimated Time of Arrival
   */
  ETA = 'ETA',
  /**
   * Estimated Time on
   */
  EON = 'EON',
  /**
   * Completed at gate
   */
  IN = 'IN',
  /**
   * Taken off
   */
  OFF = 'OFF',
  /**
   * Landed
   */
  ON = 'ON',
  /**
   * Departure at gate
   */
  OUT = 'OUT',
  /**
   * Gate assignment
   */
  GATE = 'GATE',
  /**
   * Decision
   */
  EQ_SUB = 'EQSUB',
  EQ_LINK = 'EQLINK',
  DCSN = 'DCSN',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  EROWUPDT = 'EROWUPDT',
  REINSTATE = 'REINSTATE',
  OVR = 'OVR',
  SKDCHNG = 'SKDCHNG',
  UNFLY = 'UNFLY',
  DELETE = 'DELETE',

  CYCLE = 'CYCLE',
  CREATE = 'CREATE',
  CONTINUATION = 'CONTINUATION',
  STUB_STA = 'STUBSTA',
  UNSTUB_STA = 'UNSTUBSTA',
  UNSTUB = 'UNSTUB',
  STUB = 'STUB',
  /**
   * Out Of Service
   */
  OTS = 'OTS',
  MAINTHOLD = 'MAINTHOLD',
  DIVERSION = 'DIVERSION',
  RETURN = 'RETURN',
  CANCEL = 'CANCEL',
}

export enum OutOfServiceEvents {
  OTS = 'OTS',
}

export enum EventGroups {
  CREATE = 'CREATE',
  SPECIAL = 'SPECIAL',
  GENERAL = 'GENERAL',
  OTS = 'OTS',
  UNKNOWN = 'UNKNOWN',
}

export enum LegStatus {
  CANCEL = 'Z',
  AIR_INTERRUPT = 'A',
  GROUND_INTERRUPT = 'G',
  ON_GROUND = 'O',
  DELETED = 'X',
  SCHEDULED = 'S',
  LOCKED = 'L',
}

export enum EtopsFlight {
  NON_ETOPS_AC = 'Non-ETOPS AC',
  ETOPS_FLT = 'ETOPS FLT',
}
