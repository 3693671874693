import { Flight, FlightsEntity, TaskState } from '@lm-apps/lmo/ui/data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * Populate the flight BackgroundRowColor, HasDepartureToast and DepartureToastEligible
 * if BackgroundRowColor has value then it means flight has open task, in that case we need to show the flight pair even though it is already off.
 * if BackgroundRowColor is empty string and Departure.ActualOff has value then hide the flight from view
 *
 * @param fp the flight pair
 * @return the updated flight pair
 */
export function getBackgroundRowColor(fp: Flight | FlightsEntity): {
  backgroundRowColor: string;
  hasDepartureToast: boolean;
} {
  const now = dayjs().utc();
  let backgroundRowColor = '';
  let hasDepartureToast = false;

  if (
    //inbound flight should be IN
    fp.Arrival?.ActualIn &&
    fp.Departure?.DepartureStation &&
    fp.Departure?.EstimatedDeparture &&
    dayjs(fp.Departure.EstimatedDeparture).utc().diff(now, 'minutes') < 11 &&
    fp.AllTasks &&
    fp.AllTasks.some(
      (t) =>
        t.TaskState === TaskState.Unassigned ||
        t.TaskState === TaskState.Assigned
    )
  ) {
    backgroundRowColor = 'delay-flight-with-task';
    hasDepartureToast = true;
  }
  return { backgroundRowColor, hasDepartureToast };
}
