import { State } from '../+state/flight.reducer';
import { FlightsEntity, TaskState } from '@lm-apps/lmo/ui/data';

/**
 * Merges incoming flight data with the existing state while selectively updating tasks and notifications.
 * This ensures that tasks and notifications are only updated if they are newer than the existing state,
 * preventing the state from being overridden with outdated data.
 *
 * For example, if a task was updated via Socket.IO and that update is missing from the API response,
 * we should not override the state with outdated data from the API.
 *
 * @param state - The existing state to merge the incoming flights with.
 * @param {Array} flights - The list of incoming flights to be merged into the existing state.
 *
 * @returns {Array} updatedFlights - The updated list of flights with merged tasks and notifications.
 *
 * @example
 * // Example usage of this function:
 * const updatedFlights = mergeFlightsWithState(flights, existingEntities);
 */

export function mergeFlightUpdates(
  state: State,
  flights: FlightsEntity[]
): FlightsEntity[] {
  const ignoredTasks: { flightHash: string; taskId: string; reason: string }[] =
    []; // array to store any tasks there were ignored updating as the response is outdated

  const existingEntities = state.entities;

  // Process flights and selectively update tasks/notifications
  const updatedFlights = flights.map((newFlight) => {
    // Step 1:  Get hold of existing flight, so that we can merge its tasks
    const existingFlight = existingEntities[newFlight.Hash];
    if (!existingFlight) {
      return newFlight; // If it's a new flight, don't bother with selective merging, just return it so that it can be added to the state
    }

    // Track task IDs that were ignored for later use
    const ignoredTaskIds = new Set<string>();

    // Step 2: Compare existing tasks with the incoming tasks based on ChangedOn timestamp
    const updatedTasks = newFlight.AllTasks.map((newTask) => {
      const existingTask = existingFlight.AllTasks.find(
        (task) => task.Id === newTask.Id
      );

      // Step 2.1: If no existing task or new task is newer
      if (!existingTask) {
        return newTask;
      }

      // Step 2.2: If there is matching task, compare ChangedOn timestamp and take the newer one
      if (new Date(newTask.ChangedOn) >= new Date(existingTask.ChangedOn)) {
        // considering `=` as well to be on the safe side, ideally it should just be `>`
        return newTask;
      } else {
        // Log ignored tasks for investigation purposes (if needed)
        ignoredTasks.push({
          flightHash: newFlight.Hash,
          taskId: newTask.Id,
          reason: `Existing task ${existingTask.Label} (ChangedOn: ${existingTask.ChangedOn}) is newer than API response task (ChangedOn: ${newTask.ChangedOn}), Hash: ${newFlight.Hash}`,
        });
        ignoredTaskIds.add(newTask.Id); // Track ignored task IDs for later use
        return existingTask;
      }
    });

    // Now, we need to ensure that we keep existing tasks/notifications that are not in the API response.
    // JAG: Perhaps they are just created, but also possible that they are deleted(which can be an issue. Need to discuss)
    // Eg: at what point do we consider api response as source of truth for deletions?
    // may be if notification is closed from api, we can always consider that as source of truth

    // Step 3: Combine updated tasks and keep existing ones that aren't in the new API response
    const tasksNotInApi = existingFlight.AllTasks.filter(
      (existingTask) =>
        existingTask.TaskState !== TaskState.Closed &&
        existingTask.TaskState !== TaskState.SystemClosed && // Only keep if not closed
        !newFlight.AllTasks.some((newTask) => newTask.Id === existingTask.Id)
    );
    // add tasksNotInApi Ids to ignoredTaskIds so that we don't override notifications related to these tasks
    tasksNotInApi.forEach((task) => {
      ignoredTasks.push({
        flightHash: newFlight.Hash,
        taskId: task.Id,
        reason: `Existing task ${task.Label} is not in API response, Hash: ${newFlight.Hash}`,
      });
      ignoredTaskIds.add(task.Id);
    });

    const updatedAllTasks = [
      ...tasksNotInApi, // Keep existing tasks that are not in the API response
      ...updatedTasks, // Add the updated tasks from the API response
    ];

    // Step 4: Update Notifications, basically, ignore those that are related to the tasks that were ignored above
    // ensure we don't remove notifications that are not in the API response, as they could be very newly created ones
    const updatedNotifications = newFlight.Notifications.map(
      (newNotification) => {
        const isLinkedToIgnoredTask = existingFlight.AllTasks.some(
          (task) =>
            ignoredTaskIds.has(task.Id) &&
            task.NotificationId === newNotification.Id
        );

        // If notification is linked to an ignored task, return the existing notification
        const existingNotification = existingFlight.Notifications.find(
          (existingNotification) =>
            existingNotification.Id === newNotification.Id
        );
        return isLinkedToIgnoredTask
          ? existingNotification || newNotification
          : newNotification;
      }
    );

    // Step 5: Combine updated notifications and keep existing ones that aren't in the new API response
    // as long as existing notification.IsClosed is false, we can consider it as not deleted
    const notificationsNotInApi = existingFlight.Notifications.filter(
      (existingNotification) =>
        !existingNotification.IsClosed && // Only keep if not closed
        !newFlight.Notifications.some(
          (newNotification) => newNotification.Id === existingNotification.Id
        )
    );
    const updatedAllNotifications = [
      ...notificationsNotInApi, // Keep existing notifications that are not in the API response
      ...updatedNotifications, // Add the updated notifications from the API response
    ];

    // Step 6: Return the updated flight
    return {
      ...newFlight,
      AllTasks: updatedAllTasks,
      Notifications: updatedAllNotifications,
    };
  });

  if (ignoredTasks.length > 0) {
    console.debug('Ignored Tasks (Already up-to-date):', ignoredTasks);
  }

  return updatedFlights;
}
