import { NgModule, inject } from '@angular/core';
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { PingAuthGuard } from '@aa-techops-ui/ping-authentication';
import { ToastrAuthGuard } from './guards/toastr-guard';
import { CREW_CHIEF } from '@lm-apps/lmo/ui/data';

const routes: Routes = [
  {
    path: '',
    canActivate: [PingAuthGuard],
    canActivateChild: [
      (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
        inject(ToastrAuthGuard).canActivateChild(state),
    ],

    children: [
      ...['', CREW_CHIEF].map((path) => ({
        path,
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      })),
      {
        path: 'alternate-tasks',
        loadChildren: () =>
          import('@lm-apps/lmo/ui/feature-alternate-tasks').then(
            (m) => m.LmoUiFeatureAlternateTasksModule
          ),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('@lm-apps/lmo/ui/feature-admin').then(
            (m) => m.LmoUiFeatureAdminModule
          ),
      },
      {
        path: 'my-tasks',
        loadChildren: () =>
          import('@lm-apps/lmo/ui/feature-my-tasks').then(
            (m) => m.LmoUiFeatureMyTasksModule
          ),
      },
      {
        path: 'history',
        loadChildren: () =>
          import('@lm-apps/lmo/ui/feature-history').then(
            (m) => m.LmoUiFeatureHistoryModule
          ),
      },
      //backward compatibility
      {
        path: 'mt',
        redirectTo: 'my-tasks',
      },
      {
        path: 'nonacttask',
        redirectTo: 'alternate-tasks',
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
