import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class PairsHistoryGQL extends Query<
  Pick<data.Query, 'PairsHistory'>,
  data.QueryPairsHistoryArgs
> {
  override document = gql`
    query PairsHistory($filterInput: PairsHistoryFilterInput!) {
      PairsHistory(filterInput: $filterInput) {
        Station
        Equipment {
          NoseNumber
        }
        Arrival {
          FlightNumber
          ActualOn
          ActualIn
          ActualArrival
          ArrivalGate
          EstimatedArrival
          ScheduledArrival
          DepartureStation
          LegOriginDate
          ArrivalStation
          ArrivalDelayOrEarlyStatus
          ArrivalStatus
          ArrivalUtcAdjustment
        }
        Departure {
          FlightNumber
          ActualOut
          ActualOff
          ActualDeparture
          DepartureGate
          EstimatedDeparture
          ScheduledDeparture
          DepartureStation
          LegOriginDate
          ArrivalStation
          DepartureDelayOrEarlyStatus
          DepartureStatus
          DepartureUtcAdjustment
        }
        Notifications {
          Label
          NoseNumber
          SourceKey
          IsClosed
          Created
          Discrepancy
          Task {
            Id
            CallType {
              DisplayCallType
            }
            TaskState
          }
        }
      }
    }
  `;
}
