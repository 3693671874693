import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class DeleteNoticeGQL extends Mutation<
  Pick<data.Mutation, 'DeleteNotices'>,
  data.MutationDeleteNoticesArgs
> {
  override document = gql`
    mutation DeleteNotices($ids: [UUID!]!) {
      DeleteNotices(ids: $ids)
    }
  `;
}
