import * as actions from './history.actions';
import { createReducer, on, Action } from '@ngrx/store';
import { HistoryState } from './history.interfaces';

export const initialState: HistoryState = {
  alternateTaskHistory: null,
  automaticHoldHistory: null,
  pairsHistory: null,
  taskHistoryDisplay: [],
  loaded: false,
  error: null,
};

const historyReducer = createReducer(
  initialState,
  on(actions.initAlternateTaskHistory, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(
    actions.loadAlternateTaskHistorySuccess,
    (state, { alternateTaskHistoryDetails }) => ({
      ...state,
      alternateTaskHistory: alternateTaskHistoryDetails,
      automaticHoldHistory: null,
      pairsHistory: null,
      taskHistory: [],
      loaded: true,
      error: null,
    })
  ),
  on(actions.initMTHoldHistory, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(actions.loadMTHoldHistorySuccess, (state, { automaticHoldHistory }) => ({
    ...state,
    alternateTaskHistory: null,
    automaticHoldHistory: automaticHoldHistory,
    pairsHistory: null,
    taskHistory: null,
    loaded: true,
    error: null,
  })),
  on(actions.initPairsHistory, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(actions.loadPairsHistorySuccess, (state, { pairsHistory }) => ({
    ...state,
    alternateTaskHistory: null,
    automaticHoldHistory: null,
    pairsHistory: pairsHistory,
    loaded: true,
    error: null,
  })),
  on(actions.loadTaskHistorySuccess, (state, { taskHistoryDisplay }) => ({
    ...state,
    alternateTaskHistory: null,
    automaticHoldHistory: null,
    taskHistoryDisplay: taskHistoryDisplay,
    loaded: true,
    error: null,
  })),

  on(actions.clearTaskHistory, (state) => ({
    ...state,
    alternateTaskHistory: null,
    automaticHoldHistory: null,
    taskHistoryDisplay: [],
    loaded: true,
    error: null,
  })),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    error: error,
    loaded: false,
  })),

  on(actions.resetHistoryPageState, () => ({
    alternateTaskHistory: null,
    automaticHoldHistory: null,
    pairsHistory: null,
    taskHistoryDisplay: [],
    loaded: false,
    error: null,
  }))
);

export function reducer(state: HistoryState | undefined, action: Action) {
  return historyReducer(state, action);
}
