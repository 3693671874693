<div class="assigned">
  <p-table [value]="selectedCrewMembers" tableStyleClass="table table-striped">
    <ng-template pTemplate="body" let-selectedCrewMember>
      <tr>
        <td>
          <span
            >{{ selectedCrewMember.LastName }},&nbsp;{{
              selectedCrewMember.FirstName
            }}&nbsp;({{ selectedCrewMember.EmployeeId }})</span
          >
        </td>
        <td class="text-right">
          <span
            (click)="emitAddOrRemoveSelectedMechanic(selectedCrewMember)"
            class="select-link"
            >Remove</span
          >
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
