import { STATIONS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './stations.actions';
import { StationsState } from './stations.interfaces';

export interface StationsPartialState {
  readonly [STATIONS_FEATURE_KEY]: StationsState;
}

export const initialState: StationsState = {
  allRegions: null,
  zones: [],
  loaded: false,
  error: null,
};

export const stationsReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllRegionsSuccess, (state, { regions }) => ({
    ...state,
    allRegions: regions,
    loaded: true,
    error: null,
  })),
  on(actions.loadZonesSuccess, (state, { zones }) => ({
    ...state,
    zones: zones,
    loaded: true,
    error: null,
  })),
  on(actions.resetZones, (state) => ({
    ...state,
    zones: [],
    loaded: true,
    error: null,
  })),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error: error,
  }))
);

export function reducer(state: StationsState | undefined, action: Action) {
  return stationsReducer(state, action);
}
