<span class="text-left col-xs-6">
  <span
    class="ots-stop-sign aa-mt-4 aa-mr-2"
    *ngIf="equipment?.outOfServiceAircraft?.isOutOfService"
  ></span>
  <span
    class="maintenance-hold-sign aa-mt-4 aa-mr-2"
    *ngIf="
      equipment?.isMaintenanceHold &&
      !equipment?.outOfServiceAircraft?.isOutOfService
    "
  ></span>
  <lmo-maintenance-item
    *ngFor="
      let notification of notifications
        | validNotificationsDataPipe: allTasks
        | blackNotificationsDataPipe
    "
    [notification]="notification"
  >
  </lmo-maintenance-item>

  <!--wc# tile is part of tasks, so adding a new component to handle tasks of DisplayJobType of BOW -->
  <lm-apps-maintenance-task
    *ngFor="let task of allTasks | taskFilterByJobType: bowJobTypeDisplay"
    [task]="task"
  >
  </lm-apps-maintenance-task>

  <span
    class="no-maint"
    *ngIf="
      (
        notifications
        | validNotificationsDataPipe: allTasks
        | blackNotificationsDataPipe
      ).length === 0
    "
    >No Maintenance</span
  >
</span>
