import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CrewManagerManpowerHomeComponent } from './component/home/home.component';
import { CrewManagerManpowerComponent } from './component/crew-manager/crew-manager.component';
import { ButtonModule } from 'primeng/button';
import { LmoUiCommonFeatureSharedModule } from '@lm-apps/lmo/ui/common/feature-shared';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    FontAwesomeModule,
    LmoUiCommonFeatureSharedModule,
    CheckboxModule,
    InputTextModule,
    SkeletonModule,
    LmoUiCommonPipesModule,
  ],
  declarations: [
    CrewManagerManpowerHomeComponent,
    CrewManagerManpowerComponent,
  ],
})
export class LmoUiFeatureCrewManagerManpowerModule {}
