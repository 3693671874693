import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Notice, NoticeInput } from '@lm-apps/lmo/ui/data';

export interface NoticeModel {
  id: FormControl<string | null>;
  description: FormControl<string>;
  downtimeStart: FormControl<Date | null>;
  downtimeEnd: FormControl<Date | null>;
  displayStart: FormControl<Date | null>;
  displayEnd: FormControl<Date | null>;
  title: FormControl<string>;
}
@Component({
  selector: 'lm-apps-edit-notice-modal',
  templateUrl: './edit-notice-modal.component.html',
})
export class EditNoticeModalComponent implements OnInit {
  notice: Notice | null = null;
  form!: FormGroup<NoticeModel>;
  minDate = new Date();
  isNewTask = true;

  @Output() saveNoticeEvent: EventEmitter<NoticeInput> = new EventEmitter();
  @Output() closeModalEvent: EventEmitter<void> = new EventEmitter();

  errorMessages = {
    futureTime: 'Please enter a time in the future.',
    invalidOrder: 'Start time must be earlier than end time.',
    missingControl: 'Please enter both a start time and an end time.',
  };

  constructor(public modalRef: BsModalRef, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.notice) this.isNewTask = false;
    this.form = this.formBuilder.group(
      {
        id: new FormControl(this.notice?.Id ?? null),
        title: new FormControl(this.notice?.Title ?? '', {
          validators: [
            Validators.required,
            Validators.maxLength(30),
            Validators.pattern(/^\S.*$/), // validates that the input is not empty and contains some non-whitespace content
          ],
          updateOn: 'change',
          nonNullable: true,
        }),
        description: new FormControl(this.notice?.Description ?? '', {
          validators: [
            Validators.required,
            Validators.maxLength(500),
            Validators.pattern(/^\S.*$/),
          ],
          updateOn: 'change',
          nonNullable: true,
        }),
        downtimeEnd: new FormControl(
          this.notice?.DowntimeEnd ? new Date(this.notice.DowntimeEnd) : null,
          {
            updateOn: 'change',
          }
        ),
        downtimeStart: new FormControl(
          this.notice?.DowntimeStart
            ? new Date(this.notice.DowntimeStart)
            : null,
          {
            updateOn: 'change',
          }
        ),
        displayEnd: new FormControl(
          this.notice?.DisplayEnd ? new Date(this.notice.DisplayEnd) : null,
          {
            updateOn: 'change',
          }
        ),
        displayStart: new FormControl(
          this.notice?.DisplayStart ? new Date(this.notice.DisplayStart) : null,
          {
            updateOn: 'change',
          }
        ),
      },
      {
        validators: [
          this.orderValidator('downtimeStart', 'downtimeEnd', 'downtime'),
          this.orderValidator('displayStart', 'displayEnd', 'display'),
        ],
        updateOn: 'submit',
      }
    );

    this.applyConditionalValidators();
  }

  saveChanges() {
    this.applyConditionalValidators();

    if (this.form.pristine) {
      this.closeModal();
      return;
    }

    if (this.isNewTask) {
      this.form.markAllAsTouched();
    } else {
      this.form.controls.title.markAllAsTouched();
      this.form.controls.description.markAllAsTouched();
    }

    if (this.form.valid) {
      const noticeInput: NoticeInput = {
        Id: this.form.controls.id.value,
        Title: this.form.controls.title.value,
        Description: this.form.controls.description.value,
        DisplayEnd: this.form.controls.displayEnd.value,
        DisplayStart: this.form.controls.displayStart.value,
        DowntimeEnd: this.form.controls.downtimeEnd.value,
        DowntimeStart: this.form.controls.downtimeStart.value,
      };
      this.saveNoticeEvent.emit(noticeInput);
      this.modalRef.hide();
    }
  }

  closeModal() {
    this.closeModalEvent.emit();
    this.modalRef.hide();
  }

  onClear(control: AbstractControl | null): void {
    if (control) {
      control.setValue(null);
    }
  }

  // !! When input is text only (not cal select, on submit validation is not being applied)

  applyConditionalValidators() {
    const timeFields = ['downtimeEnd', 'displayEnd'];

    timeFields.forEach((field) => {
      const control = this.form.get(field);

      if (this.isNewTask || control?.dirty) {
        control?.setValidators([this.futureTimeValidator()]);
      } else {
        control?.clearValidators();
      }
      control?.updateValueAndValidity({ onlySelf: true });
    });
  }

  futureTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const inputTime = control.value ? new Date(control.value) : null;
      if (inputTime && inputTime <= new Date()) {
        return { futureTime: true };
      }
      return null;
    };
  }
  orderValidator(
    startControlName: string,
    endControlName: string,
    type: 'display' | 'downtime'
  ): ValidatorFn {
    return (
      group: AbstractControl
    ): { [key: string]: { [key: string]: boolean } } | null => {
      const startControl = group.get(startControlName);
      const endControl = group.get(endControlName);

      if (startControl && endControl) {
        // Check if startControl is empty while endControl is not or vice versa
        if (
          (!startControl.value && endControl.value) ||
          (startControl.value && !endControl.value)
        ) {
          return { missingControl: { [type]: true } };
        }

        if (!startControl.value && !endControl.value) {
          startControl.clearValidators();
          endControl.clearValidators();
        }

        // Check if both controls have values and compare them
        if (startControl.value && endControl.value) {
          const startTime = new Date(startControl.value);
          const endTime = new Date(endControl.value);
          if (startTime >= endTime) {
            return { invalidOrder: { [type]: true } };
          }
        }
      }
      return null;
    };
  }
}
