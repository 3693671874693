import { ItemType } from './enum';

export const TEAM_OWENERSHIP_NOT_AVAILABLE_MSG =
  'Team is not available for to take ownership';

export const DUMMY_AIRCRAFT = '___';

export const APP_VERSION = 'APP_VERSION';

export const TAIL_SYNC_INDICATOR = 'Y';

export const REMOVED = 'REMOVED';
export const WCLabel = 'WC#';

//#region Feature Flags Constants used by new lmo-wall as well
export const ENABLE_STATION_ROLLOUT = 'enable-station-rollout'; // Used to enable/disable the station rollout feature
export const ENABLE_PILOT_STATION = 'enable-pilot-station'; // Used to add pilot stations, eg: enable-pilot-station-phx
//#endregion Feature Flags Constants
