import { IMMUTABLE_CONTENT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './actions';
import { ImmutableState } from './immutable.interface';
export interface ImmutablePartialState {
  readonly [IMMUTABLE_CONTENT_FEATURE_KEY]: ImmutableState;
}
export const initialState: ImmutableState = {
  deferralMels: [],
  error: null,
};
export const crewReducer = createReducer(
  initialState,
  on(actions.throwError, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(actions.loadDeferralMelSuccess, (state, { deferralMels }) => ({
    ...state,
    deferralMels,
  }))
);

export function reducer(state: ImmutableState | undefined, action: Action) {
  return crewReducer(state, action);
}
