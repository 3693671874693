import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetActiveNoticesGQL extends Query<
  Pick<data.Query, 'ActiveNotices'>
> {
  override document = gql`
    query ActiveNotices {
      ActiveNotices {
        Created
        Description
        DisplayEnd
        DisplayStart
        DowntimeEnd
        DowntimeStart
        Id
        Title
        Updated
      }
    }
  `;
}
