import { JobTypeDisplay } from './job-type-display';

export enum KeyJobType {
  AC_MOVE = 'ACMove',
  DAILY_CHECK = 'DailyCheck',
  DISCREPENCY = 'Discrepancy',
  ETOPS_CHECK = 'ETOPSCHK',
  ETOPS_OIL_CHECK = 'ETOPSOILCHK',
  HERMES = 'HERMES',
  ARMS = 'ARMS',
  MEET_GREET = 'MG',
  MOC_REQUEST = 'MOCRequest',
  RIGHT_START_MEET_GREET = 'RSMG',
  WAKE_UP = 'WKUP',
  RIGHT_START_WAKE_UP = 'RSWK',
  MEL_GENERAL = 'MEL',
  MEL_FOLLOW_UP = 'MELFLW',
  MEL_QUICK_FIX = 'QFIX',
  NEF_QUICK_FIX = 'QNEF',
  OTHER = 'OTHER',
  SERVICING = 'SERVICING',
  CHECK = 'CHECK',
  TRANSIT_CHECK = 'DLY-TRN',
  RESERVED_ITEM = 'Reserved',
  NEF_FOLLOW_UP = 'NEFFLW',
  CDL_FOLLOW_UP = 'CDLFLW',
  TAC_FOLLOW_UP = 'TACFLW',
  MON_FOLLOW_UP = 'MONFLW',
  OPEN = 'OPEN',
  CUBA = 'CUBA',
  BOW = 'BOW',
}

export interface JobTypeEnumModel {
  Id: number;
  DisplayJobType: JobTypeDisplay;
  KeyJobType: KeyJobType;
  IsActive: boolean;
}
export const JobTypesList: JobTypeEnumModel[] = [
  {
    Id: 1000,
    DisplayJobType: JobTypeDisplay.AcMoves,
    IsActive: true,
    KeyJobType: KeyJobType.AC_MOVE,
  },
  {
    Id: 1002,
    DisplayJobType: JobTypeDisplay.Discrepancy,
    IsActive: true,
    KeyJobType: KeyJobType.DISCREPENCY,
  },
  {
    Id: 1003,
    DisplayJobType: JobTypeDisplay.EtopsCheck,
    IsActive: true,
    KeyJobType: KeyJobType.ETOPS_CHECK,
  },
  {
    Id: 1004,
    DisplayJobType: JobTypeDisplay.EtopsOilCheck,
    IsActive: true,
    KeyJobType: KeyJobType.ETOPS_OIL_CHECK,
  },
  {
    Id: 1005,
    DisplayJobType: JobTypeDisplay.Hermes,
    IsActive: true,
    KeyJobType: KeyJobType.HERMES,
  },
  {
    Id: 1006,
    DisplayJobType: JobTypeDisplay.Arms,
    IsActive: true,
    KeyJobType: KeyJobType.ARMS,
  },
  {
    Id: 1007,
    DisplayJobType: JobTypeDisplay.Mg,
    IsActive: true,
    KeyJobType: KeyJobType.MEET_GREET,
  },
  {
    Id: 1008,
    DisplayJobType: JobTypeDisplay.Rsmg,
    IsActive: true,
    KeyJobType: KeyJobType.RIGHT_START_MEET_GREET,
  },
  {
    Id: 1009,
    DisplayJobType: JobTypeDisplay.WakeUp,
    IsActive: true,
    KeyJobType: KeyJobType.WAKE_UP,
  },
  {
    Id: 1010,
    DisplayJobType: JobTypeDisplay.Rswu,
    IsActive: true,

    KeyJobType: KeyJobType.RIGHT_START_WAKE_UP,
  },
  {
    Id: 1011,
    DisplayJobType: JobTypeDisplay.MelGeneral,
    IsActive: true,

    KeyJobType: KeyJobType.MEL_GENERAL,
  },
  {
    Id: 1012,
    DisplayJobType: JobTypeDisplay.MelFollowUp,
    IsActive: true,

    KeyJobType: KeyJobType.MEL_FOLLOW_UP,
  },
  {
    Id: 1013,
    DisplayJobType: JobTypeDisplay.MelQuickFix,
    IsActive: true,

    KeyJobType: KeyJobType.MEL_QUICK_FIX,
  },
  {
    Id: 1014,
    DisplayJobType: JobTypeDisplay.NefQuickFix,
    IsActive: true,

    KeyJobType: KeyJobType.NEF_QUICK_FIX,
  },
  {
    Id: 1015,
    DisplayJobType: JobTypeDisplay.Other,
    IsActive: true,

    KeyJobType: KeyJobType.OTHER,
  },
  {
    Id: 1016,
    DisplayJobType: JobTypeDisplay.Servicing,
    IsActive: true,

    KeyJobType: KeyJobType.SERVICING,
  },
  {
    Id: 1017,
    DisplayJobType: JobTypeDisplay.Check,
    IsActive: true,

    KeyJobType: KeyJobType.CHECK,
  },
  {
    Id: 1018,
    DisplayJobType: JobTypeDisplay.TransitCheck,
    IsActive: true,

    KeyJobType: KeyJobType.TRANSIT_CHECK,
  },
  {
    Id: 1020,
    DisplayJobType: JobTypeDisplay.MocRequest,
    IsActive: true,

    KeyJobType: KeyJobType.MOC_REQUEST,
  },
  {
    Id: 1021,
    DisplayJobType: JobTypeDisplay.CubaFlight,
    KeyJobType: KeyJobType.CUBA,
    IsActive: true,
  },
  {
    Id: 1022,
    DisplayJobType: JobTypeDisplay.NEFFollowUp,
    KeyJobType: KeyJobType.NEF_FOLLOW_UP,
    IsActive: true,
  },
  {
    Id: 1023,
    DisplayJobType: JobTypeDisplay.CDLFollowUp,
    KeyJobType: KeyJobType.CDL_FOLLOW_UP,
    IsActive: true,
  },
  {
    Id: 1024,
    DisplayJobType: JobTypeDisplay.TACFollowUp,
    KeyJobType: KeyJobType.TAC_FOLLOW_UP,
    IsActive: true,
  },
  {
    Id: 1025,
    DisplayJobType: JobTypeDisplay.MONFollowUp,
    KeyJobType: KeyJobType.MON_FOLLOW_UP,
    IsActive: true,
  },
  {
    Id: 1026,
    DisplayJobType: JobTypeDisplay.OPEN,
    KeyJobType: KeyJobType.OPEN,
    IsActive: true,
  },
  {
    Id: 1027,
    DisplayJobType: JobTypeDisplay.BOW,
    KeyJobType: KeyJobType.BOW,
    IsActive: true,
  },
];
