import { RegionStations, Zone } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Stations] Init');

export const throwError = createAction(
  '[Regions/API] API request failed',
  props<{ error: any }>()
);

export const loadAllRegionsSuccess = createAction(
  '[Regions/API] Load regions success',
  props<{ regions: RegionStations[] }>()
);

export const loadZones = createAction(
  '[Zones] Load zones',
  props<{ station: string }>()
);
export const loadZonesSuccess = createAction(
  '[Zones/API] Load zones success',
  props<{ zones: Zone[] }>()
);
export const resetZones = createAction('[Zones] Reset zones');
