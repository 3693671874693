import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class StationZoneGatesGQL extends Query<
  Pick<data.Query, 'StationsZonesGates'>,
  data.QueryStationsArgs
> {
  override document = gql`
    query StationsZonesGates($stationsSearchInput: StationsSearchInput) {
      StationsZonesGates(stationsSearchInput: $stationsSearchInput) {
        City
        CountryCodeNumber
        CountryState
        DSTimeZoneOffset
        Displayed
        HubStationDisplayOrder
        IataCode
        IsExtraBaseStation
        IsHubStation
        IsROD
        IsRON
        IsStaffed
        Region
        TimeZoneOffset
        Zones {
          ZoneName
        }
      }
    }
  `;
}
