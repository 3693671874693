import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetMyTeamGQL } from './query';
import { MissingDataError } from '../custom-errors';
import { EMPLOYEE_ID_FOR_MANPOWER } from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class ManpowerCrewService {
  constructor(private _getMyTeamGQL: GetMyTeamGQL) {}
  getMyTeam(employeeId: string | null = null, date: string | null = null) {
    employeeId ??= this.getEmployeeId();
    return this._getMyTeamGQL.fetch({ employeeId, date }).pipe(
      map(({ data }) => {
        if (!data || !data.GetMyTeams) new MissingDataError();
        return data.GetMyTeams;
      })
    );
  }
  getEmployeeId(): string | null {
    return localStorage.getItem(EMPLOYEE_ID_FOR_MANPOWER);
  }
}
