import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ZonesGatesGQL } from './query';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  constructor(private _zonesGatesGql: ZonesGatesGQL) {}
  getZonesGates(stations: string[]) {
    return this._zonesGatesGql.fetch({ stations }).pipe(
      map(({ data }) => {
        return data.ZonesGates;
      })
    );
  }
}
