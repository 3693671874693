import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CreateTeamAssignmentInput,
  CrewMember,
  DELETE_CREW_HEADER,
  DELETE_CREW_MESSAGE,
  OnlineStatus,
  RELEASE_OWNERSHIP_HEADER,
  RELEASE_OWNERSHIP_MESSAGE,
  SHARE_CREW_HEADER,
  SHARE_CREW_MESSAGE,
  TAKE_OWNERSHIP_HEADER,
  TAKE_OWNERSHIP_MESSAGE,
  TeamAssignedCrewMember,
  TeamAssignmentDisplay,
  UNSHARE_CREW_HEADER,
  UNSHARE_CREW_MESSAGE,
  UpdateTeamAssignmentInput,
  User,
} from '@lm-apps/lmo/ui/data';
import {
  faGripDotsVertical,
  faUserPlus,
  faArrowTurnDown,
  faArrowTurnUp,
} from '@fortawesome/pro-solid-svg-icons';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { animate, style, transition, trigger } from '@angular/animations';
import { displayName } from '@lm-apps/lmo/ui/common/pipes';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Table } from 'primeng/table';

dayjs.extend(utc);

export interface CrewTableRowModel {
  idCtrl: FormControl<string>;
  firstNameCtrl: FormControl<string>;
  lastNameCtrl: FormControl<string>;
  statusCtrl: FormControl<string>;
}

const enterTransition = transition(':enter', [
  style({
    opacity: 0.5,
    width: 0,
  }),
  animate('0.7s ease-in', style({ opacity: 1, width: '40rem' })),
]);
const exitTransition = transition(':leave', [
  style({
    opacity: 1,
    width: '40rem',
  }),
  animate('1s ease-out', style({ opacity: 0.5, width: 0 })),
]);
const fadeIn = trigger('fadeIn', [enterTransition]);
const fadeOut = trigger('fadeOut', [exitTransition]);

@Component({
  selector: 'lm-apps-crew-manager',
  templateUrl: './crew-manager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn, fadeOut],
  providers: [ConfirmationService],
})
export class CrewManagerComponent implements OnChanges, OnInit {
  @Input()
  allCrews: TeamAssignmentDisplay[] = [];
  @Input() stations: string[] = [];
  @Input() currentUser!: User;
  @Input() currentCrew: TeamAssignmentDisplay | null = null;
  @Input() allCrewMembers: CrewMember[] = [];
  @Input() selectedStation!: string;
  @Input() allCrewMemberOnlineStatus: OnlineStatus[] = [];
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() updateStationEvent: EventEmitter<string> = new EventEmitter();
  @Output() createCrewEvent: EventEmitter<CreateTeamAssignmentInput> =
    new EventEmitter();
  @Output() updateCrewEvent: EventEmitter<UpdateTeamAssignmentInput> =
    new EventEmitter();
  @Output() saveCrewEvent: EventEmitter<TeamAssignmentDisplay> =
    new EventEmitter();
  @Output() shareCrewEvent: EventEmitter<TeamAssignmentDisplay> =
    new EventEmitter();
  @Output() deleteCrewEvent: EventEmitter<TeamAssignmentDisplay> =
    new EventEmitter();
  @Output() ownershipEvent: EventEmitter<TeamAssignmentDisplay> =
    new EventEmitter();

  @ViewChild('crewMembersTable') crewMembersTable!: Table;
  @ViewChild('createCrew', { static: false })
  set createCrewFocus(element: ElementRef<HTMLInputElement>) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  faGripDotsVertical = faGripDotsVertical;
  faUserPlus = faUserPlus;
  faArrowTurnDown = faArrowTurnDown;
  faArrowTurnUp = faArrowTurnUp;

  isCreateCrew = false;
  isRemoveAll = false;
  enableSaveChanges = false;
  selectedCrew: TeamAssignmentDisplay | null = null;
  statusList = ['Off Shift', 'On Shift', 'Lunch', 'Training', 'Spec Projects'];
  draggedCrewMem: CrewMember | null = null;
  visibleRightPanel = false;
  filteredCrewMem: CrewMember[] = [];
  initialFormArray: FormGroup<CrewTableRowModel>[] = [];
  crewManagerForm = this.formBuilder.group({
    selectedCrewCtrl: new FormControl<TeamAssignmentDisplay | null>(null),
    tableRows: this.formBuilder.array(this.initialFormArray),
    createCrewCtrl: new FormControl<string>(
      { value: '', disabled: true },
      { nonNullable: true, validators: [Validators.required] }
    ),
  });

  get getTableRowsFormControls(): FormArray<FormGroup<CrewTableRowModel>> {
    return this.crewManagerForm.controls.tableRows;
  }
  get getSelectedCrewControls() {
    return this.crewManagerForm.controls.selectedCrewCtrl;
  }
  get getCreateCrewControls() {
    return this.crewManagerForm.controls.createCrewCtrl;
  }

  constructor(
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService
  ) {}
  ngOnInit() {
    this.enableSaveChanges =
      this.allCrews.length > 0 && this.isSaveChanges(this.allCrews[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const allCrews = changes['allCrews'];
    const allCrewMembers = changes['allCrewMembers'];
    if (
      allCrews &&
      allCrews.currentValue.length > 0 &&
      allCrews.currentValue[0].Id !== this.getSelectedCrewControls.value?.Id
    ) {
      // When setValue/patchValue is set then it will trigger onChange and ngModelChange by default
      this.getSelectedCrewControls.setValue(allCrews.currentValue[0]);
      // Make sure Crew Member Table is updated with latest value
      this.initTableFormArray(allCrews.currentValue[0]);
    }
    if (allCrewMembers && allCrewMembers.currentValue) {
      this.filteredCrewMem = this.filterAllCrewMem(this.cmRowToAssignedMem());
    }
  }

  initTableFormArray(crew: TeamAssignmentDisplay | null) {
    this.getTableRowsFormControls.clear();
    crew?.AssignedMembers.forEach((x) => x && this.addRow(x));
  }

  initRowForm(
    crewMember: TeamAssignedCrewMember
  ): FormGroup<CrewTableRowModel> {
    const row: CrewTableRowModel = {
      idCtrl: new FormControl(crewMember.EmployeeId, { nonNullable: true }),
      firstNameCtrl: new FormControl(crewMember.FirstName, {
        nonNullable: true,
      }),
      lastNameCtrl: new FormControl(crewMember.LastName, { nonNullable: true }),
      statusCtrl: new FormControl(
        { value: crewMember.Status, disabled: this.isStatusDisable() },
        { nonNullable: true }
      ),
    };
    return this.formBuilder.group(row);
  }
  isStatusDisable() {
    return (
      this.getSelectedCrewControls.value?.OriginalOwner.EmployeeId !==
        this.currentUser.EmployeeId ||
      Boolean(this.getSelectedCrewControls.value?.CurrentOwner)
    );
  }

  addRow(crewMember: TeamAssignedCrewMember) {
    this.getTableRowsFormControls.push(this.initRowForm(crewMember));
  }

  deleteRow(index: number) {
    if (this.getTableRowsFormControls.length > index) {
      const crewMem = this.allCrewMembers.find(
        (x) =>
          x.EmployeeId ===
          this.getTableRowsFormControls.at(index).controls.idCtrl.value
      );
      crewMem && this.filteredCrewMem.push(crewMem);
      this.getTableRowsFormControls.removeAt(index);
      this.enableSaveChanges = true;
    }
  }

  customSort(event: SortEvent) {
    event.data?.sort((data1: FormGroup, data2: FormGroup) => {
      const value1 = event.field && data1.get(event.field)?.value;
      const value2 = event.field && data2.get(event.field)?.value;
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order ? event.order * result : result;
    });
  }

  close() {
    this.closeEvent.emit();
  }
  createCrew() {
    this.isCreateCrew = true;
    this.crewManagerForm.controls.createCrewCtrl.reset();
    this.getTableRowsFormControls.clear();
    this.showAllCrewMem();
    this.enableCrewCtrl();
  }
  cancelCreateCrewOrRemoveAll() {
    this.enableSaveChanges = this.isSaveChanges(
      this.getSelectedCrewControls.value
    );
    this.showAllCrewMem();
    if (this.getSelectedCrewControls.value)
      this.initTableFormArray(this.getSelectedCrewControls.value);

    this.visibleRightPanel = false;
    this.isCreateCrew = false;
    this.isRemoveAll = false;
    this.disableCrewCtrl();
    this.preserveCrewMembersTableSorting();
  }
  enableCrewCtrl() {
    this.crewManagerForm.controls.createCrewCtrl.enable();
  }
  disableCrewCtrl() {
    this.crewManagerForm.controls.createCrewCtrl.disable();
  }

  createCrewReq(): CreateTeamAssignmentInput {
    return {
      AssignedMembers: this.cmRowToAssignedMem(),
      EmployeeId: this.currentUser.EmployeeId,
      Name: this.crewManagerForm.controls.createCrewCtrl.value,
      Station: this.selectedStation,
      Shared: false,
    };
  }
  createTempCrew(): TeamAssignmentDisplay {
    const now = new Date();
    const crew: TeamAssignmentDisplay = {
      AssignedMembers: this.cmRowToAssignedMem(),
      Id: 'placeholder',
      Name: this.crewManagerForm.controls.createCrewCtrl.value,
      OriginalOwner: {
        EmployeeId: this.currentUser.EmployeeId,
        FirstName: this.currentUser.FirstName,
        LastName: this.currentUser.LastName,
      },
      Shared: false,
      Station: this.selectedStation,
      DisplayName: '',
      Created: now,
      Updated: now,
    };
    crew.DisplayName = displayName(crew);
    return crew;
  }

  saveChanges() {
    if (!this.crewManagerForm.valid) return;

    this.enableSaveChanges = false;
    this.visibleRightPanel = false;

    if (this.isCreateCrew) {
      this.isCreateCrew = false;

      const crew: TeamAssignmentDisplay = this.createTempCrew();
      this.allCrews = [crew, ...this.allCrews];
      this.disableCrewCtrl();
      this.createCrewEvent.emit(this.createCrewReq());
      this.getSelectedCrewControls.setValue(crew);
      return;
    }

    if (!this.getSelectedCrewControls.value) return;

    const isCurrentUserOwner =
      this.getSelectedCrewControls.value.OriginalOwner.EmployeeId ===
      this.currentUser.EmployeeId;

    if (isCurrentUserOwner) {
      this.isRemoveAll = false;
      const reqUpdate = {
        Id: this.getSelectedCrewControls.value.Id,
        Name: this.getSelectedCrewControls.value.Name,
        AssignedMembers: this.cmRowToAssignedMem(),
        Station: this.selectedStation,
      };
      this.updateCrewEvent.emit(reqUpdate);
    } else this.saveCrewEvent.emit(this.getSelectedCrewControls.value);
  }

  cmRowToAssignedMem() {
    const assignedMembers: TeamAssignedCrewMember[] = [];
    this.getTableRowsFormControls.controls.forEach((c) => {
      assignedMembers.push({
        EmployeeId: c.controls.idCtrl.value,
        FirstName: c.controls.firstNameCtrl.value,
        LastName: c.controls.lastNameCtrl.value,
        Status: c.controls.statusCtrl.value,
      });
    });
    return assignedMembers;
  }
  stationChanges() {
    this.filteredCrewMem = [];
    this.updateStationEvent.emit(this.selectedStation);
  }

  /*
    crewChanges will get crew as null when user does not have crew on station
  */
  crewChanges(crew: TeamAssignmentDisplay | null) {
    this.enableSaveChanges = this.isSaveChanges(crew);

    this.initTableFormArray(crew);
    this.filteredCrewMem = crew
      ? this.filterAllCrewMem(crew.AssignedMembers)
      : this.allCrewMembers;
    //Reset sorting, everytime after selecting the new crew
    this.resetCrewMembersTableSorting();
  }

  isSaveChanges(crew: TeamAssignmentDisplay | null): boolean {
    if (
      !crew ||
      //someone has share the crew and you have not taken ownership
      (crew.OriginalOwner.EmployeeId !== this.currentUser.EmployeeId &&
        !crew.CurrentOwner) ||
      // you shared the crew and someone has taken the ownership
      (crew.OriginalOwner.EmployeeId === this.currentUser.EmployeeId &&
        crew.CurrentOwner)
    )
      return false;

    return crew.Id !== this.currentCrew?.Id;
  }

  dragStart(crewMem: CrewMember) {
    this.draggedCrewMem = crewMem;
  }
  drop() {
    if (this.draggedCrewMem) {
      const teamAss: TeamAssignedCrewMember = {
        EmployeeId: this.draggedCrewMem.EmployeeId,
        FirstName: this.draggedCrewMem.FirstName,
        LastName: this.draggedCrewMem.LastName,
        Status: this.draggedCrewMem.Status,
      };
      this.addRow(teamAss);
      this.filteredCrewMem = this.filteredCrewMem.filter(
        (x) => x.EmployeeId !== teamAss.EmployeeId
      );
      //Preserve the existing sorting order after dropping new crew member into the list
      this.preserveCrewMembersTableSorting();
      this.draggedCrewMem = null;
      this.enableSaveChanges = true;
    }
  }
  filterAllCrewMem(crewMems: TeamAssignedCrewMember[]) {
    return this.allCrewMembers.filter(
      (c) => !crewMems.some((f) => f.EmployeeId === c.EmployeeId)
    );
  }

  showAllCrewMem() {
    this.visibleRightPanel = !this.visibleRightPanel;
    if (this.visibleRightPanel && this.filteredCrewMem.length === 0) {
      const assignMem = this.cmRowToAssignedMem();
      this.filteredCrewMem = this.filterAllCrewMem(assignMem);
    }
    if (this.getCreateCrewControls.enabled)
      this.getCreateCrewControls.markAsDirty();
  }

  dragEnd() {
    this.draggedCrewMem = null;
  }

  deleteCrewConfirm() {
    if (this.crewManagerForm.valid) {
      this.confirmationService.confirm({
        message: DELETE_CREW_MESSAGE,
        header: DELETE_CREW_HEADER,
        icon: '',
        accept: () => {
          if (
            this.getSelectedCrewControls.value &&
            this.getSelectedCrewControls.value.Id
          ) {
            this.deleteCrewEvent.emit(this.getSelectedCrewControls.value);
          }
        },
      });
    }
  }
  shareConfirm() {
    if (this.crewManagerForm.valid && this.getSelectedCrewControls.value) {
      const msg = this.getSelectedCrewControls.value.Shared
        ? UNSHARE_CREW_MESSAGE
        : SHARE_CREW_MESSAGE;
      const header = this.getSelectedCrewControls.value.Shared
        ? UNSHARE_CREW_HEADER
        : SHARE_CREW_HEADER;

      this.confirmationService.confirm({
        message: msg,
        header: header,
        icon: '',
        accept: () => {
          if (
            this.getSelectedCrewControls.value &&
            this.getSelectedCrewControls.value.Id
          ) {
            this.shareCrewEvent.emit(this.getSelectedCrewControls.value);
          }
        },
      });
    }
  }
  clearCrewMember() {
    this.enableSaveChanges = true;
    this.isRemoveAll = true;
    this.getTableRowsFormControls.clear();
  }

  ownershipConfirm() {
    if (this.crewManagerForm.valid && this.getSelectedCrewControls.value) {
      const msg = this.getSelectedCrewControls.value.CurrentOwner
        ? RELEASE_OWNERSHIP_MESSAGE
        : TAKE_OWNERSHIP_MESSAGE;
      const header = this.getSelectedCrewControls.value.Shared
        ? RELEASE_OWNERSHIP_HEADER
        : TAKE_OWNERSHIP_HEADER;

      this.confirmationService.confirm({
        message: msg,
        header: header,
        icon: '',
        accept: () => {
          if (
            this.getSelectedCrewControls.value &&
            this.getSelectedCrewControls.value.Id
          ) {
            this.ownershipEvent.emit(this.getSelectedCrewControls.value);
          }
        },
      });
    }
  }

  statusChange(shift: string, index: number) {
    const row = this.getTableRowsFormControls.at(index);
    row.controls.statusCtrl.setValue(shift);
    this.enableSaveChanges = true;
  }

  private resetCrewMembersTableSorting(): void {
    this.crewMembersTable.sortOrder = 0;
    this.crewMembersTable.sortField = '';
    this.crewMembersTable.reset();
  }

  private preserveCrewMembersTableSorting(): void {
    const sortEventData: SortEvent = {
      data: this.crewMembersTable?.value,
      field: this.crewMembersTable?.sortField,
      order: this.crewMembersTable?.sortOrder,
    };
    this.customSort(sortEventData);
  }
}
