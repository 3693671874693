import { createFeatureSelector, createSelector } from '@ngrx/store';
import { STATIONS_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { StationsState } from './stations.interfaces';

export const getStationsState =
  createFeatureSelector<StationsState>(STATIONS_FEATURE_KEY);

export const selectAllRegions = createSelector(
  getStationsState,
  (state) => state.allRegions
);
export const selectZones = createSelector(
  getStationsState,
  (state) => state.zones
);
export const selectAllIataCodes = createSelector(
  selectAllRegions,
  (regions) => {
    if (!regions) return [];

    return regions.flatMap((region) =>
      region.Stations.map((station) => station.IataCode)
    );
  }
);

export const selectFormattedStationInfo = createSelector(
  selectAllRegions,
  (regions) => {
    if (!regions) return [];

    const formattedResults: string[] = [];

    regions.forEach((region) => {
      region.Stations.forEach((station) => {
        if (station && station.IataCode) {
          const iataCode = station.IataCode;
          const city = station.City ? ` - ${station.City}, ` : ' - ';
          const countryState = station.CountryState || '';
          const formattedString = `${iataCode}${city}${countryState}`;
          formattedResults.push(formattedString);
        }
      });
    });

    return formattedResults;
  }
);
