import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
} from '@angular/core';
import { FormArray, NonNullableFormBuilder, FormControl } from '@angular/forms';
import { Notice, NoticeInput } from '@lm-apps/lmo/ui/data';
import { ConfirmationService } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditNoticeModalComponent } from '../edit-notice-modal/edit-notice-modal.component';

@Component({
  selector: 'lm-apps-lmo-admin-notices',
  templateUrl: './notices.component.html',
})
export class NoticesComponent implements OnChanges {
  @Input() allNotices: Notice[] = [];
  modalRef: BsModalRef | null = null;

  @Output() deleteNoticeEvent: EventEmitter<Notice> = new EventEmitter();
  @Output() saveNoticeEvent: EventEmitter<NoticeInput> = new EventEmitter();

  initialFormArray: FormControl<Notice>[] = [];

  form = this.formBuilder.group({
    tableRows: this.formBuilder.array(this.initialFormArray),
  });

  get getTableRowsFormControls(): FormArray<FormControl<Notice>> {
    return this.form.controls.tableRows;
  }

  constructor(
    private confirmationService: ConfirmationService,
    private modalService: BsModalService,
    private formBuilder: NonNullableFormBuilder
  ) {}

  ngOnChanges() {
    this.initializeFormWithNotices();
  }

  initializeFormWithNotices() {
    this.getTableRowsFormControls.clear();
    this.allNotices.map((notice) =>
      this.getTableRowsFormControls.push(this.formBuilder.control(notice))
    );
  }

  deleteTableRow(row: FormControl<Notice>) {
    const index = this.getTableRowsFormControls.controls.findIndex(
      (x) => x === row
    );
    if (index !== -1) {
      this.getTableRowsFormControls.removeAt(index);
    }
  }

  onDeleteNotice(row: FormControl<Notice>) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this notice?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text p-button-text',
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.deleteTableRow(row);
        this.deleteNoticeEvent.emit(row.value);
      },
    });
  }

  saveChanges(noticeInput?: NoticeInput) {
    this.saveNoticeEvent.emit(noticeInput);
  }

  openModal(notice?: Notice) {
    this.modalRef = this.modalService.show(EditNoticeModalComponent, {
      class: 'modal-dialog modal-md',
      ignoreBackdropClick: true,
      initialState: {
        notice: notice ?? null,
      },
    });

    this.modalRef.content.saveNoticeEvent.subscribe((data: NoticeInput) => {
      this.saveNoticeEvent.emit(data);
    });
  }
}
