import { Pipe, PipeTransform } from '@angular/core';
import {
  ArrivalStatusCode as ArrivalStatusCodeEnum,
  EnumUtil,
} from '@lm-apps/lmo/shared/enums';
import { ArrivalStatusCode as ArrivalStatusCodeParameter } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'arrivalStatusCode',
})
export class ArrivalStatusCodePipe implements PipeTransform {
  transform(arrivalStatusCode: ArrivalStatusCodeParameter) {
    return EnumUtil.getValue(ArrivalStatusCodeEnum, arrivalStatusCode);
  }
}
