export enum HistoryNotificationTypeFilter {
  ALL = 'ALL',
  HERMES = 'HERMES',
  TRANSIT = 'TRANSIT',
  MEET_GREET = 'MG',
  RIGHT_START_MEET_GREET = 'RSMG',
  WAKE_UP = 'WKUP',
  RIGHT_START_WAKE_UP = 'RSWK',
  CFMOIL = 'CFMOIL',
  MEL_FOLLOW_UP = 'MELFLW',
  MEL_GENERAL = 'MELGEN',
  QUICK_FIX = 'QFIX',
  NEF_QUICK_FIX = 'QNEF',
  TERM = 'TERM',
  MANUAL = 'MANUAL',
  OTHER = 'Other',
}
