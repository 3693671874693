import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import { map } from 'rxjs/operators';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class AircraftLockItemGQL extends Query<
  Pick<data.Query, 'AircraftLockItems'>,
  data.QueryAircraftLockItemsArgs
> {
  override document = gql`
    query AircraftLockItems($noseNumber: String!) {
      AircraftLockItems(noseNumber: $noseNumber) {
        lockedItems {
          micNumber
        }
      }
    }
  `;
}

@Injectable({
  providedIn: 'root',
})
export class AircraftLockItemsService {
  constructor(private _aircraft: AircraftLockItemGQL) {}
  aircraftLockItems(noseNumber: string) {
    return this._aircraft
      .fetch({ noseNumber: noseNumber })
      .pipe(map(({ data }) => data?.AircraftLockItems?.lockedItems));
  }
}
