import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import {
  ALERT_AUDIO_SRC,
  ArrivalStatus,
  CC_VIEW_ONLY_CSS,
  FlightsEntity,
  MANUAL_TASK_SMALL_CAP,
  MOC_REQUEST_SMALL_CAP,
  Task,
  TaskEntity,
  TaskState,
  User,
} from '@lm-apps/lmo/ui/data';
import { Subject, take } from 'rxjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { offsetDateTime } from '@lm-apps/lmo/shared/utilities';
dayjs.extend(utc);

export interface ToastData {
  toast: ActiveToast<any>;
  extraData: {
    flightPair: FlightsEntity;
    task: TaskEntity;
    isDepartureToast: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CustomToastrService {
  toastrState: Array<ToastData> = [];
  toast?: ToastData;

  showToast = false;
  noiseAlert = false;
  currentUser: User | null = null;

  private methodCallSubject = new Subject<{
    flightPair: FlightsEntity;
    task: TaskEntity;
  }>();
  methodCall$ = this.methodCallSubject.asObservable();
  audio = new Audio(ALERT_AUDIO_SRC);
  constructor(public baseToastr: ToastrService) {}

  error(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined
  ) {
    this.baseToastr.error(message, title, override);
  }
  warning(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined
  ) {
    this.baseToastr.warning(message, title, override);
  }
  info(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined
  ) {
    this.baseToastr.info(message, title, override);
  }
  success(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined
  ) {
    this.baseToastr.success(message, title, override);
  }
  show(
    message?: string | undefined,
    title?: string | undefined,
    override?: Partial<IndividualConfig<any>> | undefined,
    type?: string | undefined
  ) {
    this.baseToastr.show(message, title, override, type);
  }
  clear(toastId?: number | undefined) {
    this.baseToastr.clear(toastId);
  }

  onHiddenToast(toast: ToastData) {
    //Dispose state for this toast as its cleared from UI
    this.updateToastrState(toast, true);
  }

  onTapToast(toast: ToastData) {
    this.methodCallSubject.next({
      flightPair: toast.extraData.flightPair,
      task: toast.extraData.task,
    });
  }
  clearAllToast() {
    this.baseToastr.clear();
  }

  triggerDepartureToast(
    flightPair: FlightsEntity,
    task: TaskEntity,
    user: User
  ) {
    this.setUser(user);
    const activeToast = this.baseToastr.show(
      `Incomplete Tasks for Departure <br>at gate: ${
        flightPair.Arrival?.ArrivalGate || '--'
      }/${
        flightPair.Departure?.DepartureGate || '--'
      }<br>${this.depatureTimeMessage(flightPair)}`,
      flightPair.Equipment.NoseNumber,
      {
        timeOut: 1000 * 100,
        extendedTimeOut: 1000 * 100,
        closeButton: true,
        toastClass: `ngx-toastr depature ${CC_VIEW_ONLY_CSS}`,
        progressBar: true,
        enableHtml: true,
      }
    );

    const toastData: ToastData = {
      toast: activeToast,
      extraData: { flightPair: flightPair, task: task, isDepartureToast: true },
    };

    activeToast.onHidden
      .pipe(take(1))
      .subscribe(() => this.onHiddenToast(toastData));
    activeToast.onTap.pipe(take(1)).subscribe(() => this.onTapToast(toastData));
    //need to play audio mp3
    if (this.isManualTaskAndCanDing(task)) {
      this.playAudio();
    }

    this.updateToastrState(toastData, false);
  }

  clearToasts(openToastrList: Array<ToastData>, canClearToast: boolean) {
    if (openToastrList.length > 0) {
      openToastrList.forEach((toastData) => {
        if (canClearToast) {
          this.baseToastr.clear(toastData.toast.toastId);
        } else if (!toastData.extraData.isDepartureToast) {
          this.baseToastr.clear(toastData.toast.toastId);
        }
      });
    }
  }

  updateToastrState(toastData: ToastData, toBeHidden: boolean) {
    if (!toBeHidden) {
      this.toastrState.push(toastData);
      console.log(
        'Toastr state updated by addition : Depth = ' + this.toastrState.length
      );
    } else {
      this.toastrState = this.toastrState.filter(function (item) {
        return item.toast.toastId !== toastData.toast.toastId;
      });
      console.log(
        'Toastr state updated by deletion : Depth = ' + this.toastrState.length
      );
    }
  }

  hasOpenToastr(taskId: string) {
    return this.toastrState.findIndex(
      (toast) => toast.extraData.task.Id == taskId
    );
  }

  getOpenToastr(taskId: string) {
    return this.toastrState.filter(
      (toast) => toast.extraData.task.Id == taskId
    );
  }

  getOpenToastrByHash(fp: FlightsEntity) {
    return this.toastrState.filter(
      (toast) => toast.extraData.flightPair.Hash === fp.Hash
    );
  }

  openTasksCount(flightPair: FlightsEntity) {
    const tasks = flightPair.AllTasks.filter(
      (task) =>
        task.TaskState === TaskState.Unassigned ||
        task.TaskState === TaskState.Assigned
    );
    return tasks ? tasks.length : 0;
  }

  setUser(user: User) {
    this.showToast = user.Filter.OtherToaster;
    this.noiseAlert = user.Filter.NoiseToaster;
    this.currentUser = user;
  }

  isOnGround(flightPair: FlightsEntity) {
    return (
      flightPair.Arrival?.ArrivalStatus === ArrivalStatus.OnGround ||
      flightPair.Arrival?.ArrivalStatus === ArrivalStatus.InGate
    );
  }
  isManualTaskAndCanDing(task: TaskEntity) {
    return (
      this.noiseAlert &&
      task.Label &&
      (task.Label.replace(' ', '').replace('_', '').toLowerCase() ===
        MANUAL_TASK_SMALL_CAP ||
        task.Label.replace(' ', '').replace('_', '').toLowerCase() ===
          MOC_REQUEST_SMALL_CAP)
    );
  }
  isArrivingIn30Min(flightPair: FlightsEntity) {
    if (!flightPair.Arrival?.ActualArrival) return false;

    const now = dayjs().utc();
    const diffDuration = dayjs(flightPair.Arrival.ActualArrival)
      .utc()
      .diff(now, 'minutes');
    console.log(
      'isArrivingIn30Min$: toUTCString: diff',
      dayjs(flightPair.Arrival.ActualArrival).utc().diff(now, 'minutes')
    );
    return diffDuration < 30 && diffDuration > 0;
  }
  isCreatedByMe(task: Task) {
    if (!this.currentUser || !this.currentUser.EmployeeId) return false;
    return task.UserId === this.currentUser.EmployeeId;
  }

  depatureTimeMessage(flightPair: FlightsEntity) {
    const stdMsg = flightPair.Departure?.ActualOff
      ? 'OFF ' +
        offsetDateTime(
          flightPair.Departure.ActualOff,
          flightPair.Departure.DepartureUtcAdjustment,
          'HH:mm'
        )
      : flightPair.Departure?.ActualOut
      ? 'OUT ' +
        offsetDateTime(
          flightPair.Departure.ActualOut,
          flightPair.Departure.DepartureUtcAdjustment,
          'HH:mm'
        )
      : flightPair.Departure?.EstimatedDeparture
      ? 'ETD ' +
        offsetDateTime(
          flightPair.Departure.EstimatedDeparture,
          flightPair.Departure.DepartureUtcAdjustment,
          'HH:mm'
        )
      : 'STD ' +
        offsetDateTime(
          flightPair.Departure?.ScheduledDeparture,
          flightPair.Departure?.DepartureUtcAdjustment,
          'HH:mm'
        );
    return stdMsg;
  }

  jobTypeNameMessage(flightPair: FlightsEntity, signalRTask: Task) {
    return `${signalRTask.JobType.DisplayJobType} at gate: ${
      flightPair.Arrival?.ArrivalGate || '--'
    }/${
      flightPair.Departure?.DepartureGate || '--'
    }<br>${this.depatureTimeMessage(flightPair)}`;
  }

  showOnGroundToaster(flightPair: FlightsEntity, signalRTask: Task): boolean {
    if (!this.isOnGround(flightPair)) return false;

    const activeToast = this.baseToastr.warning(
      this.jobTypeNameMessage(flightPair, signalRTask),
      flightPair.Equipment.NoseNumber,
      {
        disableTimeOut: true,
        closeButton: true,
        toastClass: `ngx-toastr ${CC_VIEW_ONLY_CSS}`,
        enableHtml: true,
      }
    );

    const taskEntity: TaskEntity = { ...signalRTask, IsPending: false };
    const toastData: ToastData = {
      toast: activeToast,
      extraData: {
        flightPair: flightPair,
        task: { ...signalRTask, IsPending: false },
        isDepartureToast: false,
      },
    };

    activeToast.onHidden
      .pipe(take(1))
      .subscribe(() => this.onHiddenToast(toastData));
    activeToast.onTap.pipe(take(1)).subscribe(() => this.onTapToast(toastData));
    //need to play audio mp3
    if (this.isManualTaskAndCanDing(taskEntity)) {
      this.playAudio();
    }
    this.updateToastrState(toastData, false);
    return true;
  }
  showArrivingIn30MinToaster(
    flightPair: FlightsEntity,
    signalRTask: Task
  ): boolean {
    if (!this.isArrivingIn30Min(flightPair)) return false;
    const activeToast = this.baseToastr.info(
      this.jobTypeNameMessage(flightPair, signalRTask),
      flightPair.Equipment.NoseNumber,
      {
        disableTimeOut: true,
        closeButton: true,
        toastClass: `ngx-toastr ${CC_VIEW_ONLY_CSS}`,
        enableHtml: true,
      }
    );

    const taskEntity: TaskEntity = { ...signalRTask, IsPending: false };
    const toastData: ToastData = {
      toast: activeToast,
      extraData: {
        flightPair: flightPair,
        task: taskEntity,
        isDepartureToast: false,
      },
    };

    activeToast.onTap.pipe(take(1)).subscribe(() => this.onTapToast(toastData));
    //need to play audio mp3
    if (this.isManualTaskAndCanDing(taskEntity)) {
      this.playAudio();
    }
    this.updateToastrState(toastData, false);
    return true;
  }
  triggerToast(
    flightPair: FlightsEntity,
    signalRTask: TaskEntity,
    isNewTask: boolean,
    user: User
  ) {
    this.setUser(user);

    if (
      signalRTask.TaskState === TaskState.Completed ||
      signalRTask.TaskState?.toString() === 'Completed'
    ) {
      try {
        //See if any toastr is open for this task, if so get list to close
        //See if this is departure toast, if so, it should be closed only if all the tasks are completed for the leg
        const openToastrList = this.getOpenToastr(signalRTask.Id);
        console.log(
          'Found ' +
            openToastrList.length +
            ' open toasters for the task : ' +
            signalRTask.Id
        );

        const canClearToast =
          flightPair.HasDepartureToast && this.openTasksCount(flightPair) > 0
            ? false
            : true;
        this.clearToasts(openToastrList, canClearToast);

        //This is where it gets sketchy with conflicting business rules.
        //There can be a toastr open for a closed task, For Eg if this flight pair had a departure toast(task1) and normal toast(task2), someone completed task1,
        //we dont close that because there is another task open. When someone closed last task which is task2, we cannot capture the 1st one as taskId is different.
        //So getting all the toasts for the hash and then closing them.

        if (this.openTasksCount(flightPair) === 0 && canClearToast)
          this.clearToasts(this.getOpenToastrByHash(flightPair), canClearToast);
      } catch (err) {
        console.log(
          'Error while clearing the toast on singalR completed task event'
        );
        return;
      }
      return;
    }

    if (
      this.isCreatedByMe(signalRTask) ||
      !isNewTask ||
      !signalRTask.IsNew ||
      !this.showToast
    )
      return;

    if (
      !this.showOnGroundToaster(flightPair, signalRTask) &&
      !this.showArrivingIn30MinToaster(flightPair, signalRTask)
    ) {
      const activeToast = this.baseToastr.info(
        this.jobTypeNameMessage(flightPair, signalRTask),
        flightPair.Equipment.NoseNumber,
        {
          timeOut: 1000 * 10,
          closeButton: true,
          toastClass: `ngx-toastr ${CC_VIEW_ONLY_CSS}`,
          progressBar: true,
          enableHtml: true,
        }
      );

      const taskEntity: TaskEntity = { ...signalRTask, IsPending: false };
      const toastData: ToastData = {
        toast: activeToast,
        extraData: {
          flightPair: flightPair,
          task: taskEntity,
          isDepartureToast: false,
        },
      };
      activeToast.onHidden
        .pipe(take(1))
        .subscribe(() => this.onHiddenToast(toastData));
      activeToast.onTap
        .pipe(take(1))
        .subscribe(() => this.onTapToast(toastData));

      if (this.isManualTaskAndCanDing(taskEntity)) {
        this.playAudio();
      }

      this.updateToastrState(toastData, false);
    }
  }
  playAudio() {
    this.audio.play();
  }
}
