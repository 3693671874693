import { map } from 'rxjs/operators';
import { AlternateTasksGQL } from './query/alternate-tasks.query';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlternateTasksService {
  constructor(private _alternateTasks: AlternateTasksGQL) {}

  getAlternateTasks(stations: string[]) {
    return this._alternateTasks
      .fetch({ stations })
      .pipe(map(({ data }) => data.AlternateTasks));
  }
}
