import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoticesComponent } from './components/notices/notices.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PingAuthGuard } from '@aa-techops-ui/ping-authentication';
import { NoticesContainerComponent } from './containers/notices-container/notices-container.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { EditNoticeModalComponent } from './components/edit-notice-modal/edit-notice-modal.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NoticesModalContainerComponent } from './containers/notices-modal-container/notices-modal-container.component';
import { NoticesModalComponent } from './components/notices-modal/notices-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    CommonModule,
    ConfirmDialogModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    ReactiveFormsModule,
    TableModule,
  ],
  declarations: [
    EditNoticeModalComponent,
    NoticesComponent,
    NoticesContainerComponent,
    NoticesModalComponent,
    NoticesModalContainerComponent,
  ],
  providers: [PingAuthGuard, ConfirmationService, BsModalRef],
  exports: [NoticesModalContainerComponent],
})
export class LmoUiFeatureNoticesModule {}
