export enum HistoryKeyCallTypeFilter {
  ALL = 'ALL',
  MOC_IOC = 'MOC/IOC',
  PLANNING = 'Planning',
  VERBAL = 'Verbal',
  RADIO = 'Radio',
  TELEPHONE = 'Telephone',
  VHF = 'VHF',
  RAMP = 'Ramp',
  VENDOR = 'Vendor',
  GATE_RETURN = 'GateReturn',
  RTF = 'RTF',
  DIVERSION = 'Diversion',
  SYSTEM_GENERATED = 'System',
}
