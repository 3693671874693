import { Injectable } from '@angular/core';
import { Query, QueryAlternateTasksArgs } from '@lm-apps/lmo/ui/data';
import { Query as ApolloQuery, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AlternateTasksGQL extends ApolloQuery<
  Pick<Query, 'AlternateTasks'>,
  QueryAlternateTasksArgs
> {
  override document = gql`
    query AlternateTasks($stations: [String!]!) {
      AlternateTasks(stations: $stations) {
        AircraftId
        NoseNumber
        AssignedMembers {
          AcknowledgedOn
          ArrivedOn
          AssignedBy
          AssignedByName
          AssignedOn
          ChangedBy
          ChangedOn
          CompletedOn
          EmployeeId
          FirstName
          LastName
          Status
        }
        CallType {
          DisplayCallType
          Id
          IsActive
          KeyCallType
        }
        ChangedBy
        ChangedOn
        CompletedOn
        Created
        CustomNotificationType
        DeferredReason
        Description
        Effectivity
        ExternalItemId
        Id
        InboundFlightLegId
        InternalItemId
        IsAlternateTask
        IsAvionic
        IsManualTask
        IsNfnf
        IsOilService
        JobType {
          DisplayJobType
          Id
          IsActive
          IsSelection
          KeyJobType
        }
        Label
        Location
        MaintenancePriority {
          Description
          Order
        }
        MaintenanceType
        NotificationId
        OutBoundFlightLegId
        SpecialInstructions {
          Created
          EmployeeId
          EmployeeName
          Instructions
        }
        Station
        TaskState
        TaskUserReferenceId
        To
        ToEmployeeId
        UserDefinedItemId
        UserId
        UserName
      }
    }
  `;
}
