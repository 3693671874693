import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class AllCrewMemberByStationGQL extends Query<
  Pick<data.Query, 'AllCrewMemberByStation'>,
  data.QueryAllCrewMemberByStationArgs
> {
  override document = gql`
    query AllCrewMemberByStation($station: String!) {
      AllCrewMemberByStation(station: $station) {
        ChangedBy
        ChangedOn
        Department
        EmployeeId
        FirstName
        IsOnline
        Status
        LastName
        Position
        Station
        TabletAccessedOn
      }
    }
  `;
}
