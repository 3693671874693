import { AssignedMember, SortCrewState } from '@lm-apps/lmo/ui/data';
import { sortByProperty } from '@lm-apps/lmo/ui/common/feature-shared';

/**
 * Merges crew members with assigned members from a task and sorts them based on the sort state.
 * @param crewMembers - The list of crew members.
 * @param assignedMembers - The list of assigned members from a task.
 * @param sortState - The sort state containing the column and sort order.
 * @returns The merged and sorted list of crew members.
 */
export function mergeAndSortCrewMembers(
  crewMembers: AssignedMember[],
  assignedMembers: AssignedMember[] | undefined,
  sortState: SortCrewState
): AssignedMember[] {
  const mergedMembers = [...crewMembers];

  if (assignedMembers) {
    assignedMembers.forEach((member) => {
      if (!crewMembers.some((x) => x.EmployeeId === member.EmployeeId)) {
        mergedMembers.push(member);
      }
    });
  }

  return mergedMembers.sort((a, b) =>
    sortByProperty(a, b, sortState.sortOrder, sortState.column)
  );
}
