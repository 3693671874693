import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsQueryGQL extends Query<
  { FeatureFlags: Array<data.FeatureFlag> },
  data.QueryFlightsArgs
> {
  override document = gql`
    query FeatureFlags {
      FeatureFlags {
        name
        enabled
      }
    }
  `;
}
