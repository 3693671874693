import { Pipe, PipeTransform } from '@angular/core';
import { Notification } from '@lm-apps/lmo/wall/shared';
import { ItemType } from '@lm-apps/lmo/ui/data';
import { GetPriorityClassNamePipe } from './get-priority-class-name';

@Pipe({
  name: 'getwallPriorityClassName',
  pure: true,
})
export class GetWallPriorityClassNamePipe implements PipeTransform {
  transform(noti: Notification): string {
    const pipe = new GetPriorityClassNamePipe();
    const classArray = pipe.getPriorityClassName(
      noti.type ?? ItemType.Undefined,
      noti.priorityName ?? '',
      ''
    );
    return classArray.join(' ');
  }
}
