import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CreateTeamAssignedCrewMemberInput } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-assigned-crew-member-table',
  templateUrl: './assigned-crew-member-table.component.html',
  styleUrls: ['./assigned-crew-member-table.component.scss'],
})
export class AssignedCrewMemberTableComponent {
  @Input() selectedCrewMembers!: CreateTeamAssignedCrewMemberInput[];
  @Output() selectedCrewMemberChange =
    new EventEmitter<CreateTeamAssignedCrewMemberInput>();

  emitAddOrRemoveSelectedMechanic(
    crewMember: CreateTeamAssignedCrewMemberInput
  ) {
    this.selectedCrewMemberChange.emit(crewMember);
  }
}
