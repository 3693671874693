import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class FetchBOWJobsGQL extends Query<
  Pick<data.Query, 'FetchBOWJobs'>,
  data.QueryFetchBowJobsArgs
> {
  override document = gql`
    query FetchBOWJobs($input: FetchBowJobsByInput!) {
      FetchBOWJobs(input: $input) {
        bowContents {
          actionType
          aircraftNoseNumber
          ataCode
          comment
          deferralCorrectionCode
          deferralReasonCode
          description
          employeeNumber
          isWorkCardPrinted
          jobNumber
          lockIndicator
          materialLaborIndicator
          manufacturingSerialNumberOff
          mePartNumber
          meSerialNumber
          micNumber
          penDetectDesignator
          planningPriority
          positionDescription
          screenType
          signOffStation
          taskStatus
          trackingNumberOn
          workControlStation
        }
      }
    }
  `;
}
