import { Pipe, PipeTransform } from '@angular/core';
import { FlightNotificationEntity } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'showNotificationsWithoutTask',
})
export class NotificationsWithoutTaskPipe implements PipeTransform {
  transform(notifications: FlightNotificationEntity[]) {
    return notifications.filter((n) => !n?.HasTask) ?? [];
  }
}
