export enum SourceSystem {
  SYSTEM = 'System',
  HERMES = 'HERMES',
  SCEPTRE = 'SCEPTRE',
  FLIGHT_HUB_RON = 'FHRON',
  TASK_PROVIDER = 'TaskProvider',
  FH = 'FH',
  FLIGHT_CHECK = 'Flight Check',
  LMO = 'LMO',
  OSP = 'OSP',
}
