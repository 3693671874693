import gql from 'graphql-tag';
export const FeatureFlagQuery = gql`
  query flagsQuery($appName: String!, $envName: String!) {
    featureFlags(appName: $appName, envName: $envName) {
      name
      enabled
    }
  }
`;

export const FeatureFlagSubscription = gql`
  subscription onFlagChanged($appName: String!, $envName: String!) {
    featureFlagChanged(appName: $appName, envName: $envName) {
      name
      enabled
    }
  }
`;
