/**
 * ArrivalStatus
 */
export enum ArrivalStatus {
  /**
   * Delay
   */
  DELAY = 'DLY',
  /**
   * Scheduled Arrival Time
   */
  SCHEDULED_TIME_OF_ARRIVAL = 'STA',
  /**
   * Estimated Arrival Time
   */
  ESTIMATED_TIME_OF_ARRIVAL = 'ETA',
  /**
   * The aircraft has arrived at the gate
   */
  IN_GATE = 'IN',
  /**
   * The aircraft has landed
   */
  ON_GROUND = 'ON',
  /**
   * For null values
   */
  EMPTY = '',
}
