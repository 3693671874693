<form (submit)="acknowledge()">
  <div class="modal show d-block" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header text-center bg-b4-blue">
          <h5 class="modal-title aa-white w-100">LMO Notification Alert</h5>
        </div>
        <div class="modal-body aa-m-4">
          <table class="table table-condensed">
            <tr>
              <th>Title</th>
              <th>Description</th>
              <th>Downtime</th>
            </tr>
            <tr>
              <td>{{ this.notice.Title }}</td>
              <td>{{ this.notice.Description }}</td>
              <td *ngIf="this.notice.DowntimeStart && this.notice.DowntimeEnd">
                {{
                  this.notice.DowntimeStart | date: 'M/d/yy h:mm:ss a':'local'
                }}
                -
                {{
                  this.notice.DowntimeEnd | date: 'M/d/yy h:mm:ss a z':'local'
                }}
              </td>
              <td
                *ngIf="!this.notice.DowntimeStart && !this.notice.DowntimeEnd"
              >
                -
              </td>
            </tr>
          </table>
          <p></p>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary">
            <i class="fa fa-solid fa-check"></i>&nbsp;Acknowledge
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
