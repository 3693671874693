import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ZonesGatesGQL extends Query<
  Pick<data.Query, 'ZonesGates'>,
  data.QueryZonesGatesArgs
> {
  override document = gql`
    query ZonesGates($stations: [String!]) {
      ZonesGates(stations: $stations) {
        City
        CountryState
        Gates {
          Name
        }
        IataCode
        ZoneName
      }
    }
  `;
}
