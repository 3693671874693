<section class="cc-detail-task">
  <div class="row justify-space-between aa-m-0 header header-default aa-px-4">
    <div class="row justify-start">
      <p-button
        icon="pi pi-times"
        styleClass="p-button-secondary p-button-text cross"
        class="align-self-center d-print-none"
        (onClick)="closeTask()"
      ></p-button>
      <div class="title-txt pr-1">
        BOW Details ({{ maintenanceTaskDisplay?.MaintenanceTaskInput?.Label }})
      </div>
    </div>
  </div>

  <form [formGroup]="taskDetailForm" class="d-print-block">
    <div class="modal-body task-table printable-task">
      <div class="col-sm-12 row aa-my-2">
        <div class="col-sm-12">
          <span class="bold-7"
            >Description: &nbsp;{{
              maintenanceTaskDisplay?.MaintenanceTaskInput?.Description
            }}
          </span>
        </div>
      </div>

      <div class="col-sm-12 row aa-mb-2 aa-pr-0">
        <div class="col-sm-6 aa-pr-0 hidden-print">
          <label for="ccAssignTo" class="bold-7">Assign To</label>
          <lm-apps-assign-to-dropdown
            inputId="ccAssignTo"
            [allOnlineStatus]="allCrewMemberOnlineStatus"
            [crewMembers]="crewMembersForAssignToDropdown"
            [sortState]="sortCrewState"
            (sortCrew)="sortCrew($event)"
            formControlName="selectedCrewMembers"
          ></lm-apps-assign-to-dropdown>
        </div>
        <div class="col-sm-12 aa-pr-0">
          <lm-apps-assigned-crew-detail
            *ngIf="
              this.selectedCmCtrl.value && this.selectedCmCtrl.value.length
            "
            [selectedCrewMembers]="this.selectedCmCtrl.value"
            [allCrewMemberOnlineStatus]="allCrewMemberOnlineStatus"
            (selectedCrewMemberChange)="removeSelectMechanic($event)"
          ></lm-apps-assigned-crew-detail>
        </div>
        <div class="col-sm-12 my-2">
          <label class="bold-7" for="specialInstructions"
            >Special Instructions</label
          >
          <textarea
            rows="3"
            type="text"
            class="form-control"
            id="specialInstruction"
            name="specialInstruction"
            formControlName="specialInstruction"
            minlength="1"
            maxlength="380"
            placeholder="Add instructions"
            inputId="specialInstructions"
            (ngModelChange)="canUpdate()"
          ></textarea>
          <label class="right">
            {{ taskDetailForm.controls.specialInstruction.value?.length }}
            /380</label
          >
        </div>
      </div>

      <div
        class="col-sm-12 justify-start aa-pr-0 aa-mr-0 d-print-none"
        *ngIf="
          maintenanceTaskDisplay.MaintenanceTaskInput.TaskState as taskState
        "
      >
        <div class="row col-sm-12 aa-pr-0 a-mr-0">
          <ng-container
            *ngIf="
              taskState !== taskStateValues.Deferred &&
              taskState !== taskStateValues.Closed &&
              taskState !== taskStateValues.Completed &&
              taskState !== taskStateValues.SystemClosed
            "
          >
            <!-- We are calling createBowTask() to create entry into the DB when we are assigning the AMT's for the first time, 
          but if the task is already exists in DB then we are calling updateTask() method,
           even though the button label is Assign 
           (This is because if we remove users after assigning then the task entry is already exists in DB and no need to create new entry and just have to update it) -->
            <button
              *ngIf="
                !maintenanceTaskDisplay.MaintenanceTaskInput.AssignedToIds
                  .length
              "
              [label]="'Assign'"
              pButton
              class="aa-mr-5 p-button-raised"
              [disabled]="
                !enableUpdatebtn ||
                !this.taskDetailForm.controls.selectedCrewMembers.value?.length
              "
              (click)="
                maintenanceTaskDisplay.AdditionalTaskDetail
                  .IsBowTaskCreatedOnTheFly
                  ? createBowTask()
                  : updateTask()
              "
            >
              <i class="fa fa-check aa-pr-2"></i>
            </button>

            <button
              *ngIf="
                maintenanceTaskDisplay.MaintenanceTaskInput.AssignedToIds.length
              "
              [label]="'Update'"
              pButton
              class="aa-mr-5 p-button-raised"
              [disabled]="!enableUpdatebtn"
              (click)="updateTask()"
            >
              <fa-icon [icon]="faFloppyDisk" class="aa-pr-2"></fa-icon>
            </button>
          </ng-container>
          <button
            *ngIf="canReopenTask"
            label="Reopen"
            pButton
            type="button"
            class="aa-mr-5 p-button-raised"
            (click)="reOpenTask()"
          >
            <i class="fa fa-thumbs-up aa-pr-2"></i>
          </button>
          <button
            label="Cancel"
            pButton
            type="button"
            class="aa-mr-5 p-button-raised p-button-danger"
            (click)="closeTask()"
          ></button>
          <button
            *ngIf="
              taskState !== taskStateValues.Unassigned && canCompleteOrDeferTask
            "
            label="Complete"
            pButton
            type="button"
            class="p-button-raised"
            (click)="completeTask()"
          >
            <i class="fa fa-check aa-pr-2"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</section>
