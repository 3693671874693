import {
  FLIGHT_FEATURE_KEY,
  FlightNotificationEntity,
  KeyJobType,
  SelectedFlightData,
  SortableColumn,
  TaskEntity,
} from '@lm-apps/lmo/ui/data';
import { customSort } from '@lm-apps/lmo/ui/common/feature-shared';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFlight from './flight.reducer';

export const selectFlightState =
  createFeatureSelector<fromFlight.State>(FLIGHT_FEATURE_KEY);

export const selectFlightIds = createSelector(
  selectFlightState,
  fromFlight.selectFlightIds // shorthand for flightState => fromFlight.selectFlightIds(flightState)
);

export const selectFlightEntities = createSelector(
  selectFlightState,
  fromFlight.selectFlightEntities
);

export const selectAllFlights = createSelector(
  selectFlightState,
  fromFlight.selectAllFlights
);

export const selectFlightTotal = createSelector(
  selectFlightState,
  fromFlight.selectFlightTotal
);

export const selectCurrentFlightId = createSelector(
  selectFlightState,
  fromFlight.getSelectedFlightId
);

export const selectCurrentFlight = createSelector(
  selectFlightEntities,
  selectCurrentFlightId,
  (flightEntities, flightId) => flightId && flightEntities[flightId]
);

export const selectFlightError = createSelector(
  selectFlightState,
  (state) => state.error
);

export const selectIsFlightsLoaded = createSelector(
  selectFlightState,
  (state) => state.loaded
);

export const selectSortDetail = createSelector(
  selectFlightState,
  fromFlight.getSelectedFlightSortDetail
);

export const selectAllFlightsSorted = createSelector(
  selectAllFlights,
  selectSortDetail,
  (flights, sortDetail) => {
    const flightsCopy = [...flights];
    if (flightsCopy && flightsCopy.length > 1) {
      const splitProp = SortableColumn[sortDetail.column].split('.');
      flightsCopy.sort((a, b) =>
        customSort(a, b, sortDetail.sortOrder, splitProp[0], splitProp[1])
      );
    }
    return flightsCopy;
  }
);

export const selectSelectedFlight = createSelector(
  selectFlightState,
  fromFlight.getSelectedFlight
);

export const selectSelectedMyTasksFlight = createSelector(
  selectFlightState,
  fromFlight.getSelectedMyTasksFlight
);

export const selectMyTasksSortDetail = createSelector(
  selectFlightState,
  (state) => state.myTasks.sortDetail
);

export const selectSelectedFlightData = createSelector(
  selectFlightEntities,
  selectSelectedFlight,
  (flights, selectedFlight) => {
    let result: SelectedFlightData | undefined = undefined;
    if (flights) {
      const flightData = flights[selectedFlight.FlightHash];
      let selectedNotification: FlightNotificationEntity | undefined;
      let task: TaskEntity | undefined;
      if (flightData) {
        selectedNotification = flightData.Notifications?.find(
          (notification) =>
            notification.Id == selectedFlight.FlightNotificationId
        );
        task = flightData.AllTasks?.find(
          (task) => task.Id == selectedFlight.TaskId
        );
        result = {
          flight: flightData,
          notification: selectedNotification,
          task: task,
        };
      }
    }
    return result;
  }
);

export const getFlightBillOfWorkItems = createSelector(
  selectSelectedFlightData,
  (selectedFlightData) => {
    let result: TaskEntity[] = [];
    if (selectedFlightData) {
      result = selectedFlightData.flight?.AllTasks?.filter(
        (task) => task.JobType?.KeyJobType === KeyJobType.Bow
      );
    }
    return result;
  }
);

export const getSelectedBowItem = createSelector(
  selectFlightState,
  (state) => state.selectedBowItem
);
