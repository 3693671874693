import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';
import { TaskEntity, TaskEvent, TaskState, User } from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'lm-apps-flight-task-list',
  templateUrl: './flight-task-list.component.html',
})
export class FlightTaskListComponent {
  @Input() tasks!: Array<TaskEntity>;
  @Input() isRhs = false;
  @Input() isMyTasks = false;
  @Input() user: User | null = null;

  @Output() updatedTaskState = new EventEmitter<TaskEntity>();
  @Output() selectedTask = new EventEmitter<TaskEvent>();

  taskStates: Partial<TaskState>[] = [
    TaskState.Assigned,
    TaskState.Acknowledged,
    TaskState.Arrived,
    TaskState.Completed,
  ];

  taskClicked(taskEvent: TaskEvent) {
    if (taskEvent.Task.Label === SceptreLables.AirworthinessOPS.toString()) {
      return;
    }

    this.selectedTask.emit(taskEvent);
  }

  updateTaskState(task: TaskEntity) {
    this.updatedTaskState.emit(task);
  }
}
