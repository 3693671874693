import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AssignedMember,
  OnlineStatus,
  CreateTeamAssignedCrewMemberInput,
  User,
} from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'lm-apps-assigned-crew-detail',
  templateUrl: './assigned-crew-detail.component.html',
})
export class AssignedCrewDetailComponent {
  @Input() selectedCrewMembers!: AssignedMember[];
  @Input() allCrewMemberOnlineStatus: OnlineStatus[] = [];
  @Input() user: User | null = null;
  @Input() assignedMembersFromTask: AssignedMember[] = [];
  @Output() selectedCrewMemberChange =
    new EventEmitter<CreateTeamAssignedCrewMemberInput>();
  emitAddOrRemoveSelectedMechanic(crewMember: AssignedMember) {
    this.selectedCrewMemberChange.emit(crewMember);
  }
}
