import { Pipe, PipeTransform } from '@angular/core';
import { offsetDateTime } from '@lm-apps/lmo/shared/utilities';
@Pipe({
  name: 'dateOffset',
})
export class DateOffsetPipe implements PipeTransform {
  transform(date: Date, adj: number, format: string) {
    if (!date) return null;
    return offsetDateTime(date, adj, format);
  }
}
