import { TeamAssignment, CrewMember } from '@lm-apps/lmo/ui/data';

export interface CrewState {
  crews: TeamAssignment[];
  allCrewMembers: CrewMember[];
  selectedCrewId: string | null;
  selectedStation?: string;
  loaded: boolean;
  error: string | null;
}
