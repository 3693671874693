import { Pipe, PipeTransform } from '@angular/core';
import { FlightsEntity } from '@lm-apps/lmo/ui/data';
@Pipe({
  name: 'filterByNoseNumber',
})
export class FilterByNoseNumberPipe implements PipeTransform {
  transform(flights: FlightsEntity[], noseNumber: string): FlightsEntity[] {
    return noseNumber.trim().length < 3
      ? flights
      : flights.filter((f) =>
          f.Equipment.NoseNumber.toLowerCase().includes(
            noseNumber.toLowerCase()
          )
        );
  }
}
