<div *ngIf="departure">
  <div class="h3-1" data-cy="cc-view-dep-time">{{ departureTime }}</div>
  <strong
    [ngClass]="departure.DepartureDelayOrEarlyStatusCode"
    *ngIf="
      departure.DepartureDelayOrEarlyStatus &&
      departure.DepartureDelayOrEarlyStatusCode !== ''
    "
    data-cy="cc-view-dep-delay-or-early"
    >{{ departure.DepartureDelayOrEarlyStatus }}</strong
  >
  <div>
    <strong
      [ngClass]="departure.DepartureStatusCode | departureStatusCode"
      data-cy="cc-view-dep-status"
      >{{ departure.DepartureStatus | departureStatus }}</strong
    >
  </div>
  <div>
    <strong
      class="right-start-status aa-dark-blue"
      *ngIf="departure.IsRightStart"
      data-cy="cc-view-rs"
      >RS</strong
    >
  </div>
</div>
