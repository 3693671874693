import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromNotice from '@lm-apps/lmo/ui/data-access-notices';
import { Notice } from '@lm-apps/lmo/ui/data';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'lm-apps-notices-modal-container',
  templateUrl: './notices-modal-container.component.html',
})
export class NoticesModalContainerComponent {
  @Input() activeNotices: Notice[] | null = null;

  currentNoticeIndex = 0;

  constructor(private store: Store, public modalRef: BsModalRef) {}

  get currentNotice(): Notice | null {
    if (
      this.activeNotices &&
      this.currentNoticeIndex >= 0 &&
      this.currentNoticeIndex < this.activeNotices.length
    )
      return this.activeNotices[this.currentNoticeIndex];
    return null;
  }

  acknowledgeNotice() {
    if (this.currentNotice)
      this.store.dispatch(
        fromNotice.actions.acknowledgeNotice({ notice: this.currentNotice })
      );
    this.activeNotices &&
    this.currentNoticeIndex < this.activeNotices.length - 1
      ? this.currentNoticeIndex++
      : (this.currentNoticeIndex = -1);
  }
}
