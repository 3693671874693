import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class AutomaticHoldHistoryGQL extends Query<
  Pick<data.Query, 'AutomaticHoldHistory'>,
  data.QueryAutomaticHoldHistoryArgs
> {
  override document = gql`
    query AutomaticHoldHistory(
      $automaticHoldHistoryInput: AutomaticHoldHistoryInput!
    ) {
      AutomaticHoldHistory(
        automaticHoldHistoryInput: $automaticHoldHistoryInput
      ) {
        NoseNumber
        FlightNumber
        Station
        SourceKey
        TaskId
        NotificationId
        AutomaticHoldStatus
        OriginDate
        DepartureStation
        Created
      }
    }
  `;
}
