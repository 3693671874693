import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatToShortName',
})
export class FormatToShortNamePipe implements PipeTransform {
  transform(lastName: string, firstName: string) {
    lastName = lastName || '';
    firstName = firstName || '';
    const last = lastName.length > 3 ? lastName.substring(0, 3) : lastName;
    const first = firstName.length > 1 ? firstName.substring(0, 1) : firstName;
    if (last && first) {
      return `${last.toUpperCase()}, ${first.toUpperCase()}`;
    }
    if (last || first) {
      return last.toUpperCase() || first.toUpperCase();
    }
    return '';
  }
}
