import { Arrival, Departure } from './type-defs.model';

export type PairsHistory = {
  Label: string;
  NoseNumber?: string | null | undefined;
  SourceKey: string;
  IsClosed: boolean;
  Created: Date;
  Discrepancy: string;
  TaskId?: string | null | undefined;
  TaskState?: string | null | undefined;
  Arrival: Arrival | null | undefined;
  Departure: Departure | null | undefined;
  Id: string;
  ArrivalStatus: string;
  DepartureStatus: string;
  CallType: string;
  Status: string;
};
