import { Pipe, PipeTransform } from '@angular/core';
import { JobTypeFilterMode } from '@lm-apps/lmo/shared/enums';
import { KeyJobType, TaskEntity } from '@lm-apps/lmo/ui/data';

/**
 * A custom Angular pipe that filters a list of tasks based on their KeyJobType and filterType.
 *
 * - If `filterType` is `Exclude`, it returns tasks that do NOT match the provided KeyJobType.
 * - If `filterType` is `Include`, it returns tasks that match the provided KeyJobType.
 */
@Pipe({
  name: 'filterAllTaskByJobType',
})
export class FilterAllTasksByJobTypePipe implements PipeTransform {
  transform(
    allTasks: TaskEntity[],
    keyJobType: KeyJobType,
    filterMode = JobTypeFilterMode.Exclude
  ): TaskEntity[] {
    if (!allTasks.length) {
      return [];
    }
    return allTasks.filter((task) => {
      const taskKeyJobType = task.JobType?.KeyJobType;
      return filterMode === JobTypeFilterMode.Exclude
        ? taskKeyJobType !== keyJobType
        : taskKeyJobType === keyJobType;
    });
  }
}
