import { Filter, MaintenanceTaskInput } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Root] Init');

export const updateFilter = createAction(
  '[User] Update Filter',
  props<{ filter: Partial<Filter> }>()
);

export const saveAlternateTask = createAction(
  '[Alternate Tasks] Save Alternate Task',
  props<{ maintenanceTask: MaintenanceTaskInput }>()
);
