import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCrewManpower from './+state/crew-manpower.reducer';
import { ToastrModule } from 'ngx-toastr';
import { CREW_MANPOWER_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { CrewManpowerEffects } from './+state/crew-manpower.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CREW_MANPOWER_FEATURE_KEY, fromCrewManpower.reducer),
    EffectsModule.forFeature([CrewManpowerEffects]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 0,
      preventDuplicates: true,
      newestOnTop: false,
    }),
  ],
})
export class LmoUiDataAccessCrewManpowerModule {}
