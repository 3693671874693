import {
  JobTypeInput,
  KeyJobType,
  TaskJobTypeInput,
} from '@lm-apps/lmo/ui/data';
import { JobTypeDisplay } from '@lm-apps/lmo/shared/enums';

export const Discrepancy: TaskJobTypeInput = {
  Id: 1002,
  DisplayJobType: JobTypeDisplay.Discrepancy,
  KeyJobType: KeyJobType.Discrepency,
};

export const jobTypeInputMap = new Map<string, JobTypeInput>([
  [
    JobTypeDisplay.AcMoves,
    {
      Id: 1000,
      DisplayJobType: JobTypeDisplay.AcMoves,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.AcMove,
    },
  ],
  [
    JobTypeDisplay.DailyCheck,
    {
      Id: 1001,
      DisplayJobType: JobTypeDisplay.DailyCheck,
      IsActive: false,
      IsSelection: false,
      KeyJobType: KeyJobType.DailyCheck,
    },
  ],
  [
    JobTypeDisplay.Discrepancy,
    {
      Id: 1002,
      DisplayJobType: JobTypeDisplay.Discrepancy,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Discrepency,
    },
  ],
  [
    JobTypeDisplay.EtopsCheck,
    {
      Id: 1003,
      DisplayJobType: JobTypeDisplay.EtopsCheck,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.EtopsCheck,
    },
  ],
  [
    JobTypeDisplay.EtopsOilCheck,
    {
      Id: 1004,
      DisplayJobType: JobTypeDisplay.EtopsOilCheck,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.EtopsOilCheck,
    },
  ],
  [
    JobTypeDisplay.Hermes,
    {
      Id: 1005,
      DisplayJobType: JobTypeDisplay.Hermes,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Hermes,
    },
  ],
  [
    JobTypeDisplay.Arms,
    {
      Id: 1006,
      DisplayJobType: JobTypeDisplay.Arms,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Arms,
    },
  ],
  [
    JobTypeDisplay.Mg,
    {
      Id: 1007,
      DisplayJobType: JobTypeDisplay.Mg,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MeetGreet,
    },
  ],
  [
    JobTypeDisplay.Rsmg,
    {
      Id: 1008,
      DisplayJobType: JobTypeDisplay.Rsmg,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.RightStartMeetGreet,
    },
  ],
  [
    JobTypeDisplay.WakeUp,
    {
      Id: 1009,
      DisplayJobType: JobTypeDisplay.WakeUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.WakeUp,
    },
  ],
  [
    JobTypeDisplay.Rswu,
    {
      Id: 1010,
      DisplayJobType: JobTypeDisplay.Rswu,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.RightStartWakeUp,
    },
  ],
  [
    JobTypeDisplay.MelGeneral,
    {
      Id: 1011,
      DisplayJobType: JobTypeDisplay.MelGeneral,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MelGeneral,
    },
  ],
  [
    JobTypeDisplay.MelFollowUp,
    {
      Id: 1012,
      DisplayJobType: JobTypeDisplay.MelFollowUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MelFollowUp,
    },
  ],
  [
    JobTypeDisplay.MelQuickFix,
    {
      Id: 1013,
      DisplayJobType: JobTypeDisplay.MelQuickFix,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MelQuickFix,
    },
  ],
  [
    JobTypeDisplay.NefQuickFix,
    {
      Id: 1014,
      DisplayJobType: JobTypeDisplay.NefQuickFix,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.NefQuickFix,
    },
  ],
  [
    JobTypeDisplay.Other,
    {
      Id: 1015,
      DisplayJobType: JobTypeDisplay.Other,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Other,
    },
  ],
  [
    JobTypeDisplay.Servicing,
    {
      Id: 1016,
      DisplayJobType: JobTypeDisplay.Servicing,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Servicing,
    },
  ],
  [
    JobTypeDisplay.Check,
    {
      Id: 1017,
      DisplayJobType: JobTypeDisplay.Check,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Check,
    },
  ],
  [
    JobTypeDisplay.TransitCheck,
    {
      Id: 1018,
      DisplayJobType: JobTypeDisplay.TransitCheck,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.TransitCheck,
    },
  ],
  [
    JobTypeDisplay.ReservedItem,
    {
      Id: 1019,
      DisplayJobType: JobTypeDisplay.ReservedItem,
      IsActive: false,
      IsSelection: false,
      KeyJobType: KeyJobType.ReservedItem,
    },
  ],
  [
    JobTypeDisplay.MocRequest,
    {
      Id: 1020,
      DisplayJobType: JobTypeDisplay.MocRequest,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MocRequest,
    },
  ],
  [
    JobTypeDisplay.CubaFlight,
    {
      Id: 1021,
      DisplayJobType: JobTypeDisplay.CubaFlight,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Cuba,
    },
  ],
  [
    JobTypeDisplay.OPEN,
    {
      Id: 1026,
      DisplayJobType: JobTypeDisplay.OPEN,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Open,
    },
  ],
  [
    JobTypeDisplay.NEFFollowUp,
    {
      Id: 1022,
      DisplayJobType: JobTypeDisplay.NEFFollowUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.NefFollowUp,
    },
  ],
  [
    JobTypeDisplay.CDLFollowUp,
    {
      Id: 1023,
      DisplayJobType: JobTypeDisplay.CDLFollowUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.CdlFollowUp,
    },
  ],
  [
    JobTypeDisplay.TACFollowUp,
    {
      Id: 1024,
      DisplayJobType: JobTypeDisplay.TACFollowUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.TacFollowUp,
    },
  ],
  [
    JobTypeDisplay.MONFollowUp,
    {
      Id: 1025,
      DisplayJobType: JobTypeDisplay.MONFollowUp,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.MonFollowUp,
    },
  ],
  [
    JobTypeDisplay.BOW,
    {
      Id: 1027,
      DisplayJobType: JobTypeDisplay.BOW,
      IsActive: true,
      IsSelection: true,
      KeyJobType: KeyJobType.Bow,
    },
  ],
]);

export function jobTypeList(
  isMOC: boolean,
  showAll: boolean
): TaskJobTypeInput[] {
  return Array.from(jobTypeInputMap.values())
    .filter((value) => {
      return showAll ? true : value.IsActive;
    })
    .filter((value) => {
      if (isMOC) {
        return value.KeyJobType === KeyJobType.MocRequest;
      } else {
        return (
          value.KeyJobType !== KeyJobType.MocRequest &&
          value.KeyJobType !== KeyJobType.Cuba &&
          value.KeyJobType !== KeyJobType.Arms &&
          value.KeyJobType !== KeyJobType.MelFollowUp &&
          value.KeyJobType !== KeyJobType.MelGeneral &&
          value.KeyJobType !== KeyJobType.MelQuickFix &&
          value.KeyJobType !== KeyJobType.NefQuickFix &&
          value.KeyJobType !== KeyJobType.Bow
        );
      }
    })
    .map(
      (value) =>
        <TaskJobTypeInput>{
          DisplayJobType: value.DisplayJobType,
          Id: value.Id,
          KeyJobType: value.KeyJobType,
        }
    )
    .sort((a, b) =>
      (a.DisplayJobType ?? '').localeCompare(b.DisplayJobType ?? '')
    );
}
