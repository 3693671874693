import { Component, Input, OnChanges } from '@angular/core';
import {
  FlightsEntity,
  ItemType,
  FlightNotificationEntity,
} from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-message-list',
  templateUrl: './message-list.component.html',
})
export class MessageListComponent implements OnChanges {
  @Input() flight!: FlightsEntity;
  @Input()
  safeAircraftUri!: string;
  notifications!: Array<FlightNotificationEntity>;
  ItemType = ItemType;

  ngOnChanges() {
    this.notifications =
      this.flight?.Notifications?.filter(
        (not) => not.Type == ItemType.Hermes
      ) || [];
  }
}
