import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SidePanelLayoutService } from '@lm-apps/lmo/ui/common/feature-shared';
import {
  arrivalDate,
  arrivalTime,
  departureDate,
  departureTime,
} from '@lm-apps/lmo/shared/utilities';
import {
  SortDetail,
  SortOrder,
  ColumnName,
  TaskEvent,
  SelectedFlight,
  NotificationEvent,
  FlightsEntity,
  TeamAssignment,
  SelectedFlightData,
  DELAY_ALERT_LOCAL_STORAGE_KEY,
  FlightLegDetail,
  DeferralItemType,
  Crew,
} from '@lm-apps/lmo/ui/data';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewTaskComponent } from '../new-task/new-task/new-task.component';
import { DomHandler } from 'primeng/dom';
import { take } from 'rxjs';
import {
  flightsLoaderTheme,
  isMocUser,
} from '@lm-apps/lmo/ui/common/feature-shared';
import { DelayAlertComponent } from '../delay-alert/delay-alert.component';

dayjs.extend(utc);

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-flight-detail',
  templateUrl: './flight-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightDetailComponent implements OnInit, OnChanges {
  @Input()
  loaderTheme = flightsLoaderTheme;
  @Input()
  isFlightsLoaded = false;
  @Input()
  flightsPair: FlightsEntity[] = [];
  @Input() searchFlightNumber = '';
  @Input()
  searchNoseNumber = '';
  @Input()
  currentCrew: Crew | null = null;
  @Input()
  sortDetail!: SortDetail;
  @Input() roles!: string[];
  @Output()
  selectedFlightPair: EventEmitter<SelectedFlight> = new EventEmitter();
  @Output()
  sortFlightPair: EventEmitter<SortDetail> = new EventEmitter();

  @Input()
  maintHoldToolTip: string | null = '';
  @Input() defferalMels: DeferralItemType[] = [];

  @Output()
  selectedMaintHoldNose: EventEmitter<string> = new EventEmitter();
  @Output()
  unloadMaintHold: EventEmitter<void> = new EventEmitter();

  @Input()
  selectedRow!: SelectedFlightData;

  AIRCRAFT: ColumnName = ColumnName.AIRCRAFT;
  INBOUND: ColumnName = ColumnName.INBOUND;
  GATE: ColumnName = ColumnName.GATE;
  ARRIVAL: ColumnName = ColumnName.ARRIVAL;
  OUTBOUND: ColumnName = ColumnName.OUTBOUND;
  DEPARTURE: ColumnName = ColumnName.DEPATURE;
  NOTIFICATIONS: ColumnName = ColumnName.NOTIFICATIONS;
  UNASSIGNED: ColumnName = ColumnName.UNASSIGNED;
  ASSIGNED: ColumnName = ColumnName.ASSIGNED;

  ASC = SortOrder.ASCENDING;
  DESC = SortOrder.DESCENDING;

  modalRef!: BsModalRef;
  disableClickFlightPair = false;
  isMobile = false;

  arrivalDate = arrivalDate;
  arrivalTime = arrivalTime;
  departureDate = departureDate;
  departureTime = departureTime;

  constructor(
    @Inject(PING_APP_ENVIRONMENT) private config: AppEnvironment,
    private modalService: BsModalService,
    private layoutService: SidePanelLayoutService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const flightPairChanges = changes['flightsPair'];
    const selectedRowChanges = changes['selectedRow'];
    const crewChanges = changes['currentCrew'];

    if (!crewChanges && !selectedRowChanges?.currentValue) {
      this.layoutService.closeSidePanel();
    }

    if (
      flightPairChanges &&
      flightPairChanges.currentValue.length &&
      isMocUser(this.roles)
    ) {
      this.openDelayAlertModal(flightPairChanges.currentValue);
      this.clearDelayAlertFromLocal();
    }
  }

  ngOnInit() {
    this.isMobile = DomHandler.isTouchDevice();
  }

  clickFlightPair(flight: FlightsEntity) {
    if (!this.disableClickFlightPair) {
      const selectedFlight: SelectedFlight = {
        FlightHash: flight.Hash,
      };
      this.selectedFlightPair.emit(selectedFlight);
    }
  }

  clickNotification(
    notificationEvent: NotificationEvent,
    flight: FlightsEntity
  ) {
    notificationEvent.event.stopPropagation();
    this.selectedFlightPair.emit({
      FlightHash: flight.Hash,
      FlightNotificationId: notificationEvent.Notification.Id,
    });
  }

  clickTask(taskEvent: TaskEvent, flight: FlightsEntity) {
    taskEvent.event.stopPropagation();
    this.selectedFlightPair.emit({
      FlightHash: flight.Hash,
      FlightNotificationId: taskEvent.Task.NotificationId,
      TaskId: taskEvent.Task.Id,
    });
  }

  openNewTaskModal(flight: FlightsEntity) {
    const fld: FlightLegDetail = {
      AircraftId: flight.Equipment.Id,
      ArrivalStation: flight.Arrival
        ? flight.Arrival.ArrivalStation ?? ''
        : flight.Departure?.DepartureStation ?? '',
      ArrivalId: flight.Arrival?.Id || null,
      DepartureId: flight.Arrival?.Id ? null : flight.Departure?.Id,
      DepartureStation: flight.Arrival
        ? flight.Arrival.DepartureStation ?? ''
        : flight.Departure?.DepartureStation,
      NoseNumber: flight.Equipment.NoseNumber,
      AirlineCode: flight.Equipment.AirlineCode,
    };

    this.modalRef = this.modalService.show(NewTaskComponent, {
      class: 'modal-dialog modal-xl',
      ignoreBackdropClick: true,
      initialState: {
        flight: fld,
        crew: this.currentCrew,
        isMOC: isMocUser(this.roles),
      },
    });
    this.disableClickFlightPair = true;
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.disableClickFlightPair = false;
    });
  }
  trackByFunction = (index: number, item: FlightsEntity) => {
    return item.Hash;
  };

  sortData(current: ColumnName) {
    const order =
      current !== this.sortDetail.column
        ? SortOrder.ASCENDING
        : this.sortDetail.sortOrder === SortOrder.DESCENDING
        ? SortOrder.ASCENDING
        : SortOrder.DESCENDING;

    const sortDetail: SortDetail = {
      column: current,
      sortOrder: order,
    };
    this.sortFlightPair.emit(sortDetail);
  }

  loadMaintHoldMics(noseNumber: string) {
    this.disableClickFlightPair = true;
    if (noseNumber && noseNumber != '') {
      this.maintHoldToolTip = 'loading';
      this.selectedMaintHoldNose.emit(noseNumber);
    }
    this.disableClickFlightPair = false;
  }

  unloadMaintHoldMics() {
    this.maintHoldToolTip = '';
    this.unloadMaintHold.emit();
  }

  openDelayAlertModal(flightsPair: FlightsEntity[]) {
    let toggleHeaderBgColor = false;
    flightsPair.forEach((fp) => {
      if (
        !fp.Departure?.ActualOut &&
        fp.Departure?.ScheduledDeparture &&
        fp.Departure?.DepartureDelayOrEarlyStatus &&
        fp.Departure.DepartureDelayOrEarlyStatus.includes('DLY') &&
        dayjs().utc().diff(fp.Departure.ScheduledDeparture, 'minutes') === 30 &&
        !this.isDelayAlertAlreadyShown(fp.Hash)
      ) {
        this.modalRef = this.modalService.show(DelayAlertComponent, {
          class: 'delay-alert-modal',
          ignoreBackdropClick: true,
          initialState: {
            flightPair: fp,
            toggleHeaderBgColor: toggleHeaderBgColor,
          },
        });
        toggleHeaderBgColor = !toggleHeaderBgColor;
        this.setDelayAlertFromLocal(fp.Hash);
      }
    });
  }

  setDelayAlertFromLocal(hash: string) {
    const now = dayjs().utc().toISOString();
    let hashMap = this.getDelayAlertFromLocal();
    hashMap = hashMap ? hashMap : new Map<string, string>();
    if (!hashMap.has(hash)) {
      hashMap.set(hash, now);
    }
    localStorage.setItem(
      DELAY_ALERT_LOCAL_STORAGE_KEY,
      JSON.stringify(Array.from(hashMap.entries()))
    );
  }
  getDelayAlertFromLocal() {
    const serializedValue = localStorage.getItem(DELAY_ALERT_LOCAL_STORAGE_KEY);
    return serializedValue
      ? new Map<string, string>(JSON.parse(serializedValue))
      : null;
  }
  isDelayAlertAlreadyShown(hash: string) {
    const delayAlert = this.getDelayAlertFromLocal();
    return delayAlert ? delayAlert.has(hash) : false;
  }
  clearDelayAlertFromLocal() {
    const delayAlert = this.getDelayAlertFromLocal();
    if (delayAlert) {
      delayAlert.forEach((value, key) => {
        if (dayjs(value).utc().diff(dayjs().utc(), 'minutes') > 2) {
          delayAlert.delete(key);
        }
      });
      localStorage.setItem(
        DELAY_ALERT_LOCAL_STORAGE_KEY,
        JSON.stringify(Array.from(delayAlert.entries()))
      );
    }
  }
}
