import { Component } from '@angular/core';
import { TaskDetailComponent } from '../task-detail/task-detail-main/task-detail.component';
import { TaskState } from '@lm-apps/lmo/ui/data';
@Component({
  selector: 'lm-apps-lmo-ui-bow-task-detail',
  templateUrl: './bow-task-detail.component.html',
})
export class BowtaskDetailComponent extends TaskDetailComponent {
  public readonly taskStateValues = TaskState;
}
