import { SortableCrewColumn, ColumnName, SortOrder } from '../enums/index';

export interface SortDetail {
  column: ColumnName;
  sortOrder: SortOrder;
}

export interface SortCrewState {
  column: SortableCrewColumn;
  sortOrder: SortOrder;
}
