import { pojos, PojosMetadataMap } from '@automapper/pojos';
import { createMapper, createMap, forMember, mapFrom } from '@automapper/core';
import {
  Task,
  TaskEntity,
  FlightNotification,
  FlightNotificationEntity,
  Flight,
  FlightsEntity,
  BillOfWorkDetails,
  AssignedMember,
  CallType,
  JobType,
  Priority,
  SpecialInstruction,
  ExtendedFlightAttributes,
  Arrival,
  Departure,
  Equipment,
  NotificationTaskEntity,
} from '@lm-apps/lmo/ui/data';
import { getBackgroundRowColor } from '../util';
export const assignedMember_symbol = 'AssignedMember';
export const assignedMemberEntity_symbol = 'AssignedMemberEntity';

export const billOfWorkDetails_symbol = 'BillOfWorkDetails';
export const billOfWorkDetailsEntity_symbol = 'BillOfWorkDetailsEntity';

export const callType_symbol = 'CallType';
export const jobType_symbol = 'JobType';
export const priority_symbol = 'Priority';
export const specialInstruction_symbol = 'SpecialInstruction';
export const extendedFlightAttributes_symbol = 'ExtendedFlightAttributes';

export const arrival_symbol = 'Arrival';
export const departure_symbol = 'Departure';
export const equipment_symbol = 'Equipment';
export const task_symbol = 'Task';
export const taskEntity_symbol = 'TaskEntity';
export const notificationTaskEntity_symbol = 'NotificationTaskEntity';

export const notification_symbol = 'Notification';
export const notificationEntity_symbol = 'NotificationEntity';
export const flight_symbol = 'Flight';
export const flightEntity_symbol = 'FlightEntity';
export function getFlightsMapper() {
  const mapper = createMapper({
    strategyInitializer: pojos(),
  });

  PojosMetadataMap.create<AssignedMember>(assignedMember_symbol, {
    __typename: String,
    AcknowledgedDepartureAlert: Date,
    AcknowledgedOn: Date,
    ArrivedOn: Date,
    AssignedBy: String,
    AssignedByName: String,
    AssignedOn: Date,
    ChangedBy: String,
    ChangedOn: Date,
    CompletedOn: Date,
    EmployeeId: String,
    FirstName: String,
    LastName: String,
    Status: String,
  });
  PojosMetadataMap.create<BillOfWorkDetails>(billOfWorkDetails_symbol, {
    __typename: String,
    IsBowCompleted: Boolean,
    ScheduledDate: Date,
    Status: String,
    WorkControlNumber: String,
  });

  PojosMetadataMap.create<CallType>(callType_symbol, {
    __typename: String,
    DisplayCallType: String,
    Id: Number,
    IsActive: Boolean,
    KeyCallType: String, //https://automapperts.netlify.app/docs/strategies/pojos#enum
  });

  PojosMetadataMap.create<JobType>(jobType_symbol, {
    __typename: String,
    DisplayJobType: String,
    Id: Number,
    IsActive: Boolean,
    IsSelection: Boolean,
    KeyJobType: String, //https://automapperts.netlify.app/docs/strategies/pojos#enum
  });

  PojosMetadataMap.create<Priority>(priority_symbol, {
    __typename: String,
    Description: String,
    Order: Number,
  });

  PojosMetadataMap.create<SpecialInstruction>(specialInstruction_symbol, {
    __typename: String,
    Created: Date,
    EmployeeId: String,
    EmployeeName: String,
    Instructions: String,
  });

  PojosMetadataMap.create<ExtendedFlightAttributes>(
    extendedFlightAttributes_symbol,
    {
      __typename: String,
      ArmCode: String,
      Classification: String,
      Description: String,
      MelNumber: String,
      MicNumber: String,
      ReferenceNumber: String,
      ScheduledDate: Date,
      WorkControlNumber: String,
    }
  );

  PojosMetadataMap.create<Arrival>(arrival_symbol, {
    __typename: String,
    ActualArrival: Date,
    ActualIn: Date,
    ActualOn: Date,
    ArrivalDelayOrEarlyStatus: String,
    ArrivalDelayOrEarlyStatusCode: String,
    ArrivalGate: String,
    ArrivalStation: String,
    ArrivalStatus: String,
    ArrivalStatusCode: String,
    ArrivalUtcAdjustment: Number,
    DepartureStation: String,
    EstimatedArrival: Date,
    EtopsCheckCompleted: Boolean,
    FlightNumber: String,
    Hash: String,
    Id: String,
    IsEtopsFlight: Boolean,
    LegOriginDate: String,
    LegStatus: String,
    ScheduledArrival: Date,
  });

  PojosMetadataMap.create<Departure>(departure_symbol, {
    __typename: String,
    ActualDeparture: Date,
    ActualOff: Date,
    ActualOut: Date,
    ArrivalStation: String,
    DepartureDelayOrEarlyStatus: String,
    DepartureDelayOrEarlyStatusCode: String,
    DepartureGate: String,
    DepartureStation: String,
    DepartureStatus: String,
    DepartureStatusCode: String,
    DepartureUtcAdjustment: Number,
    DuplicateDepartureNumber: Number,
    EstimatedDeparture: Date,
    EtopsCheckCompleted: Boolean,
    FlightNumber: String,
    Hash: String,
    Id: String,
    IsEtopsFlight: Boolean,
    IsRightStart: Boolean,
    LegOriginDate: String,
    LegStatus: String,
    ScheduledDeparture: Date,
  });

  PojosMetadataMap.create<Equipment>(equipment_symbol, {
    __typename: String,
    AirlineCode: String,
    AutomaticMH: Boolean,
    DeliveryAirline: String,
    EngineType: String,
    EstimatedReturnToService: Date,
    FleetSubType: String,
    FleetType: String,
    Id: String,
    IsActive: Boolean,
    IsDeleted: Boolean,
    IsEAMLEnabled: Boolean,
    IsEtopsCertified: Boolean,
    IsMaintenanceHold: Boolean,
    IsOutOfService: Boolean,
    MaintenanceHoldUpdateTime: Date,
    Manufacturer: String,
    NoseNumber: String,
    RONFlightId: String,
    RONStation: String,
    RegistrationNumber: String,
    Remark: String,
  });

  PojosMetadataMap.create<Task>(task_symbol, {
    __typename: String,
    AircraftId: String,
    AssignedMembers: [assignedMember_symbol],
    BowDetails: billOfWorkDetails_symbol,
    CallType: callType_symbol,
    ChangedBy: String,
    ChangedByName: String,
    ChangedOn: Date,
    CompletedOn: Date,
    Created: Date,
    CustomNotificationType: String,
    DeferredReason: String,
    Description: String,
    Effectivity: String,
    ExternalItemId: String,
    Id: String,
    InboundFlightLegId: String,
    InternalItemId: String,
    IsAlternateTask: Boolean,
    IsAvionic: Boolean,
    IsManualTask: Boolean,
    IsNfnf: Boolean,
    IsOilService: Boolean,
    IsOspCheck: Boolean,
    JobType: jobType_symbol,
    Label: String,
    Location: String,
    MaintenancePriority: priority_symbol,
    MaintenanceType: String,
    NotificationId: String,
    OutBoundFlightLegId: String,
    SpecialInstructions: [specialInstruction_symbol],
    Station: String,
    TaskState: String,
    TaskUserReferenceId: Number,
    To: String,
    ToEmployeeId: String,
    UserDefinedItemId: String,
    UserId: String,
    UserName: String,
  });

  PojosMetadataMap.create<NotificationTaskEntity>(
    notificationTaskEntity_symbol,
    {
      Id: String,
      AircraftId: String,
      InboundFlightLegId: String,
    }
  );

  PojosMetadataMap.create<TaskEntity>(taskEntity_symbol, {
    __typename: String,
    AircraftId: String,
    AssignedMembers: [assignedMember_symbol],
    BowDetails: billOfWorkDetails_symbol,
    CallType: callType_symbol,
    ChangedBy: String,
    ChangedByName: String,
    ChangedOn: Date,
    CompletedOn: Date,
    Created: Date,
    CustomNotificationType: String,
    DeferredReason: String,
    Description: String,
    Effectivity: String,
    ExternalItemId: String,
    Id: String,
    InboundFlightLegId: String,
    InternalItemId: String,
    IsAlternateTask: Boolean,
    IsAvionic: Boolean,
    IsManualTask: Boolean,
    IsNfnf: Boolean,
    IsOilService: Boolean,
    IsOspCheck: Boolean,
    JobType: jobType_symbol,
    Label: String,
    Location: String,
    MaintenancePriority: priority_symbol,
    MaintenanceType: String,
    NotificationId: String,
    OutBoundFlightLegId: String,
    SpecialInstructions: [specialInstruction_symbol],
    Station: String,
    TaskState: String,
    TaskUserReferenceId: Number,
    To: String,
    ToEmployeeId: String,
    UserDefinedItemId: String,
    UserId: String,
    UserName: String,
    //new added properties
    IsPending: Boolean,
    IsNew: Boolean,
    RealTimeNotification: Boolean,
  });

  PojosMetadataMap.create<FlightNotification>(notification_symbol, {
    __typename: String,
    AircraftHash: String,
    AircraftId: String,
    AirlineCode: String,
    AssignedToNames: [assignedMember_symbol],
    ChangedOn: Date,
    Created: Date,
    CustomNotificationType: String,
    Description: String,
    Discrepancy: String,
    DisplayName: String,
    Effectivity: Date,
    ExtendedAttributes: extendedFlightAttributes_symbol,
    FlighttHash: String,
    ForecastDate: Date,
    HasTask: Boolean,
    Id: String,
    InBoundFlightLegId: String,
    IsClosed: Boolean,
    IsDeleted: Boolean,
    ItemClosed: Date,
    ItemDeleted: Date,
    Label: String,
    NoseNumber: String,
    OutBoundFlightLegId: String,
    Priority: priority_symbol,
    PriorityName: String,
    ProcessDate: Date,
    SourceKey: String,
    SourceSystem: String,
    SourceType: String,
    Status: String,
    Task: task_symbol,
    Type: String,
  });

  PojosMetadataMap.create<FlightNotificationEntity>(notificationEntity_symbol, {
    __typename: String,
    AircraftHash: String,
    AircraftId: String,
    AirlineCode: String,
    AssignedToNames: [assignedMember_symbol],
    ChangedOn: Date,
    Created: Date,
    CustomNotificationType: String,
    Description: String,
    Discrepancy: String,
    DisplayName: String,
    Effectivity: Date,
    ExtendedAttributes: extendedFlightAttributes_symbol,
    FlighttHash: String,
    ForecastDate: Date,
    HasTask: Boolean,
    Id: String,
    InBoundFlightLegId: String,
    IsClosed: Boolean,
    IsDeleted: Boolean,
    ItemClosed: Date,
    ItemDeleted: Date,
    Label: String,
    NoseNumber: String,
    OutBoundFlightLegId: String,
    Priority: priority_symbol,
    PriorityName: String,
    ProcessDate: Date,
    SourceKey: String,
    SourceSystem: String,
    SourceType: String,
    Status: String,
    Type: String,
    //newly added properties
    Task: taskEntity_symbol,
    IsPending: Boolean,
  });

  PojosMetadataMap.create<Flight>(flight_symbol, {
    __typename: String,
    AllTasks: [task_symbol],
    Arrival: arrival_symbol,
    Departure: departure_symbol,
    Equipment: equipment_symbol,
    GroundTime: String,
    Hash: String,
    Notifications: [notification_symbol],
    Station: String,
  });

  PojosMetadataMap.create<FlightsEntity>(flightEntity_symbol, {
    __typename: String,
    AllTasks: [taskEntity_symbol],
    Arrival: arrival_symbol,
    Departure: departure_symbol,
    Equipment: equipment_symbol,
    GroundTime: String,
    Hash: String,
    Notifications: [notificationEntity_symbol],
    Station: String,
  });

  createMap<Task, TaskEntity>(
    mapper,
    task_symbol,
    taskEntity_symbol,
    forMember(
      (d) => d.IsPending,
      mapFrom((s) => false) //TODO: default value
    ),
    forMember(
      (d) => d.IsNew,
      mapFrom((s) => false) // TODO: Discuss the default value
    ),
    forMember(
      (d) => d.RealTimeNotification,
      mapFrom((s) => false)
    )
  );

  createMap<Task, NotificationTaskEntity>(
    mapper,
    task_symbol,
    notificationTaskEntity_symbol
  );

  createMap<FlightNotification, FlightNotificationEntity>(
    mapper,
    notification_symbol,
    notificationEntity_symbol,
    forMember(
      (d) => d.IsPending,
      mapFrom((s) => false) // default value
    )
  );

  createMap<Flight, FlightsEntity>(
    mapper,
    flight_symbol,
    flightEntity_symbol,
    //HasDepartureToast: boolean;
    //BackgroundRowColor: string;

    forMember(
      (d) => d.HasDepartureToast,
      mapFrom((s) => getBackgroundRowColor(s).hasDepartureToast)
    ),
    forMember(
      (d) => d.BackgroundRowColor,
      mapFrom((s) => getBackgroundRowColor(s).backgroundRowColor) // TODO: check if i can make it in 1 call. check docs
    )
    // ,forMember(
    //   (d) => d.Departure?.IsRightStart,
    //   mapFrom((s) => isRightStartFlight(s)) // TODO: check if this works
    // )
  );
  return mapper;
}
