<section>
  <lm-apps-crew-manager
    [allCrews]="allCrews$ | async | crewsAdjustment: (currentCrew$ | async)?.Id"
    [stations]="stations$ | async"
    [currentCrew]="currentCrew$ | async"
    [currentUser]="currentUser$ | async"
    [selectedStation]="defaultStation$ | async"
    [allCrewMembers]="(allCrewMembers$ | async) || []"
    [allCrewMemberOnlineStatus]="(allCrewMemberOnlineStatus$ | async) || []"
    (closeEvent)="closeModal()"
    (updateStationEvent)="loadDataForModal($event)"
    (createCrewEvent)="createCrew($event)"
    (updateCrewEvent)="updateCrew($event)"
    (saveCrewEvent)="saveSharedCrew($event)"
    (deleteCrewEvent)="deleteCrew($event)"
    (shareCrewEvent)="shareCrew($event)"
    (ownershipEvent)="changeOwnership($event)"
  ></lm-apps-crew-manager>
</section>
