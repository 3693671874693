import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLabelPrefix',
  pure: true,
})
export class GetLabelPrefixPipe implements PipeTransform {
  transform(classNames: string): string {
    if (classNames === null || classNames === undefined) {
      return '';
    }
    let classArray: string[] = [];
    classArray = classNames.split(' ');
    const className = classArray[0] + '.' + classArray[1];
    let prefix = '';
    switch (className) {
      case 'deferral.qfx':
        prefix = 'Q';
        break;

      case 'deferral.qdaily':
        prefix = 'QD';
        break;

      case 'deferral.qcycle':
        prefix = 'QF';
        break;

      case 'deferral.daily':
        prefix = 'D';
        break;

      case 'deferral.cycle':
        prefix = 'F';
        break;

      case 'deferral.melr':
        prefix = 'R';
        break;

      case 'deferral.melci':
        prefix = 'CI';
        break;

      case 'deferral.melf':
        prefix = 'F';
        break;

      case 'deferral.melfr':
        prefix = 'FR';
        break;

      case 'deferral.melfci':
        prefix = 'FCI';
        break;

      case 'deferral.meld':
        prefix = 'D';
        break;

      case 'deferral.meldr':
        prefix = 'DR';
        break;

      case 'deferral.meldci':
        prefix = 'DCI';
        break;

      case 'deferral.melq':
        prefix = 'Q';
        break;

      case 'deferral.melqr':
        prefix = 'QR';
        break;

      case 'deferral.melqci':
        prefix = 'QCI';
        break;

      case 'deferral.melqf':
        prefix = 'QF';
        break;

      case 'deferral.melqfri':
        prefix = 'QFRI';
        break;

      case 'deferral.melqfci':
        prefix = 'QFCI';
        break;

      case 'deferral.melqd':
        prefix = 'QD';
        break;

      case 'deferral.melqdr':
        prefix = 'QDR';
        break;

      case 'deferral.melqcid':
        prefix = 'QCID';
        break;
    }

    return prefix;
  }
}
