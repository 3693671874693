import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFlight from './+state/flight.reducer';
import { FlightEffects } from './+state/effects/flight.effects';
import { RegularTaskEffects } from './+state/effects/regular-task.effects';
import { FLIGHT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FLIGHT_FEATURE_KEY, fromFlight.reducer),
    EffectsModule.forFeature([FlightEffects, RegularTaskEffects]),
  ],
})
export class LmoUiDataAccessFlightModule {}
