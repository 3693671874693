/**
 * Misc Filters
 */
export enum MiscFilter {
  /**
   * SHOW_ALL_AC
   */
  SHOW_ALL_AC = 'All',
  /**
   * ETOPS_AC
   */
  ETOPS_AC = 'ETOPS',
  /**
   * ETOPS_FLIGHTS
   */
  ETOPS_FLIGHTS = 'ETOPSF',
  /**
   * MISMATCH_GATES
   */
  MISMATCH_GATES = 'MismatchGates',
  /**
   * RIGHT_START_FLIGHTS
   */
  RIGHT_START_FLIGHTS = 'RightStart',
  /**
   * SHOW_AOS_AC
   */
  SHOW_AOS_AC = 'OTS',
  /**
   * Hide_AOS_AC
   */
  HIDE_AOS_AC = 'HideOTS',
  /**
   * SHOW_EAML_ENABLED_AC
   */
  SHOW_EAML_ENABLED_AC = 'ShowEamlAC',
  /**
   * TURN_TIME_60
   */
  TURN_TIME_60 = 'TT60',
  /**
   * TURN_TIME_45
   */
  TURN_TIME_45 = 'TT45',
  /**
   * HIDE_UNLINKED_AC
   */
  HIDE_UNLINKED_AC = 'UnlinkedAC',
  /**
   * HIDE_UNLINKED_WITHOUT_NOTIFICATIONS
   */
  HIDE_UNLINKED_WITHOUT_NOTIFICATIONS = 'UnlinkedNoNotification',
}
