<div class="flt-tasks">
  <div class="title-txt bg-shade-gray">Tasks</div>
  <hr class="cc-hr" />
  <table class="table table-condensed">
    <thead>
      <tr>
        <th>Task</th>
        <th>Instructions</th>
        <th>Task Type</th>
        <th>Call Type</th>
        <th>Avionic</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr
        *ngFor="let task of tasks | orderBy: 'MaintenancePriority.Order'"
        class="text-clickable"
        (click)="taskClicked({ event: $event, Task: task })"
      >
        <td>
          <lm-apps-task-item
            [isRhs]="isRhs"
            [task]="task"
            (selectedTask)="taskClicked($event)"
          ></lm-apps-task-item>
        </td>
        <td>{{ task.SpecialInstructions[0]?.Instructions || '' }}</td>
        <td>{{ task.JobType.DisplayJobType }}</td>
        <td>{{ task.CallType.DisplayCallType }}</td>
        <td>{{ task.IsAvionic ? 'Yes' : 'No' }}</td>
        <td *ngIf="!isMyTasks">{{ (task.TaskState | TaskState) || '-' }}</td>
        <td *ngIf="isMyTasks">
          <lm-apps-lmo-ui-task-status
            [task]="task"
            [employeeId]="user?.EmployeeId ?? null"
            (updatedTaskState)="updateTaskState($event)"
          >
          </lm-apps-lmo-ui-task-status>
        </td>
      </tr>
    </tbody>
  </table>
</div>
