import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromHistory from './+state/history.reducer';
import { HistoryEffects } from './+state/history.effects';
import { HISTORY_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(HISTORY_FEATURE_KEY, fromHistory.reducer),
    EffectsModule.forFeature([HistoryEffects]),
  ],
})
export class LmoUiDataAccessHistoryModule {}
