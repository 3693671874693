import { QuickFix } from '@lm-apps/lmo/shared/enums';
import { Discrepancy, SystemGenerated } from '@lm-apps/lmo/shared/master';
import {
  AdditionalTaskDetail,
  Aircraft,
  AlternateTask,
  ItemType,
  MaintenanceTaskDisplay,
  MaintenanceTaskInput,
  TaskAction,
  TaskState,
} from '@lm-apps/lmo/ui/data';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function UpdateAlternateTaskObj(
  task?:
    | (AlternateTask & {
        Aircraft?: Aircraft;
      })
    | null
    | undefined
): MaintenanceTaskDisplay {
  const maintenanceTask: MaintenanceTaskInput = {
    Aircraft: task?.Aircraft
      ? {
          NoseNumber: task.Aircraft.NoseNumber,
          AirlineCode: task.Aircraft.AirlineCode,
        }
      : null,
    AircraftId: task?.AircraftId,
    AssignedToIds: task?.AssignedMembers?.map((x) => x.EmployeeId) || [],
    CallType: {
      DisplayCallType:
        task?.CallType.DisplayCallType ?? SystemGenerated.DisplayCallType,
      KeyCallType: task?.CallType.KeyCallType ?? SystemGenerated.KeyCallType,
    },
    DeferredReason: task?.DeferredReason ?? '',
    Description: task?.Description ?? '',
    IataCode: task?.Station ?? '',
    InboundFlightDesignator: null,
    InboundFlightLegId: null,
    IsAlternateTask: true,
    IsAvionic: task?.IsAvionic ?? false,
    IsManualTask: true,
    IsOilService: task?.IsOilService ?? false,
    JobType: {
      DisplayJobType:
        task?.JobType.DisplayJobType ?? Discrepancy.DisplayJobType,
      KeyJobType: task?.JobType.KeyJobType ?? Discrepancy.KeyJobType,
    },
    JsonAttribute: null,
    Label: task?.Label,
    Location: task?.Location ?? null,
    MaintenanceType: task?.MaintenanceType ?? ItemType.Undefined,
    IsNfnf: task?.IsNfnf ?? false,
    SpecialInstruction: task?.SpecialInstructions[0]?.Instructions ?? '',
    TaskState: task?.TaskState ?? TaskState.Unassigned,
    PredefinedTask: undefined,
    NotificationId: task?.NotificationId,
    MaintenancePriority: {
      Description: task?.MaintenancePriority?.Description ?? '',
    },
    TaskId: task?.Id ?? null,
    UserId: task?.UserId ?? null,
    IsUpdate: true,
    TaskAction: TaskAction.Update,
  };

  const quickFixValues: string[] = Object.values(QuickFix);

  // sort descending by created date
  const specialInstructions = (task?.SpecialInstructions ?? [])
    .slice()
    .sort((a, b) => (dayjs(a.Created) > dayjs(b.Created) ? -1 : 1));

  const add: AdditionalTaskDetail = {
    HasTask: task ? true : false,
    IsQuickFix: !!(
      task?.MaintenancePriority?.Description &&
      quickFixValues.includes(task.MaintenancePriority.Description)
    ),
    IsOpsOrRobOrRem:
      task?.MaintenanceType === ItemType.Ops ||
      task?.MaintenanceType === ItemType.Rob ||
      task?.MaintenanceType === ItemType.Rem,
    SpecialInstructions: specialInstructions,
    AssignedMembers: task?.AssignedMembers || [],
    UserName: task?.UserName ?? '',
    ChangedOn: task?.ChangedOn || dayjs().utc().toDate(),
    IsBowTaskCreatedOnTheFly:
      task?.BowDetails?.IsBowTaskCreatedOnTheFly ?? false,
  };
  const maint: MaintenanceTaskDisplay = {
    MaintenanceTaskInput: maintenanceTask,
    AdditionalTaskDetail: add,
  };
  return maint;
}
