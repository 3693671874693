import { DeferralItemType } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const deferralMelInit = createAction('[Deferral Mel] Init');
export const loadDeferralMelSuccess = createAction(
  '[Deferral Mel] Load Mel success',
  props<{ deferralMels: DeferralItemType[] }>()
);

export const throwError = createAction(
  '[Immutable] API request failed',
  props<{ error: any }>()
);
