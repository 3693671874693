import {
  FlightNotificationEntity,
  FlightsEntity,
  TaskEntity,
} from './flights.model';

export interface SelectedFlightData {
  flight: FlightsEntity;
  notification?: FlightNotificationEntity;
  task?: TaskEntity;
}
