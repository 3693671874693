import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './crew-manpower.actions';
import {
  ManpowerCrewService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class CrewManpowerEffects {
  constructor(
    private readonly actions$: Actions,
    private service: ManpowerCrewService,
    private toastService: CustomToastrService
  ) {}

  getCrewManpower$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => {
        return this.service.getMyTeam().pipe(
          map((crews) => {
            return actions.loadCrewManpowerSuccess({ crews });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load crew. Please try again later.'
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );
}
