/**
 * Delays Filters
 */
export enum DelaysFilter {
  /**
   * SHOW_ALL_AC
   */
  SHOW_ALL_AC = 'All',
  /**
   * ARRIVALS
   */
  ARRIVALS = 'arrivals',
  /**
   * DEPARTURES
   */
  DEPARTURES = 'departures',
}
