import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUsers from './+state/user.reducer';
import { UserEffects } from './+state/user.effects';
import { USER_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USER_FEATURE_KEY, fromUsers.reducer),
    EffectsModule.forFeature([UserEffects]),
  ],
})
export class LmoUiDataAccessUserModule {}
