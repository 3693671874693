import { AIRCRAFT_FEATURE_KEY, ActiveAircraft } from '@lm-apps/lmo/ui/data';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './aircraft.actions';
export interface State extends EntityState<ActiveAircraft> {
  selectedId?: string | number; // which Flight record has been selected
  loaded: boolean; // has the Flight list been loaded
  error?: string | null; // last known error (if any)
}

export interface AircraftPartialState {
  readonly [AIRCRAFT_FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<ActiveAircraft> =
  createEntityAdapter<ActiveAircraft>({
    selectId: (aircraft: ActiveAircraft) => aircraft.NoseNumber,
  });

export const initialState: State = adapter.getInitialState({
  // set initial required properties
  ids: [],
  entities: Array<ActiveAircraft>(),
  selectedId: undefined,
  loaded: false,
  error: null,
});

const aircraftReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(actions.loadAircraftSuccess, (state, { aircrafts }) =>
    adapter.setAll(aircrafts, { ...state, loaded: true })
  ),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
  }))
);

export const getSelectedAircraftId = (state: State) => state.selectedId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of aircraft ids
export const selectAircraftIds = selectIds;

// select the dictionary of aircraft entities
export const selectAircraftEntities = selectEntities;

// select the array of aircrafts
export const selectAllAircrafts = selectAll;

// select the total aircraft count
export const selectAircraftTotal = selectTotal;

export function reducer(state: State | undefined, action: Action) {
  return aircraftReducer(state, action);
}
