import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DepartureStatusCode, Departure } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-departure',
  templateUrl: './departure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepartureComponent {
  @Input() departure!: Departure;
  @Input() departureTime!: string;
  DepartureStatusCode = DepartureStatusCode;
}
