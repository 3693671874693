import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class CreateManualTaskGQL extends Mutation<
  Pick<data.Mutation, 'CreateManualTask'>,
  data.MutationCreateManualTaskArgs
> {
  override document = gql`
    mutation CreateManualTask($maintenanceTask: MaintenanceTaskInput!) {
      CreateManualTask(maintenanceTask: $maintenanceTask) {
        TaskId
      }
    }
  `;
}
