import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemType } from '@lm-apps/lmo/ui/data';
import { Notification } from '@lm-apps/lmo/wall/shared';
import { faTint } from '@fortawesome/free-solid-svg-icons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lmo-maintenance-item',
  templateUrl: './maintenance-item.component.html',
  styleUrls: ['./maintenance-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceItemComponent {
  @Input()
  notification: Notification = <Notification>{};
  ItemType = ItemType;
  faTint = faTint;
}
