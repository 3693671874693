import { JobTypeDisplay } from '@lm-apps/lmo/shared/enums';

export interface PriorityValue {
  cssClass?: string;
  name?: string;
  DeferralDescription?: string;
}

export const PriorityCssClasses: { [key: string]: PriorityValue } = {
  QFLT: { cssClass: 'qcycle', name: JobTypeDisplay.MelFollowUp },
  // 1000: { cssClass: 'qcycle' }

  FLT: { cssClass: 'cycle', name: JobTypeDisplay.MelFollowUp },
  // 1001: { cssClass: 'cycle' },
  QDAY: { cssClass: 'qdaily', name: JobTypeDisplay.MelFollowUp },
  // 1003: { cssClass: 'qdaily' },
  DAY: { cssClass: 'daily', name: JobTypeDisplay.MelFollowUp },
  // 1004: { cssClass: 'daily' },
  QFY: { cssClass: 'qfx', name: JobTypeDisplay.MelQuickFix },
  // 1005: { cssClass: 'qfx' },
  RSMG: {
    cssClass: 'rsmg',
    name: JobTypeDisplay.Rsmg,
    DeferralDescription: 'Perform Right Start Wake Up tasks as necessary',
  },
  // 2000: { cssClass: 'rsmg' },
  MG: {
    cssClass: 'mg',
    name: JobTypeDisplay.Mg,
    DeferralDescription: JobTypeDisplay.Mg,
  },
  // 2001: { cssClass: 'mg' },

  TERM: {
    cssClass: 'term',
    name: JobTypeDisplay.Check,
    DeferralDescription: JobTypeDisplay.Check,
  },
  // 2002: { cssClass: 'term' },
  WKUP: {
    cssClass: 'wkup',
    name: JobTypeDisplay.WakeUp,
    DeferralDescription: JobTypeDisplay.WakeUp,
  },
  // 2003: { cssClass: 'wkup' },

  RSWK: {
    cssClass: 'rswk',
    name: JobTypeDisplay.Rswu,
    DeferralDescription: 'Perform Right Start Wake Up tasks as necessary',
  },
  // 2004: { cssClass: 'rswk' },

  OIL_CHK: { cssClass: 'oil', name: JobTypeDisplay.Servicing },
  // 2005: { cssClass: 'oil' },

  CFMOIL: { cssClass: 'oil', name: JobTypeDisplay.Servicing },

  TIRECHK: {
    cssClass: 'tirechk',
    name: JobTypeDisplay.Check,
    DeferralDescription: JobTypeDisplay.Check,
  },
  // 2007: { cssClass: 'tirechk' },

  TRANSCHK: {
    cssClass: 'transchk',
    name: JobTypeDisplay.Check,
    DeferralDescription: JobTypeDisplay.Check,
  },
  TI_CK: {
    name: JobTypeDisplay.TransitCheck,
    DeferralDescription: JobTypeDisplay.TransitCheck,
  },
  // 2008: { cssClass: 'transchk' },

  USERDEFINED: {
    cssClass: '',
    name: JobTypeDisplay.Other,
  },
  // This should not be needed ad we are not using
  // USERDEFINED: {
  //   cssClass: 'qcycle',
  //   name: JobTypeDisplay.Other,
  //   DeferralDescription: JobTypeDisplay.Other,
  // },

  // 3000: { cssClass: 'qcycle' },

  '': { cssClass: 'default', name: JobTypeDisplay.MelGeneral },
  //1030: { cssClass: 'default' },

  R: { cssClass: 'melr', name: JobTypeDisplay.MelGeneral },
  // 1031: { cssClass: 'melr' },

  CI: { cssClass: 'melci', name: JobTypeDisplay.MelGeneral },
  // 1032: { cssClass: 'melci' },

  EA_FLT: { cssClass: 'melf', name: JobTypeDisplay.MelFollowUp },
  // 1040: { cssClass: 'melf' },

  EA_FLT_R: { cssClass: 'melfr', name: JobTypeDisplay.MelFollowUp },
  // 1041: { cssClass: 'melfr' },

  EA_FLT_CI: { cssClass: 'melfci', name: JobTypeDisplay.MelFollowUp },

  // 1042: { cssClass: 'melfci' },

  '1ST_FLT': { cssClass: 'meld', name: JobTypeDisplay.MelFollowUp },
  // 1050: { cssClass: 'meld' },
  '1ST_FLT_R': { cssClass: 'meldr', name: JobTypeDisplay.MelFollowUp },

  // 1051: { cssClass: 'meldr' },

  '1ST_FLT_CI': { cssClass: 'meldci', name: JobTypeDisplay.MelFollowUp },
  // 1052: { cssClass: 'meldci' },

  QFX: { cssClass: 'melq', name: JobTypeDisplay.MelQuickFix },

  // 1130: { cssClass: 'melq' },

  QFX_R: { cssClass: 'melqr', name: JobTypeDisplay.MelQuickFix },

  // 1131: { cssClass: 'melqr' },

  QFX_CI: { cssClass: 'melqci', name: JobTypeDisplay.MelQuickFix },
  // 1132: { cssClass: 'melqci' },

  EA_FLT_QFX: { cssClass: 'melqf', name: JobTypeDisplay.MelFollowUp },
  // 1140: { cssClass: 'melqf' },

  EA_FLT_R_QFX: { cssClass: 'melqfri', name: JobTypeDisplay.MelFollowUp },
  // 1141: { cssClass: 'melqfri' },

  EA_FLT_QFX_CI: { cssClass: 'melqfci', name: JobTypeDisplay.MelFollowUp },
  // 1142: { cssClass: 'melqfci' },

  '1ST_FLT_QFX': { cssClass: 'melqd', name: JobTypeDisplay.MelFollowUp },
  // 1150: { cssClass: 'melqd' },

  '1ST_FLT_R_QFX': { cssClass: 'melqdr', name: JobTypeDisplay.MelFollowUp },
  // 1151: { cssClass: 'melqdr' },
  '1ST_FLT_QFX_C': { cssClass: 'melqcid', name: JobTypeDisplay.MelFollowUp },
  // 1152: { cssClass: 'melqcid' },

  TII: { cssClass: 'tii', name: JobTypeDisplay.MelGeneral },
  // 1155: { cssClass: 'tii' },
  MON: { cssClass: 'mon', name: JobTypeDisplay.OPEN },
  //1156: { cssClass: 'mon' },
  OPS: { cssClass: 'ops', name: JobTypeDisplay.OPEN },
  //1157: { cssClass: 'ops' },
  ROB: { cssClass: 'rob', name: JobTypeDisplay.OPEN },
  //1158: { cssClass: 'rob'}
  REM: { cssClass: 'rem', name: JobTypeDisplay.OPEN },
  //1160: { cssClass: 'rem'}
  CUBA: { cssClass: '', name: JobTypeDisplay.CubaFlight },

  HERMES: { name: JobTypeDisplay.Hermes },

  X: { name: JobTypeDisplay.NefQuickFix },

  IFE: {
    name: JobTypeDisplay.Ife,
    DeferralDescription: JobTypeDisplay.Ife,
  },

  OIL: { cssClass: 'osp-oil' },
  //Below were not in notification priorities description
  // 'TI CK': { name: 'Transit Check' },
  // CFMOIL: { name: 'Servicing' },

  BOW: {
    cssClass: 'bowtask',
  },
};

// IT IS ALSO EQUIVALENT OF EXTERNAL ITEM TYPES
export const NotificationClasses: any = {
  DEFERRAL: { cssClass: 'deferral' },
  DAILY_CHECK: { cssClass: 'daily' },
  MON: { cssClass: 'mon' },
  CUBA: { cssClass: 'manualcubatask' },
  ARMS: { cssClass: 'arms' },
  HERMES: { cssClass: 'hermes' },
  IBGATE: { cssClass: 'ibgatemanning' },
  OBGATE: { cssClass: 'obgatemanning' },
  //1000: { cssClass: 'deferral' },
  // 1001: { cssClass: 'arms' },
  // 1003: { cssClass: '' },
  // 1004: { cssClass: '' },
  //1005: { cssClass: 'daily' },
  // 1006: { cssClass: 'tii' },
  // 1007: { cssClass: 'mon' },
  // 2000: { cssClass: 'rmsg' },
  // 2001: { cssClass: 'wkup' },
  // 2002: { cssClass: '' },
  // 2003: { cssClass: '' },
  // 2007: { cssClass: '' },
  // 2009: { cssClass: 'ibgatemanning', priorityClassFunction: null },
  // 2010: { cssClass: 'obgatemanning', priorityClassFunction: null },
  MANUAL_TASK: { cssClass: 'manualtask' },
  BOW: { cssClass: 'bowtask' },
  // 3000: { cssClass: 'manual' },
  // 3500: { cssClass: 'manualtask' },
  // 2011: { cssClass: 'manualcubatask', priorityClassFunction: null },
};
