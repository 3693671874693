import { ActiveAircraft } from '@lm-apps/lmo/ui/data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Aircraft] Init');

export const loadAircraftSuccess = createAction(
  '[Aircraft/API] Load aircrafts success',
  props<{ aircrafts: ActiveAircraft[] }>()
);

export const throwError = createAction(
  '[Aircraft/API] API request failed',
  props<{ error: any }>()
);
