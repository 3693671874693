import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Task } from '@lm-apps/lmo/wall/shared';
@Component({
  selector: 'lm-apps-maintenance-task',
  templateUrl: './maintenance-task.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceTaskComponent {
  @Input()
  task: Task | null = null;
}
