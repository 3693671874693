import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CrewManagerHomeComponent } from './component/home/home.component';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AllCrewMemComponent } from './component/all-crew-mem/all-crew-mem.component';
import { DragDropModule } from 'primeng/dragdrop';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CrewManagerComponent } from './component/crew-manager/crew-manager.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LmoUiCommonFeatureSharedModule } from '@lm-apps/lmo/ui/common/feature-shared';
@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    LmoUiCommonPipesModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    FontAwesomeModule,
    DragDropModule,
    KeyFilterModule,
    ConfirmDialogModule,
    LmoUiCommonFeatureSharedModule,
  ],
  declarations: [
    CrewManagerComponent,
    CrewManagerHomeComponent,
    AllCrewMemComponent,
  ],
})
export class LmoUiFeatureCrewManagerModule {}
