import { NgModule } from '@angular/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StationsModalComponent } from './stations-modal/stations-modal.component';
import { TagModule } from 'primeng/tag';
import { LmoUiCommonFeatureSharedModule } from '@lm-apps/lmo/ui/common/feature-shared';
import { KeyFilterModule } from 'primeng/keyfilter';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
@NgModule({
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TagModule,
    LmoUiCommonFeatureSharedModule,
    KeyFilterModule,
    LmoUiCommonPipesModule,
  ],
  declarations: [HomeComponent, StationsModalComponent],
  exports: [HomeComponent],
})
export class LmoUiFeatureStationsModule {}
