import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SidePanelLayoutService } from '@lm-apps/lmo/ui/common/feature-shared';
import {
  AircraftLockItemsService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import {
  AircraftLockItem,
  FlightNotificationEntity,
  FlightsEntity,
  KeyJobType,
  Maybe,
  SelectedFlight,
  SelectedFlightData,
  SortDetail,
  TaskEntity,
} from '@lm-apps/lmo/ui/data';
import { selectFilteredNoseNumbers } from '@lm-apps/lmo/ui/data-access-aircraft';
import * as fromFlights from '@lm-apps/lmo/ui/data-access-flight';
import * as fromStations from '@lm-apps/lmo/ui/data-access-stations';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromImmutable from '@lm-apps/lmo/ui/data-access-immutable';
import * as fromFeatureFlag from '@lm-apps/lmo/ui/data-access-feature-flag';
import * as fromRoot from '@lm-apps/lmo/ui/data-access-root';

import { Store, select } from '@ngrx/store';
import {
  Observable,
  Subscription,
  catchError,
  debounceTime,
  filter,
  map,
  of,
} from 'rxjs';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
  @Input() roles!: string[];

  currentCrew$ = this.store.pipe(select(fromRoot.selectCurrentCrew));
  defaultStation$ = this.store.pipe(select(fromUser.selectDefaultStation));
  isFlightsLoaded$ = this.store.pipe(select(fromFlights.selectIsFlightsLoaded));
  filteredStations$ = this.store.pipe(select(fromUser.selectStations));
  flightsPair$ = this.store.pipe(select(fromFlights.selectAllFlightsSorted));
  formattedStationInfo$ = this.store.pipe(
    select(fromStations.selectFormattedStationInfo)
  );
  predefinedTask$ = this.store.pipe(
    select(fromUser.selectActivePredefinedTasks)
  );

  public selectedFlightData$: Observable<SelectedFlightData | undefined> =
    this.store.pipe(select(fromFlights.selectSelectedFlightData));
  defferalMels$ = this.store.pipe(select(fromImmutable.selectDefferalMels));
  crewManagerManpowerFlag$ = this.store.pipe(
    select(fromFeatureFlag.selectManpowerCrewManagerFlag)
  );

  sortDetail$!: Observable<SortDetail>;

  selectedFlight = false;
  selectedNotification: FlightNotificationEntity | undefined;
  selectedTask: TaskEntity | undefined;
  flightNumberFromFilter = '';

  aircraftSearchResults$: Observable<string[]> | undefined = new Observable<
    string[]
  >();
  noseNumberFromFilter = '';
  scrollId = '';
  mic$?: Observable<string> = of('');

  private flightDetailSub: Subscription = new Subscription();
  private selectedFlightData: SelectedFlightData | undefined;

  constructor(
    private store: Store,
    private aircraftLockItems: AircraftLockItemsService,
    private toastService: CustomToastrService,
    private layoutService: SidePanelLayoutService
  ) {}

  ngOnInit(): void {
    this.selectCurrentCrew();
    this.flightDetailSub = this.toastService.methodCall$.subscribe(
      (value: { flightPair: FlightsEntity; task: TaskEntity }) => {
        const selected = {
          FlightHash: value.flightPair.Hash,
          TaskId: value.task.Id,
        };

        this.setSelectedFlight(selected);
      }
    );
    this.getSelectedFlightData();
  }

  selectCurrentCrew() {
    this.sortDetail$ = this.store.pipe(select(fromFlights.selectSortDetail));
  }

  ngOnDestroy() {
    // remove mouse down listener here
    this.flightDetailSub?.unsubscribe();
  }

  filteredFlightNumber(value: string) {
    this.flightNumberFromFilter = value;
  }
  getNoseNumbers(noseNumber: string) {
    this.noseNumberFromFilter = noseNumber;
    this.aircraftSearchResults$ = this.store.pipe(
      select(selectFilteredNoseNumbers(noseNumber))
    );
  }

  sortDetail(sortDetail: SortDetail) {
    this.store.dispatch(fromFlights.actions.setSortDetail({ sortDetail }));
  }

  getMaintHoldMics(noseNumber: string) {
    this.mic$ = this.aircraftLockItems.aircraftLockItems(noseNumber).pipe(
      debounceTime(1000),
      map((res: Maybe<Array<AircraftLockItem>> | undefined) => {
        if (res && res.length === 0) return 'No Items';
        const micTooltipText = res?.reduce(
          (acc, item) =>
            (acc = acc ? acc + ', ' + item.micNumber : item.micNumber),
          ''
        );

        return 'MIC : ' + micTooltipText;
      }),
      catchError(() => {
        this.toastService.error('Failed to load data', '', {
          closeButton: true,
        });
        return of('Failed');
      })
    );
  }

  unloadMaintHoldMics() {
    this.mic$ = undefined;
  }

  setSelectedFlight(selectedFlight: SelectedFlight) {
    this.store.dispatch(
      fromFlights.actions.setSelectedFlight({ selectedFlight })
    );
    this.setSelectedBowTask();
    this.openSidePanel();
  }

  openSidePanel() {
    this.layoutService.openSidePanel();
  }

  updateFilteredStations(stations: string[]) {
    this.store.dispatch(fromUser.actions.updateStations({ stations }));
  }

  scrollToTop(id: string | null) {
    this.scrollId = id ?? '';
  }

  private getSelectedFlightData(): void {
    this.flightDetailSub.add(
      this.selectedFlightData$
        .pipe(filter((data) => !!data))
        .subscribe((data) => {
          this.selectedFlightData = data;
        })
    );
  }

  //open BOW details view directly on click of the BOW item tile
  private setSelectedBowTask(): void {
    const selectedTask = this.selectedFlightData?.task;
    if (selectedTask && selectedTask.JobType?.KeyJobType === KeyJobType.Bow) {
      this.store.dispatch(
        fromFlights.actions.setSelectedBowItem({
          selectedBowItem: selectedTask,
        })
      );
    }
  }
}
