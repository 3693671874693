import { createSelector } from '@ngrx/store';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromStations from '@lm-apps/lmo/ui/data-access-stations';
import * as fromCrews from '@lm-apps/lmo/ui/data-access-crew';
import * as fromCrewManpower from '@lm-apps/lmo/ui/data-access-crew-manpower';
import * as fromFeatureFlag from '@lm-apps/lmo/ui/data-access-feature-flag';
import * as fromAlternateTasks from '@lm-apps/lmo/ui/data-access-alternate-tasks';
import { mergeAndSortCrewMembers } from './root.helpers';

import {
  AssignedMember,
  SelectedFlightData,
  SortableColumn,
  TeamAssignment,
  User,
} from '@lm-apps/lmo/ui/data';
import * as fromFlight from '@lm-apps/lmo/ui/data-access-flight';
import {
  customSort,
  sortByProperty,
} from '@lm-apps/lmo/ui/common/feature-shared';

export const selectAllSelectedStations = createSelector(
  fromStations.selectAllRegions,
  fromUser.selectStations,
  (regions, stations) => {
    if (!regions || !stations) {
      return [];
    }

    return regions
      .flatMap((region) => region.Stations)
      .filter((station) => stations.includes(station?.IataCode ?? ''));
  }
);

export const selectFlightSearchRequest = createSelector(
  fromUser.selectFilter,
  fromUser.selectStations,
  (filter, stations) => ({ ...filter, Stations: stations })
);

export const selectAllCrewsForCM = createSelector(
  fromCrews.selectAllCrews,
  fromUser.selectUser,
  (allCrew, user) => sortCrewTeam(allCrew, user)
);

export function sortCrewTeam(
  crews: TeamAssignment[],
  user: User
): TeamAssignment[] {
  const availableSharedTeams: TeamAssignment[] = [];
  const ownedTeams: TeamAssignment[] = [];
  const result: TeamAssignment[] = [];
  if (!crews || crews.length === 0 || !user.EmployeeId) return result;
  crews.map((x) =>
    x.OriginalOwner.EmployeeId !== user.EmployeeId &&
    x.Shared &&
    !x.CurrentOwner
      ? availableSharedTeams.push(x)
      : ownedTeams.push(x)
  );
  result.push(...sortTeamAssignment(ownedTeams));
  result.push(...sortTeamAssignment(availableSharedTeams));
  return result;
}

export function sortTeamAssignment(crews: TeamAssignment[]): TeamAssignment[] {
  return crews.sort(
    (a, b) => new Date(b.Updated).getTime() - new Date(a.Updated).getTime()
  );
}

export const selectStationsForCrewManager = createSelector(
  fromUser.selectDefaultStation,
  fromUser.selectStations,
  (defaultStation, stations) => {
    const tempStation = [...stations];
    const i = tempStation.indexOf(defaultStation);
    if (i === -1) return tempStation;
    else {
      tempStation.unshift(defaultStation);
      return tempStation.filter((val, i, self) => self.indexOf(val) === i);
    }
  }
);

export const selectFlightForSpecificTask = (
  aircraftId: string,
  flightLegId: string
) =>
  createSelector(
    fromFlight.selectAllFlights,
    fromUser.selectUser,
    (allFlights, user) => {
      const flight = allFlights.find(
        (f) =>
          f &&
          f.Equipment.Id === aircraftId &&
          (f.Arrival?.Id == flightLegId || f.Departure?.Id == flightLegId)
      );
      return flight ? { flight, user } : null;
    }
  );

export const selectFlightPairsWithMyTasks = createSelector(
  fromFlight.selectAllFlights,
  fromUser.selectEmployeeId,
  (flights, employeeId) => {
    return flights
      .map((flight) => {
        const filteredTasks = flight.AllTasks.filter((task) =>
          task.AssignedMembers?.some(
            (member) => member.EmployeeId === employeeId
          )
        );

        const filteredNotifications = flight.Notifications.filter(
          (notification) =>
            notification.AssignedToNames?.some(
              (member) => member.EmployeeId === employeeId
            )
        );

        return {
          ...flight,
          AllTasks: filteredTasks,
          Notifications: filteredNotifications,
        };
      })
      .filter(
        (flight) =>
          flight.AllTasks.length > 0 || flight.Notifications.length > 0
      );
  }
);

export const selectSelectedMyTasksFlightEntity = createSelector(
  selectFlightPairsWithMyTasks,
  fromFlight.selectSelectedMyTasksFlight,
  (flights, selectedFlight) => {
    let result: SelectedFlightData | undefined = undefined;

    const flightData =
      flights.find((flight) => flight.Hash === selectedFlight.FlightHash) ||
      flights[0];
    const task = flightData?.AllTasks.find(
      (task) => task.Id == selectedFlight.TaskId
    );
    result = { flight: flightData, task: task };
    return result;
  }
);

export const selectAllMyTasksAircrafts = (aircraftNumber: string) =>
  createSelector(selectFlightPairsWithMyTasks, (flights) => {
    const noseNumbers: string[] = [];
    flights.forEach((flight) => {
      noseNumbers.push(flight.Equipment.NoseNumber);
    });
    return noseNumbers.filter((noseNumber) =>
      noseNumber.toLowerCase().startsWith(aircraftNumber.toLowerCase())
    );
  });

export const selectMyTasksFlightPairsSorted = createSelector(
  selectFlightPairsWithMyTasks,
  fromFlight.selectMyTasksSortDetail,
  (flights, sortDetail) => {
    if (flights && flights.length > 1) {
      const splitProp = SortableColumn[sortDetail.column].split('.');
      flights.sort((a, b) =>
        customSort(a, b, sortDetail.sortOrder, splitProp[0], splitProp[1])
      );
    }
    return flights;
  }
);

export const selectCurrentCrew = createSelector(
  fromUser.selectCurrentCrewMapped,
  fromCrewManpower.selectCurrentMappedCrew,
  fromFeatureFlag.isManpowerCMEnabled,
  (crewFromDb, crewFromManpower, isManpowerFlagEnabled) => {
    if (isManpowerFlagEnabled) {
      return crewFromManpower;
    } else if (crewFromDb) {
      return crewFromDb;
    }
    return null;
  }
);

export const selectCrewMembersForAssignToDropdown = createSelector(
  selectCurrentCrew,
  fromUser.selectSortCrewState,
  fromFlight.selectSelectedFlightData,
  (crew, sortState, selectedFlightData) => {
    const stationMismatch =
      selectedFlightData?.flight.Station !== crew?.Station;
    const crewMembers =
      !crew?.Members || stationMismatch ? [] : [...crew.Members];

    return mergeAndSortCrewMembers(
      crewMembers,
      selectedFlightData?.task?.AssignedMembers || [],
      sortState
    );
  }
);

export const selectCrewMembersForAlternateTasksAssignToDropdown =
  createSelector(
    selectCurrentCrew,
    fromUser.selectSortCrewState,
    fromAlternateTasks.selectCurrentAlternateTask,
    (crew, sortState, selectedAlternateTask) => {
      const stationMismatch = selectedAlternateTask?.Station !== crew?.Station;
      const crewMembers =
        !crew?.Members || stationMismatch ? [] : [...crew.Members];

      return mergeAndSortCrewMembers(
        crewMembers,
        selectedAlternateTask?.AssignedMembers || [],
        sortState
      );
    }
  );
