import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskEntity, TaskState } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-bow-assigned-members-list',
  templateUrl: './bow-assigned-members-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowAssignedMembersListComponent {
  @Input({ required: true }) public selectedBowItem!: TaskEntity;
  public readonly taskState = TaskState;
}
