import { InjectionToken } from '@angular/core';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';

export interface FeatureFlagsConfig {
  /**
   * FeatureFlags Endpoint Uri.
   */
  uri?: string;
  /**
   * Application Name.
   */
  appName: string;

  /**
   * Environment.
   */
  envName?: string;
  /**
   * Default values in case Featureflags server is inaccesible.
   */
  defaults?: FeatureFlag[];
}
export const _FEATURE_FLAGS_CONFIG = new InjectionToken<FeatureFlagsConfig>(
  'FeatureFlagsConfig Internal'
);
export const FEATURE_FLAGS_CONFIG = new InjectionToken<FeatureFlagsConfig>(
  'FeatureFlagsConfig'
);
export const FEATURE_FLAGS_GATEWAY = 'FEATURE_FLAGS_GATEWAY';
export const FEATURE_FLAGS = 'FeatureFlags';
