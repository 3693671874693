import { Pipe, PipeTransform } from '@angular/core';
import {
  DepartureStatus as DepartureStatusEnum,
  EnumUtil,
} from '@lm-apps/lmo/shared/enums';
import { DepartureStatus as DepartureStatusParameter } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'departureStatus',
})
export class DepartureStatusPipe implements PipeTransform {
  transform(departureStatus: DepartureStatusParameter) {
    return EnumUtil.getValue(DepartureStatusEnum, departureStatus);
  }
}
