import { Component, Inject, OnInit } from '@angular/core';
import {
  PING_APP_ENVIRONMENT,
  PingAuthenticationService,
} from '@aa-techops-ui/ping-authentication';
import { combineLatest, filter, startWith, take, tap } from 'rxjs';
import { AppEnvironment, pageVisible$ } from '@lm-apps/lmo/shared/common';
import * as sio from 'socket.io-client';
import {
  OnlineStatusModel,
  Message,
  ServerToClientEvents,
  CrewBroadcastModel,
  TaskBroadcastModel,
} from './socketio.interfaces';
import { CustomToastrService } from '@lm-apps/lmo/ui/common/services';
import { JwtClaims } from 'oidc-client-ts';
import * as fromFlights from '@lm-apps/lmo/ui/data-access-flight';
import * as fromCrew from '@lm-apps/lmo/ui/data-access-crew';
import * as fromNotices from '@lm-apps/lmo/ui/data-access-notices';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@lm-apps/lmo/ui/data-access-root';
import {
  FlightNotificationEntity,
  FlightsEntity,
  TaskEntity,
  User,
} from '@lm-apps/lmo/ui/data';
import * as DT from '@dynatrace/dtrum-api-types';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  socket: sio.Socket<ServerToClientEvents, any>;
  unauthorized = false;
  activeNotices$ = this.store.pipe(select(fromNotices.selectActiveNotices));

  constructor(
    private authService: PingAuthenticationService,
    @Inject(PING_APP_ENVIRONMENT) private env: AppEnvironment,
    private store: Store,
    private customToaster: CustomToastrService
  ) {}
  async getWebPubSubToken() {
    const negotiateResponse = await fetch(
      `${this.env.LMO_API_BASE_URL}/negotiate`,
      {
        headers: {
          Authorization: `Bearer ${this.authService.AccessToken}`,
        },
      }
    );
    if (!negotiateResponse.ok) {
      console.log(
        'Failed to negotiate, status code =',
        negotiateResponse.status
      );
      return;
    }
    const json: { endpoint: string; path: string; token: string } =
      await negotiateResponse.json();
    return json;
  }
  async ngOnInit() {
    this.authService.OnUnauthorizedRequest.subscribe((_url: string) => {
      console.log('ping token renewal failed at', new Date());
      window.dtrum?.reportError(`ping token renewal failed at ${new Date()}`);
      this.socket.disconnect();
      if (this.env.AUTO_LOGOUT_ON_UNAUTHORIZED) {
        this.authService.startSignOut();
      }
    });

    this.authService.loggedIn$.subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        return;
      }
      //identify user in dynatrace
      window.dtrum?.identifyUser(this.authService.User.profile.sub);

      const archer_id = '2340720';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (jQuery(document) as any).surveysPlugin({
        archer_id: archer_id,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const feedback = (jQuery(document) as any).feedback({
        archer_id: archer_id,
      });
      jQuery(document).on('click touchstart', '#lnkFeedback', () => {
        feedback.show();
        return false;
      });
      // NOTE:
      // when tab is frozen/inactive/sleeping,
      // signalr can get disconnected for following reasons 1.timeout, 2.keep-live packets are not received
      // `disconnected` events might or might not emit depending on how tab freeze is handled by respective browsers/os
      // so check again when page is back to live
      combineLatest([
        this.authService.profile$,
        pageVisible$.pipe(startWith(true)),
      ])
        .pipe(
          filter(
            (_) =>
              // create connection only when its disconnected
              !this.socket || this.socket.disconnected
          ),
          tap(async ([profile, _pageVisible]: [JwtClaims, boolean]) => {
            const webPubSubConfig = await this.getWebPubSubToken();
            let station = '';

            if (profile['location']) {
              station = (profile['location'] as string).split('-')[0];
            }
            this.socket = sio.io(webPubSubConfig.endpoint, {
              path: webPubSubConfig.path,
              query: {
                access_token: webPubSubConfig.token,
                sub: profile.sub,
                station: station,
              },
              auth: {
                token: webPubSubConfig.token,
              },
              autoConnect: true,
              reconnection: true,
            });
            this.socket.on('status_change', (message) => {
              this.updateOnlineStatus(message);
            });
            this.socket.on('task_message', (message) => {
              this.updateTask(message);
            });
            this.socket.on('crew_change', (message) => {
              this.updateCrew(message);
            });
            this.socket.on('client_message', (message) => {
              this.msgToClient(message);
            }); //you can use the bruno script to send message to client
            this.socket.on('connect', () => {
              this.unauthorized = false;
              console.log('Socketio is sucessfully connected to the server');
            });
            this.socket.on('unauthorized', () => {
              this.unauthorized = true;
              console.error('Socketio is connection unauthorized');
            });
            this.socket.on('disconnect', (reason) => {
              console.warn('Socketio is disconnected due to:', reason);
            });
          })
        )
        .subscribe();

      this.store.dispatch(fromRoot.actions.init());
    });
  }

  msgToClient = (message: Message) => {
    console.debug('msgToClient: ', message);
  };

  updateCrew = (message: CrewBroadcastModel) => {
    if (!message || !message.Event || !message.TeamAssignment) return;
    this.store.dispatch(
      fromCrew.actions.updateCrewSignalr({
        event: message.Event,
        crew: message.TeamAssignment,
      })
    );
  };

  updateOnlineStatus = (crewMember: OnlineStatusModel) => {
    if (!crewMember || !crewMember.EmployeeId || !crewMember.EmployeeId.trim())
      return;
    this.store.dispatch(
      fromCrewMemberStatus.actions.updateSpecificCrewMemberStatus({
        crewMember,
      })
    );
  };

  updateTask = (message: TaskBroadcastModel) => {
    if (message) {
      const aircraftId: string = message.AffectedAircraftId;
      const flightLegId: string = message.AffectedFlightLegId;
      const notification: FlightNotificationEntity =
        message.AffectedNotification;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...taskTypeAny } = message.AffectedTask;
      const taskEntity: TaskEntity = taskTypeAny;

      this.toastrOnSocketIoMessage(
        aircraftId,
        flightLegId,
        notification,
        taskEntity
      );
      this.store.dispatch(
        fromFlights.actions.updateFlightData({
          aircraftId,
          flightLegId,
          notification,
          task: taskEntity,
          realTimeNotification: true,
        })
      );
    }
  };

  public toastrOnSocketIoMessage(
    aircraftId: string,
    flightLegId: string,
    notification: FlightNotificationEntity,
    taskEntity: TaskEntity
  ) {
    this.store
      .pipe(
        select(fromRoot.selectFlightForSpecificTask(aircraftId, flightLegId)),
        take(1)
      )
      .subscribe(
        (
          val: {
            flight: FlightsEntity;
            user: User;
          } | null
        ) => {
          if (val) {
            const flight = { ...val.flight };
            const tasks: TaskEntity[] = [];

            /*
            At this point, SingalR value is not updated to store so we can know if Manaul Task is new or existing
            */
            const isNewManualTask =
              flight.Notifications &&
              !flight.Notifications.some((not) => not.Id == notification.Id);

            /*
            If store is not updated with latest signalR task then this logic will manually merge singalR task with store task.
            */
            val.flight.AllTasks.forEach((t) => {
              if (t.Id !== taskEntity.Id) {
                return tasks.push(t);
              }
              const mergedTask = { ...t, ...taskEntity };
              tasks.push(mergedTask);
            });

            /*
            If singlaR send new Maunal task then above logic will not work, so need to maunall add task to tasks collection
            */
            if (isNewManualTask) {
              tasks.push({ ...taskEntity });
            }

            flight.AllTasks = tasks;
            /*
            Only used to trigger Departure Toast, not for closing.(Closing is handle by triggerToast)
            */
            this.store.dispatch(
              fromFlights.actions.triggerDepartureToast({
                flights: [flight],
              })
            );

            const isCreatedOnInbound =
              taskEntity.InboundFlightLegId &&
              taskEntity.InboundFlightLegId === flight.Arrival?.Id;

            const isCreatedOnOutbound =
              !flight.Arrival?.Id &&
              taskEntity.OutBoundFlightLegId &&
              taskEntity.OutBoundFlightLegId === flight.Departure?.Id;

            if (
              (isCreatedOnInbound &&
                flight.Arrival.ArrivalStation === taskEntity.Station) ||
              (isCreatedOnOutbound &&
                flight.Departure.DepartureStation === taskEntity.Station)
            ) {
              /*
              1. Used to trigger/show only toastr for manual task.
              2. Used to close all toastr
              */
              this.customToaster.triggerToast(
                flight,
                taskEntity,
                isNewManualTask,
                val.user
              );
            }
          }
        }
      );
  }
}
