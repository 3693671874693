import * as fromNotices from './+state/notices.reducer';
import { NOTICES_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { NoticesEffects } from './+state/notices.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(NOTICES_FEATURE_KEY, fromNotices.reducer),
    EffectsModule.forFeature([NoticesEffects]),
  ],
})
export class LmoUiDataAccessNoticesModule {}
