<div class="assigned">
  <table class="table">
    <thead>
      <tr>
        <th>Assigned To</th>
        <th>Assigned By</th>
        <th>Status</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr *ngFor="let selectedCrewMember of selectedCrewMembers">
        <td>
          {{ selectedCrewMember.LastName | titlecase }},&nbsp;{{
            selectedCrewMember.FirstName | titlecase
          }}&nbsp;({{ selectedCrewMember.EmployeeId | trimBadgeNumber }})
        </td>
        <td>
          @if (selectedCrewMember.AssignedBy) {
          {{ selectedCrewMember.AssignedByName | titlecase }} ({{
            selectedCrewMember.AssignedBy
          }}) } @else if(user){ {{ user.LastName | titlecase }},
          {{ user.FirstName | titlecase }} ({{
            user.EmployeeId | trimBadgeNumber
          }}) }
        </td>

        <td>
          {{ selectedCrewMember | mechanicStatus: assignedMembersFromTask }}
        </td>
        <td>
          <lm-apps-lmo-ui-online-status
            [isOnline]="
              selectedCrewMember.EmployeeId
                | getOnlineStatus: allCrewMemberOnlineStatus
            "
          ></lm-apps-lmo-ui-online-status>
        </td>
        <td>
          <span
            (click)="emitAddOrRemoveSelectedMechanic(selectedCrewMember)"
            class="select-link"
            >Remove</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
