/**
 * Arrival Status
 *
 * @export
 * @enum {string}
 */
export enum ArrivalStatusCode {
  /**
   *Scheduled
   */
  SCHEDULED = 'S',
  /**
   *Estimated Arrival posted
   */
  ESTIMATED = 'E',
  /**
   *Flight Plan Release (Route detail), P means ETA is predicted from flight plan
   */
  FLIGHT_PLAN = 'P',
  /**
   * A changeover refers to a flight schedule change or a change of flight
   */
  CHANGEOVER_RECEIVED = 'C',
  /**
   *Aircraft is holding for arrival
   */
  HOLDING = 'H',
  /**
   *ATC delay
   */
  ATC_DELAY = 'A',
  /**
   *Actual ON Status, meaning when wheels touch the ground
   */
  ON_GROUND = 'O',
  /**
   *Actual IN status, when aircraft reaches to gate
   */
  IN_GATE = 'I',
  /**
   *Indefinite delay
   */
  INDEF_DELAY = 'N',
  /**
   *Flight has arrived early
   */
  EARLY = 'T',
  /**
   * For null values
   */
  EMPTY = '',
}
