import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Departure } from '@lm-apps/lmo/ui/data';
import { EtopsFlight } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-outbound',
  templateUrl: './outbound.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutboundComponent implements OnInit {
  @Input() departure!: Departure;
  @Input() departureDate!: string;
  @Input() isEtopsCertified!: boolean;

  isEtopMismatch = false;
  etopClassValueBorder = '';
  etopClassValue = '';

  NON_ETOPS_AC = EtopsFlight.NON_ETOPS_AC;
  ETOPS_FLT = EtopsFlight.ETOPS_FLT;

  ngOnInit() {
    if (!this.departure) this.departure = <Departure>{};

    this.isEtopMismatch =
      !this.isEtopsCertified && this.departure.IsEtopsFlight ? true : false;
    this.etopClassValueBorder = this.getEtopClassValueBorder(
      this.isEtopMismatch
    );
  }

  getEtopClassValueBorder(isEtopMismatchVal: boolean): string {
    return isEtopMismatchVal ? 'redBorder' : '';
  }
}
