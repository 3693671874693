import { Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromFlights from '@lm-apps/lmo/ui/data-access-flight';
import { Observable } from 'rxjs';
import { SelectedFlightData, TaskEntity } from '@lm-apps/lmo/ui/data';
import { SidePanelLayoutService } from '@lm-apps/lmo/ui/common/feature-shared';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import { PING_APP_ENVIRONMENT } from '@aa-techops-ui/ping-authentication';

@Component({
  selector: 'lm-apps-lmo-ui-bow-details-home',
  templateUrl: './bow-details-home.component.html',
})
export class BowDetailsHomeComponent {
  public selectedBowItem$: Observable<TaskEntity | null> = this.store.pipe(
    select(fromFlights.getSelectedBowItem)
  );

  public selectedFlightData$: Observable<SelectedFlightData | undefined> =
    this.store.pipe(select(fromFlights.selectSelectedFlightData));

  public config: AppEnvironment;

  constructor(
    private store: Store<fromFlights.flightReducer.State>,
    private layoutService: SidePanelLayoutService,
    @Inject(PING_APP_ENVIRONMENT) config: AppEnvironment
  ) {
    this.config = config;
  }

  public closeSidePanel(): void {
    this.layoutService.closeSidePanel();
  }

  public openAircraftAttributesView(): void {
    this.closeSidePanel();
    this.layoutService.openSidePanel();
  }
}
