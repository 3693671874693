export const MelFollowupMapping = [
  {
    lusCode: '0',
    priorityDesc: '',
  },
  {
    lusCode: '000',
    priorityDesc: '',
  },
  {
    lusCode: '001',
    priorityDesc: 'R',
  },
  {
    lusCode: '002',
    priorityDesc: 'CI',
  },
  {
    lusCode: '003',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '004',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '005',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '006',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '007',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '008',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '009',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '010',
    priorityDesc: 'EA FLT',
  },
  {
    lusCode: '011',
    priorityDesc: 'EA FLT R',
  },
  {
    lusCode: '012',
    priorityDesc: 'EA FLT CI',
  },
  {
    lusCode: '013',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '014',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '015',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '016',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '017',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '018',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '019',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '020',
    priorityDesc: '1ST FLT',
  },
  {
    lusCode: '021',
    priorityDesc: '1ST FLT R',
  },
  {
    lusCode: '022',
    priorityDesc: '1ST FLT CI',
  },
  {
    lusCode: '023',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '024',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '025',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '026',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '027',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '028',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '029',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '030',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '031',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '032',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '033',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '034',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '035',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '036',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '037',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '038',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '039',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '040',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '041',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '042',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '043',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '044',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '045',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '046',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '047',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '048',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '049',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '050',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '051',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '052',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '053',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '054',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '055',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '056',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '057',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '058',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '059',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '060',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '061',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '062',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '063',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '064',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '065',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '066',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '067',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '068',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '069',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '070',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '071',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '072',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '073',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '074',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '075',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '076',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '077',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '078',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '079',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '080',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '081',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '082',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '083',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '084',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '085',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '086',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '087',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '088',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '089',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '090',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '091',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '092',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '093',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '094',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '095',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '096',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '097',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '098',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '099',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '100',
    priorityDesc: 'QFX',
  },
  {
    lusCode: '101',
    priorityDesc: 'QFX R',
  },
  {
    lusCode: '102',
    priorityDesc: 'QFX CI',
  },
  {
    lusCode: '103',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '104',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '105',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '106',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '107',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '108',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '109',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '110',
    priorityDesc: 'EA FLT QFX',
  },
  {
    lusCode: '111',
    priorityDesc: 'EA FLT R QFX',
  },
  {
    lusCode: '112',
    priorityDesc: 'EA FLT QFX CI',
  },
  {
    lusCode: '113',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '114',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '115',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '116',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '117',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '118',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '119',
    priorityDesc: 'TBD',
  },
  {
    lusCode: '120',
    priorityDesc: '1ST FLT QFX',
  },
  {
    lusCode: '121',
    priorityDesc: '1ST FLT R QFX',
  },
  {
    lusCode: '122',
    priorityDesc: '1ST FLT QFX C',
  },
];
