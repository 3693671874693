<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a [routerLink]="['/']" class="d-flex">
    <div class="cc-logo-icon mr-1"></div>
    <div class="logo-env pt-2">
      <b>{{ appEnvironment }}</b>
    </div>
  </a>
  <button class="navbar-toggler" (click)="toggleMenu()" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div
    [ngClass]="{ collapse: !isMenuOpen, 'navbar-collapse': true }"
    id="navbarSupportedContent"
  >
    <hr *ngIf="isMenuOpen" class="cc-hr-gray" />
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" data-dtname="admin-tab">
        <a
          [routerLink]="['/admin']"
          class="nav-link"
          (click)="closeMenu()"
          data-cy="cy-nav-admin"
        >
          <span class="aa-bright-blue">Admin</span>
        </a>
      </li>
      <li class="nav-item" data-dtname="crew-chief-tab">
        <a
          [routerLink]="['/crew-chief']"
          class="nav-link"
          (click)="closeMenu()"
          data-cy="cy-nav-cc"
        >
          <span class="aa-bright-blue">Crew Chief</span>
        </a>
      </li>
      <li class="nav-item" data-dtname="my-tasks-tab">
        <a
          [routerLink]="['/my-tasks']"
          class="nav-link aa-bright-blue"
          (click)="closeMenu()"
          data-cy="cy-nav-mytasks"
        >
          <span class="aa-bright-blue">My Tasks</span>
        </a>
      </li>
      <li class="nav-item" data-dtname="alternate-tasks-tab">
        <a
          [routerLink]="['/alternate-tasks']"
          class="nav-link aa-bright-blue"
          (click)="closeMenu()"
          data-cy="cy-nav-alternatetasks"
        >
          <span class="aa-bright-blue">Alternate Tasks</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link disabled"
          *ngIf="ping.profile$ | async as user"
          data-cy="cy-nav-user"
        >
          <span class="aa-bright-blue">
            <span>{{
              (user.given_name ?? '') + ' ' + (user.family_name ?? '')
            }}</span>
            (<span>{{ user.sub.substring(2) ?? '' }}</span
            >)
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['#']"
          class="nav-link"
          (click)="closeMenu()"
          data-cy="cy-nav-station"
        >
          <span class="aa-bright-blue">{{
            (ping.profile$ | async)?.location?.toUpperCase().substr(0, 3) || ''
          }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          id="lnkFeedback"
          title="Send Feedback"
          class="nav-link cc-feedback-icon"
          data-cy="cy-nav-feedback"
          aria-label="feedback"
          (click)="closeMenu()"
        >
        </a>
      </li>
      <li class="nav-item">
        <a
          href="https://spteam.aa.com/sites/TechOpsApps/HelpCenter/index.html?helpCenter=LMO"
          target="_blank"
          title="Help Center"
          rel="noopener"
          class="nav-link cc-help-icon"
          (click)="closeMenu()"
          data-cy="cy-nav-help"
        >
        </a>
      </li>
      <!--Keeping it explicitly hidden for future troubleshooting purpose-->
      <li class="nav-item" style="display: none">
        <a
          href="#"
          title="Refresh Token"
          class="nav-link"
          (click)="refreshToken()"
        >
          <i class="fa fa-lg fa-refresh vertical-align-middle"> </i>
        </a>
      </li>
      <li class="nav-item">
        <a
          [routerLink]="['/logout']"
          title="Logout"
          class="nav-link cc-logout-icon"
          (click)="closeMenu()"
          data-cy="cy-nav-logout"
        >
        </a>
      </li>
      <li class="nav-item">
        <a class="aa-bright-blue nav-link disabled" data-cy="cy-nav-date">
          <span class="aa-bright-blue">{{ date }}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
<hr class="cc-hr" />
