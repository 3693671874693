import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimBadgeNumber',
})
export class TrimBadgeNumberPipe implements PipeTransform {
  transform(employeeId: string): string {
    if (!employeeId) {
      throw new Error('Employee ID is required');
    }
    if (employeeId.length <= 6) {
      return employeeId.padStart(6, '0');
    }
    return employeeId?.slice(-6);
  }
}
