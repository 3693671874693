import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { trimFleetTo4Char } from '@lm-apps/lmo/ui/common/feature-shared';
import { Filter, Zone } from '@lm-apps/lmo/ui/data';
import { isEqual, sortBy, intersection } from 'lodash';

export enum SelectAllType {
  Zone,
  Fleet,
}
interface SelectAll {
  fleet: boolean;
  zone: boolean;
}

@Component({
  selector: 'lm-apps-lmo-ui-my-tasks-filter',
  templateUrl: './filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyTasksFilterComponent implements OnInit {
  @Input() filter: Filter | null = null;
  @Input() fleets: string[] = [];
  @Input() zones: Zone[] = [];

  @Output() closeEvent: EventEmitter<void> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Partial<Filter>> = new EventEmitter();

  SelectAllType = SelectAllType;
  rangeList: number[] = [2, 4, 6, 8, 10, 12];
  fleetsDisplayList: string[] = [];
  zoneNameList: string[] = [];
  zoneAll = 'All';

  readonly SELECT_ALL = 'Select All';
  readonly DESELECT_ALL = 'Deselect All';

  selectAll: SelectAll = {
    fleet: false,
    zone: false,
  };

  form = new FormGroup({
    rangeFrmCtrl: new FormControl<number>(4, {
      nonNullable: true,
    }),
    fleetFrmCtrl: new FormControl<string[]>([], {
      nonNullable: true,
    }),
    zonesFrmCtrl: new FormControl<string[]>([], {
      nonNullable: true,
    }),
  });

  ngOnInit() {
    this.setRange();
    this.setFleets();
    this.setZones();

    this.form.markAsPristine();
  }

  setRange() {
    if (this.filter) {
      this.form.controls.rangeFrmCtrl.patchValue(this.filter.Range || 4);
    }
  }

  setFleets() {
    if (this.filter) {
      this.fleetsDisplayList = trimFleetTo4Char(this.fleets);
      const selectedFleets = this.filter.Fleets.length
        ? trimFleetTo4Char(this.filter.Fleets)
        : this.fleetsDisplayList;
      this.form.controls.fleetFrmCtrl.patchValue(selectedFleets);
      this.selectAll.fleet = isEqual(
        sortBy(this.fleetsDisplayList),
        sortBy(selectedFleets)
      );
    }
  }

  setZones() {
    if (this.filter) {
      this.zoneNameList = this.zones.map((z) => z.ZoneName);

      const selectedZones = this.filter.Zones.length
        ? this.filter.Zones
        : this.zoneNameList;
      this.form.controls.zonesFrmCtrl.patchValue(selectedZones);

      /*
      - zonesList got from api and selectedZones does not have any things common then select all
        or if both are equal then select all
      */
      if (
        !intersection(this.zoneNameList, selectedZones).length ||
        isEqual(sortBy(this.zoneNameList), sortBy(selectedZones))
      )
        this.onSelectDeselectAll(true, SelectAllType.Zone);
      else this.form.controls.zonesFrmCtrl.patchValue(selectedZones);
    }
  }

  /*
  This method is used when you click on selectAll or deselectAll button on fleet, zone, notification
  */
  onSelectDeselectAll(isSelectAll: boolean, type: SelectAllType) {
    switch (type) {
      case SelectAllType.Zone:
        this.selectAll.zone = isSelectAll;
        isSelectAll
          ? this.form.controls.zonesFrmCtrl.patchValue(this.zoneNameList)
          : this.form.controls.zonesFrmCtrl.patchValue([]);

        break;
      case SelectAllType.Fleet:
        this.selectAll.fleet = isSelectAll;
        isSelectAll
          ? this.form.controls.fleetFrmCtrl.patchValue(this.fleetsDisplayList)
          : this.form.controls.fleetFrmCtrl.patchValue([]);

        break;
    }
  }

  /*
  This method is used when you click on checkbox on fleet, zone, notification.
  it will check if all values are selected if true then DeselectAll will be shown otherwise SelectAll will be shown
  */
  displaySelectDeselectAll(type: SelectAllType) {
    switch (type) {
      case SelectAllType.Zone:
        this.selectAll.zone = isEqual(
          sortBy(this.zoneNameList),
          sortBy(this.form.controls.zonesFrmCtrl.value)
        );
        break;

      case SelectAllType.Fleet:
        this.selectAll.fleet = isEqual(
          sortBy(this.fleetsDisplayList),
          sortBy(this.form.controls.fleetFrmCtrl.value)
        );
        break;
    }
  }

  getFleets(): string[] {
    if (this.selectAll.fleet) {
      return this.fleets;
    } else {
      const selectedFleets = this.form.controls.fleetFrmCtrl.value;
      return this.fleets.filter((fleet) =>
        selectedFleets.some((selected) => fleet.includes(selected))
      );
    }
  }

  getZones(): string[] {
    return this.SelectAllType.Zone ? [] : this.form.controls.zonesFrmCtrl.value;
  }

  save() {
    const filter: Partial<Filter> = {
      Range: this.form.controls.rangeFrmCtrl.value,
      Fleets: this.getFleets(),
      Zones: this.getZones(),
    };
    this.saveEvent.emit(filter);
    this.close();
  }

  close() {
    this.closeEvent.emit();
  }

  setDefaultFilter() {
    this.form.controls.fleetFrmCtrl.patchValue(this.fleetsDisplayList);
    this.selectAll.fleet = true;
    this.form.controls.zonesFrmCtrl.patchValue(this.zoneNameList);
    this.selectAll.zone = true;
  }
}
