/**
 * Carrier Type
 */
export enum CarrierType {
  /**
   * US carrier Code
   */
  US = 'US',
  /**
   * AA carrier Code
   */
  AA = 'AA',
  XA = 'XA',
}
