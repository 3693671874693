import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { faArrowsLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { DomHandler } from 'primeng/dom';
import { SidePanelLayoutService } from '../../services/side-panel-layout/side-panel-layout.service';
import { SidePanelLayoutInterface } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-side-panel-layout',
  templateUrl: './side-panel-layout.component.html',
})
export class SidePanelLayoutComponent
  implements OnInit, OnDestroy, AfterViewInit, SidePanelLayoutInterface
{
  @Input() lhsComponent!: TemplateRef<void>;
  @Input() rhsComponent!: TemplateRef<void>;
  @Input() set scrollRhs(value: string) {
    value && this.scrollToRhsTop();
  }

  leftPanelWidth!: number;
  rightPanelWidth!: number;
  isMobile = false;
  isItemSelected = false;
  resizerPos = 50;
  faArrowsLeftRight = faArrowsLeftRight;
  screenWidth = window.innerWidth;
  restrictWidth = 70;
  resizerAdjustment = 10;
  ipadPortraitLeftSpace = 30;
  defaultRightPanelWidth = 775;
  ipadPortraitWidth = 900;

  @ViewChild('resizerHandle') resizerHandle!: ElementRef;

  public mouseDownListener!: () => void;
  public mouseMoveListener!: () => void;
  public mouseUpListener!: () => void;

  constructor(
    public renderer: Renderer2,
    private layoutService: SidePanelLayoutService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {
    this.layoutService.setLayoutComponent(this);
  }

  @ViewChild('rightSec', { static: false }) rightSec!: ElementRef;

  scrollToRhsTop() {
    this.rightSec &&
      this.rightSec.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit(): void {
    this.initResizer();
    this.isMobile = DomHandler.isTouchDevice();
  }

  ngAfterViewInit() {
    const element = this.resizerHandle.nativeElement;

    // will return the function that will be used to unsubscribe to event
    this.mouseDownListener = this.renderer.listen(
      element,
      'mousedown',
      (event: MouseEvent) => {
        event.preventDefault();
        // will return the function that will be used to unsubscribe to event
        this.mouseMoveListener = this.renderer.listen(
          'document',
          'mousemove',
          this.mousemove.bind(this)
        );

        // will return the function that will be used to unsubscribe to event
        this.mouseUpListener = this.renderer.listen(
          'document',
          'mouseup',
          this.mouseup.bind(this)
        );
      }
    );
  }

  ngOnDestroy() {
    // remove mouse down listener here
    this.mouseDownListener();
  }

  @HostListener('window:resize')
  onResize() {
    this.screenWidth = window.innerWidth;

    // if right panel width need to adjust when moving browser from big screen to small
    this.rightPanelWidth =
      this.rightPanelWidth > this.screenWidth - this.restrictWidth
        ? this.screenWidth - this.restrictWidth
        : this.rightPanelWidth;
    this.leftPanelWidth = this.screenWidth - this.rightPanelWidth;
    this.resizerPos = this.rightPanelWidth - this.resizerAdjustment;
  }

  mousemove(event: MouseEvent) {
    const currentX = event.pageX;
    // first do not allow user to drag to the very end of left side
    // second, when you have extended screen then stop the dragging when current screen width is meet
    if (currentX > this.restrictWidth && currentX <= this.screenWidth) {
      this.leftPanelWidth = currentX;
      this.rightPanelWidth = this.screenWidth - currentX;
      this.resizerPos = this.rightPanelWidth - this.resizerAdjustment;
    }
  }
  mouseup() {
    // remove mousemove and mouseup listeners here
    this.mouseMoveListener();
    this.mouseUpListener();
  }

  initResizer() {
    // to show resizer at very end of right side screen
    this.resizerPos = -this.resizerAdjustment;
    this.leftPanelWidth = this.screenWidth;
    this.rightPanelWidth = 0;
  }

  public openSidePanel() {
    // to run change detection outside of angular
    this.ngZone.runOutsideAngular(() => {
      this.isItemSelected = true;
      // Right panel should open at fixed position when clicked to flight details
      // for Ipad Portrait mode it open up full screen except leaving some space to left
      if (this.screenWidth < this.ipadPortraitWidth) {
        this.rightPanelWidth = this.screenWidth - this.ipadPortraitLeftSpace;
        this.leftPanelWidth = this.ipadPortraitLeftSpace;
      } else if (this.defaultRightPanelWidth > this.rightPanelWidth) {
        this.leftPanelWidth = this.screenWidth - this.defaultRightPanelWidth;
        this.rightPanelWidth = this.defaultRightPanelWidth;
      }
      // this is only for desktop/laptop, in iPad resizer will not be visible
      this.resizerPos = this.rightPanelWidth;

      // Manually trigger change detection
      this.ngZone.run(() => {
        this.cdr.detectChanges();
      });
    });
  }

  public closeSidePanel() {
    this.initResizer();
    this.isItemSelected = false;

    // Manually trigger change detection
    this.ngZone.run(() => {
      this.cdr.detectChanges();
    });
  }
}
