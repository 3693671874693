import { Pipe, PipeTransform } from '@angular/core';
import { JobTypeDisplay } from '@lm-apps/lmo/shared/enums';
import { Task } from '@lm-apps/lmo/wall/shared';

@Pipe({
  name: 'taskFilterByJobType',
})
export class TaskFilterByJobTypePipe implements PipeTransform {
  transform(tasks: Task[], jobTypeDisplay: JobTypeDisplay): Task[] {
    return tasks.filter((task) => task.displayJobType === jobTypeDisplay);
  }
}
