import { Pipe, PipeTransform } from '@angular/core';
import {
  DepartureStatusCode as DepartureStatusCodeEnum,
  EnumUtil,
} from '@lm-apps/lmo/shared/enums';
import { DepartureStatusCode as DepartureStatusCodeParameter } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'departureStatusCode',
})
export class DepartureStatusCodePipe implements PipeTransform {
  transform(departureStatusCode: DepartureStatusCodeParameter) {
    return EnumUtil.getValue(DepartureStatusCodeEnum, departureStatusCode);
  }
}
