import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromStations from '@lm-apps/lmo/ui/data-access-stations';

@Component({
  selector: 'lm-apps-feature-stations-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  isMOC!: boolean;
  filteredStations$ = this.store.pipe(select(fromUser.selectStations));
  allRegions$ = this.store.pipe(select(fromStations.selectAllRegions));

  constructor(private store: Store) {}

  updateFilteredStations(stations: string[]) {
    this.store.dispatch(fromUser.actions.updateStations({ stations }));
  }
}
