import { Injectable } from '@angular/core';
import { Query } from 'apollo-angular';
import { FEATURE_FLAGS_GATEWAY } from './feature-flags-config';
import {
  FeatureFlagResponse,
  FeatureFlagParams,
  FeatureFlagQuery,
} from '@lm-apps/common/feature-flag-data';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsQueryService extends Query<
  FeatureFlagResponse,
  FeatureFlagParams
> {
  override client = FEATURE_FLAGS_GATEWAY;
  document = FeatureFlagQuery;
}
