/**
 * DepartureStatus
 */
export enum DepartureStatus {
  /**
   * Delay
   */
  DELAY = 'DLY',
  /**
   * Scheduled Dept Time
   */
  SCHEDULED_TIME_OF_DEPARTURE = 'STD',
  /**
   * Estimated Dept Time
   */
  ESTIMATED_TIME_OF_DEPARTURE = 'ETD',
  /**
   * The aircraft is out from gate
   */
  OUT_FROM_GATE = 'OUT',
  /**
   * The aircraft has taken off
   */
  OFF_GROUND = 'OFF',
  /**
   * For null values
   */
  EMPTY = '',
}
