import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Store, select } from '@ngrx/store';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromRoot from '@lm-apps/lmo/ui/data-access-root';
import * as fromAircraft from '@lm-apps/lmo/ui/data-access-aircraft';
import * as fromStations from '@lm-apps/lmo/ui/data-access-stations';
import { Filter } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-filter-home',
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterHomeComponent {
  @Input()
  isMoc = false;
  filter$ = this.store.pipe(select(fromUser.selectFilter));
  fleets$ = this.store.pipe(select(fromAircraft.selectAllFleets));
  zones$ = this.store.pipe(select(fromStations.selectZones));
  stations$ = this.store.pipe(select(fromUser.selectStations));

  constructor(public modalRef: BsModalRef, private store: Store<any>) {}

  closeModal() {
    this.modalRef.hide();
  }
  saveFilter(filter: Filter) {
    this.store.dispatch(fromRoot.actions.updateFilter({ filter }));
  }
}
