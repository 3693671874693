import {
  CallTypeInput,
  KeyCallType,
  TaskCallTypeInput,
} from '@lm-apps/lmo/ui/data';

export const SystemGenerated: TaskCallTypeInput = {
  DisplayCallType: 'System Generated',
  Id: 1011,
  KeyCallType: KeyCallType.SystemGenerated,
};
export const Vhf: TaskCallTypeInput = {
  DisplayCallType: 'VHF',
  Id: 1005,
  KeyCallType: KeyCallType.Vhf,
};

export const callTypeInputMap = new Map<number, CallTypeInput>([
  [
    1000,
    {
      DisplayCallType: 'MOC/IOC',
      Id: 1000,
      IsActive: true,
      KeyCallType: KeyCallType.MocIoc,
    },
  ],
  [
    1001,
    {
      DisplayCallType: 'Planning',
      Id: 1001,
      IsActive: true,
      KeyCallType: KeyCallType.Planning,
    },
  ],

  [
    1002,
    {
      DisplayCallType: 'Verbal',
      Id: 1002,
      IsActive: true,
      KeyCallType: KeyCallType.Verbal,
    },
  ],
  [
    1003,
    {
      DisplayCallType: 'Radio',
      Id: 1003,
      IsActive: true,
      KeyCallType: KeyCallType.Radio,
    },
  ],
  [
    1004,
    {
      DisplayCallType: 'Telephone',
      Id: 1004,
      IsActive: true,
      KeyCallType: KeyCallType.Telephone,
    },
  ],
  [
    1005,
    {
      DisplayCallType: 'VHF',
      Id: 1005,
      IsActive: true,
      KeyCallType: KeyCallType.Vhf,
    },
  ],
  [
    1006,
    {
      DisplayCallType: 'Ramp',
      Id: 1006,
      IsActive: true,
      KeyCallType: KeyCallType.Ramp,
    },
  ],
  [
    1007,
    {
      DisplayCallType: 'Vendor',
      Id: 1007,
      IsActive: true,
      KeyCallType: KeyCallType.Vendor,
    },
  ],
  [
    1008,
    {
      DisplayCallType: 'GateReturn',
      Id: 1008,
      IsActive: true,
      KeyCallType: KeyCallType.GateReturn,
    },
  ],
  [
    1009,
    {
      DisplayCallType: 'RTF',
      Id: 1009,
      IsActive: true,
      KeyCallType: KeyCallType.Rtf,
    },
  ],
  [
    1010,
    {
      DisplayCallType: 'Diversion',
      Id: 1010,
      IsActive: true,
      KeyCallType: KeyCallType.Diversion,
    },
  ],
  [
    1011,
    {
      DisplayCallType: 'System Generated',
      Id: 1011,
      IsActive: true,
      KeyCallType: KeyCallType.SystemGenerated,
    },
  ],
]);

export function callTypeList(showAll: boolean): CallTypeInput[] {
  return Array.from(callTypeInputMap.values())
    .filter((value) => {
      return showAll ? true : value.IsActive;
    })
    .map(
      (value) =>
        <CallTypeInput>{
          DisplayCallType: value.DisplayCallType,
          Id: value.Id,
          KeyCallType: value.KeyCallType,
        }
    )
    .sort((a, b) =>
      (a.DisplayCallType ?? '').localeCompare(b.DisplayCallType ?? '')
    );
}
