<section>
  <div
    class="modal-header"
    [class]="toggleHeaderBgColor ? 'bg-acquamarine' : 'bg-light-sky-blue'"
  >
    <span>Delay Alert</span>
  </div>
  <div class="modal-body">
    <div class="col-12 aa-pt-3" *ngIf="flightPair">
      <p>
        Acft {{ flightPair.Equipment.NoseNumber }} Flight
        {{ flightPair.Departure?.FlightNumber }} in
        {{ flightPair.Departure?.DepartureStation }} is delayed 30 mins or more
      </p>
    </div>
    <div class="col-12">
      <p-button
        label="Acknowledge"
        styleClass="p-button-raised aa-mb-4 float-right"
        (onClick)="modalRef.hide()"
      ></p-button>
    </div>
  </div>
</section>
