import { SortOrder } from '@lm-apps/lmo/ui/data';

/**
 * Sorts two data objects based on a nested property within a parent object.
 * The function supports sorting numeric and alphanumeric values, ensuring proper ordering
 * based on the specified sort order (ascending or descending).
 *
 * ### Behavior:
 * 1. **Numeric Comparison**:
 *    - If both values are numeric, it performs a direct subtraction to determine the order.
 * 2. **Mixed Numeric and Alphanumeric Comparison**:
 *    - If one value is numeric and the other is alphanumeric, the numeric value is considered smaller.
 * 3. **Alphanumeric Comparison**:
 *    - If both values are alphanumeric, it uses `localeCompare` with the `numeric: true` option
 *      to ensure proper ordering (e.g., `1`, `2`, `10` instead of `1`, `10`, `2`).
 * 4. **Missing or Undefined Properties**:
 *    - If the property is missing or undefined in either object, it defaults to an empty string (`''`) for comparison.
 *
 * ### Parameters:
 * @param data1 - The first object to compare.
 * @param data2 - The second object to compare.
 * @param order - The sort order, which can be:
 *                - `SortOrder.ASCENDING` (for ascending order)
 *                - `SortOrder.DESCENDING` (for descending order)
 * @param parentObj - The parent object containing the property to sort by.
 * @param sortingProp - The nested property within the parent object to use for comparison.
 *
 * ### Returns:
 * - `-1`: Indicates that `data1` should come before `data2`.
 * - `1`: Indicates that `data1` should come after `data2`.
 * - `0`: Indicates that `data1` and `data2` are considered equal for sorting purposes.
 *
 * ### Example Usage:
 * ```typescript
 * const data1 = { details: { name: 'Alice' } };
 * const data2 = { details: { name: 'Bob' } };
 *
 * const result = customSort(data1, data2, SortOrder.ASCENDING, 'details', 'name');
 * console.log(result); // Output: -1 (Alice comes before Bob)
 * ```
 *
 * ### Notes:
 * - This function is particularly useful for sorting arrays of objects with nested properties (ex: flight pairs for CC view columns).
 * - It ensures consistent behavior for mixed data types (numeric and alphanumeric) and handles edge cases gracefully.
 */
export function customSort(
  data1: any,
  data2: any,
  order: SortOrder,
  parentObj: string,
  sortingProp: string
) {
  const firstVal =
    data1 && data1[parentObj] && data1[parentObj][sortingProp]
      ? data1[parentObj][sortingProp]
      : '';
  const secondVal =
    data2 && data2[parentObj] && data2[parentObj][sortingProp]
      ? data2[parentObj][sortingProp]
      : '';
  let result = null;

  // if both value is number then do regular subtraction between number
  if (!isNaN(Number(firstVal)) && !isNaN(Number(secondVal))) {
    result = Number(firstVal) - Number(secondVal);
  } else if (!isNaN(Number(firstVal))) {
    // if first value is number & second value Alphanumeric then first value is consider greater

    result = -1;
  } else if (!isNaN(Number(secondVal))) {
    // if second value is number & first value Alphanumeric then second value is consider greater

    result = 1;
  } else {
    // both first and second value is not a number then use localeCompare
    result = firstVal.localeCompare(secondVal, 'en', { numeric: true });
  }
  return order == SortOrder.ASCENDING ? 1 * result : -1 * result;
}

/**
 * Sorts two data objects based on a specified property.
 * The function supports sorting numeric and alphanumeric values, ensuring proper ordering
 * based on the specified sort order (ascending or descending).
 *
 * ### Behavior:
 * 1. **Numeric Comparison**:
 *    - If both values are numeric, it performs a direct subtraction to determine the order.
 * 2. **Mixed Numeric and Alphanumeric Comparison**:
 *    - If one value is numeric and the other is alphanumeric, the numeric value is considered smaller.
 * 3. **Alphanumeric Comparison**:
 *    - If both values are alphanumeric, it uses `localeCompare` with the `numeric: true` option
 *      to ensure proper ordering (e.g., `1`, `2`, `10` instead of `1`, `10`, `2`).
 * 4. **Missing or Undefined Properties**:
 *    - If the property is missing or undefined in either object, it defaults to an empty string (`''`) for comparison.
 *
 * ### Parameters:
 * @param data1 - The first object to compare.
 * @param data2 - The second object to compare.
 * @param order - The sort order, which can be:
 *                - `SortOrder.ASCENDING` (for ascending order)
 *                - `SortOrder.DESCENDING` (for descending order)
 * @param sortingProp - The property to use for comparison.
 *
 * ### Returns:
 * - `-1`: Indicates that `data1` should come before `data2`.
 * - `1`: Indicates that `data1` should come after `data2`.
 * - `0`: Indicates that `data1` and `data2` are considered equal for sorting purposes.
 *
 * ### Example Usage:
 * ```typescript
 * const data1 = { name: 'Alice' };
 * const data2 = { name: 'Bob' };
 *
 * const result = sortByProperty(data1, data2, SortOrder.ASCENDING, 'name');
 * console.log(result); // Output: -1 (Alice comes before Bob)
 * ```
 *
 * ### Notes:
 * - This function is particularly useful for sorting arrays of objects based on a specific property (ex: crew members by id).
 * - It ensures consistent behavior for mixed data types (numeric and alphanumeric) and handles edge cases gracefully.
 */
export function sortByProperty(
  data1: any,
  data2: any,
  order: SortOrder,
  sortingProp: string
) {
  const firstVal = data1 && data1[sortingProp] ? data1[sortingProp] : '';
  const secondVal = data2 && data2[sortingProp] ? data2[sortingProp] : '';
  let result = null;

  // if both value is number then do regular subtraction between number
  if (!isNaN(Number(firstVal)) && !isNaN(Number(secondVal))) {
    result = Number(firstVal) - Number(secondVal);
  } else if (!isNaN(Number(firstVal))) {
    // if first value is number & second value Alphanumeric then first value is consider greater

    result = -1;
  } else if (!isNaN(Number(secondVal))) {
    // if second value is number & first value Alphanumeric then second value is consider greater

    result = 1;
  } else {
    // both first and second value is not a number then use localeCompare
    result = firstVal.localeCompare(secondVal, 'en', { numeric: true });
  }
  return order == SortOrder.ASCENDING ? result : -result;
}
