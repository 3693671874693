import { Pipe, PipeTransform } from '@angular/core';
import { FlightsEntity } from '@lm-apps/lmo/ui/data';
@Pipe({
  name: 'filterByFlightNumber',
})
export class FilterByFlightNumberPipe implements PipeTransform {
  transform(flights: FlightsEntity[], flightNumber: string): FlightsEntity[] {
    return flightNumber.trim() === ''
      ? flights
      : flights.filter(
          (f) =>
            f.Arrival?.FlightNumber?.startsWith(flightNumber) ||
            f.Departure?.FlightNumber?.startsWith(flightNumber)
        );
  }
}
