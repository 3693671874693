<section>
  <form [formGroup]="form" (ngSubmit)="saveChanges()">
    <div class="modal-header">
      <span class="">Admin Notice</span>
      <p-button
        icon="pi pi-times"
        styleClass="p-button-secondary p-button-text cross"
        (onClick)="closeModal()"
      ></p-button>
    </div>

    <div class="modal-body d-flex flex-column gap-2 aa-m-4">
      <label for="title"
        >Title<span class="required-form-field-asterisk">*</span></label
      >
      <input
        id="title"
        class="aa-mb-4"
        pInputText
        id="title"
        formControlName="title"
        maxlength="30"
      />

      <label for="description"
        >Description<span class="required-form-field-asterisk">*</span></label
      >
      <textarea
        id="description"
        class="aa-mb-4"
        pInputTextarea
        id="description"
        formControlName="description"
        maxlength="500"
      ></textarea>

      <label for="displayDuration">Display Duration (Local Timezone)</label>
      <span class="aa-mb-4">
        <p-calendar
          id="displayDuration"
          [minDate]="minDate"
          [showTime]="true"
          [showSeconds]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          formControlName="displayStart"
          (onClear)="onClear(this.form.controls.displayStart)"
        ></p-calendar>
        <span class="aa-pr-2 aa-pl-2">&#8594;</span>
        <p-calendar
          [minDate]="minDate"
          [showTime]="true"
          [showSeconds]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          formControlName="displayEnd"
          (onClear)="onClear(this.form.controls.displayEnd)"
        ></p-calendar>
        <div
          *ngIf="(form.controls.displayStart.dirty || form.controls.displayEnd.dirty) &&  form.controls.displayEnd.errors?.['futureTime']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.futureTime }}
        </div>
        <div
          *ngIf="(form.controls.displayStart.dirty || form.controls.displayEnd.dirty) && form.errors?.['missingControl']?.['display']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.missingControl }}
        </div>
        <div
          *ngIf="(form.controls.displayStart.dirty || form.controls.displayEnd.dirty) && form.errors?.['invalidOrder']?.['display']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.invalidOrder }}
        </div>
      </span>

      <label for="downtimeDuration">Downtime Duration (Local Timezone)</label>
      <span class="aa-mb-4">
        <p-calendar
          id="downtimeDuration"
          [minDate]="minDate"
          [showTime]="true"
          [showSeconds]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          formControlName="downtimeStart"
          (onClear)="onClear(this.form.controls.downtimeStart)"
        ></p-calendar>
        <span class="aa-pr-2 aa-pl-2">&#8594;</span>
        <p-calendar
          [minDate]="minDate"
          [showTime]="true"
          [showSeconds]="true"
          [showIcon]="true"
          [showOnFocus]="false"
          formControlName="downtimeEnd"
          (onClear)="onClear(this.form.controls.downtimeEnd)"
        ></p-calendar>
        <div
          *ngIf="(form.controls.downtimeStart.dirty || form.controls.downtimeEnd.dirty) && form.controls.downtimeEnd.errors?.['futureTime']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.futureTime }}
        </div>
        <div
          *ngIf="(form.controls.displayStart.dirty || form.controls.displayEnd.dirty) && form.errors?.['missingControl']?.['downtime']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.missingControl }}
        </div>
        <div
          *ngIf="(form.controls.downtimeStart.dirty || form.controls.downtimeEnd.dirty) && form.errors?.['invalidOrder']?.['downtime']"
          class="aa-mt-2 admin-notice-error-message"
        >
          {{ errorMessages.invalidOrder }}
        </div>
      </span>
      <hr />
      <div class="container">
        <div class="row">
          <div class="col-md-12 aa-p-0 text-right">
            <span class="cancel-button">
              <p-button
                class="aa-mr-2"
                styleClass="p-button-secondary"
                label="Cancel"
                (onClick)="closeModal()"
              ></p-button>
            </span>
            <p-button
              [disabled]="
                this.form.pristine ||
                this.form.controls.title.invalid ||
                this.form.controls.description.invalid
              "
              label="Save"
              type="submit"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
