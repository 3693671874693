import { Pipe, PipeTransform } from '@angular/core';
import { AssignedMember, TaskState } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'getIndividualAmtStatus',
})
export class GetIndividualAmtStatusPipe implements PipeTransform {
  transform(assignedMember: AssignedMember | null): {
    status: TaskState;
    timestamp: Date | null;
  } {
    const defaultAmtStatusObject: {
      status: TaskState;
      timestamp: Date | null;
    } = {
      status: TaskState.Unassigned,
      timestamp: null,
    };
    if (!assignedMember) {
      return defaultAmtStatusObject;
    }
    if (assignedMember.CompletedOn) {
      return {
        status: TaskState.Completed,
        timestamp: assignedMember.CompletedOn,
      };
    }
    if (assignedMember.ArrivedOn) {
      return {
        status: TaskState.Arrived,
        timestamp: assignedMember.ArrivedOn,
      };
    }
    if (assignedMember.AcknowledgedOn) {
      return {
        status: TaskState.Acknowledged,
        timestamp: assignedMember.AcknowledgedOn,
      };
    }

    if (assignedMember.DeliveredOn) {
      return {
        status: TaskState.Delivered,
        timestamp: assignedMember.DeliveredOn,
      };
    }

    if (assignedMember.AssignedOn) {
      return {
        status: TaskState.Assigned,
        timestamp: assignedMember.AssignedOn,
      };
    }
    return defaultAmtStatusObject;
  }
}
