@switch (taskState) { @case (taskStateValues.Assigned) {
<i class="pi pi-send aa-dark-gray"></i>
} @case (taskStateValues.Acknowledged) {
<i class="fa fa-thumbs-o-up aa-dark-green"></i>
} @case (taskStateValues.Arrived) {
<i class="fa fa-plane aa-pink fa-rotate-360"></i>
} @case (taskStateValues.Delivered) {
<span class="delivered-icon"></span>
} @case (taskStateValues.Completed) {
<i class="pi pi-check-circle aa-dark-green"></i>
} }
