<div class="row justify-start bg-shade-gray aa-m-0">
  <p-button
    *ngIf="!isMyTasks"
    icon="pi pi-times"
    styleClass="p-button-secondary p-button-text cross"
    (onClick)="close()"
  ></p-button>
  <div class="title-txt">Aircraft Attributes</div>
</div>

<lm-apps-aircraft-attribute-table
  [flight]="flight"
  [safeAircraftUri]="config.SAFE_URL"
></lm-apps-aircraft-attribute-table>
