/**
 * Task Status Filters
 */
export enum TaskStatusFilter {
  /**
   * SHOW_ALL_AC
   */
  SHOW_ALL_AC = 'All',
  /**
   * ASSIGNED
   */
  ASSIGNED = 'Assigned',
  /**
   * COMPLETED
   */
  COMPLETED = 'Completed',
  /**
   * NEW_TASK
   */
  NEW_TASK = 'New',
  /**
   * UNASSIGNED
   */
  UNASSIGNED = 'Unassigned',
  /**
   * NOT_COMPLETED
   */
  NOT_COMPLETED = 'NotCompleted',
  /**
   * WITH_NO_TASK
   */
  WITH_NO_TASK = 'NIL',
  /**
   * AVIONICS
   */
  AVIONICS = 'avionics',

  /**
   * DEFERRED
   */
  DEFERRED = 'Deferred',
}
