<span>
  <div class="h3-1" data-cy="cc-view-inbd-flight-number">
    {{ arrivalDate }}
  </div>
  <div>
    <strong
      [ngClass]="arrival?.EtopsCheckCompleted ? 'aa-green' : 'aa-dark-blue'"
      *ngIf="arrival?.IsEtopsFlight"
      data-cy="cc-view-inbd-etops"
      >ETOPS FLT<br
    /></strong>
  </div>
  <div>
    <strong
      class="aa-dark-blue"
      *ngIf="arrival?.LegStatus === 'D'"
      data-cy="cc-view-inbd-diverted"
      >DIVERTED<br
    /></strong>
  </div>
  <div class="font-weight-bold text-muted">
    <span data-cy="cc-view-inbd-departure-station">{{
      arrival?.DepartureStation
    }}</span>
    -
    <span data-cy="cc-view-inbd-arrival-station">{{
      arrival?.ArrivalStation
    }}</span>
  </div>
</span>
