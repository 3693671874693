import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AssignedMember, TaskEntity, TaskState } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-task-status',
  templateUrl: './task-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusComponent implements OnChanges {
  @Input() task!: TaskEntity;
  @Input() employeeId: string | null = null;
  @Output() updatedTaskState = new EventEmitter<TaskEntity>();

  TaskState = TaskState;
  assignedMember: AssignedMember | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    const task = changes['task'];
    if (task && task.currentValue && this.employeeId) {
      const currentTask = <TaskEntity>task.currentValue;

      this.assignedMember =
        currentTask.AssignedMembers?.find(
          (x) => x.EmployeeId === this.employeeId
        ) ?? null;
    }
  }
  save(taskState: TaskState) {
    this.updatedTaskState.emit({
      ...this.task,
      TaskState: taskState,
    });
  }
}
