export enum HistoryTaskStatusFilter {
  ALL = 'All',
  ASSIGNED = 'Assigned',
  UNASSIGNED = 'Unassigned',
  NOT_COMPLETED = 'Deferred',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  SYSTEM_CLOSED = 'SystemClosed',
  SUPPRESSED = 'Suppressed',
}
