import {
  ALTERNATE_TASKS_FEATURE_KEY,
  AlternateTasksSortableColumn,
  AlternateTask,
} from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlternateTasksState } from './alternate-tasks.interfaces';
import * as fromAlternateTasks from './alternate-tasks.reducer';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import { customSort } from './helper';

export const getAlternateTasksState =
  createFeatureSelector<AlternateTasksState>(ALTERNATE_TASKS_FEATURE_KEY);

export const selectAlternateTasks = createSelector(
  getAlternateTasksState,
  (state: AlternateTasksState) => {
    return state.ids
      .map((id) => state.entities[id])
      .filter((entity): entity is AlternateTask => entity !== undefined);
  }
);

export const selectCurrentAlternateTask = createSelector(
  getAlternateTasksState,
  (state: AlternateTasksState) => {
    return state.selectedId ? state.entities[state.selectedId] : null;
  }
);

export const selectSortDetail = createSelector(
  getAlternateTasksState,
  fromAlternateTasks.getSelectedAlternateTasksSortDetail
);

export const selectAllAlternateTasks = createSelector(
  getAlternateTasksState,
  fromAlternateTasks.selectAllAlternateTasks
);

export const selectAllAlternateTasksSorted = createSelector(
  selectAllAlternateTasks,
  selectSortDetail,
  (alternateTasks, sortDetail) => {
    if (alternateTasks && alternateTasks.length > 1) {
      const sortColumn = AlternateTasksSortableColumn[sortDetail.column];

      alternateTasks.sort((a, b) =>
        customSort(a, b, sortDetail.sortOrder, sortColumn)
      );
    }
    return alternateTasks;
  }
);

export const selectMyAlternateTasksSorted = createSelector(
  selectAllAlternateTasksSorted,
  fromUser.selectUser,
  (alternateTasks, user) => {
    return alternateTasks.filter((task) => {
      const assignedMembers: (string | undefined)[] =
        (task.AssignedMembers ?? []).map((m) => m.EmployeeId) ?? [];
      const userId = user?.EmployeeId;

      return (
        task.ChangedBy === userId ||
        task.UserId === userId ||
        assignedMembers.includes(userId)
      );
    });
  }
);

export const selectIsAlternateTasksLoaded = createSelector(
  getAlternateTasksState,
  (state) => state.loaded
);
