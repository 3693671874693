<form [formGroup]="filterSection" class="col-12">
  <div class="row">
    <div class="col">
      <div>
        <div class="sub-heading">
          <span>Range</span>
        </div>
        <div class="col-12">
          <div *ngFor="let r of rangeList">
            <p-radioButton
              [value]="r"
              formControlName="rangeFrmCtrl"
              styleClass="aa-mb-1"
              [label]="r.toString()"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Fleet</span>
          <a
            (click)="onSelectDeselectAll(!selectAll.fleet, SelectAllType.Fleet)"
            class="float-right"
            >{{ selectAll.fleet ? DESELECT_ALL : SELECT_ALL }}</a
          >
        </div>
        <div class="col-12">
          <div *ngFor="let fleet of fleetsDisplayList" class="field-checkbox">
            <p-checkbox
              [formControl]="filterSection.controls.fleetFrmCtrl"
              [label]="fleet"
              name="fleetGroup"
              [value]="fleet"
              (click)="displaySelectDeselectAll(SelectAllType.Fleet)"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Zones</span>
          <a
            class="float-right"
            (click)="onSelectDeselectAll(!selectAll.zone, SelectAllType.Zone)"
            >{{ selectAll.zone ? DESELECT_ALL : SELECT_ALL }}</a
          >
        </div>

        <div class="col-12" *ngIf="stations.length === 1">
          <div *ngFor="let zone of zones">
            <!-- using [formControl] ranther then formControlName because checkbox is not working with formControlName, it was only taking the latest value and replaced older one -->
            <p-checkbox
              name="zoneGroup"
              [value]="zone.ZoneName"
              [formControl]="filterSection.controls.zonesFrmCtrl"
              [label]="zone.ZoneName"
              (click)="displaySelectDeselectAll(SelectAllType.Zone)"
            ></p-checkbox>
            <!--               [diabled]="!filterSection.controls.gateOptionFrmCtrl.value" -->
          </div>
        </div>
        <ng-template #multiStationZone>
          <div class="col-12">
            <!-- using [formControl] ranther then formControlName because checkbox is not working with formControlName, it was only taking the latest value and replaced older one -->
            <p-checkbox
              name="allZone"
              [value]="zoneAll"
              label="All"
              [formControl]="filterSection.controls.zonesFrmCtrl"
            ></p-checkbox>
          </div>
        </ng-template>
      </div>
      <div class="gate">
        <div class="sub-heading display-flex justify-space-between">
          <div class="display-flex">
            <span>Gates</span>
            <div class="gate-toggle aa-mr-4">
              <p-inputSwitch
                styleClass=""
                formControlName="gateOptionFrmCtrl"
                (onChange)="
                  enableDisableZone(
                    !filterSection.controls.gateOptionFrmCtrl.value
                  )
                "
              ></p-inputSwitch>
            </div>
            <div
              class="aa-ml-4"
              [tooltip]="toolTemplate"
              placement="top"
              triggers="mouseenter:mouseleave click:click"
            >
              <fa-icon [icon]="faCircleInfo"></fa-icon>
            </div>
          </div>
          <a
            class="float-right"
            *ngIf="
              filterSection.controls.gateOptionFrmCtrl.value &&
              filterSection.controls.gates.value.length > 0
            "
            (click)="clearGates()"
            >Clear All</a
          >
        </div>
        <div
          class="col-12"
          [hidden]="!filterSection.controls.gateOptionFrmCtrl.value"
        >
          <div
            class="gate-input-area aa-mb-2"
            [class.border-red]="isGateInputError"
            #gateInputArea
            (click)="focusGateInput()"
          >
            <p-tag
              *ngFor="let range of filterSection.controls.gates.value"
              styleClass="gate-range-tag aa-m-1"
            >
              <span>{{ range }}</span
              >&nbsp;
              <button
                type="button"
                (click)="removeGateOrRange(range)"
                class="remove-station-button pi pi-times"
              ></button>
            </p-tag>
            <input
              type="text"
              class="gate-input-box text-uppercase"
              #gateInputField
              [(ngModel)]="gateInputText"
              [ngModelOptions]="{ standalone: true }"
              (keydown.enter)="addGateOrRange()"
              (keydown.tab)="tabOnGateInput($event)"
              (blur)="unfocusGateInput()"
              placeholder="Enter Gate Or Gate Range"
              [pKeyFilter]="gateRangeRexExp"
            />
          </div>
          <div *ngIf="invalidGateRange.length">
            <strong>Invalid Gate Range: </strong>
            <span
              *ngFor="let g of invalidGateRange; let i = index"
              class="aa-red"
              >{{ g }}{{ i !== invalidGateRange.length - 1 ? ',' : '' }}
            </span>
          </div>
        </div>
        <ng-template #toolTemplate>
          <div class="display-flex">
            <span>&#8226;&nbsp;</span
            ><span
              >Add Gates/Ranges by using either: enter, tab or comma ","</span
            >
          </div>
          <div class="display-flex">
            <span>&#8226;&nbsp;</span
            ><span>Special characters not allowed, except the comma ","</span>
          </div>

          <div class="display-flex">
            <span>&#8226;&nbsp;</span
            ><span>Single Gate is an exact match only</span>
          </div>
          <div class="display-flex">
            <span>&#8226;&nbsp;</span
            ><span
              >Progressive search (A26-C16) only, Reverse search (C16-A26) not
              allowed</span
            >
          </div>
          <div class="display-flex">
            <span>&#8226;&nbsp;</span
            ><span>Multiple range examples: A1-A10, 1-23, D21A-D28</span>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col">
      <div>
        <div class="sub-heading">
          <span>Delays</span>
        </div>
        <div class="col-12">
          <div *ngFor="let d of delaysFilterList">
            <p-radioButton
              [value]="d.keyValue"
              formControlName="delayFrmCtrl"
              styleClass="aa-mb-1"
              [label]="d.displayName"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Misc</span>
        </div>
        <div class="col-12">
          <div *ngFor="let m of miscsFilterList">
            <p-radioButton
              [value]="m.keyValue"
              formControlName="miscFrmCtrl"
              styleClass="aa-mb-1"
              [label]="m.displayName"
            ></p-radioButton>
          </div>
        </div>
      </div>

      <div>
        <div class="sub-heading">
          <span>Alert Settings</span>
        </div>
        <div class="col-12">
          <div>
            <p-checkbox
              name="otherToaster"
              [binary]="true"
              value="filterSection.controls.otherToastFrmCtrl.value"
              label="Display Amber and Blue Task alerts"
              formControlName="otherToastFrmCtrl"
            ></p-checkbox>
            <p-checkbox
              name="redToaster"
              [binary]="true"
              value="filterSection.controls.otherToastFrmCtrl.value"
              label="Display Red Alerts for Task Not Completed for STD"
              formControlName="redToastFrmCtrl"
            ></p-checkbox>
            <p-checkbox
              name="noiseToaster"
              [binary]="true"
              value="filterSection.controls.otherToastFrmCtrl.value"
              label="Enable Task Noise alerts"
              formControlName="noiseToastFrmCtrl"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div>
        <div class="sub-heading">
          <span>Task Status</span>
        </div>
        <div class="col-12">
          <p-radioButton
            [value]="TaskStatusFilter.ShowAllAc"
            formControlName="noTaskFrmCtrl"
            styleClass="aa-mb-1"
            label="Show All Aircraft"
            (click)="filterSection.controls.withTaskFrmCtrl.disable()"
          ></p-radioButton>
          <div>
            <p-radioButton
              [value]="WITH_TASK"
              formControlName="noTaskFrmCtrl"
              styleClass="aa-mb-1"
              [label]="WITH_TASK"
              (click)="withTaskClick()"
            >
            </p-radioButton>
            <p-checkbox
              [formControl]="filterSection.controls.withTaskFrmCtrl"
              label="and Avionics"
              styleClass="aa-ml-2"
              name="taskGroup"
              [value]="TaskStatusFilter.Avionics"
            ></p-checkbox>
            <div *ngFor="let t of taskStatusFilterList" class="aa-pl-4">
              <p-checkbox
                [formControl]="filterSection.controls.withTaskFrmCtrl"
                [label]="t.displayName"
                name="taskGroup"
                [value]="t.keyValue"
              ></p-checkbox>
            </div>
          </div>

          <div class="aa-mt-2">
            <p-radioButton
              [value]="TaskStatusFilter.WithNoTask"
              formControlName="noTaskFrmCtrl"
              styleClass="aa-mb-1"
              label="With No Task"
              (click)="filterSection.controls.withTaskFrmCtrl.disable()"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div>
        <div class="sub-heading">
          <span>Notification/Task Type</span>
        </div>
        <div class="col-12">
          <p-radioButton
            [value]="NotificationFilter.ShowAllAc"
            formControlName="noNotificationFrmCtrl"
            label="Show All Aircraft"
            (click)="filterSection.controls.withNotificationFrmCtrl.disable()"
          ></p-radioButton>
          <div>
            <div class="aa-mt-2">
              <p-radioButton
                [value]="WITH_NOTIFICATION"
                formControlName="noNotificationFrmCtrl"
                styleClass="aa-mb-1"
                [label]="WITH_NOTIFICATION"
                (click)="withNotificationClick()"
              >
              </p-radioButton>
              <a
                class="float-right"
                *ngIf="filterSection.controls.withNotificationFrmCtrl.enabled"
                (click)="
                  onSelectDeselectAll(
                    !selectAll.notification,
                    SelectAllType.Notification
                  )
                "
                >{{ selectAll.notification ? DESELECT_ALL : SELECT_ALL }}</a
              >
            </div>

            <div *ngFor="let n of notificationsList" class="aa-pl-4">
              <p-checkbox
                [formControl]="filterSection.controls.withNotificationFrmCtrl"
                [label]="n.displayName"
                name="notificationGroup"
                [value]="n.keyValue"
                (click)="displaySelectDeselectAll(SelectAllType.Notification)"
              ></p-checkbox>
            </div>
          </div>

          <div class="aa-mt-2">
            <p-radioButton
              [value]="NotificationFilter.WithNoNotification"
              formControlName="noNotificationFrmCtrl"
              styleClass="aa-mb-1"
              label="With No Notification"
              (click)="filterSection.controls.withNotificationFrmCtrl.disable()"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer aa-py-1">
    <p-button
      label="Select All"
      styleClass="p-button-success p-button-raised"
      (click)="setDefaultFilter()"
    ></p-button>

    <p-button
      label="Cancel"
      styleClass="p-button-danger p-button-raised aa-mx-2"
      (onClick)="close()"
    ></p-button>
    <p-button
      type="button"
      label="Save"
      styleClass="p-button-raised"
      (click)="save()"
      [disabled]="
        filterSection.controls.gateOptionFrmCtrl.value &&
        (!filterSection.controls.gates.value.length || invalidGateRange.length)
      "
    ></p-button>
  </div>
</form>
