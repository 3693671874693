import { Pipe, PipeTransform } from '@angular/core';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';
import { TaskEntity, TaskState } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'unassigned',
})
export class UnassignedTaskPipe implements PipeTransform {
  excludedNotifications = new Set([SceptreLables.AirworthinessOPS.toString()]);

  transform(tasks: TaskEntity[]) {
    return (
      tasks.filter(
        (x) =>
          x.TaskState === TaskState.Unassigned &&
          x.CallType !== null &&
          x.JobType !== null &&
          !this.excludedNotifications.has(x.Label ?? '')
      ) ?? []
    );
  }
}
