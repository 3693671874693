/* eslint-disable @angular-eslint/no-input-rename */
import { Renderer2 } from '@angular/core';
import {
  Component,
  Input,
  forwardRef,
  ElementRef,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
let counter = 0;

@Component({
  selector: 'lm-apps-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true,
    },
  ],
})
export class CheckboxListComponent implements ControlValueAccessor, OnInit {
  _data: Array<any> = [];
  get data(): Array<any> {
    return this._data;
  }

  @Input('data')
  set data(value: Array<any>) {
    this._data = value;
    if (this.isPrimitiveArray(value)) {
      this.valuePrimitive = true;
      this._data = value.map((v) => {
        return {
          id: v,
          description: v,
        };
      });
    }
  }
  @Input()
  valueField!: string;
  @Input()
  labelField!: string;
  @Input()
  valuePrimitive = true; // value will be set to this attribute values of selected items
  @Input()
  tabindex = 0;
  @Input()
  colClass = 'col-sm-4';
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  blur = new EventEmitter();

  @Input()
  selectedCss = '';
  @Output()
  selected = new EventEmitter();
  @Output()
  checkboxChange = new EventEmitter<{ checked: boolean; value: string }>();
  @Input()
  selecteditem: any;

  @Input()
  foreColorConditionField!: string;

  @Input()
  foreColorConditionCss!: string;

  @Input()
  foreColorConditionElseCss!: string;

  public _disabled = false;
  public identifier = `checkbox-${counter++}`;
  private current_value: Array<any> = [];

  @Input()
  indeterminate_values: Array<string> = [];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // to remove the blue border around the control on tab
    this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '');
  }
  private isPrimitiveArray(value: Array<any>): boolean {
    if (typeof value[0] === 'string' || typeof value[0] === 'number') {
      return true;
    }

    return false;
  }
  // this is the initial value set to the component
  // can be an array of objects or an array of integer values
  public writeValue(obj: Array<any>): void {
    if (obj && !Array.isArray(obj)) {
      throw new Error('value is not an array');
    }
    this.current_value = [];
    if (obj) {
      this.current_value = obj.map((o) => {
        if (o[this.valueField]) {
          return o;
        }
        const dummy: { [key: string]: any } = {};
        dummy[this.valueField] = o;

        return dummy;
      });
    }
  }
  public isChecked(item: any): boolean {
    return (
      this.current_value &&
      this.current_value.length > 0 &&
      this.current_value
        .filter((d) => !!d)
        .find((d) => d[this.valueField] === (item[this.valueField] || item)) !=
        null
    );
  }
  // registers 'fn' that will be fired when changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // not used, used for touch input
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  public onTouch(): void {
    this.propagateTouch();
    this.blur.emit();
  }
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  // change events from the checkbox
  public onChange(evnt: any, inputValue: any): void {
    const index = this.indeterminate_values.findIndex(
      (value) => value == inputValue
    );
    if (index > -1) {
      this.indeterminate_values.splice(index, 1);
      this.indeterminate_values = [...this.indeterminate_values];
    }

    const newValue: any = inputValue;
    if (evnt.checked) {
      const item: any = this._data
        .filter((d) => !!d)
        .find((d) => d[this.valueField] === newValue);
      this.current_value.push(item);
    } else {
      const idx = this.current_value
        .filter((d) => !!d)
        .findIndex((d) => d[this.valueField] === newValue);
      this.current_value.splice(idx, 1);
    }
    this.propagateChange(this.value);
    this.checkboxChange.emit({ checked: evnt.checked, value: inputValue });
  }
  get value(): Array<any> {
    let formValue: Array<any> = [];
    if (this.current_value && this.current_value.length > 0) {
      formValue = this.current_value
        .filter((d) => !!d)
        .map((d) => (this.valuePrimitive ? d[this.valueField] : d));
    }
    return formValue;
  }
  // the method set in registerOnChange to emit changes back to the form
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateChange = (_: Array<any> | null) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private propagateTouch = () => {};

  selectItem(item: any) {
    this.selecteditem = item;
    this.selected.emit(item);
  }
}
