import { DUMMY_AIRCRAFT } from '@lm-apps/lmo/shared/enums';
import {
  AlternateTask,
  AlternateTasksSortableColumn,
  AlternateTasksSortOrder,
} from '@lm-apps/lmo/ui/data';

/**
 * This function sort the data in numberic then to alpha numeric. eg: 3,7,1z,cc
 * values -1 and 1 are used to indicate that one element should come before or after the other element, respectively.
 * returns -1, it indicates that the first element (a) should come before the second element (b) in the sorted array,
 * returns 1, it indicates that the first element (a) should come after the second element (b) in the sorted array.
 * returns 0, it indicates both element are same
 *
 */
export function customSort(
  data1: AlternateTask,
  data2: AlternateTask,
  order: AlternateTasksSortOrder,
  sortingProp: AlternateTasksSortableColumn
) {
  const firstVal = data1[sortingProp] ?? '';
  const secondVal = data2[sortingProp] ?? '';

  let result = 0;

  if (firstVal === DUMMY_AIRCRAFT && secondVal !== DUMMY_AIRCRAFT) {
    // if first value is DUMMY_AIRCRAFT and second value is not then first value is considered less
    result = -1;
  } else if (secondVal === DUMMY_AIRCRAFT && firstVal !== DUMMY_AIRCRAFT) {
    // if second value is DUMMY_AIRCRAFT and first value is not then second value is considered less
    result = 1;
  } else if (!isNaN(Number(firstVal)) && !isNaN(Number(secondVal))) {
    // if both value is number then do regular subtraction between number
    result = Number(firstVal) - Number(secondVal);
  } else if (!isNaN(Number(firstVal))) {
    // if first value is number & second value Alphanumeric then first value is considered greater
    result = -1;
  } else if (!isNaN(Number(secondVal))) {
    // if second value is number & first value Alphanumeric then second value is considered greater
    result = 1;
  } else {
    // both first and second value is not a number then use localeCompare
    result = firstVal.localeCompare(secondVal, 'en', { numeric: true });
  }

  // if both value are the same then sort by ChangedOn in descending order (latest changed should come first)
  if (result === 0 && data1.ChangedOn !== data2.ChangedOn) {
    return data1.ChangedOn > data2.ChangedOn ? -1 : 1;
  }

  return order == AlternateTasksSortOrder.ASCENDING ? 1 * result : -1 * result;
}
