import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { BowItemStatus } from '@lm-apps/lmo/shared/enums';
import { TaskEntity } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-bow-item-status',
  templateUrl: './bow-item-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowItemStatusComponent {
  @Input({ required: true }) public selectedBowItem!: TaskEntity;
  public readonly listOfBowItemStatuses = BowItemStatus;
  public readonly completedBowItemTooltipText =
    'The Accountability Report must be printed from SAFE';
  public readonly faInfoCircleIcon = faInfoCircle;
}
