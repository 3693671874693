import { FeatureFlagsConfig } from '@aa-techops-ui/feature-flags';

export interface AppEnvironment extends FeatureFlagsConfig {
  /**
   *LMO Backend API Base URL
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  LMO_API_BASE_URL: string;

  /**
   *LMO Classic URL
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  LMO_CLASSIC_URL: string;
  /**
   *LMO WALL Classic URL
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  LMO_WALL_CLASSIC_URL: string;
  /**
   *TFP Graph URL
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  TFP_GRAPH_URL: string;
  /**
   *SAFE Application URL
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  SAFE_URL: string;
  /**
   *Application Environment
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  APP_ENVIRONMENT: string;
  APP_NAME: 'lmo' | 'lmo-wall';
  APP_VERSION: string;
  /**
   *Automatically logout user when token renewal fails or when backend returns a 401
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  AUTO_LOGOUT_ON_UNAUTHORIZED: 'Y' | 'N';
  /**
   *LMO Flights Polling Interval in seconds
   *
   * @type {number}
   * @memberof AppEnvironment
   */
  LMO_FLIGHTS_POLLING_INTERVAL_SECONDS: number;

  /**
   *BOW tasks feature flag
   *
   * @type {string}
   * @memberof AppEnvironment
   */
  ENABLE_BOW_FEATURE: 'true' | 'false';
}
