import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DelaysFilter,
  Filter,
  MiscFilter,
  NotificationFilter,
  TaskStatusFilter,
  Zone,
  defaultFilter,
  NotificationsList,
  DelaysFilterList,
  MiscsFilterList,
  TaskStatusFilterList,
} from '@lm-apps/lmo/ui/data';
import { FormControl, FormGroup } from '@angular/forms';
import { intersection, isEqual } from 'lodash';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export enum SelectAllType {
  Zone,
  Fleet,
  Notification,
}
interface SelectAll {
  fleet: boolean;
  zone: boolean;
  notification: boolean;
}

@Component({
  selector: 'lm-apps-cc-view-filter',
  templateUrl: './filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit {
  @Input() isMoc!: boolean;
  @Input() filter!: Filter;
  @Input() allFleets: string[] = [];
  @Input() zones: Zone[] = [];
  @Input() stations: string[] = [];
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() saveEvent: EventEmitter<Filter> = new EventEmitter();

  readonly showAllAc = 'ShowAllAc';
  readonly delaysFilterList = DelaysFilterList;
  readonly miscsFilterList = MiscsFilterList;
  readonly taskStatusFilterList = TaskStatusFilterList;
  readonly notificationsList = NotificationsList;
  readonly notificationsKeyValueList = this.notificationsList.map(
    (x) => x.keyValue
  );
  readonly taskKeyValueList = this.taskStatusFilterList.map((x) => x.keyValue);
  readonly TaskStatusFilter = TaskStatusFilter;
  readonly NotificationFilter = NotificationFilter;
  readonly faCircleInfo = faCircleInfo;

  SelectAllType = SelectAllType;
  zoneAll = 'All';
  readonly SELECT_ALL = 'Select All';
  readonly DESELECT_ALL = 'Deselect All';
  readonly WITH_NOTIFICATION = 'With Notification';
  readonly WITH_TASK = 'With Task';

  rangeList: number[] = [2, 4, 6, 8, 10, 12];
  fleetsDisplayList: string[] = [];
  zoneNameList: string[] = [];
  includeTasks = true;
  onlyMyTasks = false;
  gateInputText = '';
  gateRangeRexExp = /^[a-zA-Z0-9,-]+$/;
  invalidGateRange: string[] = [];
  sameRangeRegex = /^([A-Za-z0-9]+)-\1$/; // check if gate before and after hyphen are same or not
  findHyphensAtStarAndEndRegex = /^-+|-+$/g; // check hyphens at the start or end of string
  findDoubleHyphensRegex = /--+/g; // check double hyphens in the string
  gateInputFocusCss = 'gate-input-focus';
  isGateInputError = false;
  /*
this is used to display select all or deselect all button.
if prop is true then it will show deselect all button, which indicates all values are selected
*/
  selectAll: SelectAll = {
    fleet: false,
    zone: false,
    notification: false,
  };

  filterSection = new FormGroup({
    rangeFrmCtrl: new FormControl<number>(defaultFilter.Range, {
      nonNullable: true,
    }),

    fleetFrmCtrl: new FormControl<string[]>([], {
      nonNullable: true,
    }),
    zonesFrmCtrl: new FormControl<string[]>(
      { value: [], disabled: true },
      {
        nonNullable: true,
      }
    ),
    gateOptionFrmCtrl: new FormControl(false, {
      nonNullable: true,
    }),
    gates: new FormControl<string[]>([], {
      nonNullable: true,
    }),
    delayFrmCtrl: new FormControl(DelaysFilter.ShowAllAc, {
      nonNullable: true,
    }),
    miscFrmCtrl: new FormControl(MiscFilter.ShowAllAc, {
      nonNullable: true,
    }),
    otherToastFrmCtrl: new FormControl(false, {
      nonNullable: true,
    }),
    redToastFrmCtrl: new FormControl(false, {
      nonNullable: true,
    }),
    noiseToastFrmCtrl: new FormControl(false, {
      nonNullable: true,
    }),

    withNotificationFrmCtrl: new FormControl<NotificationFilter[]>(
      { value: [], disabled: true },
      {
        nonNullable: true,
      }
    ),
    noNotificationFrmCtrl: new FormControl<string>(
      NotificationFilter.ShowAllAc,
      {
        nonNullable: true,
      }
    ),
    withTaskFrmCtrl: new FormControl<TaskStatusFilter[]>(
      { value: [], disabled: true },
      {
        nonNullable: true,
      }
    ),
    noTaskFrmCtrl: new FormControl<string>(TaskStatusFilter.ShowAllAc, {
      nonNullable: true,
    }),
  });

  @ViewChild('gateInputField', { static: false })
  gateInputField!: ElementRef<HTMLInputElement>;
  @ViewChild('gateInputArea', { static: false })
  gateInputArea!: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.filterSection.controls.gates.patchValue(this.filter.Gates);
    this.setRange();
    this.setFleets();
    const enableZone =
      !this.filter.GateOptions || this.filter.Gates.length === 0;
    this.enableDisableZone(enableZone);
    this.setZoneFilter();
    this.filterSection.controls.gateOptionFrmCtrl.patchValue(
      this.filter.GateOptions
    );
    this.filterSection.controls.delayFrmCtrl.patchValue(this.filter.Delay);
    this.filterSection.controls.miscFrmCtrl.patchValue(
      this.filter.Miscellaneous
    );
    this.filterSection.controls.otherToastFrmCtrl.patchValue(
      this.filter.OtherToaster
    );
    this.filterSection.controls.redToastFrmCtrl.patchValue(
      this.filter.RedToaster
    );
    this.filterSection.controls.noiseToastFrmCtrl.patchValue(
      this.filter.NoiseToaster
    );

    this.setTaskStatus();
    this.setNotification();
  }
  setRange() {
    if (this.stations.length > 1 && !this.isMoc) {
      this.filterSection.controls.rangeFrmCtrl.patchValue(2);
      this.filterSection.controls.rangeFrmCtrl.disable();
    } else
      this.filterSection.controls.rangeFrmCtrl.patchValue(this.filter.Range);
  }

  setFleets() {
    this.fleetsDisplayList = this.trimFleetTo4Char(this.allFleets);
    const selectedFleets = this.filter.Fleets.length
      ? this.trimFleetTo4Char(this.filter.Fleets)
      : this.fleetsDisplayList;
    this.filterSection.controls.fleetFrmCtrl.patchValue(selectedFleets);
    this.selectAll.fleet = this.isEqualWithSorted(
      this.fleetsDisplayList,
      selectedFleets
    );
  }

  /**
   * Trims each fleet string in the given array to 4 characters.
   * Returns an array of unique trimmed fleet strings.
   *
   * @param fleets - The array of fleet strings to trim.
   * @returns An array of unique trimmed fleet strings.
   */
  trimFleetTo4Char(fleets: string[]): string[] {
    return [...new Set(fleets.map((fleet) => fleet.substring(0, 4)))];
  }

  enableDisableZone(enable: boolean) {
    if (enable) {
      this.filterSection.controls.zonesFrmCtrl.enable();
      return;
    }
    /*
        focusGateInput: is used to focus on gate input field when zone is disabled
        if we try to focus as soon as enable gate input field it will not work.
        it will take 1 tick for angular to update viewChild property.
      */
    this.filterSection.controls.zonesFrmCtrl.enabled &&
      setTimeout(() => this.focusGateInput(), 1);
    this.filterSection.controls.zonesFrmCtrl.disable();
  }

  setZoneFilter() {
    this.zoneNameList = this.zones.map((z) => z.ZoneName);

    if (this.stations.length > 1) {
      this.filterSection.controls.zonesFrmCtrl.patchValue([this.zoneAll]);
      this.filterSection.controls.zonesFrmCtrl.disable();
      return;
    }
    const selectedZones = [...this.filter.Zones];
    /*
    - zonesList got from api and selectedZones does not have any things common then select all
      or if both are equal then select all
    */
    if (
      !intersection(this.zoneNameList, selectedZones).length ||
      this.isEqualWithSorted(this.zoneNameList, selectedZones)
    )
      this.onSelectDeselectAll(true, SelectAllType.Zone);
    else this.filterSection.controls.zonesFrmCtrl.patchValue(selectedZones);
  }
  setTaskStatus() {
    if (
      this.filter.TaskStatus[0] === TaskStatusFilter.ShowAllAc ||
      this.filter.TaskStatus[0] === TaskStatusFilter.WithNoTask
    ) {
      this.filterSection.controls.noTaskFrmCtrl.patchValue(
        this.filter.TaskStatus[0]
      );
      return;
    }
    this.filterSection.controls.noTaskFrmCtrl.patchValue(this.WITH_TASK);
    this.filterSection.controls.withTaskFrmCtrl.enable();
    this.filterSection.controls.withTaskFrmCtrl.patchValue(
      this.filter.TaskStatus
    );
  }

  withTaskClick() {
    this.filterSection.controls.withTaskFrmCtrl.enable();
    if (!this.filterSection.controls.withTaskFrmCtrl.value.length) {
      this.filterSection.controls.withTaskFrmCtrl.patchValue(
        this.taskKeyValueList
      );
    }
  }
  setNotification() {
    /*
      when NotificationType has ShowAllAc or WithNoNotification then it will not have other value
      if we check first element is ShowAllAc or WithNoNotification then no need to check other index
    */
    if (
      this.filter.NotificationType[0] === NotificationFilter.ShowAllAc ||
      this.filter.NotificationType[0] === NotificationFilter.WithNoNotification
    ) {
      this.filterSection.controls.noNotificationFrmCtrl.patchValue(
        this.filter.NotificationType[0]
      );
      return;
    }
    this.filterSection.controls.noNotificationFrmCtrl.patchValue(
      this.WITH_NOTIFICATION
    );
    this.filterSection.controls.withNotificationFrmCtrl.enable();

    this.filterSection.controls.withNotificationFrmCtrl.patchValue(
      this.filter.NotificationType
    );
    this.selectAll.notification = this.isEqualWithSorted(
      this.notificationsKeyValueList,
      [...this.filter.NotificationType]
    );
  }

  withNotificationClick() {
    this.filterSection.controls.withNotificationFrmCtrl.enable();
    /*
    - if withNotificationFrmCtrl is empty then select all and patch all values
    */
    if (!this.filterSection.controls.withNotificationFrmCtrl.value.length) {
      this.selectAll.notification = true;
      this.filterSection.controls.withNotificationFrmCtrl.patchValue(
        this.notificationsKeyValueList
      );
    }
  }

  save() {
    const request: Filter = {
      Range: this.filterSection.controls.rangeFrmCtrl.value,
      IncludeTasks: this.includeTasks,
      OnlyMyTasks: this.onlyMyTasks,
      Fleets: this.getFleets(),
      // when their is multiple station or all terminal selected then we send empty array which means return everything from station
      Zones: this.getZones(),
      Miscellaneous: this.filterSection.controls.miscFrmCtrl.value,
      Delay: this.filterSection.controls.delayFrmCtrl.value,
      OtherToaster: this.filterSection.controls.otherToastFrmCtrl.value,
      RedToaster: this.filterSection.controls.redToastFrmCtrl.value,
      NoiseToaster: this.filterSection.controls.noiseToastFrmCtrl.value,
      Gates: this.filterSection.controls.gates.value,
      NotificationType: this.getNotificationType(),
      TaskStatus: this.getTaskStatus(),
      GateOptions: this.filterSection.controls.gateOptionFrmCtrl.value,
    };
    this.saveEvent.emit(request);
    this.close();
  }
  close() {
    this.closeEvent.emit();
  }

  getFleets(): string[] {
    const matchedFleets = this.allFleets.filter((fleet) =>
      this.filterSection.controls.fleetFrmCtrl.value.includes(
        fleet.substring(0, 4)
      )
    );
    return this.selectAll.fleet ? [] : matchedFleets;
  }

  getZones(): string[] {
    return this.stations.length > 1 ||
      this.selectAll.zone ||
      this.filterSection.controls.gateOptionFrmCtrl.value
      ? []
      : this.filterSection.controls.zonesFrmCtrl.value;
  }

  getNotificationType(): NotificationFilter[] {
    switch (this.filterSection.controls.noNotificationFrmCtrl.value) {
      case NotificationFilter.ShowAllAc:
        return [NotificationFilter.ShowAllAc];
      case NotificationFilter.WithNoNotification:
        return [NotificationFilter.WithNoNotification];
      default: {
        return this.filterSection.controls.withNotificationFrmCtrl.value;
      }
    }
  }
  getTaskStatus(): TaskStatusFilter[] {
    switch (this.filterSection.controls.noTaskFrmCtrl.value) {
      case TaskStatusFilter.ShowAllAc:
        return [TaskStatusFilter.ShowAllAc];
      case TaskStatusFilter.WithNoTask:
        return [TaskStatusFilter.WithNoTask];
      default:
        return this.filterSection.controls.withTaskFrmCtrl.value;
    }
  }
  /*
  This method is used when you click on selectAll or deselectAll button on fleet, zone, notification
  */
  onSelectDeselectAll(isSelectAll: boolean, type: SelectAllType) {
    switch (type) {
      case SelectAllType.Zone:
        this.selectAll.zone = isSelectAll;
        isSelectAll
          ? this.filterSection.controls.zonesFrmCtrl.patchValue(
              this.zoneNameList
            )
          : this.filterSection.controls.zonesFrmCtrl.patchValue([]);

        break;
      case SelectAllType.Fleet:
        this.selectAll.fleet = isSelectAll;
        isSelectAll
          ? this.filterSection.controls.fleetFrmCtrl.patchValue(
              this.fleetsDisplayList
            )
          : this.filterSection.controls.fleetFrmCtrl.patchValue([]);

        break;
      case SelectAllType.Notification:
        this.selectAll.notification = isSelectAll;
        isSelectAll
          ? this.filterSection.controls.withNotificationFrmCtrl.patchValue(
              this.notificationsKeyValueList
            )
          : this.filterSection.controls.withNotificationFrmCtrl.patchValue([]);

        break;
    }
  }

  /*
  This method is used when you click on checkbox on fleet, zone, notification.
  it will check if all values are selected if true then DeselectAll will be shown otherwise SelectAll will be shown
  */
  displaySelectDeselectAll(type: SelectAllType) {
    switch (type) {
      case SelectAllType.Zone:
        this.selectAll.zone = this.isEqualWithSorted(
          this.zoneNameList,
          this.filterSection.controls.zonesFrmCtrl.value
        );
        break;

      case SelectAllType.Fleet:
        this.selectAll.fleet = this.isEqualWithSorted(
          this.fleetsDisplayList,
          this.filterSection.controls.fleetFrmCtrl.value
        );
        break;

      case SelectAllType.Notification:
        this.selectAll.notification = this.isEqualWithSorted(
          this.notificationsKeyValueList,
          this.filterSection.controls.withNotificationFrmCtrl.value
        );
        break;
    }
  }
  isEqualWithSorted(list: string[], value: string[]): boolean {
    return isEqual(list.sort(), value.sort());
  }

  removeGateOrRange(gateOrRange: string) {
    const filteredRanges = this.filterSection.controls.gates.value.filter(
      (r) => r !== gateOrRange
    );
    this.filterSection.controls.gates.patchValue(filteredRanges);
    this.invalidGateRange = this.invalidGateRange.filter(
      (r) => r !== gateOrRange
    );
  }

  addGateOrRange() {
    const gateOrRange = this.gateInputText
      .toUpperCase()
      .split(',')
      .filter((val) => val.length > 0) // Filter out empty values
      .map((val) => val.replace(this.findHyphensAtStarAndEndRegex, '')) // Remove hyphens at the start or end
      .map((val) => val.replace(this.findDoubleHyphensRegex, '-')) // Replace double hyphens with a single hyphen;
      .map((val) => {
        const [start, end] = val.split('-');
        if (
          // restrict gate to 4 characters
          (!val.includes('-') && val.length > 4) ||
          (start &&
            end &&
            // end range should be greater than start range
            // restrict gate range to 4 characters
            (start.length > 4 ||
              end.length > 4 ||
              start.includes('-') ||
              end.includes('-') ||
              // A-1,A2-2 is invalid
              (isNaN(+start) && !isNaN(+end)) ||
              // 2-B, 2-B3A is invalid
              (!isNaN(+start) && isNaN(+end))))
        ) {
          this.invalidGateRange.push(val);
        }

        if (this.sameRangeRegex.test(val)) {
          return val.split('-')[0];
        }

        return val;
      });

    const newGateOrRange = [
      ...new Set([...gateOrRange, ...this.filterSection.controls.gates.value]),
    ];
    this.filterSection.controls.gates.patchValue(newGateOrRange);
    this.gateInputText = '';
  }

  tabOnGateInput(event: KeyboardEvent) {
    this.addGateOrRange();
    event.preventDefault();
  }

  clearGates() {
    this.filterSection.controls.gates.patchValue([]);
    this.invalidGateRange = [];
    this.focusGateInput();
  }

  focusGateInput() {
    this.gateInputArea &&
      this.gateInputArea.nativeElement &&
      this.gateInputArea.nativeElement.classList.add(this.gateInputFocusCss);
    this.gateInputField &&
      this.gateInputField.nativeElement &&
      this.gateInputField.nativeElement.focus();
    this.isGateInputError = false;
  }

  unfocusGateInput() {
    this.gateInputField &&
      this.gateInputField.nativeElement &&
      this.gateInputArea.nativeElement.classList.remove(this.gateInputFocusCss);
    this.isGateInputError =
      this.filterSection.controls.gateOptionFrmCtrl.value &&
      this.filterSection.controls.gates.value.length === 0;
  }

  setDefaultFilter() {
    this.filterSection.controls.fleetFrmCtrl.patchValue(this.fleetsDisplayList);
    this.selectAll.fleet = true;
    this.filterSection.controls.zonesFrmCtrl.patchValue(this.zoneNameList);
    this.enableDisableZone(true);
    this.selectAll.zone = true;
    this.filterSection.controls.gateOptionFrmCtrl.patchValue(false);
    this.filterSection.controls.gates.patchValue([]);
    this.filterSection.controls.delayFrmCtrl.patchValue(DelaysFilter.ShowAllAc);
    this.filterSection.controls.miscFrmCtrl.patchValue(MiscFilter.ShowAllAc);
    this.filterSection.controls.otherToastFrmCtrl.patchValue(true);
    this.filterSection.controls.redToastFrmCtrl.patchValue(true);
    this.filterSection.controls.noiseToastFrmCtrl.patchValue(true);
    this.filterSection.controls.withNotificationFrmCtrl.disable();
    this.filterSection.controls.noNotificationFrmCtrl.patchValue(
      NotificationFilter.ShowAllAc
    );
    this.filterSection.controls.withTaskFrmCtrl.disable();
    this.filterSection.controls.noTaskFrmCtrl.patchValue(
      TaskStatusFilter.ShowAllAc
    );
  }
}
