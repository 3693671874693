import { FeatureFlag } from '@lm-apps/common/feature-flag-data';
import { createAction, props } from '@ngrx/store';

export const init = createAction('[Feature Flag] Init');

export const throwError = createAction(
  '[Feature Flag] API request failed',
  props<{ error: any }>()
);
export const loadFeatureFlagSuccess = createAction(
  '[Feature Flag] Load feature flag success',
  props<{ flags: FeatureFlag[] }>()
);
export const upsertFlag = createAction(
  '[Feature Flag] Upsert specific feature flag',
  props<{ flag: FeatureFlag }>()
);
