import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

/**
 * Automatically transforms all dates in json in defined regex format to Date entity
 */
export function TransformDates() {
  return function (target: any, key: string, descriptor: any) {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args: any[]) {
      return originalMethod
        .apply(this, args)
        .pipe(map((obj) => convertDates(obj)));
    };
    return descriptor;
  };
}

const isDateString = (s: string) => {
  dayjs.extend(customParseFormat);
  return dayjs(s, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid(); //considering all dates will be in UTC
};

function convertDates(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }
  if (isDateString(obj)) {
    return new Date(obj);
  }
  if (obj instanceof Array) {
    return obj.map((n) => convertDates(n));
  }
  if (typeof obj === 'object' && Object.keys(obj).length > 0) {
    Object.keys(obj).forEach((k) => {
      obj[k] = convertDates(obj[k]);
    });
    return obj;
  }
  return obj;
}
