<div>
  <div *ngIf="this.noseNumber">
    <div [ngClass]="headerClass">
      <a
        [href]="safeAircraftUri + '/' + this.noseNumber"
        [textContent]="noseNumber"
        target="_blank"
        data-cy="lm-apps-nosenumber"
      ></a>
      <span *ngIf="isEAMLEnabled" class="eaml-enabled"></span>
    </div>
  </div>

  <div *ngIf="!this.noseNumber">
    <span>
      <span [ngClass]="headerClass"> Non Aircraft </span>
    </span>
  </div>

  <div class="d-flex justify-content-end">
    <strong
      class="aa-dark-blue aa-mr-2"
      *ngIf="isEtopsCertified"
      data-cy="cc-view-ac-etops"
      >ETOPS</strong
    >
    <strong class="text-muted" data-cy="cc-view-fleet-type">{{
      fleetType
    }}</strong>
  </div>
</div>
