import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LmoUiFeatureCcViewModule } from '@lm-apps/lmo/ui/feature-cc-view';
import { LmoUiDataAccessRootModule } from '@lm-apps/lmo/ui/data-access-root';
import { HomeComponent } from './home.component';
import { CommonModule } from '@angular/common';
const moduleRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(moduleRoutes),
    LmoUiFeatureCcViewModule,
    LmoUiDataAccessRootModule,
  ],
  declarations: [HomeComponent],
})
export class HomeModule {}
