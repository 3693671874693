import { AircraftAttributeComponent } from './components/rhs-attribute/aircraft-attribute/aircraft-attribute.component';
import { ArrivalComponent } from './components/arrival/arrival.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { DepartureComponent } from './components/departure/departure.component';
import { FlightDetailComponent } from './components/flight-detail/flight-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { GateComponent } from './components/gate/gate.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { InboundComponent } from './components/inbound/inbound.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { LmoUiCommonPipesModule } from '@lm-apps/lmo/ui/common/pipes';
import { LmoUiCommonServicesModule } from '@lm-apps/lmo/ui/common/services';
import { LmoUiDataAccessAircraftModule } from '@lm-apps/lmo/ui/data-access-aircraft';
import { LmoUiDataAccessCrewModule } from '@lm-apps/lmo/ui/data-access-crew';
import { LmoUiDataAccessUserModule } from '@lm-apps/lmo/ui/data-access-user';
import { LmoUiFeatureCrewManagerModule } from '@lm-apps/lmo/ui/feature-crew-manager';
import { LmoUiFeatureFilterModule } from '@lm-apps/lmo/ui/feature-filter';
import { LmoUiFeatureStationsModule } from '@lm-apps/lmo/ui/feature-stations';
import { LmoUiCommonFeatureSharedModule } from '@lm-apps/lmo/ui/common/feature-shared';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NewTaskComponent } from './components/new-task/new-task/new-task.component';
import { NgModule } from '@angular/core';
import { OutboundComponent } from './components/outbound/outbound.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SearchNoseNumberComponent } from './components/new-task/search-nose-number/search-nose-number.component';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastrModule } from 'ngx-toastr';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { DividerModule } from 'primeng/divider';
import { NotificationComponent } from './components/notification/notification.component';
import { FlightLegResultComponent } from './components/new-task/flight-leg-result/flight-leg-result.component';
import { FlightTaskListComponent } from './components/rhs-attribute/flight-task-list/flight-task-list.component';
import { AcAttrMaintComponent } from './components/rhs-attribute/ac-attr-maint/ac-attr-maint.component';
import { AircraftAttributeTableComponent } from './components/rhs-attribute/aircraft-attribute-table/aircraft-attribute-table.component';
import { TaskDetailComponent } from './components/rhs-attribute/task-detail/task-detail-main/task-detail.component';
import { MessageListComponent } from './components/rhs-attribute/message-list/message-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SplInstructionHistoryComponent } from './components/rhs-attribute/task-history/spl-instruction-history.component';
import { AssignedComponent } from './components/assigned/assigned.component';
import { UnassignedComponent } from './components/unassigned/unassigned.component';
import { HomeComponent as rhsHomeComponent } from './components/rhs-attribute/home/home.component';
import { TaskStatusComponent } from './components/rhs-attribute/task-status/task-status.component';
import { DelayAlertComponent } from './components/delay-alert/delay-alert.component';
import { LmoUiDataAccessImmutableModule } from '@lm-apps/lmo/ui/data-access-immutable';
import { LmoUiFeatureCrewManagerManpowerModule } from '@lm-apps/lmo/ui/feature-crew-manager-manpower';
import { BowItemsComponent } from './components/rhs-attribute/bow-items/bow-items.component';
import { BowDetailsHomeComponent } from './components/rhs-bill-of-work/bow-details-home/bow-details-home.component';
import { BowDetailsHeaderComponent } from './components/rhs-bill-of-work/bow-details-header/bow-details-header.component';
import { BowItemAttributesComponent } from './components/rhs-bill-of-work/bow-item-attributes/bow-item-attributes.component';
import { BowItemStatusComponent } from './components/rhs-bill-of-work/bow-item-status/bow-item-status.component';
import { BowDetailsAircraftAttributesComponent } from './components/rhs-bill-of-work/bow-details-aircraft-attributes/bow-details-aircraft-attributes.component';
import { BowtaskDetailComponent } from './components/rhs-attribute/bow-task-detail/bow-task-detail.component';
import { BowAssignedMembersListComponent } from './components/rhs-bill-of-work/bow-assigned-members-list/bow-assigned-members-list.component';

const billOfWorkComponents = [
  BowItemsComponent,
  BowDetailsHomeComponent,
  BowDetailsHeaderComponent,
  BowItemAttributesComponent,
  BowItemStatusComponent,
  BowDetailsAircraftAttributesComponent,
  BowtaskDetailComponent,
  BowAssignedMembersListComponent,
];

@NgModule({
  imports: [
    AutoCompleteModule,
    CheckboxModule,
    CommonModule,
    DividerModule,
    DropdownModule,
    FontAwesomeModule,
    FormsModule,
    InputSwitchModule,
    KeyFilterModule,
    LmoUiCommonFeatureSharedModule,
    LmoUiCommonPipesModule,
    LmoUiCommonServicesModule,
    LmoUiDataAccessAircraftModule,
    LmoUiDataAccessAircraftModule,
    LmoUiDataAccessCrewModule,
    LmoUiDataAccessUserModule,
    LmoUiFeatureCrewManagerModule,
    LmoUiFeatureCrewManagerManpowerModule,
    LmoUiFeatureFilterModule,
    LmoUiFeatureStationsModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    ReactiveFormsModule,
    RouterModule,
    SliderModule,
    TableModule,
    TagModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 0,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      newestOnTop: false,
    }),
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
    }),
    ButtonModule,
    MultiSelectModule,
    TooltipModule.forRoot(),
    LmoUiDataAccessImmutableModule,
  ],
  declarations: [
    AircraftAttributeComponent,
    ArrivalComponent,
    DepartureComponent,
    FlightDetailComponent,
    GateComponent,
    HeaderComponent,
    HomeComponent,
    InboundComponent,
    NewTaskComponent,
    OutboundComponent,
    SearchNoseNumberComponent,
    NotificationComponent,
    FlightLegResultComponent,
    AircraftAttributeTableComponent,
    FlightTaskListComponent,
    AcAttrMaintComponent,
    TaskDetailComponent,
    MessageListComponent,
    SplInstructionHistoryComponent,
    AssignedComponent,
    UnassignedComponent,
    rhsHomeComponent,
    TaskStatusComponent,
    DelayAlertComponent,
    ...billOfWorkComponents,
  ],
  exports: [
    HeaderComponent,
    HomeComponent,
    AircraftAttributeComponent,
    FlightTaskListComponent,
    AcAttrMaintComponent,
    MessageListComponent,
    InboundComponent,
    OutboundComponent,
    AssignedComponent,
    UnassignedComponent,
    GateComponent,
    ArrivalComponent,
    DepartureComponent,
    DelayAlertComponent,
    BowItemsComponent,
  ],
  providers: [],
})
export class LmoUiFeatureCcViewModule {}
