import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromNotice from '@lm-apps/lmo/ui/data-access-notices';
import { Notice, NoticeInput } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-notices-container',
  templateUrl: './notices-container.component.html',
})
export class NoticesContainerComponent {
  allNotices$ = this.store.pipe(select(fromNotice.selectAllNotices));

  constructor(private store: Store) {}

  saveNotice(notice: NoticeInput) {
    this.store.dispatch(fromNotice.actions.saveNotice({ notice }));
  }

  deleteNotice(notice: Notice) {
    this.store.dispatch(fromNotice.actions.deleteNotice({ notice }));
  }
}
