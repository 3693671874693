<section class="cm-all-crew">
  <p-table
    #dt1
    [value]="allCrewMem"
    styleClass="p-datatable-sm p-datatable-striped"
    [globalFilterFields]="['FirstName', 'LastName', 'EmployeeId']"
    scrollHeight="460px"
    touchUI="true"
  >
    <ng-template pTemplate="caption" class="bg-b4-blue">
      <input
        pInputText
        pKeyFilter="alphanum"
        placeholder="Name or Emp#"
        class="dropdown-width"
        (input)="
          setMaxLength($event.target.value);
          dt1.filterGlobal($event.target.value, 'contains')
        "
        [maxLength]="dynamicMaxLength"
      />
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="cm-rp-dot"></th>
        <th class="cm-rp-name">Name</th>
        <th class="cm-rp-emp">Emp#</th>
        <th>Role</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-member>
      <tr
        pDraggable="dragCrews"
        (onDragStart)="dragStart(member)"
        (onDragEnd)="dragEnd()"
        class="cursor-moveable"
      >
        <td class="cm-rp-dot">
          <fa-icon [icon]="faGripDotsVertical"></fa-icon>
        </td>
        <td class="cm-rp-name">
          {{ (member.LastName | titlecase) || member.EmployeeId }},&nbsp;{{
            (member.FirstName | titlecase) || member.EmployeeId
          }}
        </td>
        <td class="cm-rp-emp">{{ member.EmployeeId | slice: 2 }}</td>
        <td>{{ member.Position }}</td>
      </tr>
    </ng-template>
  </p-table>
</section>
