import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  AssignToColumnName,
  OnlineStatus,
  SortableCrewColumn,
  SortCrewState,
  SortOrder,
} from '@lm-apps/lmo/ui/data';

interface Assignable {
  [key: string]: any;
}
@Component({
  selector: 'lm-apps-assign-to-dropdown',
  templateUrl: './assign-to-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssignToDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignToDropdownComponent<T extends Assignable>
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() inputId: string | null = null;
  @Input() crewMembers: T[] | null = null;
  @Input() allOnlineStatus: OnlineStatus[] = [];
  @Input() showOnlineStatus = true;
  @Input() showCheckmarkOnSelected = true;
  @Input({ required: true }) sortState: SortCrewState | null = null;
  @Input() showSearchBar = false;
  @Output() sortCrew = new EventEmitter<SortableCrewColumn>();

  columnName = AssignToColumnName;
  sortBy = SortableCrewColumn;
  sortOrder = SortOrder;

  selectedMembers: T[] = [];
  onChange: any = () => {
    console.log('onChange empty method got trigger');
  };
  onTouched: any = () => {
    console.log('onTouched empty method got trigger');
  };
  touched = false;
  disabled = false;
  // to keep the track of selected members and used to apply respective css
  selectedState: Record<string, boolean> = {};
  // This is to show the first member in the dropdown
  ddOptions: T[] = [];

  //#region Methods for ControlValueAccessor

  writeValue(selectedMembers: T[]): void {
    if (selectedMembers && !Array.isArray(selectedMembers)) {
      throw new Error('value passed in assign to dropdown is not an array');
    }
    this.selectedMembers = [...(selectedMembers || [])];
    this.updateSelectedState();
  }
  registerOnChange(onChangeFn: any): void {
    this.onChange = onChangeFn;
  }
  registerOnTouched(onTouchedFn: any): void {
    this.onTouched = onTouchedFn;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
  //#endregion
  ngOnInit() {
    this.ddOptions = this.crewMembers?.length ? [this.crewMembers[0]] : [];
    this.updateSelectedState();
  }
  ngOnChanges(): void {
    this.updateSelectedState();
  }
  toggleMember(event: Event, crewMember: T) {
    const id = SortableCrewColumn.ID;
    const index = this.selectedMembers.findIndex(
      (x) => x[id] === crewMember[id]
    );
    index === -1
      ? this.selectedMembers.push(crewMember)
      : this.selectedMembers.splice(index, 1);
    this.onChange(this.selectedMembers);
    this.markAsTouched();
    this.updateSelectedState();
    event.stopPropagation(); // Prevent the dropdown from closing
  }
  updateSelectedState() {
    const id = SortableCrewColumn.ID;
    this.selectedState = {};
    this.crewMembers?.forEach((m) => {
      this.selectedState[m[id]] = this.selectedMembers.some(
        (s) => s[id] === m[id]
      );
    });
  }

  onSort(column: SortableCrewColumn, event: Event) {
    event.preventDefault();
    event.stopPropagation(); // Prevent the dropdown from closing
    this.sortCrew.emit(column);
  }
}
