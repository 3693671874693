export enum LMXTaskStatus {
  ASSIGNED = 'ASSIGNED',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  ARRIVED = 'ARRIVED',
  COMPLETED = 'COMPLETED',
  DELIVERED = 'DELIVERED',
  DEFERRED_INSUFFICIENT_MANPOWER = 'DEFERRED_INSUFFICIENT_MANPOWER',
  DEFERRED_INSUFFICIENT_GROUND_TIME = 'DEFERRED_INSUFFICIENT_GROUND_TIME',
  DEFERRED_FROM_SAFE = 'DEFERRED_FROM_SAFE',
}
