import {
  OnlineStatus,
  CREW_MEMBER_STATUS_FEATURE_KEY,
} from '@lm-apps/lmo/ui/data';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './crew-member-status.actions';
export interface State extends EntityState<OnlineStatus> {
  loaded: boolean; // is api call in progress
  error?: string | null; // last known error (if any)
}

export interface CrewMemberOnlineStatusPartialState {
  readonly [CREW_MEMBER_STATUS_FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<OnlineStatus> =
  createEntityAdapter<OnlineStatus>({
    selectId: (crewMember: OnlineStatus) => crewMember.EmployeeId,
  });

export const initialState: State = adapter.getInitialState({
  // set initial required properties
  ids: [],
  entities: Array<OnlineStatus>(),
  loaded: false,
  error: null,
});

const crewMemberOnlineStatusReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(actions.loadCrewMemberOnlineAllSuccess, (state, { crewMembers }) =>
    adapter.setAll(crewMembers, { ...state, loaded: true })
  ),
  on(actions.updateSpecificCrewMemberStatus, (state, { crewMember }) =>
    adapter.upsertOne(crewMember, { ...state, loaded: true })
  ),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return crewMemberOnlineStatusReducer(state, action);
}
