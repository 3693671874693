<section class="cc-detail-task">
  <div class="row justify-space-between aa-m-0 header header-default aa-px-4">
    <div class="row justify-start">
      <p-button
        icon="pi pi-times"
        styleClass="p-button-secondary p-button-text cross"
        (onClick)="closeTask()"
      ></p-button>
      <div class="title-txt pr-1">Task Details</div>
      <strong class="aa-pt-4-4">
        ({{
          maintenanceTaskDisplay.AdditionalTaskDetail.ReferenceNumber
        }})</strong
      >
    </div>

    <div class="print-icon">
      <i class="fa fa-duotone fa-print fa-lg aa-pr-2" onclick="print()"></i>
    </div>
  </div>
  <form [formGroup]="taskDetailForm" class="d-print-block printable-task">
    <div class="modal-body task-table">
      <div class="col-sm-12 row aa-my-2">
        <div class="col-sm-12">
          <span class="bold-7"
            >Description: &nbsp;{{
              maintenanceTaskDisplay.AdditionalTaskDetail.DeferralDescription
            }}
          </span>
        </div>
      </div>
      <div class="col-sm-12 row aa-mb-2 aa-pr-0">
        <div class="col-sm-6 aa-pr-0 hidden-print">
          <label for="ccAssignTo" class="bold-7">Assign To</label>
          <lm-apps-assign-to-dropdown
            inputId="ccAssignTo"
            [allOnlineStatus]="allCrewMemberOnlineStatus"
            [crewMembers]="crewMembersForAssignToDropdown"
            [sortState]="sortCrewState"
            (sortCrew)="sortCrew($event)"
            formControlName="selectedCrewMembers"
          ></lm-apps-assign-to-dropdown>
        </div>
        <div class="col-sm-3 aa-pr-0">
          <label for="jobType" class="bold-7">Task Type</label>
          <p-dropdown
            inputId="jobType"
            formControlName="jobType"
            [options]="jobTypes"
            optionLabel="DisplayJobType"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
            [placeholder]="
              maintenanceTaskDisplay.MaintenanceTaskInput.JobType
                ?.DisplayJobType || ''
            "
            (onChange)="canUpdate()"
          ></p-dropdown>
        </div>
        <div class="col-sm-3 aa-pr-0">
          <label for="callType" class="bold-7">Call Type</label>
          <p-dropdown
            inputId="callType"
            formControlName="callType"
            [options]="callTypes"
            optionLabel="DisplayCallType"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
            [placeholder]="
              maintenanceTaskDisplay.MaintenanceTaskInput.CallType
                ?.DisplayCallType || ''
            "
            (onChange)="canUpdate()"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-sm-12 aa-pr-0">
        <lm-apps-assigned-crew-detail
          *ngIf="this.selectedCmCtrl.value && this.selectedCmCtrl.value.length"
          [selectedCrewMembers]="this.selectedCmCtrl.value"
          [allCrewMemberOnlineStatus]="allCrewMemberOnlineStatus"
          (selectedCrewMemberChange)="removeSelectMechanic($event)"
          [assignedMembersFromTask]="
            this.maintenanceTaskDisplay.AdditionalTaskDetail.AssignedMembers
          "
          [user]="this.user"
        ></lm-apps-assigned-crew-detail>
      </div>
      <div class="col-sm-12 row aa-mb-2 aa-pr-0">
        <div
          class="col-sm-6 aa-pr-0"
          *ngIf="!maintenanceTaskDisplay.AdditionalTaskDetail.HasTask"
        >
          <label for="predefinedTypes" class="bold-7">Pre-defined</label>

          <p-dropdown
            inputId="predefinedTypes"
            formControlName="predefinedTypes"
            [options]="predefinedTask"
            optionLabel="Title"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
            placeholder="Select a Predefined Task Type"
            (onChange)="predefinedChange($event.value)"
          ></p-dropdown>
        </div>
      </div>
      <div class="col-sm-12 my-2">
        <label class="bold-7" for="specialInstruction"
          >Special Instructions</label
        >
        <textarea
          rows="3"
          type="text"
          class="form-control"
          id="specialInstruction"
          name="specialInstruction"
          formControlName="specialInstruction"
          minlength="1"
          maxlength="380"
          placeholder="Add instructions"
          (ngModelChange)="canUpdate()"
        ></textarea>
        <label class="right"
          >{{
            taskDetailForm.controls.specialInstruction.value?.length
          }}/380</label
        >
      </div>
      <div class="col-sm-12 aa-pt-2 aa-pb-3">
        <p-button
          [label]="
            showHistory
              ? maintenanceTaskDisplay.AdditionalTaskDetail.SpecialInstructions
                  .length
                ? 'Hide History (' +
                  maintenanceTaskDisplay.AdditionalTaskDetail
                    .SpecialInstructions.length +
                  ')'
                : 'Hide History'
              : maintenanceTaskDisplay.AdditionalTaskDetail.SpecialInstructions
                  .length
              ? 'Show History (' +
                maintenanceTaskDisplay.AdditionalTaskDetail.SpecialInstructions
                  .length +
                ')'
              : 'Show History'
          "
          [disabled]="
            !maintenanceTaskDisplay.AdditionalTaskDetail.SpecialInstructions
              .length
          "
          id="history"
          styleClass="cc-task-history hidden-print p-button-raised"
          (onClick)="showHideHistory()"
        ></p-button>
      </div>
      <div class="col-sm-12 aa-pr-0" *ngIf="showHistory">
        <lm-apps-task-history
          [instructionList]="
            maintenanceTaskDisplay.AdditionalTaskDetail.SpecialInstructions
          "
        ></lm-apps-task-history>
      </div>

      <div class="col-sm-12 row aa-pr-0">
        <div class="col-sm-7">
          <span class="mr-4">
            <p-checkbox
              class="bold-7"
              formControlName="isAvionic"
              [binary]="true"
              inputId="binary"
              label="Avionic"
              (onChange)="canUpdate()"
            ></p-checkbox>
          </span>

          <span class="mr-4">
            <p-checkbox
              class="bold-7"
              formControlName="isNFNF"
              [binary]="true"
              inputId="binary"
              label="NFNF"
              (onChange)="canUpdate()"
            ></p-checkbox>
          </span>
          <span
            class="mr-4"
            *ngIf="maintenanceTaskDisplay.MaintenanceTaskInput.IsManualTask"
          >
            <p-checkbox
              class="bold-7"
              formControlName="isOilService"
              [binary]="true"
              inputId="binary"
              label="Oil Service"
              (onChange)="canUpdate()"
            ></p-checkbox>
          </span>
        </div>
      </div>
    </div>
    <div class="aa-pb-4"></div>
    <div class="col-sm-12 justify-start aa-pr-0 aa-mr-0 d-print-none">
      <div class="row col-sm-12 aa-pr-0 a-mr-0">
        <button
          label="Create"
          pButton
          type="submit"
          class="aa-mr-5 p-button-raised"
          (click)="createTask()"
          *ngIf="!maintenanceTaskDisplay.AdditionalTaskDetail.HasTask"
        ></button>
        <button
          label="Update"
          pButton
          type="submit"
          styleClass="p-button-raised"
          class="aa-mr-5"
          (click)="updateTask()"
          [disabled]="disableButtons"
          *ngIf="
            maintenanceTaskDisplay.AdditionalTaskDetail.HasTask &&
            enableUpdatebtn
          "
        >
          <fa-icon [icon]="faFloppyDisk" class="aa-pr-2"></fa-icon>
        </button>

        <button
          label="Complete"
          pButton
          class="aa-mr-5 p-button-raised"
          (click)="completeTask()"
          *ngIf="canCompleteOrDeferTask && !enableDeferralReasonMsg"
          [disabled]="disableButtons"
        >
          <i class="fa fa-check aa-pr-2"></i>
        </button>
        <div
          class="cc-td-def-reason aa-mr-5"
          *ngIf="
            maintenanceTaskDisplay.AdditionalTaskDetail.HasTask &&
            !canReopenTask
          "
        >
          <p-dropdown
            #dropdown
            formControlName="deferralReason"
            [options]="deferralReasonList"
            panelStyleClass="dropdown-task"
            styleClass="dropdown-width"
            placeholder="Reason Not Completed"
            (onChange)="reasonNotCompleteChange($event.value)"
          ></p-dropdown>
        </div>
        <button
          label="Cannot Complete"
          pButton
          type="button"
          class="aa-mr-5 p-button-raised p-button-danger"
          (click)="cannotComplete()"
          [disabled]="disableButtons"
          *ngIf="canCompleteOrDeferTask"
        >
          <i class="fa fa-thumbs-down aa-pr-2"></i>
        </button>
        <button
          label="QTNC"
          pButton
          class="p-button-raised p-button-danger"
          (click)="quickTaskNotCompleted()"
          *ngIf="canCompleteOrDeferTask && !enableDeferralReasonMsg"
          [disabled]="enableDeferralReasonMsg || disableButtons"
        >
          <i class="fa fa-solid fa-calendar aa-pr-2"></i>
        </button>
        <button
          label="Reopen"
          pButton
          (click)="reOpenTask()"
          styleClass="p-button-raised p-button-info"
          class="aa-mr-5"
          *ngIf="canReopenTask && !this.enableDeferralReasonMsg"
          [disabled]="disableButtons"
        >
          <i class="fa fa-thumbs-up aa-pr-2"></i>
        </button>
      </div>
      <div
        class="col-sm-12 aa-red aa-pt-2 aa-pl-0"
        *ngIf="enableDeferralReasonMsg"
      >
        This is not an actual deferral, to defer go to SAFE. This will mark the
        item as not completed in LMO ONLY.
      </div>
      <div
        class="col-sm-12 aa-red aa-pt-2 aa-pl-0"
        *ngIf="showSelectDeferralReasonMsg"
      >
        You must select a deferral reason.
      </div>
    </div>

    <div
      class="col-sm-12 aa-pt-4"
      *ngIf="maintenanceTaskDisplay.AdditionalTaskDetail.HasTask"
    >
      <div
        class="col-sm-12 aa-red aa-pt-2 aa-pl-0"
        *ngIf="showTaskAlreadyUpdatedMsg"
      >
        This task was recently updated. Please close and reopen the task.
      </div>

      <div class="col-sm-12 row">
        <label for="ccDetailTaskStatus" class="bold-7">Status:</label>&nbsp;
        <span id="ccDetailTaskStatus" class="bold-7">{{
          this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskState ===
          'DEFERRED'
            ? 'Not Completed'
            : (this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskState
              | titlecase)
        }}</span>
      </div>
      <div
        class="col-sm-12 row"
        *ngIf="
          maintenanceTaskDisplay.MaintenanceTaskInput.TaskState === Deferred
        "
      >
        <label for="ccDetailTaskStatus" class="bold-7">Employee:</label>&nbsp;
        <span id="ccDetailTaskStatus" class="bold-7">{{
          this.maintenanceTaskDisplay.AdditionalTaskDetail.UserName +
            ' (' +
            this.maintenanceTaskDisplay.MaintenanceTaskInput.UserId +
            ')'
        }}</span>
      </div>
      <div
        class="col-sm-12 row"
        *ngIf="
          maintenanceTaskDisplay.MaintenanceTaskInput.TaskState === Deferred
        "
      >
        <label for="ccDetailTaskStatus" class="bold-7">Reason:</label>&nbsp;
        <span id="ccDetailTaskStatus" class="bold-7">{{
          this.maintenanceTaskDisplay.MaintenanceTaskInput.DeferredReason
        }}</span>
      </div>
    </div>
  </form>
</section>
