import { FlightsEntity } from '@lm-apps/lmo/ui/data';
import { LegStatus } from '@lm-apps/lmo/ui/data';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import dayjs from 'dayjs';
import { GroundTimeRemainingClassName } from '@lm-apps/lmo/shared/enums';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-gate',
  templateUrl: './gate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GateComponent implements OnInit, OnDestroy {
  @Input() flightPair!: FlightsEntity;
  LegStatus = LegStatus;
  className = '';
  text = '';
  intervalId: ReturnType<typeof setInterval> | undefined;

  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.getGroundTimeText();
      this.cdr.detectChanges();
    }, 1000);
  }

  // If arrivalGate is equal to departureGate, it returns arrivalGate if it exists, otherwise '--/--'.
  // If arrivalGate is not equal to departureGate, the function returns a string concatenating arrivalGate if it exists,
  // otherwise '--', and a '/' and departureGate if it exists, otherwise '--'.
  getGate(arrivalGate: string, departureGate: string): string {
    return arrivalGate === departureGate
      ? arrivalGate || '--/--'
      : (arrivalGate || '--') + '/' + (departureGate || '--');
  }

  getGroundTimeText() {
    const nowUTC = new Date();
    if (
      this.flightPair.Departure &&
      this.flightPair.Departure.FlightNumber &&
      'OFX'.indexOf(this.flightPair.Departure.DepartureStatusCode || 'S') === -1
    ) {
      if (
        this.flightPair.Arrival &&
        this.flightPair.Arrival.ActualArrival &&
        this.flightPair.Departure.ActualDeparture
      ) {
        const bgnT = dayjs(
          this.getLater(nowUTC, this.flightPair.Arrival.ActualArrival)
        );
        const endT = dayjs(
          this.getLater(nowUTC, this.flightPair.Departure.ActualDeparture)
        );

        const duration = endT.diff(bgnT, 'minutes');
        const hours = endT.diff(bgnT, 'hours') | 0;
        const minutes = (duration % 60) + 1;

        this.text =
          duration <= 0 ? '' : this.getGTRemainingText(hours, minutes);
        this.className = this.getGTRemainingClassName(duration);
      }
    }
  }

  getGTRemainingClassName(duration: number): string {
    if (duration < 10) return GroundTimeRemainingClassName.RED;
    if (duration < 20) return GroundTimeRemainingClassName.ORANGE;
    return GroundTimeRemainingClassName.GREEN;
  }

  getGTRemainingText(hour: number, minute: number): string {
    this.text = '';
    if (hour > 0) {
      this.text += hour.toString() + 'h ';
    }
    this.text += minute.toString() + 'm';
    return this.text;
  }

  getLater(t1: Date, t2: Date): Date {
    return t1 > t2 ? t1 : t2;
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}
