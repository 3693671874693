import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignedMember, Crew, CREW_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { CrewState } from './crew.interfaces';

export const getCrewState = createFeatureSelector<CrewState>(CREW_FEATURE_KEY);

export const selectAllCrews = createSelector(
  getCrewState,
  (state) => state.crews
);
export const selectAllMappedCrews = createSelector(selectAllCrews, (crews) => {
  if (!crews) return [];

  const crewsMapped: Crew[] = [];
  crews.forEach((crew) => {
    const ams: AssignedMember[] = [];
    crew.AssignedMembers.forEach((member) => {
      ams.push({
        EmployeeId: member.EmployeeId,
        FirstName: member.FirstName,
        LastName: member.LastName,
        Status: member.Status,
      });
    });
    crewsMapped.push({
      CrewName: crew.Name,
      Station: crew.Station,
      CrewDisplayName: '',
      Members: ams,
    });
  });
  return crewsMapped;
});

export const selectSelectedStation = createSelector(
  getCrewState,
  (state) => state.selectedStation
);

export const selectAllCrewMembers = createSelector(
  getCrewState,
  (state) => state.allCrewMembers
);

export const selectCurrentCrewId = createSelector(
  getCrewState,
  (state) => state.selectedCrewId
);

export const selectCurrentCrew = createSelector(
  selectCurrentCrewId,
  selectAllCrews,
  (currentCrewId, allCrews) => {
    return allCrews.find((crew) => crew.Id === currentCrewId) || null;
  }
);
