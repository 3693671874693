import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Arrival } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-inbd',
  templateUrl: './inbound.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboundComponent {
  @Input() arrival!: Arrival;
  @Input() arrivalDate!: string;
}
