import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { isCcView } from '@lm-apps/lmo/ui/common/services';
import { CC_VIEW_ONLY_CSS, TOAST_CONTAINER } from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class ToastrAuthGuard {
  canActivateChild(state: RouterStateSnapshot): boolean {
    // if toastr has cc-view-only css then hide it when you are in other view
    if (isCcView(state)) {
      const toastrContainer = document.getElementById(TOAST_CONTAINER);
      if (toastrContainer) {
        const divs = toastrContainer.querySelectorAll('div');
        divs.forEach((div) => {
          if (div.classList.contains(CC_VIEW_ONLY_CSS)) {
            div.style.display = 'block';
          }
        });
      }
    } else {
      const toastrContainer = document.getElementById(TOAST_CONTAINER);
      if (toastrContainer) {
        const divs = toastrContainer.querySelectorAll('div');
        divs.forEach((div) => {
          if (div.classList.contains(CC_VIEW_ONLY_CSS)) {
            div.style.display = 'none';
          }
        });
      }
    }
    return true;
  }
}
