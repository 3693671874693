import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './../flight.actions';
import { Scalars, TaskState } from '@lm-apps/lmo/ui/data';
import {
  CustomToastrService,
  RegularTaskService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class RegularTaskEffects {
  createRegularTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createTask),
      switchMap((action) =>
        this.regularTaskService.createTask(action.maintenanceTaskArgs).pipe(
          map((taskId: Scalars['UUID']['output']) => {
            if (action.maintenanceTaskArgs.maintenanceTask.TaskId)
              this.toastService.success('Task Updated');
            else {
              this.toastService.success('Task Created');
            }
            return actions.createTaskSuccess({ taskId });
          }),
          catchError((error) => {
            this.toastService.error(
              'Task creation failed. Please retry or contact support if the issue persists.'
            );
            return of(actions.throwError({ error }));
          })
        )
      )
    )
  );

  updateTaskState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateTaskState),
      switchMap((action) =>
        this.regularTaskService
          .updateTaskState(action.taskState, action.taskId)
          .pipe(
            map(
              ({
                taskState,
                taskId,
              }: {
                taskState: TaskState;
                taskId: Scalars['UUID']['output'];
              }) => {
                this.toastService.success('Task status successfully updated');
                return actions.loadUpdateTaskStateSuccess({
                  taskId,
                  taskState,
                });
              }
            ),
            catchError((error) => {
              this.toastService.error(
                'Failed to update task status. Please retry or contact support if the issue persists.'
              );
              return of(actions.throwError({ error }));
            })
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private regularTaskService: RegularTaskService,
    private toastService: CustomToastrService
  ) {}
}
