import { Pipe, PipeTransform } from '@angular/core';
import {
  ArrivalStatus as ArrivalStatusEnum,
  EnumUtil,
} from '@lm-apps/lmo/shared/enums';
import { ArrivalStatus as ArrivalStatusParameter } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'arrivalStatus',
})
export class ArrivalStatusPipe implements PipeTransform {
  transform(arrivalStatus: ArrivalStatusParameter) {
    return EnumUtil.getValue(ArrivalStatusEnum, arrivalStatus);
  }
}
