/**
 * Trims each fleet string in the given array to 4 characters.
 * Returns an array of unique trimmed fleet strings.
 *
 * @param fleets - The array of fleet strings to trim.
 * @returns An array of unique trimmed fleet strings.
 */
export function trimFleetTo4Char(fleets: string[]): string[] {
  return [...new Set(fleets.map((fleet) => fleet.substring(0, 4)))];
}
