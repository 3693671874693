import { EnumUtil } from '../enum-util';

const contains = EnumUtil.doesExistInCollection;

export enum MaintenancePriorities {
  /**
   * QFLT - 1000
   */
  QFLT = 'QFLT',
  /**
   * FLT - 1001
   */
  FLT = 'FLT',
  /**
   * QDAY - 1003
   */
  QDAY = 'QDAY',
  /**
   * DAY - 1004
   */
  DAY = 'DAY',
  /**
   * QNEF - 1018
   */
  QNEF = 'QNEF',
  /**
   * NO_TAG - 1030
   */
  NO_TAG = '',
  /**
   * RESTRICTIVE - 1031
   */
  RESTRICTIVE = 'R',
  /**
   * Customer Impact - 1032
   */
  CI = 'CI',
  /**
   * EACH_FLT - 1040
   */
  EACH_FLT = 'EA FLT',
  /**
   * EACH_FLT_R - 1041
   */
  EACH_FLT_R = 'EA FLT R',
  /**
   * EACH_FLT_CI - 1042
   */
  EACH_FLT_CI = 'EA FLT CI',
  /**
   * FIRST_FLT - 1050
   */
  FIRST_FLT = '1ST FLT',
  /**
   * FIRST_FLT_R - 1051
   */
  FIRST_FLT_R = '1ST FLT R',
  /**
   * FIRST_FLT_CI - 1052
   */
  FIRST_FLT_CI = '1ST FLT CI',
  /**
   * QUICK_FIX - 1130
   */
  QUICK_FIX = 'QFX',
  /**
   * QUICK_FIX_Y - 1005
   */
  QUICK_FIX_Y = 'QFY',
  /**
   * QUICK_FIX_R - 1131
   */
  QUICK_FIX_R = 'QFX R',
  /**
   * QUICK_FIX_CI - 1132
   */
  QUICK_FIX_CI = 'QFX CI',
  /**
   * EACH_FLT_QUICK_FIX - 1140
   */
  EACH_FLT_QUICK_FIX = 'EA FLT QFX',
  /**
   * EACH_FLT_R_FIX - 1141
   */
  EACH_FLT_R_FIX = 'EA FLT R QFX',
  /**
   * EACH_FLT_Q_FIX_CI - 1142
   */
  EACH_FLT_Q_FIX_CI = 'EA FLT QFX CI',
  /**
   * FIRST_FLT_Q_FIX - 1150
   */
  FIRST_FLT_Q_FIX = '1ST FLT QFX',
  /**
   * FIRST_FLT_RQ_FIX - 1151
   */
  FIRST_FLT_RQ_FIX = '1ST FLT R QFX',
  /**
   * FIRST_FLT_Q_FIX_C - 1152
   */
  FIRST_FLT_Q_FIX_C = '1ST FLT QFX C',
  /**
   * MONITORS - 1156
   */
  MONITORS = 'MON',
  /**
   * OPS - 1157
   */
  OPS = 'OPS',
  /**
   * ROB - 1158
   */
  ROB = 'ROB',
  RSMG = 'RSMG',
  MG = 'MG',
  TERM = 'TERM',
  WKUP = 'WKUP',
  RSWK = 'RSWK',
  CFMOIL = 'CFMOIL',
  IFE = 'IFE',
  TIRECHK = 'TIRECHK',
  TRANSCHK = 'TRANSCHK',
  IBGATE = 'I/B GATE',
  OBGATE = 'O/B GATE',
  CUBA = 'CUBA',
  USER_DEFINED = 'UserDefined',
  DLY_TRN = 'DLY-TRN',
  DAILY_CHK = 'DAILY CHK',
  REM = 'REM',
  BOW = 'BOW',
}
// Black Mels
export const AutoGenerateMelTaskList: MaintenancePriorities[] = [
  //1130 - These are APU & Fuel Quantity MELs
  MaintenancePriorities.NO_TAG,
];

// MON Mics
export const AutoGenerateMonTaskList: MaintenancePriorities[] = [
  MaintenancePriorities.MONITORS,
  MaintenancePriorities.OPS,
  MaintenancePriorities.ROB,
  MaintenancePriorities.REM,
];

export const FirstFlightTaskList: MaintenancePriorities[] = [
  // 1050 1051 1052 1150 1151 1152
  MaintenancePriorities.FIRST_FLT,
  MaintenancePriorities.FIRST_FLT_R,
  MaintenancePriorities.FIRST_FLT_CI,
  MaintenancePriorities.FIRST_FLT_Q_FIX,
  MaintenancePriorities.FIRST_FLT_RQ_FIX,
  MaintenancePriorities.FIRST_FLT_Q_FIX_C,
];

export const EachFlightPrioritiesToApplyHold: MaintenancePriorities[] = [
  //1040 1041  1042  1140  1141  1142 1156
  MaintenancePriorities.EACH_FLT,
  MaintenancePriorities.EACH_FLT_R,
  MaintenancePriorities.EACH_FLT_CI,
  MaintenancePriorities.EACH_FLT_QUICK_FIX,
  MaintenancePriorities.EACH_FLT_R_FIX,
  MaintenancePriorities.EACH_FLT_Q_FIX_CI,
  MaintenancePriorities.MONITORS,
];

export const QuickFixList: MaintenancePriorities[] = [
  // 1130 1131 1132
  MaintenancePriorities.QUICK_FIX,
  MaintenancePriorities.QUICK_FIX_R,
  MaintenancePriorities.QUICK_FIX_CI,
];

export const NotificationPriorityDescriptionsMELFLW: MaintenancePriorities[] = [
  MaintenancePriorities.DAY,
  MaintenancePriorities.FLT,
  MaintenancePriorities.QDAY,
  MaintenancePriorities.QFLT,
  MaintenancePriorities.EACH_FLT,
  MaintenancePriorities.EACH_FLT_R,
  MaintenancePriorities.EACH_FLT_CI,
  MaintenancePriorities.FIRST_FLT,
  MaintenancePriorities.FIRST_FLT_R,
  MaintenancePriorities.FIRST_FLT_CI,
  MaintenancePriorities.EACH_FLT_QUICK_FIX,
  MaintenancePriorities.EACH_FLT_R_FIX,
  MaintenancePriorities.EACH_FLT_Q_FIX_CI,
  MaintenancePriorities.FIRST_FLT_Q_FIX,
  MaintenancePriorities.FIRST_FLT_RQ_FIX,
  MaintenancePriorities.FIRST_FLT_Q_FIX_C,
];

export const NotificationPriorityDescriptionsMELGEN: MaintenancePriorities[] = [
  MaintenancePriorities.NO_TAG,
  MaintenancePriorities.RESTRICTIVE,
  MaintenancePriorities.CI,
];

export const NotificationPriorityDescriptionsQFIX: MaintenancePriorities[] = [
  MaintenancePriorities.QUICK_FIX,
  MaintenancePriorities.QUICK_FIX_Y,
  MaintenancePriorities.QUICK_FIX_R,
  MaintenancePriorities.QUICK_FIX_CI,
  MaintenancePriorities.EACH_FLT_QUICK_FIX,
  MaintenancePriorities.EACH_FLT_R_FIX,
  MaintenancePriorities.EACH_FLT_Q_FIX_CI,
  MaintenancePriorities.FIRST_FLT_Q_FIX,
  MaintenancePriorities.FIRST_FLT_RQ_FIX,
  MaintenancePriorities.FIRST_FLT_Q_FIX_C,
];

export const NotificationPriorityDescriptionsQNEF: MaintenancePriorities[] =
  NotificationPriorityDescriptionsQFIX;

export const NotificationPriorityDescriptionsOPEN: MaintenancePriorities[] = [
  MaintenancePriorities.ROB,
  MaintenancePriorities.OPS,
  MaintenancePriorities.REM,
];

export const AutoGenerateTaskList: MaintenancePriorities[] = [
  ...QuickFixList,
  ...EachFlightPrioritiesToApplyHold.filter(
    (h) => h !== MaintenancePriorities.MONITORS
  ),
  ...FirstFlightTaskList,
  ...AutoGenerateMelTaskList,
  ...AutoGenerateMonTaskList,
];

export const TasksToBeMovedToNextLeg = [
  ...EachFlightPrioritiesToApplyHold,
  ...AutoGenerateMelTaskList,
  ...QuickFixList,
];

export const FollowupTasks = [
  ...EachFlightPrioritiesToApplyHold,
  ...FirstFlightTaskList,
  ...AutoGenerateMonTaskList,
];

export const MicsTobeUnassignedOnInitialCreation = [
  ...AutoGenerateMonTaskList.filter(
    (h) => h !== MaintenancePriorities.MONITORS
  ),
];

export const AircraftNoFlyNotifications = [
  ...AutoGenerateMonTaskList.filter(
    (h) => h !== MaintenancePriorities.MONITORS
  ),
];

export function IsMicToBeIgnoredOnInitialCreation(
  priorityName: string
): boolean {
  return contains<MaintenancePriorities>(QuickFixList, priorityName);
}

export function IsQuickFix(priorityName: string): boolean {
  return contains<MaintenancePriorities>(QuickFixList, priorityName);
}

export function IsAutoGenerateMelTask(priorityName: string): boolean {
  return contains<MaintenancePriorities>(AutoGenerateMelTaskList, priorityName);
}

export function IsEachFlightToApplyHold(taskPriority: string): boolean {
  return contains<MaintenancePriorities>(
    EachFlightPrioritiesToApplyHold,
    taskPriority
  );
}

export function IsEachFlightFollowup(priorityName: string): boolean {
  return contains<MaintenancePriorities>(TasksToBeMovedToNextLeg, priorityName);
}

export function IsEachFlightFollowupTask(priorityName: string): boolean {
  return contains<MaintenancePriorities>(
    EachFlightPrioritiesToApplyHold,
    priorityName
  );
}

export function IsAutoGenerateFlightTask(priorityName: string): boolean {
  return contains<MaintenancePriorities>(AutoGenerateTaskList, priorityName);
}

export function IsTaskToBeMovedToNextLeg(priorityName: string): boolean {
  return contains<MaintenancePriorities>(TasksToBeMovedToNextLeg, priorityName);
}

export function IsFollowupTask(priorityName: string): boolean {
  return contains<MaintenancePriorities>(FollowupTasks, priorityName);
}

export function IsMicToBeUnassignedOnInitialCreation(
  priorityName: string
): boolean {
  return contains<MaintenancePriorities>(
    MicsTobeUnassignedOnInitialCreation,
    priorityName
  );
}

export function IsAircraftNoFlyNotificationType(priorityName: string): boolean {
  return contains<MaintenancePriorities>(
    AircraftNoFlyNotifications,
    priorityName
  );
}

export function IsFirstFlight(priorityName: string): boolean {
  return contains<MaintenancePriorities>(FirstFlightTaskList, priorityName);
}

export function IsOPSPriority(priorityName: string): boolean {
  return contains<MaintenancePriorities>(
    [MaintenancePriorities.OPS],
    priorityName
  );
}

export function IsROBPriority(priorityName: string): boolean {
  return contains<MaintenancePriorities>(
    [MaintenancePriorities.ROB],
    priorityName
  );
}

export function IsREMPriority(priorityName: string): boolean {
  return contains<MaintenancePriorities>(
    [MaintenancePriorities.REM],
    priorityName
  );
}
