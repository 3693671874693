<section
  class="ac-attr-container"
  *ngIf="selectedFlightData$ | async as selectedFlightData"
>
  <div class="vpanel">
    <lm-apps-lmo-ui-bow-details-header
      [selectedBowItem]="selectedBowItem$ | async"
      (aircraftAttributesViewEvent)="openAircraftAttributesView()"
      (closeEvent)="closeSidePanel()"
    >
    </lm-apps-lmo-ui-bow-details-header>
    <lm-apps-lmo-ui-bow-details-aircraft-attributes
      [flight]="selectedFlightData.flight"
      [safeConfigURL]="config.SAFE_URL"
    >
    </lm-apps-lmo-ui-bow-details-aircraft-attributes>
    <lm-apps-lmo-ui-bow-item-attributes
      [selectedBowItem]="selectedBowItem$ | async"
    >
    </lm-apps-lmo-ui-bow-item-attributes>
  </div>
</section>
