import {
  DelaysFilter,
  AlternateTasksFilter,
  HistoryKeyCallTypeFilter,
  FlightStatusFilter,
  HistoryTaskStatusFilter,
  HistoryNotificationTypeFilter,
  MiscFilter,
  NotificationFilter,
  TaskStatusFilter,
} from './models';
import { FilterType } from './models/notification-filter-type.model';

export const SHOW_ALL_AIRCRAFT = 'Show All Aircraft';

export const DelaysFilterList: FilterType<DelaysFilter>[] = [
  {
    displayName: 'Show All Aircraft',
    keyValue: DelaysFilter.ShowAllAc,
  },
  {
    displayName: 'Delayed Arrivals',
    keyValue: DelaysFilter.Arrivals,
  },
  {
    displayName: 'Delayed Departures',
    keyValue: DelaysFilter.Departures,
  },
];

export const MiscsFilterList: FilterType<MiscFilter>[] = [
  {
    displayName: 'Show All Aircraft',
    keyValue: MiscFilter.ShowAllAc,
  },
  {
    displayName: 'ETOPS Aircraft',
    keyValue: MiscFilter.EtopsAc,
  },
  {
    displayName: 'ETOPS Flights',
    keyValue: MiscFilter.EtopsFlights,
  },
  {
    displayName: 'Hide AOS Aircraft',
    keyValue: MiscFilter.HideAosAc,
  },
  {
    displayName: 'Show AOS Aircraft',
    keyValue: MiscFilter.ShowAosAc,
  },
  {
    displayName: 'Hide Unlinked Aircraft',
    keyValue: MiscFilter.HideUnlinkedAc,
  },
  {
    displayName: 'Hide Unlinked without Notifications',
    keyValue: MiscFilter.HideUnlinkedWithoutNotifications,
  },
  {
    displayName: 'Mismatch Gates',
    keyValue: MiscFilter.MismatchGates,
  },
  {
    displayName: 'Right Start Flights',
    keyValue: MiscFilter.RightStartFlights,
  },
  {
    displayName: 'Show eAML Enabled Aircraft',
    keyValue: MiscFilter.ShowEamlEnabledAc,
  },
  {
    displayName: 'Turn Time < 45 Minutes',
    keyValue: MiscFilter.TurnTime_45,
  },
  {
    displayName: 'Turn Time < 60 Minutes',
    keyValue: MiscFilter.TurnTime_60,
  },
];

export const NotificationsList: FilterType<NotificationFilter>[] = [
  {
    displayName: 'HERMES',
    keyValue: NotificationFilter.Hermes,
  },
  {
    displayName: 'TRANSIT Check',
    keyValue: NotificationFilter.Transit,
  },
  {
    displayName: 'Meet & Greet',
    keyValue: NotificationFilter.Mg,
  },
  {
    displayName: 'Wake Up',
    keyValue: NotificationFilter.Wkup,
  },
  {
    displayName: 'Right Start Wake Up',
    keyValue: NotificationFilter.Rswk,
  },
  {
    displayName: 'Oil Check',
    keyValue: NotificationFilter.Cfmoil,
  },
  {
    displayName: 'Follow Up (MEL/NEF/CDL/TAC)',
    keyValue: NotificationFilter.Melflw,
  },
  {
    displayName: 'Follow Up MON(1 - 4 Cycles)',
    keyValue: NotificationFilter.FollowUpMon,
  },
  {
    displayName: 'MEL (General)',
    keyValue: NotificationFilter.Melgen,
  },
  {
    displayName: 'MEL (Quick Fix)',
    keyValue: NotificationFilter.Qfix,
  },
  {
    displayName: 'NEF (Quick Fix)',
    keyValue: NotificationFilter.Qnef,
  },
  {
    displayName: 'CDL (General)',
    keyValue: NotificationFilter.Cdl,
  },
  {
    displayName: 'MOC Request',
    keyValue: NotificationFilter.MocRequest,
  },
  {
    displayName: 'Maintenance Hold',
    keyValue: NotificationFilter.MaintenanceHold,
  },
  {
    displayName: 'OPEN',
    keyValue: NotificationFilter.Open,
  },
  {
    displayName: 'PILOT eMIC',
    keyValue: NotificationFilter.PiloTeMic,
  },
  {
    displayName: 'Other',
    keyValue: NotificationFilter.Other,
  },
];

export const TaskStatusFilterList: FilterType<TaskStatusFilter>[] = [
  {
    displayName: 'Assigned',
    keyValue: TaskStatusFilter.Assigned,
  },
  {
    displayName: 'Completed',
    keyValue: TaskStatusFilter.Completed,
  },
  {
    displayName: 'New Task (within 30 minutes)',
    keyValue: TaskStatusFilter.NewTask,
  },
  {
    displayName: 'Unassigned',
    keyValue: TaskStatusFilter.Unassigned,
  },
  {
    displayName: 'Not Completed',
    keyValue: TaskStatusFilter.Deferred,
  },
];

//#region  HistoryFilters
export const HistoryStatusList: FilterType<HistoryTaskStatusFilter>[] = [
  {
    displayName: 'Status',
    keyValue: HistoryTaskStatusFilter.All,
  },
  {
    displayName: 'Assigned',
    keyValue: HistoryTaskStatusFilter.Assigned,
  },
  {
    displayName: 'Unassigned',
    keyValue: HistoryTaskStatusFilter.Unassigned,
  },
  {
    displayName: 'Not Completed',
    keyValue: HistoryTaskStatusFilter.NotCompleted,
  },
  {
    displayName: 'Completed',
    keyValue: HistoryTaskStatusFilter.Completed,
  },
  {
    displayName: 'Closed',
    keyValue: HistoryTaskStatusFilter.Closed,
  },
  {
    displayName: 'SystemClosed',
    keyValue: HistoryTaskStatusFilter.SystemClosed,
  },
  {
    displayName: 'Suppressed',
    keyValue: HistoryTaskStatusFilter.Suppressed,
  },
];

export const HistoryFlightStatusList: FilterType<FlightStatusFilter>[] = [
  {
    displayName: 'Flight Status',
    keyValue: FlightStatusFilter.All,
  },
  {
    displayName: 'Delayed',
    keyValue: FlightStatusFilter.Delayed,
  },
  {
    displayName: 'On Time',
    keyValue: FlightStatusFilter.OnTime,
  },
  {
    displayName: 'Scheduled',
    keyValue: FlightStatusFilter.Scheduled,
  },
];

export const HistoryCallTypeList: FilterType<HistoryKeyCallTypeFilter>[] = [
  {
    displayName: 'Call Type',
    keyValue: HistoryKeyCallTypeFilter.All,
  },
  {
    displayName: 'MOC/IOC',
    keyValue: HistoryKeyCallTypeFilter.MocIoc,
  },
  {
    displayName: 'Planning',
    keyValue: HistoryKeyCallTypeFilter.Planning,
  },
  {
    displayName: 'Verbal',
    keyValue: HistoryKeyCallTypeFilter.Verbal,
  },
  {
    displayName: 'Radio',
    keyValue: HistoryKeyCallTypeFilter.Radio,
  },
  {
    displayName: 'Telephone',
    keyValue: HistoryKeyCallTypeFilter.Telephone,
  },
  {
    displayName: 'VHF',
    keyValue: HistoryKeyCallTypeFilter.Vhf,
  },
  {
    displayName: 'Ramp',
    keyValue: HistoryKeyCallTypeFilter.Ramp,
  },
  {
    displayName: 'Vendor',
    keyValue: HistoryKeyCallTypeFilter.Vendor,
  },
  {
    displayName: 'GateReturn',
    keyValue: HistoryKeyCallTypeFilter.GateReturn,
  },
  {
    displayName: 'RTF',
    keyValue: HistoryKeyCallTypeFilter.Rtf,
  },
  {
    displayName: 'Diversion',
    keyValue: HistoryKeyCallTypeFilter.Diversion,
  },
  {
    displayName: 'System',
    keyValue: HistoryKeyCallTypeFilter.SystemGenerated,
  },
];

export const HistoryTypeList: FilterType<HistoryNotificationTypeFilter>[] = [
  {
    displayName: 'Type',
    keyValue: HistoryNotificationTypeFilter.All,
  },
  {
    displayName: 'HERMES',
    keyValue: HistoryNotificationTypeFilter.Hermes,
  },
  {
    displayName: 'TRANSIT CHKs',
    keyValue: HistoryNotificationTypeFilter.Transit,
  },
  {
    displayName: 'Meet & Greet',
    keyValue: HistoryNotificationTypeFilter.MeetGreet,
  },
  {
    displayName: 'RS Meet & Greet',
    keyValue: HistoryNotificationTypeFilter.RightStartMeetGreet,
  },
  {
    displayName: 'Wake Up',
    keyValue: HistoryNotificationTypeFilter.WakeUp,
  },
  {
    displayName: 'Right Start Wake Up',
    keyValue: HistoryNotificationTypeFilter.RightStartWakeUp,
  },
  {
    displayName: 'Oil Check',
    keyValue: HistoryNotificationTypeFilter.Cfmoil,
  },
  {
    displayName: 'MEL (Follow Up)',
    keyValue: HistoryNotificationTypeFilter.MelFollowUp,
  },
  {
    displayName: 'MEL (General)',
    keyValue: HistoryNotificationTypeFilter.MelGeneral,
  },
  {
    displayName: 'MEL (Quick Fix)',
    keyValue: HistoryNotificationTypeFilter.QuickFix,
  },
  {
    displayName: 'NEF (Quick Fix)',
    keyValue: HistoryNotificationTypeFilter.NefQuickFix,
  },
  {
    displayName: 'Term Check',
    keyValue: HistoryNotificationTypeFilter.Term,
  },
  {
    displayName: 'Manual',
    keyValue: HistoryNotificationTypeFilter.Manual,
  },
  {
    displayName: 'Other',
    keyValue: HistoryNotificationTypeFilter.Other,
  },
];

export const HistoryAlternateTasksList: FilterType<AlternateTasksFilter>[] = [
  {
    displayName: 'Alternate Tasks',
    keyValue: AlternateTasksFilter.None,
  },
  {
    displayName: 'Non Aircraft',
    keyValue: AlternateTasksFilter.NonAircraft,
  },
  {
    displayName: 'Aircraft',
    keyValue: AlternateTasksFilter.Aircraft,
  },
  {
    displayName: 'All',
    keyValue: AlternateTasksFilter.All,
  },
];

//#endregion
