import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAircraft from './+state/aircraft.reducer';
import { AircraftEffects } from './+state/aircraft.effects';
import { AIRCRAFT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(AIRCRAFT_FEATURE_KEY, fromAircraft.reducer),
    EffectsModule.forFeature([AircraftEffects]),
  ],
})
export class LmoUiDataAccessAircraftModule {}
