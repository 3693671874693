import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TaskState } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-bow-task-status-icon',
  templateUrl: './bow-task-status-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowTaskStatusIconComponent {
  @Input({ required: true }) public taskState!: TaskState | null;
  public readonly taskStateValues = TaskState;
}
