<section id="off1" class="ac-attr-container">
  <div class="vpanel" *ngIf="selectedFlightData$ | async as selectedData">
    <lm-apps-lmo-ui-cc-view-aircraft-attribute
      (closeEvent)="closeSidePanel()"
      *ngIf="selectedData.flight"
      [flight]="selectedData.flight"
    ></lm-apps-lmo-ui-cc-view-aircraft-attribute>

    <div *ngIf="showTaskDetail">
      <lm-apps-lmo-ui-task-detail
        [maintenanceTaskDisplay]="maintenanceTaskDisplay"
        [flight]="selectedData.flight"
        [predefinedTask]="(predefinedTask$ | async) || []"
        [crew]="crew$ | async"
        [user]="user$ | async"
        [allCrewMemberOnlineStatus]="(allCrewMemberOnlineStatus$ | async) || []"
        [canCompleteOrDeferTask]="canCompleteOrDeferTask()"
        [canReopenTask]="canReopenTask()"
        [crewMembersForAssignToDropdown]="
          (crewMembersForAssignToDropdown$ | async) || []
        "
        [sortCrewState]="sortCrewState$ | async"
        (closeTaskSection)="closeTask()"
        (emitTask)="upsertTask($event)"
        (sortCrewEvent)="sortCrew($event)"
      >
      </lm-apps-lmo-ui-task-detail>
    </div>
    <br />

    <div *ngIf="showBowTaskDetail">
      <lm-apps-lmo-ui-bow-task-detail
        [maintenanceTaskDisplay]="maintenanceTaskDisplay"
        [flight]="selectedData.flight"
        [predefinedTask]="(predefinedTask$ | async) || []"
        [crew]="crew$ | async"
        [user]="user$ | async"
        [allCrewMemberOnlineStatus]="(allCrewMemberOnlineStatus$ | async) || []"
        [crewMembersForAssignToDropdown]="
          (crewMembersForAssignToDropdown$ | async) || []
        "
        [sortCrewState]="sortCrewState$ | async"
        (closeTaskSection)="closeTask()"
        (emitTask)="upsertTask($event)"
        (sortCrewEvent)="sortCrew($event)"
        (emitManualTask)="createManualTask($event)"
        [canCompleteOrDeferTask]="canCompleteOrDeferTask()"
        [canReopenTask]="canReopenTask()"
      >
      </lm-apps-lmo-ui-bow-task-detail>
    </div>
    <br />

    <lm-apps-flight-task-list
      [isRhs]="true"
      [tasks]="
        selectedData.flight.AllTasks | filterAllTaskByJobType: keyJobTypes.Bow
      "
      (selectedTask)="setSelectedFlight(selectedData.flight, '', $event)"
    ></lm-apps-flight-task-list>

    <ng-container *ngIf="billOfWorkItemsList$ | async as listOfBowItems">
      <lm-apps-lmo-ui-cc-view-bow-items
        *ngIf="config.ENABLE_BOW_FEATURE === 'true'"
        [bowItemsList]="listOfBowItems"
        (selectedTask)="setSelectedFlight(selectedData.flight, '', $event)"
      ></lm-apps-lmo-ui-cc-view-bow-items>
    </ng-container>

    <lm-apps-message-list [flight]="selectedData.flight"></lm-apps-message-list>

    <lm-apps-lmo-ui-ac-attr-maint
      [flight]="selectedData.flight"
      [safeAircraftUri]="config.SAFE_URL"
      (emitSelectNotification)="setSelectedFlight(selectedData.flight, $event)"
    ></lm-apps-lmo-ui-ac-attr-maint>
  </div>
</section>
