import { Pipe, PipeTransform } from '@angular/core';

export const BADGE_NUMBER_LENGTH = 8;
@Pipe({
  name: 'parseBadgeNumber',
})
export class ParseBadgeNumberPipe implements PipeTransform {
  transform(value: string) {
    return parseBadgeNumber(value, false);
  }
}

/**
 *Parse BadgeNumber and return the last 8 characters(prepended with 0's if less than 6)
 *
 * @export
 * @param {string} value
 * @return {*}
 */
// TODO: make shared function with nestjs pipe in libs/common
export function parseBadgeNumber(value: string, optional = true) {
  if (optional && !value) {
    return value;
  }
  if (!value) {
    throw new Error(`Badge number(${value}) is invalid`);
  }
  value = value.trim();
  if (value.length > BADGE_NUMBER_LENGTH) {
    return value.slice(-BADGE_NUMBER_LENGTH);
  }
  return value.padStart(BADGE_NUMBER_LENGTH, '0');
}
