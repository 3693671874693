/**
 * Sceptre Lables
 */
export enum SceptreLables {
  /**
   * FR MON
   */
  FR_MON = 'FR MON',
  /**
   * Open Mic
   */
  OPS = 'OPEN MIC',
  /**
   * Daily Chk
   */
  DAILY_CHECK = 'DAILY CHK',
  PilotOPS = 'PILOT eMIC',
  AirworthinessOPS = 'OPEN eAWR',
}
