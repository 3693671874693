import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { StationZoneGatesGQL } from './query/station-zone-gates.query';
import { StationsGQL } from './query';

@Injectable({
  providedIn: 'root',
})
export class StationsService {
  constructor(
    private _stations: StationsGQL,
    private _stationZoneGates: StationZoneGatesGQL
  ) {}
  getStationsByIataCodes(stationCodes: string[]) {
    return this._stations
      .fetch({ stationsSearchInput: { Codes: stationCodes } })
      .pipe(
        map(({ data }) => {
          return data;
        })
      );
  }
  getStationZonesByIataCode(stationCode: string) {
    return this._stationZoneGates
      .fetch({ stationsSearchInput: { Codes: [stationCode] } })
      .pipe(map(({ data }) => data.StationsZonesGates));
  }
  getAllStations() {
    return this._stations.fetch().pipe(
      map(({ data }) => {
        return data;
      })
    );
  }
}
