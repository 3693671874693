export enum JobTypeDisplay {
  AcMoves = 'A/C Moves',
  MelFollowUp = 'MEL (Follow Up)',
  MelQuickFix = 'MEL (Quick Fix)',
  MelGeneral = 'MEL (General)',
  NefQuickFix = 'NEF (Quick Fix)',
  Rsmg = 'Right Start Meet & Greet',
  Mg = 'Meet & Greet',
  WakeUp = 'Wake Up',
  Rswu = 'Right Start Wake Up',
  Servicing = 'Servicing',
  Check = 'Check',
  DailyCheck = 'Daily Check',
  Discrepancy = 'Discrepancy',
  EtopsCheck = 'ETOPS Check',
  EtopsOilCheck = 'ETOPS Oil Check',
  Hermes = 'Hermes',
  Arms = 'Arms',
  Other = 'Other',
  TransitCheck = 'Transit Check',
  ReservedItem = 'Reserved Item',
  MocRequest = 'MOC Request',
  CubaFlight = 'CUBA',
  Ife = 'Inflight Entertainment',
  NEFFollowUp = 'NEF (Follow Up)',
  CDLFollowUp = 'CDL (Follow Up)',
  TACFollowUp = 'TAC (Follow Up)',
  MONFollowUp = 'MON (Follow Up)',
  OPEN = 'OPEN',
  BOW = 'Bill Of Work',
}
