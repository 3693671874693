import { Injectable } from '@angular/core';
import { HistoryService } from '@lm-apps/lmo/ui/common/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './history.actions';

@Injectable()
export class HistoryEffects {
  constructor(
    private readonly actions$: Actions,
    private historyService: HistoryService,
    private toastService: ToastrService
  ) {}

  getAlternateTaskHistoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initAlternateTaskHistory),
      switchMap(({ alternateTaskHistoryInput }) => {
        return this.historyService
          .getAlternateTaskHistoryDetails(alternateTaskHistoryInput)
          .pipe(
            map((alternateTaskHistoryDetails) => {
              return actions.loadAlternateTaskHistorySuccess({
                alternateTaskHistoryDetails,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                'Failed to load alternate task history at station:',
                alternateTaskHistoryInput.Station
              );
              return of(
                actions.throwError({ error: error.message || 'Unknown error' })
              );
            })
          );
      })
    )
  );

  getMTHoldHistoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initMTHoldHistory),
      switchMap(({ automaticHoldHistoryInput }) => {
        return this.historyService
          .getMTHoldHistoryDetails(automaticHoldHistoryInput)
          .pipe(
            map((automaticHoldHistory) => {
              return actions.loadMTHoldHistorySuccess({
                automaticHoldHistory,
              });
            }),
            catchError((error) => {
              this.toastService.error(
                'Failed to load automatic hold history at station:',
                automaticHoldHistoryInput.Station
              );
              return of(
                actions.throwError({ error: error.message || 'Unknown error' })
              );
            })
          );
      })
    )
  );

  getPairsHistoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initPairsHistory),
      switchMap(({ filterInput }) => {
        return this.historyService.getPairsHistoryDetails(filterInput).pipe(
          map((pairsHistory) => {
            return actions.loadPairsHistorySuccess({
              pairsHistory,
            });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load automatic hold history at station:',
              filterInput.Station
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );

  getTaskHistoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.initTaskHistory),
      switchMap(({ taskId }) => {
        return this.historyService.getTaskHistoryDetails(taskId).pipe(
          map((taskHistoryDisplay) => {
            return actions.loadTaskHistorySuccess({
              taskHistoryDisplay,
            });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load task history for task Id:',
              taskId
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );
}
