import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lm-apps-nose-number',
  templateUrl: './nosenumber.component.html',
  styleUrls: ['./nosenumber.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NosenumberComponent {
  @Input() noseNumber!: string;
  @Input() safeAircraftUri!: string;
  @Input() fleetType!: string;
  @Input() isEtopsCertified!: boolean;
  @Input() headerClass: 'h3-1' | 'h3-2' = 'h3-1';
  @Input() isEAMLEnabled = false;
}
