<div class="table-responsive hidden-print">
  <h2 class="text-centered" *ngIf="flightLegs">
    {{ flightLegs[0]?.Equipment?.NoseNumber }}
  </h2>

  <table class="table text-center">
    <thead>
      <tr class="text-center">
        <th class="text-center"></th>
        <th class="text-center">Flight #</th>
        <th class="text-center">DPT DT</th>
        <th class="text-center">FROM</th>
        <th class="text-center">TO</th>
        <th class="text-center">DPT TIME</th>
        <th class="text-center">DPT STAT</th>
        <th class="text-center">ARR TIME</th>
        <th class="text-center">ARR STAT</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let leg of flightLegs" class="text-center">
        <td *ngIf="selectedStations.indexOf(leg.ArrivalStation) === -1"></td>
        <td
          class="select-link"
          *ngIf="selectedStations.indexOf(leg.ArrivalStation) !== -1"
          (click)="createManualTask(leg)"
        >
          Select
        </td>
        <td>{{ leg.Designator.FlightNumber }}</td>
        <td>{{ leg.Designator.LegOriginDate | date }}</td>
        <td>{{ leg.DepartureStation }}</td>
        <td>{{ leg.ArrivalStation }}</td>
        <td>
          {{
            leg.ActualDeparture | dateOffset: leg.DepartureUtcAdjustment:'HH:mm'
          }}
        </td>

        <td
          [ngClass]="{
            O: (leg.DepartureStatusCode | departureStatusCode) === 'O',
            F: (leg.DepartureStatusCode | departureStatusCode) === 'F'
          }"
        >
          <span></span>
          {{
            getDepartureStatusText(
              leg.DepartureStatusCode | departureStatusCode
            )
          }}
        </td>
        <td>
          {{ leg.ActualArrival | dateOffset: leg.ArrivalUtcAdjustment:'HH:mm' }}
        </td>

        <td
          [ngClass]="{
            O: (leg.ArrivalStatusCode | arrivalStatusCode) === 'O',
            I: (leg.ArrivalStatusCode | arrivalStatusCode) === 'I'
          }"
        >
          {{ getArrivalStatusText(leg.ArrivalStatusCode | arrivalStatusCode) }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
