import { Injectable } from '@angular/core';
import {
  AlternateTasksService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import { AlternateTask } from '@lm-apps/lmo/ui/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './alternate-tasks.actions';

@Injectable()
export class AlternateTasksEffects {
  getAlternateTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(({ stations }) => {
        return this.alternateTasksService.getAlternateTasks(stations).pipe(
          map((alternateTasks: AlternateTask[]) =>
            actions.loadAlternateTasksSuccess({
              alternateTasks,
            })
          ),
          catchError((error) => {
            this.toastService.error(
              'Failed to load alternate tasks at stations:',
              stations.join(', ')
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private alternateTasksService: AlternateTasksService,
    private toastService: CustomToastrService
  ) {}
}
