import { Pipe, PipeTransform } from '@angular/core';
import { TaskStatusLabel } from '@lm-apps/lmo/shared/enums';
import { TaskState } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'getTaskStatusLabel',
})
export class GetTaskStatusLabelPipe implements PipeTransform {
  transform(status: TaskState | null): string | TaskStatusLabel {
    if (!status) {
      return '-';
    }
    switch (status) {
      case TaskState.Assigned:
        return TaskStatusLabel.Assigned;
      case TaskState.Acknowledged:
        return TaskStatusLabel.Acknowledged;
      case TaskState.Arrived:
        return TaskStatusLabel.Arrived;
      case TaskState.Completed:
        return TaskStatusLabel.Completed;
      case TaskState.Delivered:
        return TaskStatusLabel.Delivered;
      case TaskState.Unassigned:
        return TaskStatusLabel.Unassigned;
      case TaskState.Closed:
        return TaskStatusLabel.Closed;
      default:
        return '-';
    }
  }
}
