/**
 * Maint Hold Types
 */
export enum MaintHoldType {
  /**
   * None
   */
  None = 0,
  /**
   * Followup
   */
  Followup = 1,
}
