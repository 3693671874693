import { HISTORY_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HistoryState } from './history.interfaces';
import { DUMMY_AIRCRAFT } from '@lm-apps/lmo/shared/enums';

export const getHistoryState =
  createFeatureSelector<HistoryState>(HISTORY_FEATURE_KEY);

export const selectAllAlternateTasks = createSelector(
  getHistoryState,
  (state) => state.alternateTaskHistory
);

export const selectNonAcAlternateTasks = createSelector(
  getHistoryState,
  (state) => {
    return (
      state.alternateTaskHistory?.filter(
        (a) => a.NoseNumber === DUMMY_AIRCRAFT
      ) ?? []
    );
  }
);

export const selectAircraftAlternateTasks = createSelector(
  getHistoryState,
  (state) => {
    const results = state.alternateTaskHistory?.filter(
      (a) => a.NoseNumber != DUMMY_AIRCRAFT
    );
    return results ? results : [];
  }
);

export const selectAutomaticHoldHistory = createSelector(
  getHistoryState,
  (state) => state.automaticHoldHistory
);

export const selectPairsHistory = createSelector(
  getHistoryState,
  (state) => state.pairsHistory
);

export const selectTaskHistory = createSelector(
  getHistoryState,
  (state) => state.taskHistoryDisplay
);

export const selectIsHistoryLoaded = createSelector(
  getHistoryState,
  (state) => state.loaded
);
