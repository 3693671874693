<div class="d-flex flex-wrap gap-4">
  <lm-apps-lmo-ui-notification-item
    *ngFor="
      let notification of notifications
        | filterNotificationsToBeHidden: defferalMels:fleetType
        | showNotificationsWithoutTask
    "
    [notification]="notification"
    (selectedNotification)="notiClicked($event)"
  >
  </lm-apps-lmo-ui-notification-item>
</div>
