import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lm-apps-lmo-ui-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlineStatusComponent),
      multi: true,
    },
  ],
})
export class OnlineStatusComponent {
  @Input() isOnline = false;

  onChange: any = () => {
    console.log('');
  };
  onTouched: any = () => {
    console.log('');
  };

  writeValue(val: boolean): void {
    this.isOnline = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
