import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MaintenancePriorities } from '@lm-apps/lmo/shared/enums';
import {
  Discrepancy,
  Vhf,
  callTypeList,
  jobTypeList,
} from '@lm-apps/lmo/shared/master';
import { FlightByAircraftService } from '@lm-apps/lmo/ui/common/services';
import {
  CreateTeamAssignedCrewMemberInput,
  Crew,
  FlightLeg,
  FlightLegDetail,
  ItemType,
  MANUAL_TASK,
  MOC_REQUEST,
  MutationCreateManualTaskArgs,
  PredefinedTask,
  preDefinedTaskLabels,
  SortableCrewColumn,
  TaskAction,
  TaskCallTypeInput,
  TaskJobTypeInput,
  TaskState,
} from '@lm-apps/lmo/ui/data';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';
import { actions } from '@lm-apps/lmo/ui/data-access-flight';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import { Store, select } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

@Component({
  selector: 'lm-apps-new-task',
  templateUrl: './new-task.component.html',
})
export class NewTaskComponent implements OnInit {
  @Input()
  flight: FlightLegDetail | null = null;
  @Input()
  crew: Crew | null = null;
  @Input()
  isMOC!: boolean;
  callTypes = callTypeList(false);
  jobTypes = jobTypeList(false, false);
  selectedStations$ = this.store.pipe(select(fromUser.selectStations));
  predefinedTask$ = this.store.pipe(
    select(fromUser.selectActivePredefinedTasks)
  );
  flightLegs$!: Observable<FlightLeg[]>;
  allCrewMemberOnlineStatus$ = this.store.pipe(
    select(fromCrewMemberStatus.selectAllCrewMemberOnlineStatus)
  );
  sortCrewState$ = this.store.pipe(select(fromUser.selectSortCrewState));

  newTaskForm = this.formBuilder.group({
    predefinedTypes: new FormControl<PredefinedTask>({} as PredefinedTask),
    callType: new FormControl<TaskCallTypeInput>(Vhf, {
      nonNullable: true,
    }),
    jobType: new FormControl<TaskJobTypeInput>(Discrepancy, {
      nonNullable: true,
    }),
    selectedCrewMembers: new FormControl<CreateTeamAssignedCrewMemberInput[]>(
      []
    ),
    label: new FormControl<string>('', { nonNullable: true }),
    description: new FormControl<string>(MANUAL_TASK),
    specialInstruction: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(380),
    ]),
    isAvionic: new FormControl<boolean>(false, { nonNullable: true }),
    isOilService: new FormControl<boolean>(false, { nonNullable: true }),
    isNFNF: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private store: Store,
    private flightByAircraftService: FlightByAircraftService
  ) {}

  ngOnInit() {
    this.setMocJobTypes();
  }

  closeModal(): void {
    this.modalRef.hide();
  }

  setMocJobTypes(): void {
    if (this.isMOC) {
      this.jobTypes = jobTypeList(this.isMOC, false);
      this.newTaskForm.patchValue({
        label: MOC_REQUEST,
        jobType: this.jobTypes[0],
      });
      this.newTaskForm.controls.jobType.disable();
    }
  }

  predefinedChange(selectedValue: PredefinedTask) {
    this.newTaskForm.patchValue({
      description: selectedValue.Description,
    });

    this.newTaskForm.patchValue({
      specialInstruction: selectedValue.Description,
    });

    const description = document.getElementById('description');
    if (description) {
      description.style.height = '100%';
    }

    const specialInstruction = document.getElementById('specialInstruction');
    if (specialInstruction) {
      specialInstruction.style.height = 'Auto';
    }
  }

  addOrRemoveSelectedMechanic(crewMember: CreateTeamAssignedCrewMemberInput) {
    if (this.newTaskForm.controls.selectedCrewMembers.value) {
      let updatedVal: CreateTeamAssignedCrewMemberInput[] = [];
      if (
        this.newTaskForm.controls.selectedCrewMembers.value.includes(crewMember)
      ) {
        updatedVal = this.newTaskForm.controls.selectedCrewMembers.value.filter(
          (x) => x.EmployeeId !== crewMember.EmployeeId
        );
      } else {
        updatedVal = [
          ...this.newTaskForm.controls.selectedCrewMembers.value,
          crewMember,
        ];
      }
      this.newTaskForm.controls.selectedCrewMembers.setValue(updatedVal);
    }
  }

  submitForm() {
    if (this.newTaskForm.valid && this.flight) {
      const maintenanceTaskArgs: MutationCreateManualTaskArgs = {
        maintenanceTask: {
          Aircraft: {
            NoseNumber: this.flight.NoseNumber,
            AirlineCode: this.flight.AirlineCode,
          },
          AircraftId: this.flight.AircraftId,
          CallType: <TaskCallTypeInput>{
            DisplayCallType:
              this.newTaskForm.controls.callType.value.DisplayCallType,
            Id: this.newTaskForm.controls.callType.value.Id,
            KeyCallType: this.newTaskForm.controls.callType.value.KeyCallType,
          },
          AssignedToIds: this.newTaskForm.controls.selectedCrewMembers.value
            ? this.newTaskForm.controls.selectedCrewMembers.value.map(
                (crewMember) => crewMember.EmployeeId
              )
            : [],
          Description:
            this.newTaskForm.controls.description.value?.trim() || MANUAL_TASK,
          IataCode: this.flight.ArrivalId
            ? this.flight.ArrivalStation ?? ''
            : this.flight.DepartureStation ?? '',
          InboundFlightLegId: this.flight.ArrivalId
            ? this.flight.ArrivalId
            : null,
          OutBoundFlightLegId: this.flight.ArrivalId
            ? null
            : this.flight.DepartureId,
          IsAlternateTask: false,
          IsAvionic: this.newTaskForm.controls.isAvionic.value,
          IsManualTask: true,
          IsOilService: this.newTaskForm.controls.isOilService.value,
          JobType: <TaskJobTypeInput>{
            KeyJobType: this.newTaskForm.controls.jobType.value.KeyJobType,
            Id: this.newTaskForm.controls.jobType.value.Id,
            DisplayJobType:
              this.newTaskForm.controls.jobType.value.DisplayJobType,
          },
          Label: this.getTaskLabel(),
          MaintenanceType: ItemType.ManualTask,
          IsNfnf: this.newTaskForm.controls.isNFNF.value,
          SpecialInstruction:
            this.newTaskForm.controls.specialInstruction.value,
          TaskState:
            this.newTaskForm.controls.selectedCrewMembers.value &&
            this.newTaskForm.controls.selectedCrewMembers.value.length > 0
              ? TaskState.Assigned
              : TaskState.Unassigned,
          PredefinedTask: this.newTaskForm.controls.predefinedTypes.value?.Title
            ? {
                Title: this.newTaskForm.controls.predefinedTypes.value.Title,
                Id: this.newTaskForm.controls.predefinedTypes.value.Id,
                IsActive:
                  this.newTaskForm.controls.predefinedTypes.value.IsActive,
                Description:
                  this.newTaskForm.controls.predefinedTypes.value.Description,
              }
            : undefined,
          MaintenancePriority: {
            Description: MaintenancePriorities.USER_DEFINED,
          },
          IsUpdate: false,
          TaskAction: TaskAction.Create,
        },
      };

      this.store.dispatch(actions.createManualTask({ maintenanceTaskArgs }));
      this.closeModal();
    }
  }

  searchFlightLegs(noseNumber: string): void {
    this.flightLegs$ = this.flightByAircraftService.flightsByAircraft(
      noseNumber.toUpperCase()
    );
  }

  printSection() {
    const sections = document.querySelectorAll<HTMLElement>('.printable-task');
    sections.forEach((section) => {
      section['style'].display = 'none';
    });
    window.print();
    sections.forEach((section) => {
      section['style'].display = 'block';
    });
  }
  showManualTask(fld: FlightLegDetail) {
    this.flight = fld;
  }

  getTaskLabel(): string {
    const label =
      this.newTaskForm.controls.label.value.trim() ||
      (this.isMOC ? MOC_REQUEST : MANUAL_TASK);

    if (this.isMOC && label === MOC_REQUEST) {
      return label;
    }
    const text = preDefinedTaskLabels.filter((t) => t === label.toLowerCase());
    return text.length > 0 ? label + '-Manual' : label;
  }

  sortCrew(column: SortableCrewColumn) {
    this.store.dispatch(fromUser.actions.sortCrew({ column }));
  }
}
