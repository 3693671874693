import {
  AvailableTeamAssignments,
  CrewMember,
  TeamAssignment,
} from './type-defs.model';

export interface AvailableTeamAssignmentsResponse {
  AvailableTeamAssignments: AvailableTeamAssignments;
}

export interface CreateTeamAssignmentResponse {
  CreateTeamAssignment: TeamAssignment;
}

export interface UpdateTeamAssignmentResponse {
  UpdateTeamAssignment: TeamAssignment;
}

export interface AllCrewMemberByStationResponse {
  AllCrewMemberByStation: CrewMember[];
}
export interface DeleteTeamAssignmentResponse {
  DeleteTeamAssignment: TeamAssignment;
}
export interface DisableTeamSharingResponse {
  DisableTeamSharing: TeamAssignment;
}
export interface EnableTeamSharingResponse {
  EnableTeamSharing: TeamAssignment;
}

export interface TakeTeamOwnershipResponse {
  TakeTeamOwnership: TeamAssignment;
}
export interface ReleaseTeamOwnershipResponse {
  ReleaseTeamOwnership: TeamAssignment;
}
