import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class StationsGQL extends Query<
  Pick<data.Query, 'Stations'>,
  data.QueryStationsArgs
> {
  override document = gql`
    query Stations($stationsSearchInput: StationsSearchInput) {
      Stations(stationsSearchInput: $stationsSearchInput) {
        CountryCodeNumber
        DSTimeZoneOffset
        Displayed
        HubStationDisplayOrder
        IataCode
        IsExtraBaseStation
        IsHubStation
        IsROD
        IsRON
        IsStaffed
        City
        CountryState
        Region
        TimeZoneOffset
      }
    }
  `;
}
