<section class="flt-tasks">
  <div class="title-txt bg-shade-gray">Messages</div>
  <hr class="cc-hr" />

  <table class="table table-condensed">
    <thead>
      <tr>
        <th>Type</th>
        <th>Reference#</th>
        <th>Description</th>
        <th>Date/Time</th>
        <th>Assigned</th>
      </tr>
    </thead>
    <tbody class="attr">
      <tr *ngFor="let notification of notifications">
        <td>
          <lm-apps-lmo-ui-notification-item
            [isRhs]="true"
            [notification]="notification"
          ></lm-apps-lmo-ui-notification-item>
        </td>
        <td>{{ notification.ExtendedAttributes?.ArmCode || '-' }}</td>
        <td>
          {{
            notification.Description ||
              notification.ExtendedAttributes?.Description ||
              ' -'
          }}
        </td>
        <td>
          {{ notification.Effectivity | date: 'MMM dd HH:mm':'UTC' }}&nbsp;UTC
        </td>
        <!-- <td>{{notification.AssignedToNames | json}}</td> -->
        <td>
          <table class="table table-condensed">
            <tbody>
              <tr *ngFor="let item of notification.AssignedToNames">
                <td>{{ item.LastName | formatToShortName: item.FirstName }}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</section>
