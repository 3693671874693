import { Pipe, PipeTransform } from '@angular/core';
import { ItemType } from '@lm-apps/lmo/ui/data';
import { Notification } from '@lm-apps/lmo/wall/shared';

@Pipe({
  name: 'blackNotificationsDataPipe',
  pure: true,
})
export class BlackNotificationsDataPipe implements PipeTransform {
  notsToBeHiddenIds = ['', 'R', 'CI'];
  transform(notifications: Notification[]): Notification[] {
    return notifications.filter((notification) => {
      if (notification.type !== ItemType.Deferral) return true;
      return !(
        notification.priorityName &&
        this.notsToBeHiddenIds.includes(notification.priorityName)
      );
    });
  }
}
