import {
  ENABLE_SUPER_USER,
  FEATURE_FLAG_FEATURE_KEY,
  MANPOWER_CREW_MANAGER,
} from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFeatureFlag from './feature-flag.reducer';
import {
  ENABLE_STATION_ROLLOUT,
  ENABLE_PILOT_STATION,
} from '@lm-apps/lmo/shared/enums';

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  fromFeatureFlag.adapter.getSelectors();

export const selectFeatureFlagState =
  createFeatureSelector<fromFeatureFlag.State>(FEATURE_FLAG_FEATURE_KEY);
export const selectAllFeatureFlags = createSelector(
  selectFeatureFlagState,
  selectAll
);
export const selectEntitiesFeatureFlag = createSelector(
  selectFeatureFlagState,
  selectEntities
);
export const selectManpowerCrewManagerFlag = createSelector(
  selectAllFeatureFlags,
  (state) => state.find((flag) => flag.name === MANPOWER_CREW_MANAGER) || null
);
export const isManpowerCMEnabled = createSelector(
  selectManpowerCrewManagerFlag,
  (flag) => flag && flag.enabled
);
export const selectFeatureFlagsLoaded = createSelector(
  selectFeatureFlagState, // Adjust based on your state structure
  (state) => state.loaded
);

export const selectStationRolloutFlag = createSelector(
  selectAllFeatureFlags,
  (state) => state.find((flag) => flag.name === ENABLE_STATION_ROLLOUT) || null
);

// Intention: enable-pilot-station-phx, enable-pilot-station-dfw gets converted to ['PHX', 'DFW']
export const selectEnabledPilotStations = createSelector(
  selectAllFeatureFlags,
  (state) =>
    state
      .filter(
        (flag) => flag.enabled && flag.name.startsWith(ENABLE_PILOT_STATION)
      )
      .map((flag) => flag.name.split('-')[3]?.toUpperCase())
);

// Intention: disable-pilot-station-redirection-0012345, disable-pilot-station-redirection-00324543 gets converted to ['0012345', '00324543']
export const superUsers = createSelector(
  selectAllFeatureFlags,
  selectFeatureFlagsLoaded, // Get `loaded` state
  (featureFlags, loaded) => {
    if (!loaded) return null;
    return featureFlags
      .filter((flag) => flag.enabled && flag.name.startsWith(ENABLE_SUPER_USER))
      .map((flag) => flag.name.split('-')[3]?.toUpperCase());
  }
);
