export enum ApplicationEvents {
  ON = 'ON',
  OFF = 'OFF',
  ETD = 'ETD',
  DCSN = 'DCSN',
  DIVERSION = 'DIVERSION',
  EQSUB = 'EQSUB',
  EQLINK = 'EQLINK',
  CONTINUATION = 'CONTINUATION',
  CYCLE = 'CYCLE',
  CREATE = 'CREATE',
  TASK_CREATE = 'TASK_CREATE',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_CLEANUP = 'TASK_CLEANUP',
  TASK_DELETE = 'TASK_DELETE',
  TASK_CLOSED = 'TASK_CLOSED',
  MIC_CREATE = 'MIC_CREATE',
  MIC_REMOVE = 'MIC_REMOVE',
  UNSUPPORTED = 'UNSUPPORTED',
  CLEARSCEPTRE = 'CLEARSCEPTRE',
}
