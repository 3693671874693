import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  Discrepancy,
  Vhf,
  callTypeList,
  jobTypeList,
} from '@lm-apps/lmo/shared/master';
import {
  AssignedMember,
  CreateTeamAssignedCrewMemberInput,
  Crew,
  FlightsEntity,
  KeyCallType,
  KeyJobType,
  MaintenanceTaskDisplay,
  MaintenanceTaskInput,
  MutationCreateManualTaskArgs,
  MutationCreateTaskArgs,
  OnlineStatus,
  PredefinedTask,
  SortableCrewColumn,
  SortCrewState,
  TaskAction,
  TaskCallTypeInput,
  TaskJobTypeInput,
  TaskState,
  User,
} from '@lm-apps/lmo/ui/data';

import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import {
  DeferralReasons,
  JobTypeDisplay,
  MaintenancePriorities,
} from '@lm-apps/lmo/shared/enums';
import { AddOrRemoveQFXDeferralReason } from '@lm-apps/lmo/ui/common/feature-shared';
import { Dropdown } from 'primeng/dropdown';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lm-apps-lmo-ui-task-detail',
  templateUrl: './task-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDetailComponent implements OnChanges, OnDestroy, OnInit {
  @Input() flight!: FlightsEntity;
  @Input() maintenanceTaskDisplay!: MaintenanceTaskDisplay;
  @Input() crew!: Crew | null;
  @Input() predefinedTask!: PredefinedTask[];
  @Input() user!: User | null;
  @Input() canCompleteTask!: boolean;
  @Input() canReopenTask!: boolean;
  @Input() canCompleteOrDeferTask!: boolean;
  @Input() allCrewMemberOnlineStatus: OnlineStatus[] = [];
  @Input() crewMembersForAssignToDropdown: AssignedMember[] = [];
  @Input() sortCrewState!: SortCrewState | null;

  @Output() closeTaskSection: EventEmitter<void> = new EventEmitter();
  @Output() emitTask: EventEmitter<MutationCreateTaskArgs> = new EventEmitter();
  @Output() sortCrewEvent = new EventEmitter<SortableCrewColumn>();
  @Output() emitManualTask: EventEmitter<MutationCreateTaskArgs> =
    new EventEmitter();

  callTypes = callTypeList(true);
  jobTypes = jobTypeList(false, true);
  deferralReasonList: string[] = [];
  enableDeferralReasonMsg = false;
  showSelectDeferralReasonMsg = false;
  showTaskAlreadyUpdatedMsg = false;
  showHistory = false;
  enableUpdatebtn = false;
  Deferred = TaskState.Deferred;
  faFloppyDisk = faFloppyDisk;
  disableButtons = false;

  @ViewChild('dropdown') dropdown!: Dropdown;

  private destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  taskDetailForm = this.formBuilder.group({
    selectedCrewMembers: new FormControl<AssignedMember[]>([]),
    callType: new FormControl(Vhf, { nonNullable: true }),
    jobType: new FormControl(Discrepancy, { nonNullable: true }),
    predefinedTypes: new FormControl<PredefinedTask>(<PredefinedTask>{}),
    specialInstruction: new FormControl<string>('', [
      Validators.maxLength(380),
    ]),
    deferralReason: new FormControl('', { nonNullable: true }),
    isAvionic: new FormControl(false, { nonNullable: true }),
    isOilService: new FormControl(false, { nonNullable: true }),
    isNFNF: new FormControl(
      { value: false, disabled: false },
      { nonNullable: true }
    ),
  });

  get selectedCmCtrl() {
    return this.taskDetailForm.controls.selectedCrewMembers;
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngOnInit(): void {
    this.taskDetailForm.controls.selectedCrewMembers.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.canUpdate());
  }

  ngOnChanges(changes: SimpleChanges) {
    const task = changes['maintenanceTaskDisplay'];
    const crew = changes['crew'];

    if (crew && crew.currentValue) {
      this.selectedCmCtrl.setValue([]);
    }
    if (!task) return;
    /*
     Form's control should be update only if component is firstly created or
     new task/notification is selected. Initialized Form only when maintenanceTaskDisplay is changed.
    */
    if (
      task.firstChange ||
      task.currentValue.MaintenanceTaskInput.NotificationId !==
        task.previousValue.MaintenanceTaskInput.NotificationId
    ) {
      this.initForm();
      this.showHistory = false;
      this.enableDeferralReasonMsg = false;
      this.showTaskAlreadyUpdatedMsg = false;
      this.disableButtons = false;
    } else if (
      task.currentValue.AdditionalTaskDetail &&
      task.currentValue.AdditionalTaskDetail.ChangedOn >
        task.previousValue.AdditionalTaskDetail.ChangedOn
    ) {
      this.maintenanceTaskDisplay = task.previousValue;
      this.showTaskAlreadyUpdatedMsg = true;
      this.disableButtons = true;
      return;
    }
  }

  initForm() {
    this.selectedCmCtrl.setValue([
      ...(this.maintenanceTaskDisplay.AdditionalTaskDetail.AssignedMembers ??
        []),
    ]);
    if (this.maintenanceTaskDisplay.AdditionalTaskDetail.IsOpsOrRobOrRem) {
      this.taskDetailForm.controls.isNFNF.disable();
    } else {
      this.taskDetailForm.controls.isNFNF.enable();
    }

    this.taskDetailForm.controls.callType.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.CallType ?? Vhf
    );
    this.taskDetailForm.controls.jobType.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.JobType ?? Discrepancy
    );

    this.taskDetailForm.controls.predefinedTypes.setValue(<PredefinedTask>{});

    this.taskDetailForm.controls.deferralReason.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.DeferredReason ?? ''
    );
    this.taskDetailForm.controls.isAvionic.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsAvionic
    );
    this.taskDetailForm.controls.isOilService.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsOilService
    );
    this.taskDetailForm.controls.isNFNF.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsNfnf
    );

    if (!this.maintenanceTaskDisplay.MaintenanceTaskInput.IsManualTask) {
      this.taskDetailForm.controls.jobType.disable();
      this.taskDetailForm.controls.callType.disable();
    }
    // if it is MOC Request then we want to disable JobType no matter type of task
    if (
      this.maintenanceTaskDisplay.MaintenanceTaskInput.JobType
        ?.DisplayJobType === JobTypeDisplay.MocRequest
    )
      this.taskDetailForm.controls.jobType.disable();

    this.deferralReasonList = AddOrRemoveQFXDeferralReason(
      this.maintenanceTaskDisplay.AdditionalTaskDetail.IsQuickFix
    );

    this.taskDetailForm.controls.specialInstruction.setValue(
      this.maintenanceTaskDisplay.MaintenanceTaskInput.SpecialInstruction ?? ''
    );

    if (this.maintenanceTaskDisplay.MaintenanceTaskInput.IsAvionic) {
      this.taskDetailForm.controls.isAvionic.setValue(true);
    }

    if (this.maintenanceTaskDisplay.MaintenanceTaskInput.IsNfnf) {
      this.taskDetailForm.controls.isNFNF.setValue(true);
    }

    if (this.maintenanceTaskDisplay.MaintenanceTaskInput.IsOilService) {
      this.taskDetailForm.controls.isOilService.setValue(true);
    }
  }

  removeSelectMechanic(crewMember: AssignedMember) {
    if (this.selectedCmCtrl.value) {
      const updatedValue = this.selectedCmCtrl.value.filter(
        (cm) => cm.EmployeeId !== crewMember.EmployeeId
      );
      this.selectedCmCtrl.setValue(updatedValue);
    }
    this.canUpdate();
  }

  addSelectedMechanic(crewMember: CreateTeamAssignedCrewMemberInput) {
    if (this.selectedCmCtrl.value) {
      const index = this.selectedCmCtrl.value?.findIndex(
        (value) => value.EmployeeId == crewMember.EmployeeId
      );
      if (index > -1) {
        this.selectedCmCtrl.value.splice(index, 1);
      } else {
        if (this.user) {
          const addMember: AssignedMember = {
            EmployeeId: crewMember.EmployeeId,
            FirstName: crewMember.FirstName,
            LastName: crewMember.LastName,
            AssignedByName: `${this.user.LastName}, ${this.user.FirstName}`,
            AssignedBy: <string>this.user.EmployeeId,
            ChangedBy: <string>this.user.EmployeeId,
            Status: crewMember.Status,
          };
          this.selectedCmCtrl.value.push(addMember);
        }
      }
    }

    this.canUpdate();
  }

  setTaskState(
    newTaskState: TaskState | null,
    taskState: TaskState | null | undefined,
    assignedToIds: string[]
  ): TaskState | undefined | null {
    if (newTaskState) return newTaskState;

    if (this.canReopenTask) return taskState;

    const validStates = [
      TaskState.Acknowledged,
      TaskState.Arrived,
      TaskState.Completed,
    ];

    if (
      taskState &&
      validStates.includes(taskState) &&
      assignedToIds.length > 0
    ) {
      return taskState;
    }

    return assignedToIds.length > 0 ? TaskState.Assigned : TaskState.Unassigned;
  }

  createMaintenanceTaskInput(
    action: TaskAction,
    taskId: string | null = null,
    taskState: TaskState | null = null
  ) {
    if (this.taskDetailForm.valid) {
      const temp: MaintenanceTaskInput =
        this.maintenanceTaskDisplay.MaintenanceTaskInput;

      temp.AssignedToIds =
        this.selectedCmCtrl.value?.map((cm) => cm.EmployeeId) ?? [];

      temp.TaskState = this.setTaskState(
        taskState,
        temp.TaskState,
        temp.AssignedToIds
      );

      temp.PredefinedTask = this.taskDetailForm.controls.predefinedTypes.value
        ?.Title
        ? {
            ...this.taskDetailForm.controls.predefinedTypes.value,
          }
        : undefined;
      // Don't save the same instruction again and again
      if (
        this.maintenanceTaskDisplay.MaintenanceTaskInput.SpecialInstruction !=
        this.taskDetailForm.controls.specialInstruction.value
      ) {
        temp.SpecialInstruction =
          this.taskDetailForm.controls.specialInstruction.value;
      } else {
        temp.SpecialInstruction = '';
      }

      temp.IsAvionic = this.taskDetailForm.controls.isAvionic.value;
      temp.IsNfnf = this.taskDetailForm.controls.isNFNF.value;
      temp.IsOilService = this.taskDetailForm.controls.isOilService.value;
      temp.DeferredReason = this.taskDetailForm.controls.deferralReason.value;
      temp.CallType = <TaskCallTypeInput>{
        KeyCallType: this.taskDetailForm.controls.callType.value.KeyCallType,
        Id: this.taskDetailForm.controls.callType.value.Id,
        DisplayCallType:
          this.taskDetailForm.controls.callType.value.DisplayCallType,
      };
      temp.JobType = <TaskJobTypeInput>{
        KeyJobType: this.taskDetailForm.controls.jobType.value.KeyJobType,
        Id: this.taskDetailForm.controls.jobType.value.Id,
        DisplayJobType:
          this.taskDetailForm.controls.jobType.value.DisplayJobType,
      };

      if (taskId) {
        temp.TaskId = taskId;
      }

      /*
      Note: If task is Closed and User make any update then do not open the task just make update only
      */
      temp.IsUpdate = this.canReopenTask;
      temp.TaskAction = action;

      temp.InboundFlightLegId = this.flight.Arrival
        ? this.flight.Arrival.Id
        : null;

      // Set OutBoundFlightLegId based on task type and flight details:
      // - For Right Start or WakeUp tasks, use DepartureId even if Arrival exists.
      // - If Arrival exists and the task is not Right Start or WakeUp, set it to null.
      // - If Arrival doesn't exist and the task is not Right Start or WakeUp, set it to DepartureId.
      temp.OutBoundFlightLegId =
        this.flight.Arrival &&
        ![KeyJobType.RightStartWakeUp, KeyJobType.WakeUp].includes(
          temp.JobType.KeyJobType
        )
          ? null
          : this.flight.Departure?.Id;

      const maintenanceTaskArgs: MutationCreateTaskArgs = {
        maintenanceTask: temp,
      };
      this.emitTask.emit(maintenanceTaskArgs);
      this.closeTask();
    }
  }

  createTask() {
    this.createMaintenanceTaskInput(TaskAction.Create);
  }

  createBowTask() {
    if (this.taskDetailForm.valid && this.flight) {
      const maintenanceTaskArgs: MutationCreateManualTaskArgs = {
        maintenanceTask: {
          Aircraft: {
            NoseNumber: this.flight.Equipment.NoseNumber,
            AirlineCode: this.flight.Equipment.AirlineCode,
          },
          AircraftId: this.flight.Equipment.Id,
          CallType: <TaskCallTypeInput>{
            DisplayCallType: 'System Generated',
            Id: 1011,
            KeyCallType: KeyCallType.SystemGenerated,
          },
          AssignedToIds: this.taskDetailForm.controls.selectedCrewMembers.value
            ? this.taskDetailForm.controls.selectedCrewMembers.value.map(
                (crewMember) => crewMember.EmployeeId
              )
            : [],
          Description:
            this.maintenanceTaskDisplay.MaintenanceTaskInput.Description,
          IataCode: this.flight.Arrival?.Id
            ? this.flight.Arrival?.ArrivalStation ?? ''
            : this.flight.Departure?.DepartureStation ?? '',
          InboundFlightLegId: this.flight.Arrival
            ? this.flight.Arrival.Id
            : null,
          OutBoundFlightLegId: this.flight.Arrival
            ? null
            : this.flight.Departure?.Id,
          IsAlternateTask: false,
          IsAvionic: false,
          IsManualTask: false,
          IsOilService: false,
          JobType: <TaskJobTypeInput>{
            KeyJobType: KeyJobType.Bow,
            Id: 1027,
            DisplayJobType: JobTypeDisplay.BOW,
          },
          Label: this.maintenanceTaskDisplay.MaintenanceTaskInput.Label,
          MaintenanceType: null,
          IsNfnf: false,
          SpecialInstruction:
            this.taskDetailForm.controls.specialInstruction.value,
          TaskState: TaskState.Assigned,
          PredefinedTask: null,
          MaintenancePriority: {
            Description: MaintenancePriorities.BOW,
            Order: 3100,
          },
          IsUpdate: false,
          TaskAction: TaskAction.Create,
          WorkControlNumber:
            this.maintenanceTaskDisplay.MaintenanceTaskInput.WorkControlNumber,
          ScheduledDate:
            this.maintenanceTaskDisplay.MaintenanceTaskInput.ScheduledDate,
        },
      };
      this.emitManualTask.emit(maintenanceTaskArgs);
      this.closeTask();
    }
  }

  closeTask() {
    this.closeTaskSection.emit();
  }

  predefinedChange(selectedValue: PredefinedTask) {
    this.taskDetailForm.patchValue({
      specialInstruction: selectedValue.Description,
    });
  }
  reasonNotCompleteChange(val: string) {
    this.enableDeferralReasonMsg = true;
    this.showSelectDeferralReasonMsg = false;
    if (val === DeferralReasons.None) {
      this.taskDetailForm.controls.deferralReason.setValue('', {
        emitEvent: false,
      });
      this.enableDeferralReasonMsg = false;
    }
  }

  showHideHistory() {
    this.showHistory = !this.showHistory;
  }
  completeTask() {
    this.createMaintenanceTaskInput(
      TaskAction.Complete,
      this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId,
      TaskState.Completed
    );
  }

  reOpenTask() {
    const assignedToIds =
      this.selectedCmCtrl.value?.map((cm) => cm.EmployeeId) ?? [];
    const taskState =
      assignedToIds.length > 0 ? TaskState.Assigned : TaskState.Unassigned;
    this.createMaintenanceTaskInput(
      TaskAction.Reopen,
      this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId,
      taskState
    );
  }

  quickTaskNotCompleted() {
    this.taskDetailForm.controls.deferralReason.setValue(
      'Quick Task Not Completed'
    );
    this.cannotComplete();
  }
  cannotComplete() {
    if (this.taskDetailForm.controls.deferralReason.value) {
      this.showSelectDeferralReasonMsg = false;
      if (
        this.taskDetailForm.controls.deferralReason.value ===
        DeferralReasons.QFIXRequiresExtendedTime
      ) {
        this.createMaintenanceTaskInput(
          TaskAction.Defer,
          this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId,
          TaskState.Suppressed
        );
      } else {
        this.createMaintenanceTaskInput(
          TaskAction.Defer,
          this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId,
          TaskState.Deferred
        );
      }
    } else {
      this.showSelectDeferralReasonMsg = true;

      this.dropdown.containerViewChild.nativeElement.click();
    }
  }

  canUpdate() {
    if (
      this.isCallTypeChanged() ||
      this.isJobTypeChanged() ||
      this.isNfnfChanged() ||
      this.isAvionicChanged() ||
      this.isOilServiceChanged() ||
      this.isSpecialInstructionChanged() ||
      this.isAssignedMemChanged()
    ) {
      this.enableUpdatebtn = true;
    } else {
      this.enableUpdatebtn = false;
    }
  }
  isAssignedMemChanged() {
    if (
      this.selectedCmCtrl.value?.length !==
      this.maintenanceTaskDisplay.AdditionalTaskDetail.AssignedMembers.length
    )
      return true;

    return this.selectedCmCtrl.value?.some(
      (x) =>
        !this.maintenanceTaskDisplay.AdditionalTaskDetail.AssignedMembers.some(
          (y) => y.EmployeeId == x.EmployeeId
        )
    );
  }

  isSpecialInstructionChanged(): boolean {
    return (
      this.taskDetailForm.controls.specialInstruction.value !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.SpecialInstruction
    );
  }

  isOilServiceChanged(): boolean {
    return (
      this.taskDetailForm.controls.isOilService.value !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsOilService
    );
  }

  isAvionicChanged(): boolean {
    return (
      this.taskDetailForm.controls.isAvionic.value !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsAvionic
    );
  }

  isNfnfChanged(): boolean {
    return (
      this.taskDetailForm.controls.isNFNF.value !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.IsNfnf
    );
  }

  isJobTypeChanged(): boolean {
    return (
      this.taskDetailForm.controls.jobType.value.DisplayJobType !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.JobType?.DisplayJobType
    );
  }

  isCallTypeChanged() {
    return (
      this.taskDetailForm.controls.callType.value.DisplayCallType !==
      this.maintenanceTaskDisplay.MaintenanceTaskInput.CallType?.DisplayCallType
    );
  }

  updateTask() {
    this.createMaintenanceTaskInput(
      TaskAction.Update,
      this.maintenanceTaskDisplay.MaintenanceTaskInput.TaskId
    );
  }

  sortCrew(column: SortableCrewColumn) {
    this.sortCrewEvent.emit(column);
  }
}
