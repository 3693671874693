import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CustomToastrService, isCcView } from '@lm-apps/lmo/ui/common/services';
import {
  MANPOWER_CREW_MANAGER,
  FilterInput,
  SOCKETIO_UPDATED,
  SaveUserSource,
  TaskState,
} from '@lm-apps/lmo/ui/data';
import * as fromAircrafts from '@lm-apps/lmo/ui/data-access-aircraft';
import * as fromAlternateTasks from '@lm-apps/lmo/ui/data-access-alternate-tasks';
import * as fromCrew from '@lm-apps/lmo/ui/data-access-crew';
import * as fromCrewMemberStatus from '@lm-apps/lmo/ui/data-access-crew-member-status';
import * as fromFlights from '@lm-apps/lmo/ui/data-access-flight';
import * as fromNotices from '@lm-apps/lmo/ui/data-access-notices';
import * as fromStations from '@lm-apps/lmo/ui/data-access-stations';
import * as fromUser from '@lm-apps/lmo/ui/data-access-user';
import * as fromImmutable from '@lm-apps/lmo/ui/data-access-immutable';
import * as fromFeatureFlag from '@lm-apps/lmo/ui/data-access-feature-flag';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Observable,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import * as actions from './root.actions';
import { selectFlightSearchRequest } from './root.selectors';
import { DUMMY_AIRCRAFT } from '@lm-apps/lmo/shared/enums';
import * as fromCrewManpower from '@lm-apps/lmo/ui/data-access-crew-manpower';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';

dayjs.extend(utc);

const taskIncludedInToast = new Map<string, number>();

@Injectable()
export class RootEffects {
  initializeAllStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => [
        fromUser.actions.init(),
        fromStations.actions.init(),
        fromAircrafts.actions.init(),
        fromCrewMemberStatus.actions.init(),
        fromNotices.actions.init(),
        fromImmutable.actions.deferralMelInit(),
        fromFeatureFlag.actions.init(),
      ])
    )
  );
  loadBasedOnFeatureFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFeatureFlag.actions.loadFeatureFlagSuccess),
      switchMap(({ flags }) => this.actionToDispatchBasedOnFeatureFlag(flags))
    )
  );
  actionToDispatchBasedOnFeatureFlag(flags: FeatureFlag[]): Action[] {
    const actions: Action[] = [];
    if (flags.some((x) => x.name === MANPOWER_CREW_MANAGER && x.enabled))
      actions.push(fromCrewManpower.actions.init());
    return actions;
  }

  updateStations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUser.actions.updateStations),
      withLatestFrom(this.store.pipe(select(selectFlightSearchRequest))),
      switchMap(([_, searchCriteria]) => {
        return searchCriteria.Stations.length === 1
          ? [
              fromFlights.actions.loadFlightsRequest({
                searchCriteria: { ...searchCriteria, Zones: [] },
              }),
              fromStations.actions.loadZones({
                station: searchCriteria.Stations[0],
              }),
            ]
          : [
              fromFlights.actions.loadFlightsRequest({ searchCriteria }),
              fromStations.actions.resetZones(),
            ];
      })
    )
  );

  saveAlternateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveAlternateTask),
      switchMap(({ maintenanceTask }) => [
        fromFlights.actions.createManualTask({
          maintenanceTaskArgs: { maintenanceTask: maintenanceTask },
        }),
      ])
    )
  );

  refreshAlternateTasksOnCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFlights.actions.createManualTaskSuccess),
      withLatestFrom(this.store.pipe(select(fromUser.selectStations))),
      switchMap(([_, stations]) => [
        fromAlternateTasks.actions.init({ stations }),
      ])
    )
  );

  updateFilter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateFilter),
      withLatestFrom(this.store.pipe(select(fromUser.getUserState))),
      switchMap(([{ filter }, state]) => {
        const filterInput: FilterInput = {
          ...state.User.Filter,
          ...filter,
        };
        return [
          fromUser.actions.updateUser({
            saveUserInput: { Filter: filterInput },
            source: SaveUserSource.UPDATE_FILTER,
          }),
          fromFlights.actions.loadFlightsRequest({
            searchCriteria: { ...filterInput, Stations: state.Stations },
          }),
        ];
      })
    )
  );

  saveCrewToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCrew.actions.setSelectedCrew),
      map(({ crew }) => fromUser.actions.updateCurrentCrew({ crew }))
    )
  );

  clearSelectedCrewIfSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCrew.actions.clearSelectedCrewIfSelected),
      withLatestFrom(this.store.select(fromUser.selectCurrentCrewId)),
      filter(([{ crew }, currentCrewId]) => currentCrewId === crew.Id),
      map(() => fromCrew.actions.setSelectedCrew({ crew: null }))
    )
  );

  saveCurrentCrewToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCrew.actions.updateCrewSignalr),
      withLatestFrom(
        this.store.select(fromCrew.selectCurrentCrewId),
        this.store.select(fromFlights.selectSelectedFlight),
        this.store.select(fromCrew.selectCurrentCrew)
      ),
      switchMap(([{ event, crew }, crewId, selectedFlight, currentCrew]) => {
        const newActions: Array<Action> = [];
        if (crewId == SOCKETIO_UPDATED && selectedFlight.FlightNotificationId) {
          newActions.push(
            fromFlights.actions.setSelectedFlight({
              selectedFlight: {
                ...selectedFlight,
                FlightNotificationId: undefined,
                TaskId: undefined,
              },
            })
          );
          newActions.push(
            fromCrew.actions.updateSelectedCrewId({ crewId: null })
          );
          newActions.push(
            fromUser.actions.updateCurrentCrew({ crew: currentCrew })
          );
          this.toastr.warning(`${crew.Name} ownership modified`);
        } else {
          newActions.push(
            fromUser.actions.updateCurrentCrew({ crew: currentCrew })
          );
        }

        return of(newActions).pipe(mergeMap((action) => of(...action)));
      })
    )
  );

  departureToaster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromFlights.actions.triggerDepartureToast),
        withLatestFrom(this.store.select(fromUser.selectUser)),
        tap(([{ flights }, user]) => {
          // Toastr should not be shown if it is not CC View
          if (!isCcView(this.router.routerState.snapshot)) return;

          const now = dayjs().utc();
          flights.forEach((fp) => {
            if (
              fp.Departure?.EstimatedDeparture &&
              dayjs(fp.Departure.EstimatedDeparture)
                .utc()
                .diff(now, 'minutes') < 3
            ) {
              const isTaskToasted = taskIncludedInToast.has(fp.Hash);

              const task = fp.AllTasks.find(
                (t) =>
                  t.TaskState &&
                  (t.TaskState.toLowerCase() ===
                    TaskState.Unassigned.toLowerCase() ||
                    t.TaskState.toLowerCase() ===
                      TaskState.Assigned.toLowerCase())
              );
              if (task && !isTaskToasted && user.Filter.RedToaster) {
                this.toastr.triggerDepartureToast(fp, { ...task }, user);
                taskIncludedInToast.set(fp.Hash, 1);
              }
              if (!task && isTaskToasted) taskIncludedInToast.delete(fp.Hash);
            }
          });
        })
      ),
    { dispatch: false }
  );

  userAcknowledgeNotice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotices.actions.acknowledgeNotice),
      withLatestFrom(this.store.pipe(select(fromUser.selectUser))),
      switchMap(([notice, user]) => {
        if (user.Id === null || user.Id === undefined) {
          throw new Error('User ID cannot be null or undefined');
        }
        return [
          fromUser.actions.userAcknowledgeNotice({
            noticeId: notice.notice.Id,
          }),
        ];
      })
    )
  );

  /*
   * This effect is triggered when alternate tasks are loaded successfully.
   * It fetches the aircrafts for the alternate tasks and populates the details.
   */
  loadAlternateTaskDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAlternateTasks.actions.loadAlternateTasksSuccess),
      withLatestFrom(this.store.select(fromAircrafts.selectAircraftEntities)),
      map(([action, aircraftEntities]) => {
        const alternateTasks = action.alternateTasks.map((task) => {
          return {
            Aircraft: aircraftEntities[task.NoseNumber ?? DUMMY_AIRCRAFT],
            ...task,
          };
        });

        return fromAlternateTasks.actions.loadAlternateTaskDetailsSuccess({
          alternateTasks,
        });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private store: Store,
    private toastr: CustomToastrService,
    private router: Router
  ) {}
}
