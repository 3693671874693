import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';
import * as data from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class TaskHistoryGQL extends Query<
  Pick<data.Query, 'TaskHistory'>,
  data.QueryTaskHistoryArgs
> {
  override document = gql`
    query TaskHistory($taskId: UUID!) {
      TaskHistory(taskId: $taskId) {
        TaskId
        ChangedOn
        AssignedMembers {
          AssignedBy
          AssignedByName
          AcknowledgedOn
          ArrivedOn
          EmployeeId
          LastName
          FirstName
        }
        TaskState
        ChangedBy
      }
    }
  `;
}
