import { Pipe, PipeTransform } from '@angular/core';
import { isEqual } from 'lodash';

@Pipe({
  name: 'arraysEqual',
})
export class ArraysEqualPipe implements PipeTransform {
  transform<T>(array1: T[], array2: T[]): boolean {
    // Check if both arrays are defined
    if (!array1 || !array2) {
      return false;
    }

    // Check if both arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Create a copy of the arrays and sort them
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();

    return isEqual(sortedArray1, sortedArray2);
  }
}
