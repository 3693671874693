import { SortCrewState, User } from '@lm-apps/lmo/ui/data';

export interface UserState {
  User: User;
  SortingPreferences: {
    CrewMembers: SortCrewState;
  };
  Stations: string[];
  Loaded: boolean;
  Error: string | null;
}
