import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class RegionsGQL extends Query<
  Pick<data.Query, 'RegionsStations'>,
  data.QueryRegionsStationsArgs
> {
  override document = gql`
    query RegionsStations($names: [String!]) {
      RegionsStations(names: $names) {
        Name
        DisplayOrder
        Stations {
          CountryCodeNumber
          DSTimeZoneOffset
          Displayed
          HubStationDisplayOrder
          IataCode
          IsExtraBaseStation
          IsHubStation
          IsROD
          IsRON
          IsStaffed
          City
          CountryState
          Region
          TimeZoneOffset
        }
      }
    }
  `;
}
