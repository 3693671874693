import { Injectable } from '@angular/core';
import { RegionsGQL } from './query';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RegionsService {
  constructor(private _regions: RegionsGQL) {}
  getAllRegions() {
    return this._regions.fetch().pipe(
      map(({ data }) => {
        return data.RegionsStations;
      })
    );
  }
}
