import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './crew-member-status.actions';
import {
  CrewMemberService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class CrewMemberStatusEffects {
  constructor(
    private readonly actions$: Actions,
    private crewMemberService: CrewMemberService,
    private toastService: CustomToastrService
  ) {}

  getAllCrewMemberOnlineStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.init),
      switchMap(() => {
        return this.crewMemberService.onlineCrewMemberStatusAll().pipe(
          map((crewMembers) => {
            return actions.loadCrewMemberOnlineAllSuccess({ crewMembers });
          }),
          catchError((error) => {
            this.toastService.error(
              'Failed to load crew members online status'
            );
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        );
      })
    )
  );
}
