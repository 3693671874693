import { Pipe, PipeTransform } from '@angular/core';
import { AssignedMember } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'mechanicStatus',
})
export class MechanicStatusPipe implements PipeTransform {
  transform(
    assignedMember: AssignedMember,
    assignedMemberList: AssignedMember[]
  ) {
    let status = 'Assigned';
    const member = assignedMemberList.find(
      (x) => x.EmployeeId == assignedMember.EmployeeId
    );
    if (!member) return status; // handles case where hasn't saved to API yet

    if (member.CompletedOn) {
      status = 'Completed';
    } else if (member.ArrivedOn) {
      status = 'Arrived';
    } else if (member.AcknowledgedOn) {
      status = 'Acknowledged';
    }

    return status;
  }
}
