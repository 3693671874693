export enum KeyCallType {
  MOC_IOC = 'MOC/IOC',
  PLANNING = 'Planning',
  VERBAL = 'Verbal',
  RADIO = 'Radio',
  TELEPHONE = 'Telephone',
  VHF = 'VHF',
  RAMP = 'Ramp',
  VENDOR = 'Vendor',
  GATE_RETURN = 'GateReturn',
  RTF = 'RTF',
  DIVERSION = 'Diversion',
  SYSTEM_GENERATED = 'System',
}

export interface CallTypeEnumModel {
  Id: number;
  DisplayCallType: string;
  KeyCallType: KeyCallType;
  IsActive: boolean;
}

export const CallTypesList: CallTypeEnumModel[] = [
  {
    DisplayCallType: 'MOC/IOC',
    Id: 1000,
    IsActive: true,
    KeyCallType: KeyCallType.MOC_IOC,
  },
  {
    DisplayCallType: 'Planning',
    Id: 1001,
    IsActive: true,
    KeyCallType: KeyCallType.PLANNING,
  },
  {
    DisplayCallType: 'Verbal',
    Id: 1002,
    IsActive: true,
    KeyCallType: KeyCallType.VERBAL,
  },
  {
    DisplayCallType: 'Radio',
    Id: 1003,
    IsActive: true,
    KeyCallType: KeyCallType.RADIO,
  },
  {
    DisplayCallType: 'Telephone',
    Id: 1004,
    IsActive: true,
    KeyCallType: KeyCallType.TELEPHONE,
  },
  {
    DisplayCallType: 'VHF',
    Id: 1005,
    IsActive: true,
    KeyCallType: KeyCallType.VHF,
  },
  {
    DisplayCallType: 'Ramp',
    Id: 1006,
    IsActive: true,
    KeyCallType: KeyCallType.RAMP,
  },
  {
    DisplayCallType: 'Vendor',
    Id: 1007,
    IsActive: true,
    KeyCallType: KeyCallType.VENDOR,
  },
  {
    DisplayCallType: 'GateReturn',
    Id: 1008,
    IsActive: true,
    KeyCallType: KeyCallType.GATE_RETURN,
  },
  {
    DisplayCallType: 'RTF',
    Id: 1009,
    IsActive: true,
    KeyCallType: KeyCallType.RTF,
  },
  {
    DisplayCallType: 'Diversion',
    Id: 1010,
    IsActive: true,
    KeyCallType: KeyCallType.DIVERSION,
  },
  {
    DisplayCallType: 'System Generated',
    Id: 1011,
    IsActive: true,
    KeyCallType: KeyCallType.SYSTEM_GENERATED,
  },
];
