/**
 * Notification Filters
 */
export enum NotificationFilter {
  /**
   * SHOW_ALL_AC
   */
  SHOW_ALL_AC = 'All',
  /**
   * MELFLW
   */
  MELFLW = 'MELFLW',
  /**
   * FOLLOW_UP_MON
   */
  FOLLOW_UP_MON = 'MELFLWMON',
  /**
   * MELGEN
   */
  MELGEN = 'MELGEN',
  /**
   * QFIX
   */
  QFIX = 'QFIX',
  /**
   * QNEF
   */
  QNEF = 'QNEF',
  /**
   * CDL
   */
  CDL = 'CDL',

  /**
   * MOC REQUEST
   */
  MOC_REQUEST = 'MOC Request',
  /**
   * MAINTENANCE_HOLD
   */
  MAINTENANCE_HOLD = 'Maintenance Hold',
  /**
   * OPEN
   */
  OPEN = 'OPEN',
  /**
   * OTHER
   */
  OTHER = 'Other',

  HERMES = 'HERMES',
  TRANSIT = 'TRANSIT',
  MG = 'MG',
  RSMG = 'RSMG',
  WKUP = 'WKUP',
  RSWK = 'RSWK',
  CFMOIL = 'CFMOIL',
  PILOTeMIC = 'PILOTeMIC',
  WITH_NO_NOTIFICATION = 'NIL',
}
