import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TaskEvent, TaskEntity, FlightsEntity } from '@lm-apps/lmo/ui/data';
import { getTooltip } from '@lm-apps/lmo/ui/common/feature-shared';
@Component({
  selector: 'lm-apps-lmo-ui-assigned',
  templateUrl: './assigned.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedComponent {
  @Input()
  flight!: FlightsEntity;
  @Output() selectedTask = new EventEmitter<TaskEvent>();

  taskClicked(taskEvent: TaskEvent) {
    this.selectedTask.emit(taskEvent);
  }

  getTooltip(task: TaskEntity) {
    return getTooltip(this.flight, task);
  }
}
