import { Inject, Injectable } from '@angular/core';
import {
  FeatureFlagsConfig,
  FEATURE_FLAGS,
  FEATURE_FLAGS_CONFIG,
  // FEATURE_FLAGS_GATEWAY,
} from './feature-flags-config';
import {
  FeatureFlag,
  FeatureFlagParams,
  // FeatureFlagChangedResponse,
  // FeatureFlagParams,
  // FeatureFlagResponse,
} from '@lm-apps/common/feature-flag-data';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FeatureFlagsQueryService } from './feature-flags-query.service';
import { FeatureFlagsSubscriptionService } from './feature-flags-subscription.service';
import { PingAuthenticationService } from '@aa-techops-ui/ping-authentication';
//import { Apollo, gql } from 'apollo-angular';
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  inputParams: FeatureFlagParams;
  defaults: FeatureFlag[];
  constructor(
    private featureFlagsQueryService: FeatureFlagsQueryService,
    private featureFlagsSubscriptionService: FeatureFlagsSubscriptionService,
    private authService: PingAuthenticationService,
    //private apollo: Apollo,
    @Inject(FEATURE_FLAGS_CONFIG) private config: FeatureFlagsConfig
  ) {
    const runtimeConfig: Partial<FeatureFlagsConfig> =
      authService.environment[FEATURE_FLAGS];
    const appName = runtimeConfig.appName || config.appName;
    const envName = runtimeConfig.envName || config.envName;
    console.log(
      `runtimeConfig appName: ${runtimeConfig.appName} envName: ${runtimeConfig.envName}, config:${config.appName} envName: ${config.envName}`
    );
    if (!appName || !envName)
      throw new Error('FeatureFlagsService: appName and envName are required');

    this.inputParams = {
      appName,
      envName,
    };
    this.defaults = runtimeConfig.defaults || [];
  }
  /*Alternative Approach
  public getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.apollo
      .use(FEATURE_FLAGS_GATEWAY)
      .query<FeatureFlagResponse, FeatureFlagParams>({
        query: gql`
          query flagsQuery($app: String, $env: String) {
            FeatureFlags(app: $app, env: $env) {
              name
              enabled
            }
          }
        `,
        variables: {
          app: this.config.app_name,
          env: this.config.environment,
        },
      })
      .pipe(
        map((result) => {
          if (result.errors && result.errors.length > 0) {
            console.log('ERROR', result.errors);
            return [];
          }
          return result.data.FeatureFlags;
        })
      );
  }

  public onFeatureChange(): Observable<FeatureFlag> {
    return this.apollo
      .use(FEATURE_FLAGS_GATEWAY)
      .subscribe<FeatureFlagChangedResponse, FeatureFlagParams>({
        query: gql`
          subscription onFlagChanged($app: String, $env: String) {
            FeatureFlag_Changed(app: $app, env: $env) {
              flag {
                name
                enabled
              }
            }
          }
        `,
        variables: {
          app: this.config.app_name,
          env: this.config.environment,
        },
      })
      .pipe(
        filter((result) => {
          return result.data != null && result.data.FeatureFlag_Changed != null;
        }),
        map((result) => {
          return result.data.FeatureFlag_Changed.flag;
        })
      );
  }
*/
  public getFeatureFlags(): Observable<FeatureFlag[]> {
    const featureFlags$ = this.authService.loggedIn$.pipe(
      filter((loggedIn) => loggedIn),
      switchMap((_loggedIn) =>
        this.featureFlagsQueryService.fetch(this.inputParams).pipe(
          map((result) => {
            if (result.errors && result.errors.length > 0) {
              console.log('ERROR', result.errors);
              return this.defaults;
            }
            return result.data.featureFlags
              .concat(...this.defaults) //merge with defaults
              .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i); //take first occurance only
          }),
          catchError((err) => {
            console.log('ERROR', err);
            return of(this.defaults);
          })
        )
      )
    );
    return featureFlags$;
  }
  public onFeatureChange(): Observable<FeatureFlag> {
    const featureFlag$ = this.authService.loggedIn$.pipe(
      filter((loggedIn) => loggedIn),
      switchMap((_loggedIn) =>
        this.featureFlagsSubscriptionService.subscribe(this.inputParams).pipe(
          filter(
            (result) =>
              result != null &&
              result.data != null &&
              result.data.featureFlagChanged != null
          ),
          map((result) => {
            return result.data!.featureFlagChanged;
          })
        )
      )
    );
    return featureFlag$;
  }
}
