import { Pipe, PipeTransform } from '@angular/core';
import {
  TaskState as TaskStateEnum,
  EnumUtil,
} from '@lm-apps/lmo/shared/enums';
import { TaskState as TaskStateParameter } from '@lm-apps/lmo/ui/data';

@Pipe({
  name: 'TaskState',
})
export class TaskStatePipe implements PipeTransform {
  transform(departureStatusCode: TaskStateParameter) {
    const status = EnumUtil.getValue(TaskStateEnum, departureStatusCode);
    let result;
    switch (status) {
      case TaskStateEnum.EMPTY: {
        result = '';
        break;
      }
      case TaskStateEnum.DEFERRED: {
        result = 'Not Completed';
        break;
      }
      default: {
        result = status;
        break;
      }
    }
    return result;
  }
}
