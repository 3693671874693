import { Pipe, PipeTransform } from '@angular/core';
import { TaskEntity, ItemType } from '@lm-apps/lmo/ui/data';
import {
  NotificationClasses,
  PriorityCssClasses,
} from '../../constants/notifications.constant';
import { SceptreLables } from '@lm-apps/lmo/shared/enums';

@Pipe({
  name: 'getTaskPriorityClassName',
  pure: true,
})
export class GetTaskPriorityClassNamePipe implements PipeTransform {
  transform(task: TaskEntity): string {
    let classArray: string[] = [];

    if (task.IsNfnf && task.IsNfnf === true) {
      classArray.push('nfnf');
    } else {
      const type = task.MaintenanceType;
      const priority = task.MaintenancePriority?.Description || '';

      classArray = this.getPriorityClassName(
        type,
        priority,
        task.CustomNotificationType ?? ''
      );
    }

    if (task.Label === SceptreLables.AirworthinessOPS.toString()) {
      classArray.push('airworthiness');
    }

    //This can be applied any time we want to show task is pending in ui
    // if (task.IsPending) {
    //   classArray.push('cc-italic');
    // }
    return classArray.join(' ');
  }

  getPriorityClassName = (
    type: ItemType | undefined | null,
    priorityName: string,
    customNotificationType: string
  ): string[] => {
    const classArray: string[] = [];

    const p = priorityName.toUpperCase().trim().split(' ').join('_');

    if (type) {
      const styles = NotificationClasses[type] || {};
      classArray.push(styles.cssClass ?? '');
    }

    classArray.push(PriorityCssClasses[p]?.cssClass ?? 'default');
    customNotificationType === 'melCycle' && classArray.push('melCycle');

    return classArray;
  };
}
