import { FEATURE_FLAG_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './feature-flag.action';
import { FeatureFlag } from '@lm-apps/common/feature-flag-data';
export interface State extends EntityState<FeatureFlag> {
  loaded: boolean; // is api call in progress
  error?: string | null; // last known error (if any)
}

export interface FeatureFlagPartialState {
  readonly [FEATURE_FLAG_FEATURE_KEY]: State;
}

export const adapter: EntityAdapter<FeatureFlag> =
  createEntityAdapter<FeatureFlag>({
    selectId: (featureFlag: FeatureFlag) => featureFlag.name,
  });

export const initialState: State = adapter.getInitialState({
  // set initial required properties
  ids: [],
  entities: Array<FeatureFlag>(),
  loaded: false,
  error: null,
});

const featureFlagReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({ ...state, loaded: false, error: null })),
  on(actions.loadFeatureFlagSuccess, (state, { flags }) =>
    adapter.setAll(flags, { ...state, loaded: true })
  ),
  on(actions.upsertFlag, (state, { flag }) =>
    adapter.upsertOne(flag, { ...state, loaded: true })
  ),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return featureFlagReducer(state, action);
}
