export enum BowItemStatus {
  NOT_SENT_TO_TSX = 'NOT SENT TO TSX',
  SENT_TO_TSX = 'SENT TO TSX',
  COMPLETED = 'COMPLETED',
}

export enum BOWType {
  ROD = 'ROD',
  RON = 'RON',
  BASE = 'BASE',
}
