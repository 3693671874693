import { NOTICES_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './notices.actions';
import { NoticesState } from './notices.interfaces';

export interface NoticePartialState {
  readonly [NOTICES_FEATURE_KEY]: NoticesState;
}

export const initialState: NoticesState = {
  allNotices: null,
  activeNotices: null,
  loaded: false,
  error: null,
};

export const noticesReducer = createReducer(
  initialState,
  on(actions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(actions.loadAllNoticesSuccess, (state, { notices }) => ({
    ...state,
    allNotices: notices,
    loaded: true,
    error: null,
  })),
  on(actions.loadActiveNoticesSuccess, (state, { notices }) => ({
    ...state,
    activeNotices: notices,
    loaded: true,
    error: null,
  })),
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error: error,
  }))
);

export function reducer(state: NoticesState | undefined, action: Action) {
  return noticesReducer(state, action);
}
