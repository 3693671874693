import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as actions from './../actions/index';
import {
  MelService,
  CustomToastrService,
} from '@lm-apps/lmo/ui/common/services';
import { catchError, map, of, switchMap } from 'rxjs';
@Injectable()
export class MelEffects {
  constructor(
    private readonly actions$: Actions,
    private melService: MelService,
    private toastService: CustomToastrService
  ) {}

  deferralMelItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deferralMelInit),
      switchMap(() =>
        this.melService.deferralMelItem().pipe(
          map((deferralMels) => {
            return actions.loadDeferralMelSuccess({ deferralMels });
          }),
          catchError((error) => {
            this.toastService.error('Failed to load Deferral Mel');
            return of(
              actions.throwError({ error: error.message || 'Unknown error' })
            );
          })
        )
      )
    )
  );
}
