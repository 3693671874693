export enum AlternateTasksSortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export enum AlternateTasksColumnName {
  AIRCRAFT = 'AC',
  GATE = 'GATE',
}

export enum AlternateTasksSortableColumn {
  AC = 'NoseNumber',
  GATE = 'Location',
}

export interface AlternateTasksSortDetail {
  column: AlternateTasksColumnName;
  sortOrder: AlternateTasksSortOrder;
}
