<section>
  <div class="title-txt bg-shade-gray">Maintenance Items</div>
  <hr class="cc-hr" />
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="text-muted">Type</th>
        <th class="text-muted">Priority</th>
        <th class="text-muted text-nowrap">Ref/MIC #</th>
        <th class="text-muted">Description</th>
        <th class="text-muted">Class</th>
        <th class="text-muted">Status</th>
        <th class="text-muted">Assigned</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let notification of filteredNotifications">
        <td>
          <lm-apps-lmo-ui-notification-item
            [notification]="notification"
            [isRhs]="true"
            (selectedNotification)="selectNotification($event)"
          ></lm-apps-lmo-ui-notification-item>
        </td>
        <td class="priority-width">
          {{ notification.PriorityName || '-' }}
        </td>
        <td>
          <a
            *ngIf="
              notification.ExtendedAttributes &&
              notification.ExtendedAttributes.MicNumber &&
              notification.Type !== ItemType.DailyCheck
            "
            href="{{ safeAircraftUri }}/{{ flight.Equipment.NoseNumber }}/{{
              flight.Equipment.AirlineCode
            }}/logbook/detail/{{ notification.ExtendedAttributes.MicNumber }}"
            target="_blank"
            >{{ notification.ExtendedAttributes.MicNumber || '-' }}</a
          >

          <a
            *ngIf="notification.Type === ItemType.Oil"
            href="https://osp.maverick.aa.com/safe-oil-add?ac={{
              flight.Equipment.NoseNumber
            }}&operator=US"
            target="_blank"
            ref="noopener"
          >
            OSP
          </a>
          <a
            *ngIf="
              !notification.ExtendedAttributes?.MicNumber &&
              notification.Type !== ItemType.Oil
            "
            class="cursor-default"
            >{{ '-' }}</a
          >
        </td>
        <td>
          {{
            notification.ExtendedAttributes?.Description ||
              notification.Description ||
              '-'
          }}
        </td>
        <td>
          <div *ngIf="notification.Type === ItemType.Oil">OIL</div>
          <div *ngIf="notification.Type !== ItemType.Oil">
            {{ notification.ExtendedAttributes?.Classification || '-' }}
          </div>
        </td>
        <td>
          {{
            notification.Status === 'Deferred'
              ? 'Not Completed'
              : notification.Status
          }}
        </td>
        <td>
          <div *ngFor="let item of notification.AssignedToNames">
            <span>{{ item.LastName | formatToShortName: item.FirstName }}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</section>
