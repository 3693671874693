import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { HeaderHomeComponent } from './components/home/home.component';
import { RouterModule } from '@angular/router';
import { LmoUiDataAccessUserModule } from '@lm-apps/lmo/ui/data-access-user';

@NgModule({
  imports: [CommonModule, RouterModule, LmoUiDataAccessUserModule],
  declarations: [HeaderComponent, HeaderHomeComponent],
  exports: [HeaderHomeComponent],
})
export class LmoUiFeatureHeaderModule {}
