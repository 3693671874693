export enum EtopsFlight {
  NON_ETOPS_AC = 'Non-ETOPS AC',
  ETOPS_FLT = 'ETOPS FLT',
}

export enum LegStatus {
  RETURN_TO_FIELD = 'RTF',
  RETURN_TO_GATE = 'RTG',
  GROUND_INTERRUPT = 'G',
  AIR_INTERRUPT = 'A',
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export enum ColumnName {
  AIRCRAFT = 'AC',
  INBOUND = 'INBD',
  GATE = 'GATE',
  ARRIVAL = 'ARR',
  OUTBOUND = 'OUTBD',
  DEPATURE = 'DEP',
  NOTIFICATIONS = 'NOTIFICATIONS',
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
}

export enum SortableColumn {
  AC = 'Equipment.NoseNumber',
  INBD = 'Arrival.FlightNumber',
  GATE = 'Arrival.ArrivalGate',
  ARR = 'Arrival.ActualArrival',
  OUTBD = 'Departure.FlightNumber',
  DEP = 'Departure.ActualDeparture',
  // below enum are for not to get errors
  NOTIFICATIONS = 'Notifications',
  UNASSIGNED = 'Unassigned',
  ASSIGNED = 'Assigned',
}
