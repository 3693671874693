import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader';

export const defaultConfigTheme: NgxSkeletonLoaderConfigTheme = {
  height: '4.2rem',
  'background-color': 'rgba(118, 118, 128, 0.12)',
};

export const flightsLoaderTheme: NgxSkeletonLoaderConfigTheme = {
  ...defaultConfigTheme,
  'margin-bottom': '0.5px',
};
