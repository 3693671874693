import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Query } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class GetNoticeGQL extends Query<Pick<data.Query, 'Notice'>> {
  override document = gql`
    query Notice($id: UUID!) {
      Notice(id: $id) {
        Created
        Description
        DisplayEnd
        DisplayStart
        DowntimeEnd
        DowntimeStart
        Id
        Title
        Updated
      }
    }
  `;
}
