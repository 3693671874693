import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { IMMUTABLE_CONTENT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { MelEffects } from './+state/effects';
import { EffectsModule } from '@ngrx/effects';
import * as fromImmutable from './+state/immutable.reducer';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      IMMUTABLE_CONTENT_FEATURE_KEY,
      fromImmutable.reducer
    ),
    EffectsModule.forFeature([MelEffects]),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 0,
      preventDuplicates: true,
      newestOnTop: false,
    }),
  ],
})
export class LmoUiDataAccessImmutableModule {}
