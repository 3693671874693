import { UserRole } from '@lm-apps/lmo/ui/data';
import { User as oidcUser } from 'oidc-client-ts';

export async function getAppProfile(
  user: oidcUser,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  env: { [key: string]: any }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const graphUrl = `${env['LMO_API_BASE_URL']}/graphql?op=Roles`;
  try {
    const response = await fetch(graphUrl, {
      method: 'POST',
      headers: {
        Authorization: `${user.token_type} ${user.access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'Roles',
        query: `query Roles {
            Roles {
                Action
                Name
                RoleID
                TypeID
            }
          }`,
        variables: {},
      }),
    });
    const userResp: { data: { Roles: UserRole[] } } = await response.json();
    const roles = userResp.data?.Roles ?? [];
    return { roles: roles.map((ur: UserRole) => ur.Name) };
  } catch (err) {
    // swallow and return default
    return { roles: [] };
  }
}
