import { Component, Inject, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { interval, map } from 'rxjs';
import { AppEnvironment } from '@lm-apps/lmo/shared/common';
import {
  PingAuthenticationService,
  PING_APP_ENVIRONMENT,
} from '@aa-techops-ui/ping-authentication';
dayjs.extend(utc);
@Component({
  selector: 'lm-apps-lmo-header-home',
  templateUrl: './home.component.html',
})
export class HeaderHomeComponent {
  date$ = interval(1000).pipe(
    map((_i) => dayjs.utc().format('MMM DD HH:mm [UTC]'))
  );
  isMenuOpen = false;

  constructor(
    public ping: PingAuthenticationService,
    @Inject(PING_APP_ENVIRONMENT) private env: AppEnvironment
  ) {
    dayjs.extend(utc);
  }

  getAppEnvString() {
    return this.env.APP_ENVIRONMENT === 'Prod' ? '' : this.env.APP_ENVIRONMENT;
  }
}
