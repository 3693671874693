import { Injectable } from '@angular/core';
import { SidePanelLayoutComponent } from '../../components/side-panel-layout/side-panel-layout.component';
import { SidePanelLayoutInterface } from '@lm-apps/lmo/ui/data';

@Injectable({
  providedIn: 'root',
})
export class SidePanelLayoutService implements SidePanelLayoutInterface {
  private layoutComponent!: SidePanelLayoutComponent;

  setLayoutComponent(layoutComponent: SidePanelLayoutComponent) {
    this.layoutComponent = layoutComponent;
  }

  closeSidePanel() {
    this.layoutComponent && this.layoutComponent.closeSidePanel();
  }

  openSidePanel() {
    this.layoutComponent && this.layoutComponent.openSidePanel();
  }
}
