import { Pipe, PipeTransform } from '@angular/core';
import { OnlineStatus } from '@lm-apps/lmo/ui/data';
@Pipe({
  name: 'getOnlineStatus',
})
export class GetOnlineStatusPipe implements PipeTransform {
  transform(employeeId: string, crewMembersStatus: OnlineStatus[]): boolean {
    if (crewMembersStatus.length === 0 || !employeeId || !employeeId.trim())
      return false;
    const found = crewMembersStatus.find(
      (x) => Number(x.EmployeeId.trim()) === Number(employeeId.trim())
    );
    return found ? found.IsOnline : false;
  }
}
