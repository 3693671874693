import {
  AssignedMember,
  Crew,
  CREW_MANPOWER_FEATURE_KEY,
  ManpowerCrewMember,
  DEFAULT,
} from '@lm-apps/lmo/ui/data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromManpowerCrew from './crew-manpower.reducer';

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  fromManpowerCrew.adapter.getSelectors();

export const selectCrewManpowerState =
  createFeatureSelector<fromManpowerCrew.State>(CREW_MANPOWER_FEATURE_KEY);

export const selectAllCrews = createSelector(
  selectCrewManpowerState,
  selectAll
);

export const selectEntitiesCrewManpower = createSelector(
  selectCrewManpowerState,
  selectEntities
);

export const selectCrewManpowerLoaded = createSelector(
  selectCrewManpowerState,
  (state) => state.loaded
);

export const selectCurrentCrewName = createSelector(
  selectAllCrews,
  (crews) => (crews && crews.length && crews[0].crew) || ''
);

export const selectAllCrewMembers = createSelector(selectAllCrews, (crews) => {
  const crewMembers: ManpowerCrewMember[] = [];
  if (!crews || !crews.length) return crewMembers;
  // USER STORY 1565736: concate all crew members from different teams to single list
  crews.map(
    (crew) => crew.members && crewMembers.push(...crew.members.employees)
  );
  return crewMembers.map((c) => ({
    ...c,
    codes: c.codes?.filter((x) => x.toUpperCase() !== DEFAULT) || c.codes,
  }));
});

export const selectCurrentMappedCrew = createSelector(
  selectAllCrews,
  selectAllCrewMembers,
  (crews, crewMembers) => {
    if (!crews || !crews.length) return null;
    const ams: AssignedMember[] = [];

    // USER STORY 1565736: Use the first Crew Name listed in Manpower if their is more then on crew
    const mappedCrew: Crew = {
      CrewName: crews[0].name,
      Station: crews[0].station,
      CrewDisplayName: crews[0].crew,
      Members: ams,
    };
    crewMembers.forEach((cm) => {
      ams.push({
        EmployeeId: cm.employeeNumber,
        FirstName: cm.firstName,
        LastName: cm.lastName,
        Status: cm.status,
      });
    });

    mappedCrew.Members = ams;
    return mappedCrew;
  }
);
