import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import {
  EmployeeShiftStatus,
  ManpowerCrewMember,
  OnlineStatus,
} from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lmo-ui-crew-manager-manpower',
  templateUrl: './crew-manager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrewManagerManpowerComponent implements OnChanges {
  @Input()
  allCrewMembers: ManpowerCrewMember[] = [];
  @Input() crewName = '';
  @Input() allMembersOnlineStatus: OnlineStatus[] = [];
  @Input() crewMembersloaded = false;

  filteredCrewMembers: ManpowerCrewMember[] = [];
  exceptionChecked = false;
  EmployeeShiftStatus = EmployeeShiftStatus;

  ngOnChanges() {
    this.filteredCrewMembers = this.allCrewMembers;
  }

  exceptionClick() {
    if (!this.exceptionChecked) {
      this.filteredCrewMembers = this.allCrewMembers;
      return;
    }
    this.filteredCrewMembers = this.allCrewMembers.filter(
      (x) => x.codes?.length
    );
  }
}
