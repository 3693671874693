import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IMMUTABLE_CONTENT_FEATURE_KEY } from '@lm-apps/lmo/ui/data';
import { ImmutableState } from './immutable.interface';

export const getImmutableState = createFeatureSelector<ImmutableState>(
  IMMUTABLE_CONTENT_FEATURE_KEY
);

export const selectDefferalMels = createSelector(
  getImmutableState,
  (state) => state.deferralMels
);
