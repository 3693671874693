import { Injectable } from '@angular/core';
import * as data from '@lm-apps/lmo/ui/data';
import { gql, Mutation } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class UserAcknowledgeNoticeGQL extends Mutation<
  Pick<data.Mutation, 'UserAcknowledgeNotice'>,
  data.MutationUserAcknowledgeNoticeArgs
> {
  override document = gql`
    mutation UserAcknowledgeNotice($noticeId: String!) {
      UserAcknowledgeNotice(noticeId: $noticeId)
    }
  `;
}
