import { createAction, props } from '@ngrx/store';
import { AlternateTasksSortDetail, AlternateTask } from '@lm-apps/lmo/ui/data';

export const init = createAction(
  '[AlternateTasks] Init',
  props<{ stations: string[] }>()
);

export const throwError = createAction(
  '[AlternateTasks/API] API request failed',
  props<{ error: any }>()
);

export const loadAlternateTasksSuccess = createAction(
  '[AlternateTasks/API] Load alternate tasks success',
  props<{ alternateTasks: AlternateTask[] }>()
);

export const loadAlternateTaskDetailsSuccess = createAction(
  '[AlternateTasks/API] Load alternate task details success',
  props<{ alternateTasks: AlternateTask[] }>()
);

export const setSelectedAlternateTask = createAction(
  '[AlternateTasks] Set Selected Alternate Task',
  props<{ alternateTask: AlternateTask }>()
);

export const resetSelectedAlternateTask = createAction(
  '[AlternateTasks] Reset Selected Alternate Task'
);

export const setSortDetail = createAction(
  '[AlternateTasks] Update sort details',
  props<{ sortDetail: AlternateTasksSortDetail }>()
);
