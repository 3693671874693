import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlightsEntity } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-bow-details-aircraft-attributes',
  templateUrl: './bow-details-aircraft-attributes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BowDetailsAircraftAttributesComponent {
  @Input({ required: true }) flight!: FlightsEntity;
  @Input({ required: true }) safeConfigURL!: string;
}
