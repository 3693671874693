import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BowItemStatus } from '@lm-apps/lmo/shared/enums';
import { TaskEntity, TaskEvent, User } from '@lm-apps/lmo/ui/data';

@Component({
  selector: 'lm-apps-lmo-ui-cc-view-bow-items',
  templateUrl: './bow-items.component.html',
  styleUrl: './bow-items.component.scss',
})
export class BowItemsComponent {
  @Input({ required: true }) public bowItemsList!: TaskEntity[];
  @Input() public isMyTasks = false;
  @Input() user: User | null = null;

  @Output() selectedTask = new EventEmitter<TaskEvent>();
  @Output() updatedTaskState = new EventEmitter<TaskEntity>();

  public readonly listOfBowItemStatuses = BowItemStatus;
  public readonly completedBowItemTooltipText =
    'The Accountability Report must be printed from SAFE';

  public openBowDetailsView(bowItemEvent: TaskEvent): void {
    this.selectedTask.emit(bowItemEvent);
  }

  updateTaskState(task: TaskEntity) {
    this.updatedTaskState.emit(task);
  }
}
