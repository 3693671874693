import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PingAuthenticationModule } from '@aa-techops-ui/ping-authentication';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FeatureFlagsModule } from '@aa-techops-ui/feature-flags';
import { StoreModule } from '@ngrx/store';
import { LmoUiDataAccessUserModule } from '@lm-apps/lmo/ui/data-access-user';
import { LmoUiDataAccessAlternateTasksModule } from '@lm-apps/lmo/ui/data-access-alternate-tasks';
import { LmoUiDataAccessFlightModule } from '@lm-apps/lmo/ui/data-access-flight';
import { LmoUiDataAccessStationsModule } from '@lm-apps/lmo/ui/data-access-stations';
import { LmoUiDataAccessHistoryModule } from '@lm-apps/lmo/ui/data-access-history';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { LmoSharedCommonModule } from '@lm-apps/lmo/shared/common';
import {
  GraphQLModule,
  LmoUiCommonServicesModule,
} from '@lm-apps/lmo/ui/common/services';
import { DateInterceptor } from './interceptor/date-interceptor';
import { HomeModule } from './home/home.module';
import { LmoUiFeatureHeaderModule } from '@lm-apps/lmo/ui/feature-header';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { getAppProfile } from './utils/get-app-profile';
import { LmoUiDataAccessCrewMemberStatusModule } from '@lm-apps/lmo/ui/data-access-crew-member-status';
import { LmoUiDataAccessNoticesModule } from '@lm-apps/lmo/ui/data-access-notices';
import { LmoUiFeatureNoticesModule } from '@lm-apps/lmo/ui/feature-notices';
import { LmoUiDataAccessFeatureFlagModule } from '@lm-apps/lmo/ui/data-access-feature-flag';
import { LmoUiDataAccessCrewManpowerModule } from '@lm-apps/lmo/ui/data-access-crew-manpower';
import * as DT from '@dynatrace/dtrum-api-types';
export class DTErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    // Report the error
    window.dtrum?.reportError(error);
    console.error(error);
  }
}
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    PingAuthenticationModule.forRoot({
      post_logout_redirect_uri: window.location.origin,
      app_role_attribute: 'roles',
      set_app_profile: getAppProfile,
    }),
    LmoUiDataAccessNoticesModule,
    LmoUiDataAccessUserModule,
    LmoUiDataAccessAlternateTasksModule,
    LmoUiCommonServicesModule,
    LmoUiDataAccessFlightModule,
    LmoUiDataAccessHistoryModule,
    LmoUiDataAccessCrewMemberStatusModule,
    LmoUiFeatureNoticesModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    AppRoutingModule,
    FeatureFlagsModule.forRoot({
      appName: 'LMO',
      envName: 'stage',
    }),
    GraphQLModule,
    LmoSharedCommonModule,
    LmoUiFeatureHeaderModule,
    LmoUiDataAccessStationsModule,
    HomeModule,
    BrowserAnimationsModule,
    LmoUiDataAccessFeatureFlagModule,
    LmoUiDataAccessCrewManpowerModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: DTErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
