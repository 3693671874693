import { CREW_FEATURE_KEY, SOCKETIO_UPDATED } from '@lm-apps/lmo/ui/data';
import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './crew.actions';
import { CrewState } from './crew.interfaces';
import cloneDeep from 'lodash/cloneDeep';
import { CrewBroadcastEvent } from '@lm-apps/lmo/shared/enums';
export interface CrewPartialState {
  readonly [CREW_FEATURE_KEY]: CrewState;
}
export const initialState: CrewState = {
  crews: [],
  allCrewMembers: [],
  selectedCrewId: null,
  selectedStation: undefined,
  loaded: false,
  error: null,
};
export const crewReducer = createReducer(
  initialState,
  on(actions.throwError, (state, { error }) => ({
    ...state,
    loaded: false,
    error: error,
  })),
  on(actions.updateSelectedStation, (state, { station }) => ({
    ...state,
    selectedStation: station,
  })),
  on(actions.setSelectedCrew, (state, { crew }) => ({
    ...state,
    selectedCrewId: crew ? crew.Id : null,
  })),
  on(actions.loadCrewMembersByStationSuccess, (state, { crewMembers }) => ({
    ...state,
    allCrewMembers: crewMembers,
    loaded: true,
    error: null,
  })),
  on(actions.loadAvailableCrewsSuccess, (state, { crews }) => ({
    ...state,
    crews: crews,
    loaded: true,
    error: null,
  })),
  on(actions.loadUpsertCrewSuccess, (state, { crew }) => {
    const updatedCrews = state.crews
      .filter((c) => c.Id !== crew.Id)
      .concat(crew);
    return {
      ...state,
      crews: updatedCrews,
      loaded: true,
      error: null,
    };
  }),
  on(actions.updateSelectedCrewId, (state, { crewId }) => ({
    ...state,
    selectedCrewId: crewId,
  })),

  on(actions.updateCrewSignalr, (state, { event, crew }) => {
    const updatedState = cloneDeep(state);
    const selectedCrew = updatedState.crews.find((cr) => cr.Id == crew.Id);

    if (selectedCrew) {
      if (event == CrewBroadcastEvent.TAKE_OWNERSHIP) {
        selectedCrew.CurrentOwner = crew.CurrentOwner;
        selectedCrew.Updated = new Date(crew.Updated);
        if (updatedState.selectedCrewId === crew.Id) {
          updatedState.selectedCrewId = SOCKETIO_UPDATED;
        }
      }
      if (event == CrewBroadcastEvent.RELEASE_OWNERSHIP) {
        selectedCrew.CurrentOwner = crew.CurrentOwner;
        selectedCrew.Updated = new Date(crew.Updated);
        updatedState.selectedCrewId = updatedState.selectedCrewId
          ? updatedState.selectedCrewId
          : crew.Id;
      }
      if (event == CrewBroadcastEvent.NO_SHARE) {
        updatedState.crews = updatedState.crews.filter(
          (x) => x != selectedCrew
        );
        if (updatedState.selectedCrewId === crew.Id) {
          updatedState.selectedCrewId = SOCKETIO_UPDATED;
        }
      }
    }

    return updatedState;
  }),
  on(actions.removeCrew, (state, { crewId }) => {
    const updatedCrews = state.crews.filter((c) => c.Id !== crewId);
    return {
      ...state,
      crews: updatedCrews,
      loaded: true,
      error: null,
    };
  })
);

export function reducer(state: CrewState | undefined, action: Action) {
  return crewReducer(state, action);
}
