import dayjs from 'dayjs';

// Do not use this method for saving data back to DB. This is only for reading the time after adjustment. It alters the underlying
// UTC datetime
export const offsetDateTime = (
  date?: Date | null,
  offsetSeconds?: number | null,
  format?: string
): Date | string | null => {
  const offsetMinutes = offsetSeconds ? offsetSeconds / 60 : 0;
  let offset: Date | string | null = null;

  date = typeof date == 'string' ? new Date(date) : date; //this conversion is needed even though date is of type Date

  if (date) {
    if (format) {
      offset = dayjs(date.toUTCString())
        .add(date.getTimezoneOffset() + offsetMinutes, 'minutes')
        .format(format);
    } else {
      offset = dayjs(date.toUTCString())
        .add(date.getTimezoneOffset() + offsetMinutes, 'minutes')
        .toDate();
    }
  }
  return offset;
};

// Do not use this method for saving data back to DB. These are helpers created to read the time after adjustment. It alters the underlying
// UTC datetime
export const offsetDateTimeDayjs = (
  date?: Date | null,
  offsetSeconds?: number | null
): dayjs.Dayjs | null => {
  const offsetMinutes = offsetSeconds ? offsetSeconds / 60 : 0;
  let offset: dayjs.Dayjs | null = null;

  date = typeof date == 'string' ? new Date(date) : date;

  if (date) {
    offset = dayjs(date.toUTCString()).add(
      date.getTimezoneOffset() + offsetMinutes,
      'minutes'
    );
  }
  return offset;
};
